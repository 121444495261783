import { Icon } from "office-ui-fabric-react";
import * as React from "react";
import { IHub } from "../../models/Hub";

const DEFAULT_HUB_IMAGE = "assets/bimtrack-empty-hub.svg";

interface HubItemProps {
    hub: IHub;
    setSelectedHub: (hub: IHub) => void;
}
function HubItemEach({ hub, setSelectedHub }: HubItemProps) {
    const hubImage = hub.image?.Url || DEFAULT_HUB_IMAGE;

    return (
        <div onClick={() => setSelectedHub(hub)} className="hub-selector-dashboard-item">
            <div className="hub-selector-dashboard-item-left">
                <div className="hub-selector-dashboard-image-wrapper">
                    <img className="hub-selector-dashboard-image" src={hubImage} />
                </div>
                <div className="hub-selector-dashboard-title">{hub.name}</div>
            </div>

            <Icon className="hub-selector-dashboard-arrow" iconName="ChevronRightSmall" />
        </div>
    );
}

export default HubItemEach;
