import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import LabelComponent from "../shared/label/LabelComponent";
import ProgressComponent from "../shared/progress/ProgressComponent";
import EmailListRowItemComponent from "./emailListRowItem/EmailListRowItemComponent";
import "./FileMultipleEmailComponent.less";
import SelectAllLabel from "../shared/label/SelectAllLabel";
import { EmailListItemWithSelectedStatus } from "./FileMultipleEmailComponent";
import DebouncedSearchComponent from "../debouncedSearch/DebouncedSearchComponent";
import NoResultsFoundComponent from "../projectEmail/NoResultsFoundComponent/NoResultsFoundComponent";

interface EmailBodyProps extends LocalizeContextProps {
    isLoadingFolderName: boolean;
    isLoadingEmails: boolean;
    emailOffsetToken: string;
    searchTerm: string;
    isFiling: boolean;
    folderName: string;
    isLoadingMoreEmails: boolean;
    emails: EmailListItemWithSelectedStatus[];
    folderId: string | null;
    loadEmails: (isLoadingMore: boolean, searchValue: string, offsetToken: string) => Promise<void>;
    onSelectAllChanged(event?: React.FormEvent<HTMLElement>, checked?: boolean): void;
    areAllItemsSelected: (emails: EmailListItemWithSelectedStatus[]) => boolean;
    onEmailSearchClear: () => Promise<void>;
    onEmailSearch: (searchValue?: string) => Promise<void>;
    onEmailSelectionStateChanged(id: string, checked: boolean): void;
}

function EmailBodyComponent(props: EmailBodyProps): JSX.Element {
    async function fetchNext() {
        const { isLoadingMoreEmails, searchTerm, emailOffsetToken } = props;
        await props.loadEmails(isLoadingMoreEmails, searchTerm, emailOffsetToken);
    }

    const getLoadingIndicator = (): JSX.Element => (
        <>
            {props.isLoadingMoreEmails || props.isLoadingEmails || props.isLoadingFolderName ? (
                <ProgressComponent
                    className="newforma-loadingEmailsIndicator"
                    message={props.translate("FILE_MULTIPLE_EMAIL.LOADING_EMAILS") as string}
                />
            ) : null}
        </>
    );

    const renderEmailRows = (): JSX.Element => {
        const emailItems = props.emails.map((email, index) => (
            <EmailListRowItemComponent
                key={`${email.id}${index}`}
                disabled={props.isFiling}
                subject={email.subject ? email.subject : (props.translate("FILE_MULTIPLE_EMAIL.NO_SUBJECT") as string)}
                from={email.from}
                preview={email.bodyPreview}
                id={email.id}
                isFiled={email.isFiled}
                categories={email.categories}
                isSelected={email.isSelected}
                sentDateTime={email.sentDateTime}
                onSelectionStateChanged={props.onEmailSelectionStateChanged}
            />
        ));

        return <>{emailItems}</>;
    };

    const renderEndMessage = (): JSX.Element => {
        if (!props.folderName) {
            return <div className="newforma-endOfListMessage">{props.translate("APP.INVALID_EMAIL") as string}</div>;
        }
        return (
            <div className="newforma-endOfListMessage">
                {props.translate("FILE_MULTIPLE_EMAIL.END_OF_LIST") as string}
            </div>
        );
    };

    return (
        <div className="email-body">
            <DebouncedSearchComponent
                className="newforma-searchBox"
                onTextChange={props.onEmailSearch}
                onClear={props.onEmailSearchClear}
                placeholder={props.translate("FILE_MULTIPLE_EMAIL.SEARCH_BOX_PLACEHOLDER") as string}
                dataTestId={"searchBox"}
            />
            <div className={`newforma-multipleEmailListHeader ${props.isLoadingFolderName ? "hidden" : ""}`}>
                <SelectAllLabel
                    id="selectAll-checkbox"
                    className={"ms-Label newforma-selectEmailHeader-checkbox"}
                    onSelectAllChanged={props.onSelectAllChanged}
                    areAllItemsSelected={props.areAllItemsSelected(props.emails)}
                />
                <LabelComponent className="newforma-folderLabel" text={props.folderName} />
            </div>
            <div id="scrollableEmailList" className="newforma-scrollableEmailList">
                {props.isLoadingEmails || props.isLoadingFolderName ? (
                    getLoadingIndicator()
                ) : props.emails.length === 0 ? (
                    <div className="newforma-noResultsContainer">
                        <NoResultsFoundComponent />
                    </div>
                ) : (
                    <InfiniteScroll
                        className="newforma-infiniteScroll"
                        scrollableTarget="scrollableEmailList"
                        pullDownToRefreshThreshold={50}
                        height={"calc(100vh - 200px)"}
                        dataLength={props.emails.length}
                        next={fetchNext}
                        hasMore={!!props.emails.length && !!props.emailOffsetToken}
                        loader={getLoadingIndicator()}
                        endMessage={renderEndMessage()}
                    >
                        {renderEmailRows()}
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
}

export default withLocalize(EmailBodyComponent);
