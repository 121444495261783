import { DefaultButton, ITag, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";
import { useState } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import useSubmittalReviewResponse from "../../hooks/useSubmittalReviewResponse";
import { IHub } from "../../models/Hub";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { FileUploadApiService } from "../../services/PimTrackApi/FileUploadApiService";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { RelatedItemApiService } from "../../services/PimTrackApi/RelatedItemApiService";
import { SubmittalApiService } from "../../services/PimTrackApi/SubmittalApiService";
import { ValueListApiService } from "../../services/PimTrackApi/ValueListApiService";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import ContactDropdownComponent from "../shared/field/contactDropdown/ContactDropdownComponent";
import DropdownComponent from "../shared/field/dropdown/DropdownComponent";
import { DEFAULT_TEXT_FIELD_ROWS, LONG_FIELD_LENGTH, MULTI_DROPDOWN_ONE_ITEM } from "../shared/field/fieldConstants";
import TextFieldComponent from "../shared/field/inputText/TextFieldComponent";
import ProjectItemPickerComponent from "../shared/field/projectItemPicker/ProjectItemPickerComponent";
import "./SubmittalReviewResponseComponent.less";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { SupportedAddinItems } from "../../models/SupportedItems";
import { ToastMessage } from "../../models/ToastMessage";

export interface SubmittalReviewResponseComponentProps extends LocalizeContextProps {
    officeWrapper: OfficeWrapper;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    selectedHub: IHub | null;
    selectedProject: ITag | null;
    showProgress: (message: string | null) => void;
    submittalApiService: SubmittalApiService;
    formValidationHelpers: FormValidationHelpers;
    valueListApiServices: ValueListApiService;
    fileUploadApiService: FileUploadApiService;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
}
function SubmittalReviewResponseComponent(props: SubmittalReviewResponseComponentProps) {
    const [isCcOpen, setIsCcOpen] = useState<boolean>(false);

    const {
        submittalReviewerResponseForm,
        updateField,
        userOptions,
        responseOptions,
        onForwardSubmittalInputChange,
        isSubmittalReviewResponseFormValid,
        submitSubmittalReviewResponse,
        isFieldDisabled,
    } = useSubmittalReviewResponse({
        officeWrapper: props.officeWrapper,
        valueListApiServices: props.valueListApiServices,
        selectedHub: props.selectedHub,
        selectedProject: props.selectedProject,
        translate: props.translate,
        onShowToast: props.onShowToast,
        showProgress: props.showProgress,
        submittalApiService: props.submittalApiService,
        setIsCcOpen,
        fileUploadApiService: props.fileUploadApiService,
        emailApiService: props.emailApiService,
        relatedItemApiService: props.relatedItemApiService,
        officeRoamingSettings: props.officeRoamingSettings,
        supportedAddinItems: props.supportedAddinItems,
    });

    return (
        <div className={"submittalReviewResponseComponent"} data-testid="submittalReviewResponseComponent">
            <div className={"submittalReviewResponseForm"}>
                <ProjectItemPickerComponent
                    className="newforma-formSpacing submittalForwardedSubmittalField"
                    required
                    disabled={isFieldDisabled}
                    onChange={(selectedItems) => {
                        updateField({ submittalId: selectedItems ? selectedItems[0] : null });
                    }}
                    onResolveSuggestions={(filter, _) => onForwardSubmittalInputChange(filter)}
                    onEmptyInputFocus={() => onForwardSubmittalInputChange()}
                    label={props.translate("SUBMITTALS.SUBMITTAL_DROPDOWN.LABEL") as string}
                    inputPlaceholder={props.translate("SUBMITTALS.SUBMITTAL_DROPDOWN.PLACEHOLDER") as string}
                    selectedItems={submittalReviewerResponseForm.submittalId}
                />

                <DropdownComponent
                    className="newforma-formSpacing submittalResponseField"
                    options={responseOptions}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                    label={props.translate("SUBMITTALS.RESPONSE.LABEL") as string}
                    disabled={isFieldDisabled || !responseOptions.length}
                    onChange={(option) => {
                        updateField({ response: option });
                    }}
                    selectedOption={submittalReviewerResponseForm.response}
                    required
                />

                <ContactDropdownComponent
                    className="submittalFromField newforma-formSpacing"
                    label={props.translate("SHARED.FROM_AND_TO.FROM_LABEL") as string}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                    formValidationHelpers={props.formValidationHelpers}
                    required
                    items={userOptions}
                    itemLimit={MULTI_DROPDOWN_ONE_ITEM}
                    disabled={isFieldDisabled || !userOptions.length}
                    selectedContacts={submittalReviewerResponseForm.from}
                    onChange={(selectedItem) => {
                        updateField({ from: selectedItem });
                    }}
                    allowCustomInput
                />

                <div className="submittalToFieldRow">
                    <ContactDropdownComponent
                        className="submittalToField newforma-formSpacing"
                        label={props.translate("SHARED.FROM_AND_TO.TO_LABEL") as string}
                        placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                        formValidationHelpers={props.formValidationHelpers}
                        required
                        items={userOptions}
                        disabled={isFieldDisabled || !userOptions.length}
                        selectedContacts={submittalReviewerResponseForm.to}
                        onChange={(selectedItem) => {
                            updateField({ to: selectedItem });
                        }}
                    />
                    {!isCcOpen && (
                        <div
                            className="ccButton"
                            data-testid="ccButton"
                            onClick={() => {
                                setIsCcOpen(!isCcOpen);
                            }}
                        >
                            {props.translate("SHARED.FROM_AND_TO.ADD_CC") as string}
                        </div>
                    )}
                </div>

                {isCcOpen && (
                    <ContactDropdownComponent
                        className="submittalCcField newforma-formSpacing"
                        label={props.translate("SHARED.FROM_AND_TO.CC_LABEL") as string}
                        placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                        formValidationHelpers={props.formValidationHelpers}
                        required={false}
                        items={userOptions}
                        disabled={isFieldDisabled || !userOptions.length}
                        selectedContacts={submittalReviewerResponseForm.cc}
                        onChange={(selectedItem) => {
                            updateField({ cc: selectedItem });
                        }}
                    />
                )}

                <TextFieldComponent
                    className="newforma-formSpacing submittalRemarksField"
                    label={props.translate("SUBMITTALS.REMARKS.LABEL") as string}
                    placeholder={props.translate("SUBMITTALS.REMARKS.PLACEHOLDER") as string}
                    multiline
                    resizable
                    value={submittalReviewerResponseForm.remarks}
                    onChange={(newValue) => {
                        updateField({ remarks: newValue });
                    }}
                    maxLength={LONG_FIELD_LENGTH}
                    rows={DEFAULT_TEXT_FIELD_ROWS}
                    required={false}
                    disabled={isFieldDisabled}
                />

                <AttachmentsComponent
                    attachments={submittalReviewerResponseForm.attachments}
                    disabled={isFieldDisabled}
                    onAttachmentSelectionChange={(attachmentIds) => {
                        updateField({ selectedAttachmentIds: attachmentIds });
                    }}
                    allowFileUploads={false}
                />
            </div>

            <div id="footer" key="footer" className="newforma-footer">
                <DefaultButton
                    key="submittalReviewResponseSubmitButton"
                    className="newforma-footerButton"
                    id="submittalReviewResponseButton"
                    primary={true}
                    onClick={submitSubmittalReviewResponse}
                    text={props.translate("SUBMITTALS.SUBMIT_BUTTON.RESPONSE") as string}
                    disabled={isFieldDisabled || !isSubmittalReviewResponseFormValid}
                />
            </div>
        </div>
    );
}

export default withLocalize(SubmittalReviewResponseComponent);
