import { IPersonaProps } from "office-ui-fabric-react";
import { ContactMapping } from "../mapping/ContactMapping";
import { useAppService } from "../services/AppServiceProvider";
import { useAppState } from "../store/AppProvider";
import { useQuery } from "@tanstack/react-query";

interface ProjectUsersHookReturnType {
    users: IPersonaProps[];
    loading: boolean;
    error: boolean;
}

export const useProjectUsers = (): ProjectUsersHookReturnType => {
    const {
        services: { valueListApiService },
    } = useAppService();

    const {
        appState: { selectedHub, selectedProject },
    } = useAppState();

    const { isFetching, isError, data } = useQuery(
        ["getProjectUsers", selectedHub?.key, selectedProject?.key],
        async () => {
            if (!selectedHub || !selectedProject) {
                return;
            }

            const bimtrackContacts = await valueListApiService.getBimTrackUser(selectedHub, selectedProject);

            const mapperUser = (bimtrackContacts ?? [])
                .map(ContactMapping.mapResponseToIPersonaProps)
                .sort((contact1, contact2) => (contact1.text ?? "").localeCompare(contact2.text ?? ""));

            return mapperUser;
        },
        {
            enabled: !!selectedHub && !!selectedProject,
        }
    );

    return {
        users: data ?? [],
        loading: isFetching,
        error: isError,
    };
};
