export interface CreateProjectTeamMembersRequest {
    operations: CreateProjectTeamMemberOperation[];
}

export interface CreateProjectTeamMemberOperation {
    op: CreateProjectTeamMemberOps;
    params: { email?: string; nrn?: string };
}

export enum CreateProjectTeamMemberOps {
    add = "add",
    remove = "remove",
}
