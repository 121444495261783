import { OfficeWrapper } from "../../services/OfficeWrapper";
import AsyncResult = Office.AsyncResult;
import Dialog = Office.Dialog;
import { ConfigurationService } from "../../services/ConfigurationService";
import { Logger } from "../../services/Logger";

export default class LoginDialog {
    constructor(private office: OfficeWrapper, private configService: ConfigurationService, private logger: Logger) {}

    /**
     *
     * @param loginUrl
     * @param promptBeforeOpen This prevents the "Allow popup" dialog from showing. This COULD cause some popup blockers to
     * block the login dialog, but so far in testing, it hasn't made a difference except when refreshing tokens,
     * because then it pops up without any user action triggering it. It seems that as long as the popup opens in
     * response to a user click, it is ok.
     */
    async openAuthDialog(loginUrl: string, promptBeforeOpen: boolean): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.office.displayDialogAsync(
                `${this.configService.originURL}/index.html?redirectUrl=${encodeURIComponent(loginUrl)}#loginrouting`,
                {
                    height: 50,
                    width: 50,
                    promptBeforeOpen: promptBeforeOpen,
                },
                (asyncResult: AsyncResult<Dialog>) => {
                    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                        reject(asyncResult.error);
                        return;
                    }

                    const dialog = asyncResult.value;

                    /* Messages are sent by developers programmatically from the dialog using office.context.ui.messageParent(...)*/
                    /* Events are sent by the platform in response to user actions or errors. For example, the dialog is closed via the 'x' button*/
                    dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                        try {
                            const jsonResult = JSON.parse(arg.message);
                            if (!jsonResult) {
                                reject(new Error("Ill-formatted or missing message"));
                                return;
                            }

                            const url = new URL(jsonResult.value.replace("#", "?"));
                            const code = url.searchParams.get("code");

                            if (!code) {
                                const errorMessage = `Required parameter "code" cannot be found in url : "${jsonResult.value}"`;
                                reject(new Error(errorMessage));
                                return;
                            }

                            resolve(code);
                        } finally {
                            dialog.close();
                        }
                    });

                    dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
                        this.logger.warning(`Login dialog closed unexpectedly\n${JSON.stringify(arg)}`);
                        const errorMessage = `Unexpected error in LoginDialog.eventHandler: ${JSON.stringify(arg)}`;
                        reject(new Error(errorMessage));
                    });
                }
            );
        });
    }
}
