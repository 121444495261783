import * as $ from "jquery";

export class HttpRequestWrapper {
    async get(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: any,
        options?: any
    ) {
        return $.ajax({ url, dataType, headers, data, type: "GET", ...options });
    }

    async post(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: string | FormData,
        options?: any
    ) {
        return $.ajax({ url, dataType, headers, data, type: "POST", ...options });
    }

    async patch(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: string,
        options?: any,
        contentType?: string
    ) {
        return $.ajax({ url, dataType, headers, data, type: "PATCH", ...options, contentType });
    }

    async put(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: string | Blob,
        options?: any
    ) {
        return $.ajax({ url, dataType, headers, data, type: "PUT", ...options });
    }
}
