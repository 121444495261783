// TODO: Write tests in work item: 37549
import { ITag, MessageBarType } from "office-ui-fabric-react";
import { useState, useEffect, useCallback } from "react";
import { SendAndFileComponentProps } from "../components/sendAndFile/SendAndFileComponent";
import { ExpiredSessionError } from "../models/ExpiredSessionError";
import { ProjectBaseInformation } from "../models/ProjectsResponse";
import { ConflictError } from "../models/shared/ConflictError";
import { UnauthorizedError } from "../models/shared/UnauthorizedError";
import { LocalStorageKeys } from "../models/StorageKeys";
import { SuggestedProject } from "../services/SmartFiling/SuggestedProject";
import { normalizeAccessToBaseInfo } from "../utils/normalizer";
import { ProjectEmailSettings } from "@newforma/platform-client-api-sdk/dist/email";
import { AppPage } from "../models/AppPage";
import { IHub } from "../models/Hub";
import { useAppState } from "../store/AppProvider";
import { useAppService } from "../services/AppServiceProvider";

const useSendAndFileHook = (props: SendAndFileComponentProps) => {
    const [isFiling, setIsFiling] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [suggestedProjects, setSuggestedProjects] = useState<SuggestedProject[]>([]);
    const [emailSettings, setEmailSettings] = useState<ProjectEmailSettings>();
    const {
        actions: { setSelectedHub },
    } = useAppState();

    const {
        sendAndFileHelpers,
        selectedHub,
        logger,
        bimProjectsApiService,
        officeWrapper,
        storageWrapper,
        selectedProject,
        smartFilingManager,
        configService,
        translate,
        onShowToast,
        onExpiredSession,
        onSetNavigationPage,
        onProjectSelected,
    } = props;

    const fetchProjectEmailSettings = useCallback(async () => {
        if (!selectedProject || !selectedHub) {
            return null;
        }

        const fetchedEmailSettings = await bimProjectsApiService.fetchProjectEmailSettings(
            selectedHub,
            selectedProject
        );
        setEmailSettings(fetchedEmailSettings);

        return fetchedEmailSettings;
    }, [selectedProject?.key, selectedHub?.key]);

    useEffect(() => {
        setUserInfo();
        loadRememberedHub();
        getSuggestProjects();
        onSetNavigationPage(AppPage.SendAndFile);
    }, []);

    useEffect(() => {
        fetchProjectEmailSettings();
    }, [fetchProjectEmailSettings]);

    const setUserInfo = async (): Promise<void> => {
        const userInfo = await bimProjectsApiService.getUserinfo();
        const outLookUser = officeWrapper.userProfileEmailAddress;

        if (userInfo.email.toLowerCase() !== outLookUser.toLowerCase()) {
            const errorMessage = { message: translate("APP.WRONG_EMAIL") as string };
            onShowToast(errorMessage, MessageBarType.error);
            logger.error("Mismatching User");
            return;
        }

        setIsSubmitDisabled(false);
    };

    const getEmailsToCC = (): Office.EmailAddressDetails[] => {
        if (!emailSettings) {
            logger.error("No EmailSettings Found");
            throw new Error();
        }

        const domainName = `${(selectedHub as IHub).name}.${configService.email}`;

        const emailToCC = [
            {
                emailAddress: `${emailSettings?.projectEmailAddressPrefix}@${domainName}`,
                displayName: `${emailSettings?.projectEmailAddressPrefix}@${domainName}`,
                appointmentResponse: "none",
                recipientType: "externalUser",
            },
        ];

        return emailToCC;
    };

    const handleSubmitSend = async (): Promise<void> => {
        setIsFiling(true);
        try {
            if (emailSettings?.projectEmailAddressPrefix) {
                const emailsToCC = getEmailsToCC();
                await sendAndFileHelpers.addUniqueEmailsCc(emailsToCC);
            }

            await sendAndFileHelpers.updateRecipientsWithProjectEmailAddress({
                name: selectedProject?.name as string,
                nrn: selectedProject?.key as string,
            });
            await sendAndFileHelpers.sendAndFile(selectedHub as IHub, selectedProject as ITag);

            const successMessage = {
                message: (translate("SEND_AND_FILE.MARKED_FOR_FILE_TOAST") as string).replace(
                    /\[\[project-name]]/gi,
                    selectedProject?.name as string
                ),
            };
            onShowToast(successMessage, MessageBarType.success);
            setIsFiling(false);
        } catch (error) {
            handleFilingError(error);
        }
    };

    const handleFilingError = (error: unknown) => {
        if (ExpiredSessionError.isInstanceOf(error)) {
            onExpiredSession();
            logger.error(`ExpiredSessionError: ${JSON.stringify(error)}`);
            setIsFiling(false);
        } else if (UnauthorizedError.isInstanceOf(error)) {
            setIsFiling(false);
            logger.error(`UnauthorizedError: ${JSON.stringify(error)}`);
            onShowToast({ message: translate("SEND_AND_FILE.UNAUTHORIZED_ERROR") as string }, MessageBarType.error);
        } else if (ConflictError.isInstanceOf(error)) {
            console.error(error);
            setIsFiling(false);
            logger.error(`ConflictError: ${JSON.stringify(error)}`);
            onShowToast({ message: translate("SEND_AND_FILE.CONFLICT_ERROR") as string }, MessageBarType.error);
        } else {
            console.error(error);
            setIsFiling(false);
            logger.error(`Error: ${JSON.stringify(error)}`);
            onShowToast({ message: translate("SEND_AND_FILE.ERROR") as string }, MessageBarType.error);
        }
    };

    const getSuggestProjects = async (): Promise<void> => {
        if (!selectedHub) {
            return;
        }

        try {
            const { items: projectsWithEmailAccess } = await bimProjectsApiService.getProjectsEmailFilingAccess(
                selectedHub
            );

            logger.info(`Fetched Email Projects: ${JSON.stringify(projectsWithEmailAccess)}`);

            const projectBaseInfo: ProjectBaseInformation[] = normalizeAccessToBaseInfo(
                projectsWithEmailAccess,
                translate
            );
            const totalSuggestionResults: SuggestedProject[] = await smartFilingManager.getSuggestedProjects(
                projectBaseInfo
            );

            if (totalSuggestionResults.length > 0) {
                onProjectSelected({
                    name: totalSuggestionResults[0].name,
                    key: totalSuggestionResults[0].nrn,
                    projectNumber: totalSuggestionResults[0].number,
                } as ITag);
            }

            setSuggestedProjects(totalSuggestionResults);
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                logger.error(`ExpiredSessionError: ${JSON.stringify(error)}`);
                onExpiredSession();
            }
            logger.error(`getSuggestProjects: ${JSON.stringify(error)}`);
            throw new Error(`getSuggestProjects: ${error}`);
        }
    };

    const loadRememberedHub = (): void => {
        const cachedHub = storageWrapper.loadLocalStorage(LocalStorageKeys.hub);

        if (cachedHub) {
            setSelectedHub(JSON.parse(cachedHub) as IHub);
        }
    };

    const isFormValid = (): boolean => !!selectedHub && !!selectedProject;

    const onHubSelected = (hub: IHub | null): IHub | null => {
        if (!hub) {
            setSelectedHub(null);
            return null;
        }
        setSelectedHub(hub);
        return hub;
    };

    return {
        actions: {
            handleSubmitSend,
            onProjectSelected,
            isFormValid,
            onHubSelected,
        },
        isSubmitDisabled,
        isFiling,
        suggestedProjects,
        selectedProject,
        emailSettings,
    };
};

export default useSendAndFileHook;
