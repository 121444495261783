import { DefaultButton, Icon, ITag, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { DateHelpers } from "../../helpers/DateHelpers";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { FileUploadApiService } from "../../services/PimTrackApi/FileUploadApiService";
import { RelatedItemApiService } from "../../services/PimTrackApi/RelatedItemApiService";
import { RfiApiService } from "../../services/PimTrackApi/RfiApiService";
import { ValueListApiService } from "../../services/PimTrackApi/ValueListApiService";
import WorkflowItemSuccessComponent from "../shared/itemSuccess/ItemSuccessComponent";
import RfiComponent from "./RfiComponent";
import "./RfiComponentWrapper.less";
import RfiReviewResponseComponent from "./RfiReviewResponseComponent";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { useWorkflowItem } from "../shared/itemSuccess/ItemContext";
import { SupportedAddinItems } from "../../models/SupportedItems";
import { ToastMessage } from "../../models/ToastMessage";
import { BimProjectsApiService } from "../../services/BimApi/BimProjectsApiService";

export interface RfiComponentWrapperProps extends LocalizeContextProps {
    formValidationHelpers: FormValidationHelpers;
    officeWrapper: OfficeWrapper;
    dateHelpers: DateHelpers;
    rfiApiService: RfiApiService;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    valueListApiServices: ValueListApiService;
    selectedProject: ITag | null;
    selectedHub: ITag | null;
    showProgress: (message: string | null) => void;
    fileUploadApiService: FileUploadApiService;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    bimProjectsApiService: BimProjectsApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
}

export enum RfiTabOptions {
    LogRfi = "rfi-new",
    SubmitResponse = "rfi-response",
}

function RfiComponentWrapper(props: RfiComponentWrapperProps) {
    const [selectedRFITag, setSelectedRFITag] = useState<RfiTabOptions>(RfiTabOptions.LogRfi);
    const {
        actions: { setSuccessInfo, setIsEmailFromDeletion },
        states: { successInfo, isEmailFromDeletion },
    } = useWorkflowItem();

    useEffect(() => {
        // Set successInfo to undefined when email itemId changed
        if (successInfo) {
            // If email was deleted previously reset IsEmailFromDeletion to false
            // So on the next props.officeWrapper.currentContextItem.itemId change, it will hide success screen
            if (isEmailFromDeletion) {
                setIsEmailFromDeletion(false);
            } else {
                setSuccessInfo(undefined);
            }
        }
    }, [props.officeWrapper.currentContextItem.itemId]);

    const handleRFITabSelect = (item: RfiTabOptions) => {
        setSelectedRFITag(item);
    };

    const handleButtonClassNew = selectedRFITag === RfiTabOptions.LogRfi ? "button-selected" : "button-unselected";
    const handleButtonClassResponse =
        selectedRFITag === RfiTabOptions.SubmitResponse ? "button-selected" : "button-unselected";

    return (
        <div className="rficomponent-wrapper">
            {successInfo ? (
                <WorkflowItemSuccessComponent successInfo={successInfo} />
            ) : (
                <>
                    <div className="tabs">
                        <DefaultButton
                            className={`button-left ${handleButtonClassNew}`}
                            onClick={() => handleRFITabSelect(RfiTabOptions.LogRfi)}
                            allowDisabledFocus
                        >
                            <Icon className="add-icon" iconName="Add" />
                            {props.translate("SHARED.LOG_NEW")}
                        </DefaultButton>
                        <DefaultButton
                            className={`button-right ${handleButtonClassResponse}`}
                            onClick={() => handleRFITabSelect(RfiTabOptions.SubmitResponse)}
                            allowDisabledFocus
                        >
                            <Icon className="file-icon" iconName="FileBack" />
                            {props.translate("SHARED.LOG_RESPONSE")}
                        </DefaultButton>
                    </div>
                    {selectedRFITag === RfiTabOptions.LogRfi ? (
                        <RfiComponent
                            formValidationHelpers={props.formValidationHelpers}
                            officeWrapper={props.officeWrapper}
                            dateHelpers={props.dateHelpers}
                            onShowToast={props.onShowToast}
                            selectedHub={props.selectedHub}
                            selectedProject={props.selectedProject}
                            valueListApiServices={props.valueListApiServices}
                            showProgress={props.showProgress}
                            rfiApiService={props.rfiApiService}
                            fileUploadApiService={props.fileUploadApiService}
                            emailApiService={props.emailApiService}
                            relatedItemApiService={props.relatedItemApiService}
                            bimProjectsApiService={props.bimProjectsApiService}
                            officeRoamingSettings={props.officeRoamingSettings}
                            supportedAddinItems={props.supportedAddinItems}
                            navigateBackToForm={() => setSuccessInfo(undefined)}
                        />
                    ) : (
                        <RfiReviewResponseComponent
                            formValidationHelpers={props.formValidationHelpers}
                            officeWrapper={props.officeWrapper}
                            onShowToast={props.onShowToast}
                            selectedHub={props.selectedHub}
                            selectedProject={props.selectedProject}
                            valueListApiServices={props.valueListApiServices}
                            showProgress={props.showProgress}
                            rfiApiService={props.rfiApiService}
                            fileUploadApiService={props.fileUploadApiService}
                            emailApiService={props.emailApiService}
                            relatedItemApiService={props.relatedItemApiService}
                            officeRoamingSettings={props.officeRoamingSettings}
                            supportedAddinItems={props.supportedAddinItems}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default withLocalize(RfiComponentWrapper);
