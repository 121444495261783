import { RoamingSettings } from "../models/RoamingSettings";
import FiledProject from "../models/FiledProject";
import { OfficeWrapper } from "./OfficeWrapper";
import * as _ from "lodash";
import { Email } from "@newforma/platform-client-api-sdk";

import { Logger } from "./Logger";

export class OfficeRoamingSettings {
    constructor(private officeWrapper: OfficeWrapper, private logger: Logger) {}

    async delete(deleteKeys: Array<keyof RoamingSettings>): Promise<void> {
        for (const key of deleteKeys) {
            this.officeWrapper.removeRoamingSetting(key);
        }
        await this.officeWrapper.saveRoamingSettings();
    }

    /**
     * There is a limited amount of memory to use for storing settings. Using this method will attempt to shrink the
     * recent projects list if an error occurs when saving the settings. Since there is not a specific error that is
     * thrown when this happens, we have to just assume it is due to having too many settings. If we keep getting
     * the error even after shrinking the project list, it assumes that it was some other error and resets the original
     * project list so that projects aren't lost. There have been quite a few cases that trigger this to happen, so
     * it is important to keep this code around.
     * @param roamingSettings
     * @param [throwOnFailure]
     */
    async saveWithRetry(roamingSettings: RoamingSettings, throwOnFailure = false): Promise<void> {
        this.logger.info("Updating roaming settings");
        const originalFiledProjects = _.cloneDeep(roamingSettings.filedProjects);

        for (const key of Object.keys(roamingSettings) as Array<keyof RoamingSettings>) {
            const value = this.serializeSetting(roamingSettings[key]);
            this.officeWrapper.setRoamingSetting(key, value);
        }

        const maxRetries = 5;
        let saved = false;
        let retries = 0;
        while (!saved && retries < maxRetries) {
            try {
                await this.officeWrapper.saveRoamingSettings();
                saved = true;
            } catch (error) {
                this.logger.error(`Error saving settings: ${JSON.stringify(error)}`);

                const genericErrorCode = 9019;
                if ((error as any).code === genericErrorCode) {
                    this.logger.warning("Trimming recent projects");

                    const filedProjects = roamingSettings.filedProjects || this.getFiledProjects();
                    if (filedProjects && filedProjects.length > 0) {
                        filedProjects.sort(
                            (a, b) =>
                                new Date(a.d || "0001-01-01T00:00:00Z").getTime() -
                                new Date(b.d || "0001-01-01T00:00:00Z").getTime()
                        );
                        filedProjects.shift();
                        this.officeWrapper.setRoamingSetting("filedProjects", this.serializeSetting(filedProjects));
                        retries++;
                    } else {
                        // Don't re-throw error, for now we assume that an error saving settings isn't a big deal, may need to re-think this in the future
                        this.logger.info("Skipping retry since no recent projects being set");
                        break;
                    }
                } else {
                    // Don't re-throw error, for now we assume that an error saving settings isn't a big deal, may need to re-think this in the future
                    this.logger.warning("Unknown error, skipping retry");
                    break;
                }
            }
        }

        if (!saved && originalFiledProjects) {
            // We were never able to save anything, even after trimming the recent projects, so it was probably was failing
            // for a different reason, so just reset the original set of filed projects so we don't lose them
            this.officeWrapper.setRoamingSetting("filedProjects", this.serializeSetting(originalFiledProjects));
            if (throwOnFailure) {
                this.logger.error("Unable to save current roaming settings");
                throw new Error("Unable to save current roaming settings");
            }
        }
    }

    private serializeSetting(setting: any): string {
        return typeof setting === "string" ? setting : JSON.stringify(setting);
    }

    getFiledProjects(): FiledProject[] | null {
        const fileProjects = this.getSetting("filedProjects");

        if (fileProjects) {
            return fileProjects.map((p) => {
                if (p.d) {
                    p.d = new Date(p.d);
                }
                if ((p as any).id) {
                    p.nrn = (p as any).id;
                    delete (p as any).id;
                }
                return p;
            });
        } else {
            return null;
        }
    }

    getPostFilingAction(): Email.PostFilingAction {
        return this.getSetting("postFilingAction") || Email.PostFilingAction.none;
    }

    getDeleteEmailAfterFiling(): boolean {
        return this.getPostFilingAction() === Email.PostFilingAction.delete;
    }

    getFileEmailConversation(): boolean {
        return this.getSetting("fileEmailConversation") === true;
    }

    resetFilingHistory(): Promise<void> {
        return this.delete(["filedProjects"]);
    }

    // this is legacy and not technically needed but by leaving the method in
    // signing out will remove the unused roaming settings for the user freeing up valuable space
    logoutFromApp(): Promise<void> {
        return this.delete(["authorizeUrlTemplate", "refreshUrlTemplate", "credentials"]);
    }

    private getSetting<K extends keyof RoamingSettings>(key: K): RoamingSettings[K] | undefined {
        const roamingSetting = this.officeWrapper.getRoamingSetting(key);

        if (roamingSetting) {
            try {
                return JSON.parse(roamingSetting) as RoamingSettings[K];
            } catch (e) {
                return roamingSetting as RoamingSettings[K];
            }
        }

        return undefined;
    }
}
