import "core-js/stable";
import "regenerator-runtime/runtime";
import { initializeIcons } from "@uifabric/icons";
import "./styles.less";
import { OfficeRoamingSettings } from "./services/OfficeRoamingSettings";
import { EntryPoint } from "./services/EntryPoint";
import LoginDialog from "./components/login/LoginDialog";
import { AnalyticsManager } from "./services/AnalyticsManager";
import { NewformaApiClient } from "./services/NewformaApi/NewformaApiClient";
import { Authenticator } from "./services/Authenticator";
import { FileUploadApiService } from "./services/PimTrackApi/FileUploadApiService";
import { RelatedItemApiService } from "./services/PimTrackApi/RelatedItemApiService";
import { SubmittalApiService } from "./services/PimTrackApi/SubmittalApiService";
import { UnauthenticatedApiClient } from "./services/UnauthenticatedApiClient";
import { SmartFilingManager } from "./services/SmartFiling/SmartFilingManager";
import { ConfigurationService } from "./services/ConfigurationService";
import { OfficeWrapper } from "./services/OfficeWrapper";
import { HttpRequestWrapper } from "./services/HttpRequestWrapper";
import { UserDisplayService } from "./services/UserDisplayService";
import { OutlookApiService } from "./services/OutlookApiService";
import { StorageWrapper } from "./services/StorageWrapper";
import { WindowWrapper } from "./services/WindowWrapper";
import { FormValidationHelpers } from "./helpers/FormValidationHelpers";
import { TranslationService } from "./services/TranslationService";
import { Logger } from "./services/Logger";
import { CustomerSupportEmailService } from "./services/CustomerSupportEmailService";
import { ProjectsApiService } from "./services/NewformaApi/ProjectsApiService";
import { SendAndFileHelpers } from "./helpers/SendAndFile/SendAndFileHelpers";
import { DateHelpers } from "./helpers/DateHelpers";
import { RfiApiService } from "./services/PimTrackApi/RfiApiService";
import { EmailApiService } from "./services/NewformaApi/EmailApiService";
import { BimProjectsApiService } from "./services/BimApi/BimProjectsApiService";
import GainSight from "./services/gainSight/GainSight";
import { ValueListApiService } from "./services/PimTrackApi/ValueListApiService";
import { SubscriptionApiService } from "./services/SubscriptionApiService/SubscriptionApiService";
import { IssueApiService } from "./services/BimApi/IssueApiService";

initializeIcons(); // Function needed to initialize Fabric icons

Office.initialize = () => {
    // NOTE: Keep - required. This also fires before .async()
};
// tslint:disable-next-line
(async () => {
    await Office.onReady();

    const logger = new Logger();
    const requestWrapper = new HttpRequestWrapper();
    const analyticsManager = new AnalyticsManager();
    const formValidationHelpers = new FormValidationHelpers();
    const userDisplayService = new UserDisplayService();
    const dateHelpers = new DateHelpers();

    const windowWrapper = new WindowWrapper(logger);
    const config = new ConfigurationService(logger);

    await config.initConfig();

    const storageWrapper = new StorageWrapper(logger);
    const officeWrapper = new OfficeWrapper(logger);
    const translationService = new TranslationService(logger);
    const officeRoamingSettings = new OfficeRoamingSettings(officeWrapper, logger);
    const loginDialog = new LoginDialog(officeWrapper, config, logger);
    const outlookApiService = new OutlookApiService(officeWrapper, requestWrapper, windowWrapper, logger);
    const unauthenticatedApiClient = new UnauthenticatedApiClient(config, requestWrapper, logger);
    const smartFilingManager = new SmartFilingManager(officeWrapper, logger);
    const authenticator = new Authenticator(
        config,
        windowWrapper,
        loginDialog,
        storageWrapper,
        unauthenticatedApiClient,
        officeWrapper,
        analyticsManager,
        logger
    );
    const apiClient = new NewformaApiClient(config, authenticator, logger);
    const customerSupportEmailService = new CustomerSupportEmailService(logger, officeWrapper, windowWrapper, config);
    const projectsApiService = new ProjectsApiService(logger, apiClient, userDisplayService, requestWrapper);
    const bimProjectsApiService = new BimProjectsApiService(logger, config, apiClient, requestWrapper);
    const emailApiService = new EmailApiService(
        config,
        logger,
        apiClient,
        requestWrapper,
        analyticsManager,
        officeRoamingSettings,
        officeWrapper,
        smartFilingManager
    );

    const sendAndFileHelpers = new SendAndFileHelpers(
        officeWrapper,
        logger,
        projectsApiService,
        emailApiService,
        analyticsManager,
        smartFilingManager
    );
    const fileUploadApiService = new FileUploadApiService(logger, apiClient, requestWrapper, outlookApiService);
    const rfiApiService = new RfiApiService(logger, apiClient, requestWrapper, config);

    const gainSightAnalytics = new GainSight(logger, storageWrapper, config);
    const valueListApiServices = new ValueListApiService(logger, apiClient, requestWrapper, config);
    const relatedItemApiService = new RelatedItemApiService(logger, apiClient, requestWrapper);
    const subscriptionApiService = new SubscriptionApiService(
        logger,
        config,
        apiClient,
        requestWrapper,
        storageWrapper
    );
    const submittalApiService = new SubmittalApiService(logger, apiClient, requestWrapper, config);
    const issueApiService = new IssueApiService(logger, config, apiClient, requestWrapper, outlookApiService);

    const entryPoint = new EntryPoint(
        authenticator,
        apiClient,
        unauthenticatedApiClient,
        analyticsManager,
        smartFilingManager,
        officeWrapper,
        officeRoamingSettings,
        config,
        storageWrapper,
        windowWrapper,
        formValidationHelpers,
        translationService,
        customerSupportEmailService,
        logger,
        projectsApiService,
        bimProjectsApiService,
        sendAndFileHelpers,
        dateHelpers,
        rfiApiService,
        emailApiService,
        gainSightAnalytics,
        valueListApiServices,
        fileUploadApiService,
        relatedItemApiService,
        subscriptionApiService,
        submittalApiService,
        issueApiService
    );

    if ((module as any).hot) {
        (module as any).hot.accept("./app", () => {
            entryPoint.render();
        });
    }
})();

export * from "./index";
