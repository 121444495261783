import { TranslateFunction } from "react-localize-redux";

export function addMinutesToCurrentTime(minutes: number) {
    const currentTime = Date.now();
    const minutesInMilliseconds = minutes * 60 * 1000;
    const newTime = currentTime + minutesInMilliseconds;
    return newTime;
}

export function pluralizeSentence(sentence: string, word: string, count: number, translate: TranslateFunction): string {
    const isPlural = count > 1;

    let translatedWord = translate(`COMMON.SINGULAR.${word}`) as string;

    if (isPlural) {
        translatedWord = translate(`COMMON.PLURAL.${word}`) as string;
        return sentence.replace(`[[${word}]]`, translatedWord);
    }

    return sentence.replace(`[[${word}]]`, translatedWord);
}
