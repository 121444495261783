import { MessageBar } from "office-ui-fabric-react";
import * as React from "react";
import { useToast, useToastData } from "./useToast";

export default function Toast(): JSX.Element | null {
    const { dismissToast } = useToast();
    const { toastMessage, toastType } = useToastData();

    if (!toastMessage || !toastType) {
        return null;
    }

    let message: string | JSX.Element = toastMessage.message;
    if (toastMessage.link && toastMessage.linkText) {
        message = (
            <div>
                {message}
                <hr className="toastMessageSeparator" />
                <a className="toastMessageLink" target="_blank" href={toastMessage.link}>
                    {toastMessage.linkText}
                    <img
                        className="smallIcon"
                        src="assets/external-link.svg"
                        alt="Newforma Konekt external link icon"
                    />
                </a>
            </div>
        );
    }

    return (
        <div className="newforma-messageBarContainer" data-testid="newforma-messageBarContainer">
            <MessageBar
                className="newforma-messageBar"
                messageBarType={toastType}
                isMultiline={false}
                onDismiss={dismissToast}
            >
                {message}
            </MessageBar>
        </div>
    );
}
