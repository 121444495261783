import { Dropdown, IDropdownOption, ResponsiveMode } from "office-ui-fabric-react";
import * as React from "react";
import { useCallback } from "react";
import LabelComponent from "../../label/LabelComponent";

export interface DropdownComponentProps {
    className?: string;
    disabled: boolean;
    placeholder: string;
    label: string;
    onChange: (option: IDropdownOption | undefined) => void;
    options: IDropdownOption[];
    selectedOption?: IDropdownOption;
    required: boolean;
}

function DropdownComponent(props: DropdownComponentProps) {
    const onDropdownRenderLabel = useCallback(
        () => <LabelComponent text={props.label} required={props.required} />,
        [props.label, props.required]
    );

    return (
        <Dropdown
            className={`${props.className ?? ""} dropdownComponent`}
            options={props.options}
            placeholder={props.placeholder}
            label={props.label}
            responsiveMode={ResponsiveMode.large}
            onRenderLabel={onDropdownRenderLabel}
            disabled={props.disabled}
            onChange={(_, option) => {
                props.onChange(option && option.key !== -1 ? option : undefined);
            }}
            selectedKey={props.selectedOption && props.selectedOption.key !== -1 ? props.selectedOption.key : null}
            required
        />
    );
}

export default DropdownComponent;
