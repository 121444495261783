export class OfficeNotificationService {
    showInfoToast(
        mailboxItem: Office.MessageCompose | Office.AppointmentCompose | null,
        key: string,
        message: string
    ): void {
        if (!mailboxItem) {
            return;
        }

        mailboxItem.notificationMessages.replaceAsync(key, {
            type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
            icon: "unsure",
            message: message,
            persistent: false,
        });
    }

    dismissInfoToast(mailboxItem: Office.MessageCompose | Office.AppointmentCompose | null, key: string): void {
        if (!mailboxItem) {
            return;
        }
        mailboxItem.notificationMessages.removeAsync(key);
    }

    truncateMessage(originalMessage: string): string {
        const trimmedMessage = originalMessage.trim();

        if (originalMessage.length > 150) {
            return `${trimmedMessage.substr(0, 149).trim()}...`;
        }
        return trimmedMessage;
    }
}
