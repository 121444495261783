import { MsGraphTokenService } from "./MsGraphTokenService";
import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { Logger } from "./Logger";
import { ExpiredSessionError } from "../models/ExpiredSessionError";

export class GraphRequestWrapper {
    constructor(
        private httpRequestWrapper: HttpRequestWrapper,
        private msGraphTokenService: MsGraphTokenService,
        private logger: Logger
    ) {}

    async get(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: any,
        options?: any
    ) {
        try {
            const graphToken = await this.msGraphTokenService.getGraphToken();
            const headersWithAuthorization = {
                ...headers,
                Authorization: `Bearer ${graphToken}`,
            };
            return await this.httpRequestWrapper.get(url, dataType, headersWithAuthorization, data, options);
        } catch (error) {
            if ((error as any).status === 400) {
                throw error;
            } else {
                this.logger.error("invalid graphToken");
                throw new ExpiredSessionError();
            }
        }
    }

    async post(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: string,
        options?: any
    ) {
        try {
            const accessToken = await this.msGraphTokenService.getGraphToken();
            const headersWithAuthorization = {
                ...headers,
                Authorization: `Bearer ${accessToken}`,
            };
            return await this.httpRequestWrapper.post(url, dataType, headersWithAuthorization, data, options);
        } catch (error) {
            this.logger.error("invalid graphToken");
            throw new ExpiredSessionError();
        }
    }
}
