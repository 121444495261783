import { Common, Rfi } from "@newforma/platform-client-api-sdk";
import { DateHelpers } from "../helpers/DateHelpers";
import { BaseProjectItemMapping } from "./BaseProjectItemMapping";
import { ValidLogRfiFieldForm } from "../models/workflow/rfi/LogRfi";

export class LogRfiMapping extends BaseProjectItemMapping {
    static toRequest(
        logRfiFieldForm: ValidLogRfiFieldForm,
        specSectionOptions: Common.LocalizedValue[],
        sessionIds: string[]
    ): Rfi.LogRequest {
        return {
            subject: logRfiFieldForm.subject.trim(),
            number: logRfiFieldForm.number || undefined,
            specSection: logRfiFieldForm.specSection
                ? this.mapSpecSection(logRfiFieldForm.specSection, specSectionOptions)
                : undefined,
            receivedDate: DateHelpers.getStartOfDay(logRfiFieldForm.receivedDate),
            dueDate: DateHelpers.getStartOfDay(logRfiFieldForm.dueDate),
            isUrgent: logRfiFieldForm.urgent,
            type: logRfiFieldForm.type ? this.toIdValue(logRfiFieldForm.type.key) : undefined,
            senderNumber: logRfiFieldForm.senderNumber.trim() || undefined,
            from: logRfiFieldForm.from.map((x) => this.mapContact(x))[0],
            to: logRfiFieldForm.to.map(this.mapContactWithId),
            question: logRfiFieldForm.question.trim(),
            suggestion: logRfiFieldForm.suggestions?.trim() || undefined,
            impactSchedule: logRfiFieldForm.impactSchedule
                ? this.toIdValue(logRfiFieldForm.impactSchedule.key)
                : undefined,
            impactCost: logRfiFieldForm.impactCost ? this.toIdValue(logRfiFieldForm.impactCost.key) : undefined,
            reasons: logRfiFieldForm.reasons.map((x) => this.toIdValue(x.key)),
            disciplines: logRfiFieldForm.discipline.map((x) => this.toIdValue(x.key)),
            keywords: logRfiFieldForm.keywords.map((x) =>
                x.key === x.name ? { customName: x.name } : this.toIdValue(x.key)
            ),
            attachments: sessionIds && sessionIds.length ? { uploadIds: sessionIds } : undefined,
        };
    }
}
