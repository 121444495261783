import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Label, Panel, PanelType, PrimaryButton, Toggle } from "office-ui-fabric-react";
import MessageDialogComponent from "../MessageDialogComponent";
import { OfficeRoamingSettings } from "../../../services/OfficeRoamingSettings";
import { Email } from "@newforma/platform-client-api-sdk";
import "./SettingsComponent.less";
import { OfficeWrapper } from "../../../services/OfficeWrapper";
import { Logger } from "../../../services/Logger";
import { StorageWrapper } from "../../../services/StorageWrapper";
import { LocalStorageKeys } from "../../../models/StorageKeys";
import { ConfigurationService } from "../../../services/ConfigurationService";
import { WindowWrapper } from "../../../services/WindowWrapper";
import { IHub } from "../../../models/Hub";
import { SupportedAddinItems } from "../../../models/SupportedItems";

export interface SettingsProps extends LocalizeContextProps {
    showSettingsPanel: boolean;
    windowWrapper: WindowWrapper;
    onDismiss: () => void;
    officeRoamingSettings: OfficeRoamingSettings;
    officeWrapper: OfficeWrapper;
    logger: Logger;
    onSettingsChanged: () => void;
    storageWrapper: StorageWrapper;
    configService: ConfigurationService;
    setSelectedHub: (hub: IHub | null) => void;
    handleDiagnostic: () => Promise<string>;
    supportedAddinItems: SupportedAddinItems | null;
}

export interface SettingsState {
    hideSmartFilingHistoryDialog: boolean;
    deleteEmailAfterFiling: boolean;
    fileEmailConversation: boolean;
    isDiagnosticsCopied: boolean;
}

class SettingsComponent extends React.Component<SettingsProps, SettingsState> {
    private highestOfficeApiVersionSupported: string;

    constructor(props: Readonly<SettingsProps>, context: any) {
        super(props, context);

        this.state = {
            hideSmartFilingHistoryDialog: true,
            deleteEmailAfterFiling: this.props.officeRoamingSettings.getDeleteEmailAfterFiling(),
            fileEmailConversation: this.props.officeRoamingSettings.getFileEmailConversation(),
            isDiagnosticsCopied: false,
        };

        this.highestOfficeApiVersionSupported = this.getLatestSupportedOfficeApiVersion();
    }

    private getLatestSupportedOfficeApiVersion(): string {
        const minorVersion = this.props.officeWrapper.getLatestSupportedOfficeApiVersion();
        return minorVersion || (this.props.translate("APP.SETTINGS.UNKNOWN") as string);
    }

    private showSmartFilingHistoryDialog(): void {
        this.setState({ hideSmartFilingHistoryDialog: false });
    }

    private closeSmartFilingHistoryDialog(): void {
        this.setState({ hideSmartFilingHistoryDialog: true });
    }

    private async resetHistoryConfirmed(): Promise<void> {
        this.props.logger.info("Add-in filing history reset");
        await this.props.officeRoamingSettings.resetFilingHistory();
        this.props.storageWrapper.removeLocalStorageItem(LocalStorageKeys.hub);
        this.props.setSelectedHub(null);
        this.setState({ hideSmartFilingHistoryDialog: true });
        this.props.onDismiss();
    }

    private async onPostFileActionChanged(event: React.MouseEvent<HTMLElement>, checked?: boolean): Promise<void> {
        this.props.logger.info("Post-filing setting changed");
        if (checked === undefined) {
            return;
        }

        const postFilingAction = checked ? Email.PostFilingAction.delete : Email.PostFilingAction.none;
        await this.props.officeRoamingSettings.saveWithRetry({ postFilingAction: postFilingAction });
        this.setState({ deleteEmailAfterFiling: checked });
        this.props.onSettingsChanged();
    }

    private async onFileEmailConversationChanged(
        event: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ): Promise<void> {
        this.props.logger.info("File email conversation setting changed");
        if (checked === undefined) {
            return;
        }

        await this.props.officeRoamingSettings.saveWithRetry({ fileEmailConversation: checked });
        this.setState({ fileEmailConversation: checked });
        this.props.onSettingsChanged();
    }

    private async handleDiagnostics(): Promise<void> {
        const logs = await this.props.handleDiagnostic();
        this.setState({ isDiagnosticsCopied: !!logs });
    }

    private handleGoBack(): void {
        this.setState({ isDiagnosticsCopied: false });
        this.props.onDismiss();
    }

    // Components
    private renderBackButton(): JSX.Element {
        return (
            <a className="newforma-settingsComponent-back" onClick={() => this.handleGoBack()}>
                <span>&#8592;</span>
                <span>&nbsp;</span>
                {this.props.translate("APP.SETTINGS.BACK")}
            </a>
        );
    }
    private renderEmailDeleteWhenFiled(): JSX.Element {
        return (
            <div data-testid="settings-renderEmailDeleteWhenFiled" className="newforma-settingsComponent-box">
                <div className="newforma-settingsComponent-box-container">
                    <div className="newforma-settingsComponent-box-title">
                        {this.props.translate("APP.SETTINGS.FILE_DELETE")}
                    </div>
                    <Toggle
                        className="newforma-settingsComponent-toggle"
                        id="deleteEmailAfterFilingControl"
                        checked={this.state.deleteEmailAfterFiling}
                        onChange={this.onPostFileActionChanged.bind(this)}
                    />
                </div>
                <div className="newforma-settingsComponent-box-text">
                    {this.props.translate("APP.SETTINGS.DELETE_DELETE_DETAILS")}
                </div>
            </div>
        );
    }

    private renderFilePastConversation(): JSX.Element {
        return (
            <div data-testid="settings-renderFilePastConversation" className="newforma-settingsComponent-box">
                <div className="newforma-settingsComponent-box-container">
                    <div className="newforma-settingsComponent-box-title">
                        {this.props.translate("APP.SETTINGS.FILE_CONVERSATION")}
                    </div>
                    <Toggle
                        className="newforma-settingsComponent-toggle"
                        id="fileEmailConversationControl"
                        checked={this.state.fileEmailConversation}
                        onChange={this.onFileEmailConversationChanged.bind(this)}
                    />
                </div>
                <div className="newforma-settingsComponent-box-text">
                    {this.props.translate("APP.SETTINGS.FILE_CONVERSATION_DETAILS")}
                </div>
            </div>
        );
    }

    private renderResetFilingHistory(): JSX.Element {
        return (
            <div data-testid="settings-renderResetFilingHistory" className="newforma-settingsComponent-box">
                <div className="newforma-settingsComponent-box-container">
                    <div className="newforma-settingsComponent-box-title">
                        {this.props.translate("APP.SETTINGS.FILING_HISTORY_TITLE")}
                    </div>
                </div>
                <div className="newforma-settingsComponent-box-text">
                    {this.props.translate("APP.SETTINGS.FILING_HISTORY_DETAILS")}
                </div>

                <div className="newforma-settingsComponent-button">
                    <PrimaryButton
                        className="newforma-settingsButton"
                        onClick={this.showSmartFilingHistoryDialog.bind(this)}
                    >
                        {this.props.translate("APP.SETTINGS.RESET_FILING_HISTORY.BUTTON")}
                    </PrimaryButton>
                </div>
            </div>
        );
    }
    private renderVersions(): JSX.Element {
        return (
            <div data-testid="settings-renderVersions" className="newforma-settingsComponent-footer">
                <div className="newforma-settingsComponent-footer-text">
                    {this.props.translate("APP.SETTINGS.OFFICE_API_VERSION")} {this.highestOfficeApiVersionSupported}
                </div>
                <div className="newforma-settingsComponent-footer-text">
                    {this.props.translate("APP.SETTINGS.ADD_IN_VERSION")} {this.props.configService.version}
                </div>
            </div>
        );
    }

    private renderDiagnosticButton(): JSX.Element | null {
        return (
            <div className="diagnostic-wrapper">
                <button className="diagnostic-button" onClick={() => this.handleDiagnostics()}>
                    {this.state.isDiagnosticsCopied
                        ? (this.props.translate("APP.SETTINGS.COPIED_TO_CLIPBOARD") as string)
                        : (this.props.translate("APP.SETTINGS.GET_DIAGNOSTICS") as string)}
                </button>
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <div data-testid="settings-component">
                <Panel
                    hasCloseButton={false}
                    isOpen={this.props.showSettingsPanel}
                    type={PanelType.smallFixedFar}
                    className="newforma-settingsPanel"
                >
                    <div className="newforma-settingsComponent">
                        {this.renderBackButton()}
                        <Label className="newforma-settingsComponent-title">
                            {this.props.translate("APP.COMMANDBAR.SETTINGS") as string}
                        </Label>
                        {/* // NOTE: Turned off as per PIM-26367 */}
                        {this.props.supportedAddinItems?.canDeleteEmail && this.renderEmailDeleteWhenFiled()}
                        {this.renderFilePastConversation()}
                        {this.renderResetFilingHistory()}
                        {this.renderVersions()}
                        {this.renderDiagnosticButton()}
                    </div>
                </Panel>
                <MessageDialogComponent
                    title={this.props.translate("APP.SETTINGS.RESET_FILING_HISTORY.TITLE") as string}
                    subText={this.props.translate("APP.SETTINGS.RESET_FILING_HISTORY.TEXT") as string}
                    hidden={this.state.hideSmartFilingHistoryDialog}
                    onDismiss={this.closeSmartFilingHistoryDialog.bind(this)}
                    onConfirmButtonClicked={this.resetHistoryConfirmed.bind(this)}
                />
            </div>
        );
    }
}

export default withLocalize(SettingsComponent);
