import { IDropdownOption, IPersonaProps, ITag } from "office-ui-fabric-react";
import { FileWithId } from "../../shared/FileWithId";
import AttachmentDetails = Office.AttachmentDetails;

export interface SubmittalReviewResponseFieldForm {
    submittalId: ITag | null;
    response?: IDropdownOption;
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    remarks?: string;
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
}

export interface ValidSubmittalReviewResponseFieldForm {
    submittalId: ITag;
    response: IDropdownOption;
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    remarks?: string;
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
}
export const defaultClearedSubmittalReviewerResponseFieldForm: SubmittalReviewResponseFieldForm = {
    submittalId: null,
    response: undefined,
    from: [],
    to: [],
    cc: [],
    remarks: "",
    attachments: [],
    selectedAttachmentIds: [],
};
