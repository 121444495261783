import { OfficeWrapper } from "./OfficeWrapper";
import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { MsGraphGetExpandedEmailAttachmentResponse } from "../models/MsGraphGetExpandedEmailAttachmentResponse";
import { EmailAttachmentExtensionAndMime } from "../models/EmailAttachmentExtensionAndMime";
import { Logger } from "./Logger";
import { WindowWrapper } from "./WindowWrapper";
import { FileWithId } from "../models/shared/FileWithId";
import { AttachmentDetailsMetadata } from "../models/AttachmentDetailsMetadata";
import AttachmentDetails = Office.AttachmentDetails;

export enum EmailAttachmentOdataType {
    Contact = "#Microsoft.OutlookServices.Contact",
    Event = "#Microsoft.OutlookServices.Event",
    Message = "#Microsoft.OutlookServices.Message",
    Task = "#Microsoft.OutlookServices.Task",
}

export enum OutlookItemMimeType {
    email = "message/rfc822",
    contact = "text/vcard",
    event = "text/calendar",
}

export class OutlookApiService {
    private readonly valueParam = "/$value";
    private readonly messagesUriComponent = "/v2.0/me/messages";
    private readonly retryAfterHeaderKey = "Retry-After";
    private readonly defaultRetryTimeInSeconds = 1;
    private readonly maxRetryAttempts = 5;
    constructor(
        private officeWrapper: OfficeWrapper,
        private requestWrapper: HttpRequestWrapper,
        private windowWrapper: WindowWrapper,
        private logger: Logger
    ) {}

    // https://docs.microsoft.com/en-us/previous-versions/office/office-365-api/api/version-2.0/mail-rest-operations#GetAttachments
    async getEmailAttachmentBytes(
        attachmentId: string,
        attachmentType: Office.MailboxEnums.AttachmentType | string,
        attachmentMimeType: string
    ): Promise<Blob> {
        this.logger.info("Retrieving email attachment bytes from Microsoft Graph");
        const authorizationToken = await this.officeWrapper.getCallbackTokenWithRetry();

        const itemRestId = this.officeWrapper.convertToRestId(this.officeWrapper.currentContextItem);
        const urlSuffix = attachmentType === Office.MailboxEnums.AttachmentType.File ? "" : this.valueParam;
        const url = `${this.officeWrapper.getRestUrl()}${
            this.messagesUriComponent
        }/${itemRestId}/attachments/${this.convertToUnifiedId(attachmentId)}${urlSuffix}`;
        const headers = { Authorization: `Bearer ${authorizationToken}` };

        const result = await this.makeRequestWithRetry(() =>
            this.requestWrapper.get(url, undefined, headers, undefined, undefined)
        );

        return this.getBlobFromBase64EncodedByteString(result.ContentBytes || result, attachmentMimeType);
    }

    async getExtensionAndMimeForItemAttachment(
        attachmentId: string,
        attachmentType: Office.MailboxEnums.AttachmentType | string,
        contentType: string | undefined
    ): Promise<EmailAttachmentExtensionAndMime> {
        if (attachmentType !== Office.MailboxEnums.AttachmentType.Item) {
            return { extension: "", mimeType: contentType || "" };
        }

        const expandedAttachmentDetails = await this.getExpandedEmailAttachmentDetails(attachmentId);

        const attachmentItemType = expandedAttachmentDetails.Item["@odata.type"];

        const extension = this.getExtension(attachmentItemType);
        const mimeType = this.getMimeType(attachmentItemType);

        return { extension: extension, mimeType: mimeType };
    }

    async getFileAttachmentDetailsMetadata(
        attachments: (AttachmentDetails | FileWithId)[]
    ): Promise<AttachmentDetailsMetadata[]> {
        let noFileNameIndex = 0;
        const promises = attachments.map(async (attachment) => {
            if (this.isAttachmentDetails(attachment)) {
                const attachmentMetadata = await this.getExtensionAndMimeForItemAttachment(
                    attachment.id,
                    attachment.attachmentType,
                    attachment.contentType
                );
                const attachmentContent = await this.getEmailAttachmentBytes(
                    attachment.id,
                    attachment.attachmentType,
                    attachmentMetadata.mimeType
                );
                return {
                    id: attachment.id,
                    fileName: `${attachment.name || `_[${noFileNameIndex++}]`}${attachmentMetadata.extension}`,
                    file: attachmentContent,
                };
            }
            const file = attachment as FileWithId;
            return {
                id: attachment.id,
                fileName: attachment.name,
                file: file.file,
            };
        });

        return Promise.all(promises);
    }

    private async getExpandedEmailAttachmentDetails(
        attachmentId: string
    ): Promise<MsGraphGetExpandedEmailAttachmentResponse> {
        this.logger.info("Retrieving email attachment expanded details from Outlook REST API");
        const authorizationToken = await this.officeWrapper.getCallbackTokenWithRetry();

        const itemRestId = this.officeWrapper.convertToRestId(this.officeWrapper.currentContextItem);
        const url = `${this.officeWrapper.getRestUrl()}${
            this.messagesUriComponent
        }/${itemRestId}/attachments/${this.convertToUnifiedId(
            attachmentId
        )}?$expand=Microsoft.OutlookServices.ItemAttachment/Item`;
        const headers = { Authorization: `Bearer ${authorizationToken}` };

        return this.makeRequestWithRetry(() => this.requestWrapper.get(url, undefined, headers, undefined, undefined));
    }

    private convertToUnifiedId(id: string) {
        return id.replace(/\//g, "-").replace(/\+/g, "_");
    }

    private getExtension(attachmentItemType: string): string {
        switch (attachmentItemType) {
            case EmailAttachmentOdataType.Message:
            case EmailAttachmentOdataType.Task:
                return ".eml";
            case EmailAttachmentOdataType.Contact:
                return ".vcf";
            case EmailAttachmentOdataType.Event:
                return ".ics";
            default: {
                return ".eml";
            }
        }
    }

    private getMimeType(attachmentItemType: EmailAttachmentOdataType) {
        switch (attachmentItemType) {
            case EmailAttachmentOdataType.Message:
            case EmailAttachmentOdataType.Task:
                return OutlookItemMimeType.email;
            case EmailAttachmentOdataType.Contact:
                return OutlookItemMimeType.contact;
            case EmailAttachmentOdataType.Event:
                return OutlookItemMimeType.event;
            default:
                return OutlookItemMimeType.email;
        }
    }

    private async makeRequestWithRetry(request: () => Promise<any>, attemptCount: number = 1): Promise<any> {
        try {
            return await request();
        } catch (error) {
            if (attemptCount === this.maxRetryAttempts) {
                this.logger.error(
                    `Max retry attempts (${this.maxRetryAttempts}) reached for request.\n${JSON.stringify(error)}`
                );
                throw error;
            }
            if ((error as any).status && (error as any).status === 429) {
                const retryAfter =
                    ((error as any).getResponseHeader(this.retryAfterHeaderKey) || this.defaultRetryTimeInSeconds) *
                    1000;
                this.logger.error(
                    `The request was throttled by the office api on attempt (${attemptCount}). retrying after ${retryAfter}ms... `
                );
                await new Promise((resolve) => {
                    setTimeout(resolve, retryAfter);
                });
                return this.makeRequestWithRetry(request, attemptCount + 1);
            }
        }
    }

    private getBlobFromBase64EncodedByteString(fileBytes: string, mimeType: string): Blob {
        let decodedBytes: string;
        try {
            decodedBytes = this.windowWrapper.base64Decode(fileBytes);
        } catch {
            decodedBytes = fileBytes;
        }
        const binaryLength = decodedBytes.length;
        const blobParts = new Uint8Array(binaryLength);
        for (let i = 0; i < binaryLength; i++) {
            blobParts[i] = decodedBytes.charCodeAt(i);
        }
        return new Blob([blobParts], { type: mimeType });
    }

    private isAttachmentDetails(attachment: any): attachment is AttachmentDetails {
        return !!attachment.attachmentType;
    }
}
