import { IssueRequest } from "../models/IssueRequest";
import { ValidLogIssueFieldForm } from "../models/workflow/issue/LogIssue";
import { IDropdownOption, IPersonaProps, ITag } from "office-ui-fabric-react";
import { CustomAttributeValue } from "../hooks/useLogIssue";

export class LogIssueMapping {
    static toRequest(
        logIssueFieldForm: ValidLogIssueFieldForm,
        customAttributeValues: CustomAttributeValue[]
    ): IssueRequest {
        return {
            // required field
            Title: logIssueFieldForm.title,
            // optional fields
            AssignedToUserId: this.mapContactWithId(logIssueFieldForm.assignedTo),
            CustomAttributes: customAttributeValues,
            Description: logIssueFieldForm.description,
            Disciplines: logIssueFieldForm.disciplines?.map((x) => Number(x.key)),
            DueDate: logIssueFieldForm.dueDate,
            Group: this.getGroupName(logIssueFieldForm.group),
            IsRestricted: logIssueFieldForm.isRestricted || false,
            NotifyList: {
                TeamsToNotify: logIssueFieldForm.teamsInvolved?.map((x) => Number(x.key)),
                UsersToNotify: logIssueFieldForm.notify?.map((x) => Number(x.id)),
            },
            PriorityId: this.getIDropdownOptionId(logIssueFieldForm.priority),
            ProjectPhaseId: this.getIDropdownOptionId(logIssueFieldForm.phase),
            ProjectZoneId: this.getIDropdownOptionId(logIssueFieldForm.zone),
            StatusId: this.getIDropdownOptionId(logIssueFieldForm.status),
            TypeId: this.getIDropdownOptionId(logIssueFieldForm.type),
        };
    }

    protected static mapContactWithId(values: IPersonaProps[]): number | undefined {
        if (values.length === 0) {
            return undefined;
        }

        return Number(values[0].id);
    }

    protected static getIDropdownOptionId(value: IDropdownOption | undefined) {
        if (!value) {
            return undefined;
        }

        return Number(value.key);
    }

    protected static getGroupName(values: ITag[]): string | undefined {
        if (values.length === 0) {
            return undefined;
        }

        return values[0].name;
    }
}
