import { useCallback } from "react";
import { Logger } from "../services/Logger";
import { ToastMessage } from "../models/ToastMessage";
import { MessageBarType } from "office-ui-fabric-react";
import { AppPage } from "../models/AppPage";
import { TranslateFunction } from "react-localize-redux";
import { EmailListItemWithSelectedStatus } from "../components/fileMultipleEmail/FileMultipleEmailComponent";
import { EmailListItem } from "../models/EmailListResponse";
import { ExpiredSessionError, InvalidGraphSessionError } from "../models/ExpiredSessionError";
import { UnauthorizedError } from "../models/shared/UnauthorizedError";
import { ConflictError } from "../models/shared/ConflictError";
import { useAppState } from "../store/AppProvider";
import { pluralizeSentence } from "../utils/helpers";

export interface UseFileMultipleEmailHelpers {
    actions: {
        onExpiredSession: () => void;
        onShowToast: (message: ToastMessage | null, type: MessageBarType) => void;
        onSetNavigationPage: (page: AppPage) => void;
        showProgress: (message: string | null) => void;
        translate: TranslateFunction;
    };
    states: {
        deleteEmailAfterFiling: boolean;
    };
    services: {
        logger: Logger;
    };
}

const selectedEmailsIds = (emailsWithSelectedStatus: EmailListItemWithSelectedStatus[]): string[] =>
    emailsWithSelectedStatus.filter((email) => email.isSelected).map((email) => email.id);

const areAllItemsSelected = (emailsWithSelectedStatus: EmailListItemWithSelectedStatus[]): boolean => {
    const selectedEmailIds = selectedEmailsIds(emailsWithSelectedStatus);
    return selectedEmailIds.length > 0 && selectedEmailIds.length === emailsWithSelectedStatus.length;
};

export function useFileEmailHelpers(props: UseFileMultipleEmailHelpers) {
    const { appState } = useAppState();
    const { selectedProject } = appState;

    const { actions, services } = props;
    const { translate, onShowToast, onExpiredSession } = actions;
    const { logger } = services;

    const handleApiError = useCallback(
        (error: any, messageToDisplay?: string) => {
            logger.error(`${JSON.stringify(error)}`);

            switch (true) {
                case ExpiredSessionError.isInstanceOf(error):
                    onExpiredSession();
                    break;

                case InvalidGraphSessionError.isInstanceOf(error):
                    onShowToast({ message: translate("APP.WRONG_EMAIL") as string }, MessageBarType.error);
                    break;

                case UnauthorizedError.isInstanceOf(error):
                    onShowToast(
                        { message: translate("FILE_MULTIPLE_EMAIL.ERROR_FILING_EMAIL_UNAUTHORIZED") as string },
                        MessageBarType.error
                    );
                    break;

                case ConflictError.isInstanceOf(error):
                    onShowToast(
                        { message: translate("FILE_MULTIPLE_EMAIL.ERROR_FILING_EMAIL_CONFLICT") as string },
                        MessageBarType.error
                    );
                    break;

                default:
                    if (messageToDisplay) {
                        onShowToast({ message: messageToDisplay }, MessageBarType.error);
                    }
                    break;
            }
        },
        [logger, onExpiredSession, onShowToast, translate]
    );

    const moveSelectedEmailToTopOfList = (
        currentEmails: EmailListItemWithSelectedStatus[],
        currentlySelectedEmail: EmailListItem
    ): EmailListItemWithSelectedStatus[] => {
        if (!currentlySelectedEmail) {
            return currentEmails;
        }

        const selectedEmail = currentEmails.find((email) => email.id === currentlySelectedEmail.id);

        if (!selectedEmail) {
            return currentEmails;
        }

        const emailsWithoutSelected = currentEmails.filter((email) => email.id !== currentlySelectedEmail.id);
        return [{ ...currentlySelectedEmail, isSelected: true }, ...emailsWithoutSelected];
    };

    const getFilingMessage = useCallback(
        (emailsWithSelectedStatus: EmailListItemWithSelectedStatus[]) => {
            const messageTemplate = translate("FILE_MULTIPLE_EMAIL.FILING_IN_PROGRESS") as string;
            const count = selectedEmailsIds(emailsWithSelectedStatus).length;
            const pluralizedMessageTemplate = pluralizeSentence(messageTemplate, "EMAIL", count, translate);
            return pluralizedMessageTemplate.replace("[[count]]", `${count}`);
        },
        [translate, selectedEmailsIds]
    );

    const isFormValid = useCallback(
        (emailsWithSelectedStatus: EmailListItemWithSelectedStatus[]) =>
            !!selectedEmailsIds(emailsWithSelectedStatus).length && !!selectedProject,
        [selectedEmailsIds, selectedProject]
    );

    const getButtonText = useCallback(
        (emailsWithSelectedStatus: EmailListItemWithSelectedStatus[]) => {
            const countText = selectedEmailsIds(emailsWithSelectedStatus).length
                ? `(${selectedEmailsIds(emailsWithSelectedStatus).length})`
                : "";
            const count = selectedEmailsIds(emailsWithSelectedStatus).length;
            const buttonLabelTemplate = translate("FILE_MULTIPLE_EMAIL.SUBMIT_BUTTON") as string;
            const buttonLabel = pluralizeSentence(buttonLabelTemplate, "EMAIL", count, translate);

            return `${buttonLabel} ${countText}`.trim();
        },
        [translate, selectedEmailsIds]
    );

    return {
        isFormValid,
        getButtonText,
        handleApiError,
        moveSelectedEmailToTopOfList,
        selectedEmailsIds,
        areAllItemsSelected,
        getFilingMessage,
    };
}
