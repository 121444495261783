import { Common } from "@newforma/platform-client-api-sdk";
import { IDropdownOption, ITag } from "office-ui-fabric-react";
import { BimProjectAttribute } from "../models/Bimtrack/BimProjectAttribute";

export class ValueListMapping {
    static mapResponseToITagWithDescription(response: Common.LocalizedValue): ITag {
        return { name: response.name, key: response.description || "" };
    }

    static mapResponseToIDropdownOption(response: Common.LocalizedValue): IDropdownOption {
        return { text: response.name, key: response.id, data: response };
    }

    static mapResponseToITag(response: Common.LocalizedValue): ITag {
        return { name: response.name, key: response.id.toString() };
    }

    static mapBimProjectAttributeToITag(response: BimProjectAttribute): ITag {
        return { name: response.Name, key: response.Id.toString() };
    }
}
