import { Logger } from "./Logger";
import { OfficeWrapper } from "./OfficeWrapper";
import { WindowWrapper } from "./WindowWrapper";
import { ConfigurationService } from "./ConfigurationService";
import { DateTime } from "@bimone/bimtrack-dates";

export class CustomerSupportEmailService {
    constructor(
        private logger: Logger,
        private officeWrapper: OfficeWrapper,
        private windowWrapper: WindowWrapper,
        private configService: ConfigurationService
    ) {}

    getSupportEmailAddress(): string {
        return "support@newforma.com";
    }

    getSubject(): string {
        const date = new Date();
        const localDate = DateTime.fromFormat(date.toDateString(), "lll").format("lll");
        return `Newforma Outlook web add-in logs - ${localDate}`;
    }

    getDiagnostics(): string {
        const diagnosticsArr: string[] = [
            "------------------------Diagnostics------------------------",
            `User Name:  ${this.officeWrapper.userProfileDisplayName}`,
            `Customer Domain: ${this.officeWrapper.userProfileEmailDomain}`,
            `Time Zone: ${this.officeWrapper.getTimezone()}`,
            `Newforma Add-in Version ${this.configService.version}`,
            `Mail Client: ${this.officeWrapper.getClientPlatform()}`,
            `Mail Client Version: ${this.officeWrapper.getClientVersion()}`,
            `Browser Info: ${this.windowWrapper.getAppVersion()}`,
            `Office API version ${this.officeWrapper.getLatestSupportedOfficeApiVersion() || "unknown"}\n`,
            "Logs:",
        ].concat(this.logger.getLogs());

        const diagnostics = diagnosticsArr.join("\n");

        return diagnostics;
    }
}
