import { useAppService } from "../services/AppServiceProvider";
import { IHub } from "../models/Hub";
import { ITag, MessageBarType } from "office-ui-fabric-react";
import { ComposeEmail, ComposeType } from "../models/ComposeEmail";
import { useState } from "react";
import { TranslateFunction } from "react-localize-redux";
import { useProgressBar } from "../components/shared/progressBar/useProgressBar";
import { useQueryClient } from "@tanstack/react-query";
import useProjectEmailAddress from "./useProjectEmailAddress";
import { GraphAPIAttachment } from "../models/NewMessageFormParams";
import { useToast } from "../components/shared/toast/useToast";
import { UnauthorizedError } from "../models/shared/UnauthorizedError";

export interface UseComposeEmailProps {
    translate: TranslateFunction;
}

interface UseComposeEmailState {
    actions: {
        composeNewEmail: (
            hub: IHub,
            project: ITag,
            internetMessageId: string,
            composeType: ComposeType
        ) => Promise<void>;
    };
    states: {
        isComposingNewEmail: boolean;
    };
}

export const getAttachmentContent = async (attachmentBlob: Blob): Promise<string> =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(attachmentBlob);
    });

const useComposeEmail = (props: UseComposeEmailProps): UseComposeEmailState => {
    const { showProgress } = useProgressBar();
    const { showToast } = useToast();
    const [isComposingNewEmail, setIsComposingNewEmail] = useState(false);
    const {
        services: { pimTrackEmailAPIService, officeWrapper, msGraphApiService, logger },
    } = useAppService();
    const {
        actions: { fetchProjectEmailSettings },
    } = useProjectEmailAddress();
    const queryClient = useQueryClient();

    const getAttachments = async (
        hub: IHub,
        project: ITag,
        internetMessageId: string,
        attachments: { name: string; id: string }[]
    ): Promise<GraphAPIAttachment[]> => {
        const failedAttachments: string[] = [];
        const promisesResult = await Promise.allSettled(
            attachments.map(async ({ name, id }) => {
                try {
                    const attachmentBlob: Blob = await queryClient.fetchQuery({
                        queryKey: ["attachmentInfo", hub, project, internetMessageId, id],
                        queryFn: async () =>
                            pimTrackEmailAPIService.getAttachmentAsBlob(hub, project, internetMessageId, id),
                    });

                    const attachmentContent = await getAttachmentContent(attachmentBlob);
                    const [, contentType, base64Data] = /data:([^;]+);base64,(.*)/.exec(attachmentContent) || [];
                    return {
                        "@odata.type": "#microsoft.graph.fileAttachment",
                        name: name,
                        contentType: contentType || "application/octet-stream",
                        contentBytes: base64Data,
                    };
                } catch (error) {
                    logger.error(`${error}`);
                    failedAttachments.push(name);
                    throw error;
                }
            })
        );
        if (failedAttachments.length > 0) {
            showToast(
                {
                    message: props.translate("PROJECT_EMAIL.ERRORS.ATTACHMENTS_NOT_PROCESSED", {
                        attachments: failedAttachments.map((a) => `- ${a}`).join("\n"),
                    }) as string,
                },
                MessageBarType.error
            );
        }

        return promisesResult
            .filter((result): result is PromiseFulfilledResult<GraphAPIAttachment> => result.status === "fulfilled")
            .map((result) => result.value);
    };

    const showToastError = (error: unknown, composeType: ComposeType) => {
        logger.error(`Error composing email: ${JSON.stringify(error)}`);

        let toastMessage = props.translate(
            `PROJECT_EMAIL.ERRORS.GENERIC_OPEN_${composeType.toUpperCase()}_EMAIL`
        ) as string;

        if (UnauthorizedError.isInstanceOf(error)) {
            toastMessage += props.translate("PROJECT_EMAIL.ERRORS.UNAUTHORIZED") as string;
        }

        showToast({ message: toastMessage }, MessageBarType.error);
    };

    const composeNewEmail = async (
        hub: IHub,
        project: ITag,
        internetMessageId: string,
        composeType: ComposeType
    ): Promise<void> => {
        setIsComposingNewEmail(true);
        showProgress(props.translate(`PROJECT_EMAIL.OPENING_${composeType.toUpperCase()}_EMAIL`) as string);

        try {
            const [email, projectEmailSettings] = await Promise.all([
                pimTrackEmailAPIService.fetchEmailDetails(hub, project, internetMessageId),
                fetchProjectEmailSettings(),
            ]);
            let attachments: GraphAPIAttachment[] = [];

            if (composeType === ComposeType.Forward && email.hasAttachments && email.attachments) {
                attachments = await getAttachments(
                    hub,
                    project,
                    internetMessageId,
                    email.attachments?.map(({ id, name }) => ({ id, name }))
                );
            }

            const newEmail = ComposeEmail.createNewMessageFormParams({
                email,
                composeType,
                translate: props.translate,
                currentUserEmailAddress: officeWrapper.userProfileEmailAddress,
                attachments,
                ccProjectEmail: projectEmailSettings?.includeProjectEmailInCC
                    ? projectEmailSettings.projectEmail
                    : null,
            });

            const draftId = await msGraphApiService.createNewDraft(newEmail);

            Office.context.mailbox.displayMessageForm(draftId);
        } catch (error) {
            showToastError(error, composeType);
        } finally {
            showProgress(null);
            setIsComposingNewEmail(false);
        }
    };

    return {
        actions: { composeNewEmail },
        states: { isComposingNewEmail },
    };
};

export default useComposeEmail;
