import * as React from "react";
import { useProjectUsers } from "./useProjectUsers";
import { IFiltersState, initialFiltersState, useProjectEmailSearch } from "./useProjectEmailSearch";
import { useState } from "react";

interface FiltersHookProps {
    hideModal: () => void;
}

export default function useFiltersModal({ hideModal }: FiltersHookProps) {
    const { users: projectUsers, loading: loadingUsers, error: errorLoadingUsers } = useProjectUsers();

    const {
        states: { filters },
        actions: { updateFilters: updateAppliedFilters },
    } = useProjectEmailSearch();

    const [formState, setFormState] = useState<IFiltersState>(filters);
    const [isFormModified, setIsFormModified] = useState(false);

    const updateFormState = (partial: Partial<IFiltersState>) => {
        setIsFormModified(true);
        setFormState((prevState) => ({ ...prevState, ...partial }));
    };

    const applyFilters = () => {
        setIsFormModified(false);
        hideModal();
        updateAppliedFilters(formState);
    };

    const clearFilters = () => {
        setIsFormModified(true);
        setFormState(initialFiltersState);
    };

    const closeModal = () => {
        if (isFormModified) {
            setFormState(filters);
            setIsFormModified(false);
        }

        hideModal();
    };

    const receivedDatesMinMax = {
        from: {
            max: formState.receivedDateTo ? formState.receivedDateTo : new Date(),
        },
        to: {
            ...(formState.receivedDateFrom && {
                min: formState.receivedDateFrom,
            }),
            max: new Date(),
        },
    };

    return {
        states: {
            loadingUsers,
            errorLoadingUsers,
            formState,
            projectUsers,
            receivedDatesMinMax,
        },
        actions: {
            applyFilters,
            updateFormState,
            clearFilters,
            closeModal,
        },
    };
}
