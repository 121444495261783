import * as React from "react";
import "./SuggestedItemWithSubtextComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";

export interface SuggestedItemWithSubtextComponentProps extends LocalizeContextProps {
    primaryText: string;
    subtext: string;
}

class SuggestedItemWithSubtextComponent extends React.Component<SuggestedItemWithSubtextComponentProps, any> {
    constructor(props: SuggestedItemWithSubtextComponentProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <>
                <div className="newforma-suggestedItemWithSubtextContainer" key={this.props.primaryText}>
                    <div className="newforma-primaryText">{this.props.primaryText}</div>
                    <div className="newforma-subtext">{this.props.subtext}</div>
                </div>
            </>
        );
    }
}

export default withLocalize(SuggestedItemWithSubtextComponent);
