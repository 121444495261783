import { RelatedItem } from "@newforma/platform-client-api-sdk";
import { Request } from "aws-sign-web";
import { ITag } from "office-ui-fabric-react";
import { IHub } from "../../models/Hub";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Logger } from "../Logger";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { BaseApiService, Method } from "./BaseApiService";

export class RelatedItemApiService extends BaseApiService {
    constructor(
        private logger: Logger,
        protected newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper
    ) {
        super(newformaApiClient);
    }

    async linkItems(
        selectedHub: IHub,
        selectedProject: ITag,
        itemTolink: RelatedItem.RelatedItem,
        body: RelatedItem.PostRelatedItemsRequest
    ): Promise<{}> {
        this.logger.info(`Link item ${itemTolink.type} to ${body.items.map((x) => x.type).join(",")}`);
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildPostRelatedItemEndpoint(
            domain,
            selectedHub.key.toString(),
            selectedProject.key.toString(),
            itemTolink
        );

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Post), body: JSON.stringify(body) },
            async (signedOptions: Request) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private buildPostRelatedItemEndpoint(
        domain: string,
        hubId: string,
        projectId: string,
        itemTolink: RelatedItem.RelatedItem
    ): string {
        const relatedItemUrl = new URL(`${this.apiVersion}/hubs/${hubId}/projects/${projectId}/relateditems`, domain);

        relatedItemUrl.searchParams.append("itemType", itemTolink.type);
        relatedItemUrl.searchParams.append("itemId", itemTolink.id);

        return relatedItemUrl.toString();
    }
}
