import { IHub } from "../../models/Hub";
import { UserInfo } from "../../models/PimModels";
import { LocalStorageKeys } from "../../models/StorageKeys";
import { UserPreferenceType } from "../BimApi/BimProjectsApiService";
import { ConfigurationService } from "../ConfigurationService";
import { Logger } from "../Logger";
import { StorageWrapper } from "../StorageWrapper";
import { UserInfoAll, getGainSightUserIdentity } from "./GainSightUserIdentity";
import GainSightWindow, { Aptrinsic } from "./gainSightWindow";

const BASE_URL = "https://web-sdk.aptrinsic.com/api/aptrinsic.js";
const SCRIPT_ELEMENT_ID = "gainSightScript";

export interface GainSightHubIdentity {
    id: string;
    hubId: string;
    name: string;
    hubCreationDate: string;
    hubDataCenter: "CanadaEast" | "CanadaEastLocal" | "Europe" | "NorthEurope" | "USEast";
    isHubFreePlan: boolean;
    hubUserCount: number;
}

export default class GainSight {
    constructor(
        private logger: Logger,
        private storageWrapper: StorageWrapper,
        private configService: ConfigurationService
    ) {
        GainSightWindow.aptrinsic =
            GainSightWindow.aptrinsic ||
            ((...params: unknown[]): void => {
                GainSightWindow.aptrinsic?.q?.push(params);
            });

        GainSightWindow.aptrinsic.q = GainSightWindow.aptrinsic.q || [];
        GainSightWindow.aptrinsic.p = this.configService.gainSightProductKey;
        GainSightWindow.aptrinsic.c = { iframeModeEnabled: false };

        const addinUserInfoAll = this.getAddinUserInfo();
        const addinUserSelectedHubIdentity = this.getAddinHubIdentity();

        if (addinUserInfoAll) {
            const userIdentity = getGainSightUserIdentity(addinUserInfoAll);
            GainSightWindow.aptrinsic(
                "identify",
                userIdentity,
                addinUserSelectedHubIdentity ? addinUserSelectedHubIdentity : null
            );
            this.logger.info(`GainSightUserIdentity: ${JSON.stringify(userIdentity)}`);
        }

        if (addinUserSelectedHubIdentity) {
            GainSightWindow.aptrinsic("set", "globalContext", addinUserSelectedHubIdentity);
            this.logger.info(`GainSightHubIdentity: ${JSON.stringify(addinUserSelectedHubIdentity)}`);
        }

        this.logger.info("Gainsight constructor created");
    }

    reinitializeOnHubChange() {
        const addinUserSelectedHubIdentity = this.getAddinHubIdentity();
        (GainSightWindow.aptrinsic as Aptrinsic)("set", "globalContext", addinUserSelectedHubIdentity);
        this.logger.info("Re-initialized GainSight globalContext");
    }

    initialize(): HTMLScriptElement {
        const scriptElement = document.createElement("script");

        scriptElement.id = SCRIPT_ELEMENT_ID;
        scriptElement.src = `${BASE_URL}?a=${this.configService.gainSightProductKey}`;
        scriptElement.async = true;

        const firstScriptElement = document.getElementsByTagName("script")[0];
        const parentNode = firstScriptElement?.parentNode ?? document.head ?? document.body;

        parentNode.insertBefore(scriptElement, firstScriptElement);

        return scriptElement;
    }

    getAddinUserInfo(): UserInfoAll | null {
        const storedAddinUserHubPreferenceInfo = this.storageWrapper.loadLocalStorage(
            LocalStorageKeys.userHubPreference
        );
        const storedAddinUserInfo = this.storageWrapper.loadLocalStorage(LocalStorageKeys.addInUser);

        if (storedAddinUserInfo && storedAddinUserHubPreferenceInfo) {
            const storedAddinUser: UserInfo = JSON.parse(storedAddinUserInfo);
            const storedAddinUserHubPreference: UserPreferenceType = JSON.parse(storedAddinUserHubPreferenceInfo);

            const addinUserInfoAll: UserInfoAll = {
                ...storedAddinUserHubPreference,
                ...storedAddinUser,
            };

            return addinUserInfoAll;
        }
        return null;
    }

    getAddinHubIdentity() {
        const storedUserHub = this.storageWrapper.loadLocalStorage(LocalStorageKeys.hub);

        if (storedUserHub) {
            const parsedAddinUserHub: IHub = JSON.parse(storedUserHub);

            return {
                id: parsedAddinUserHub.key,
                hubId: parsedAddinUserHub.key,
                name: parsedAddinUserHub.name,
                hubDataCenter: parsedAddinUserHub.regionSummary?.Location,
            };
        }
        return null;
    }
}
