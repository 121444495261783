import * as React from "react";

const HubIcon = () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_507_43-250492)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.563 0.0350342V5.43703L12.563 5.43769V12.7502H11.438V6.56269L9.563 6.56203V12.7502H8.438V1.46494L1.313 3.19219V12.7502H0.188004V2.30776L9.563 0.0350342ZM6.563 8.43769V12.7502H5.438V9.56269H4.313V12.7502H3.188V8.43769H6.563Z"
                fill="#0078D4"
            />
        </g>
        <defs>
            <clipPath id="clip0_507_43-250492">
                <rect width="13" height="13" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default HubIcon;
