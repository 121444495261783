import { TranslateFunction } from "react-localize-redux";
import { BimProjectITag, ProjectPickerComponentProps } from "../components/shared/projectPicker/ProjectPickerComponent";
import { ProjectBaseInformation } from "../models/ProjectsResponse";
import { EmailProjectFilingAccess } from "../services/BimApi/BimProjectsApiService";
import { SuggestedProject } from "../services/SmartFiling/SuggestedProject";
import { IHub } from "../models/Hub";
import { HubSubscriptionInfo } from "../services/SubscriptionApiService/SubscriptionApiService";

export const normalizeAccessToBaseInfo = (
    authorizedProjects: EmailProjectFilingAccess[],
    translate: TranslateFunction
): ProjectBaseInformation[] =>
    authorizedProjects.map((project) => {
        const { projectName, projectId, projectNumber, projectStatus } = project;
        return {
            nrn: `${projectId}`, // TODO: rename nrn -> projectID
            name: projectStatus === "Completed" ? `${translate("APP.COMPLETED")} - ${projectName}` : projectName,
            number: projectNumber || "",
        };
    });

export const normalizeSuggestedProjectToITag = (suggestionResults: SuggestedProject[]): BimProjectITag[] =>
    suggestionResults.map((project) => ({
        name: project.name.replace(/ *\([^)]*\) */g, ""), // remove project number from the title before displaying
        key: project.nrn,
        projectNumber: project.number,
    }));

export const normalizeEmailAccessProjectsToITag = (
    projectEmailAccess: EmailProjectFilingAccess[],
    translate: TranslateFunction
): BimProjectITag[] =>
    projectEmailAccess.map((project) => ({
        name:
            project.projectStatus === "Completed"
                ? `${translate("APP.COMPLETED")} - ${project.projectName}`
                : project.projectName,
        key: `${project.projectId}`,
        projectNumber: project.projectNumber || "",
    }));

export const filterLowPriorityProjects = (
    allProjects: BimProjectITag[],
    topSuggestedProjectsITag: BimProjectITag[],
    translate: TranslateFunction
): BimProjectITag[] => {
    const lowPriorityProjects = allProjects
        .filter((project) => !topSuggestedProjectsITag.some((suggestion) => suggestion.key === project.key))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return moveCompletedToEnd(lowPriorityProjects, translate);
};

export const moveCompletedToEnd = (projectsITag: BimProjectITag[], translate: TranslateFunction) => {
    const completed = projectsITag.filter((item) => item.name.includes(`${translate("APP.COMPLETED")} - `));
    const other = projectsITag.filter((item) => !item.name.includes(`${translate("APP.COMPLETED")} - `));
    return other.concat(completed);
};
