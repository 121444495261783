import { IPersonaProps } from "office-ui-fabric-react";
import * as React from "react";
import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

export interface IFiltersState {
    receivedDateFrom: Date | null;
    receivedDateTo: Date | null;
    selectedFromUsers: IPersonaProps[];
    selectedToUsers: IPersonaProps[];
    hasAttachments: boolean;
}

export interface SearchContextProps {
    states: {
        query: string;
        filters: IFiltersState;
        isFilterOn: boolean;
    };
    actions: {
        setQuery(text: string): void;
        clearFilters(): void;
        updateFilters(patch: Partial<IFiltersState>): void;
    };
}

export const initialFiltersState: IFiltersState = {
    receivedDateFrom: null,
    receivedDateTo: null,
    selectedFromUsers: [],
    selectedToUsers: [],
    hasAttachments: false,
};

const ProjectEmailSearchContext = createContext<SearchContextProps>({
    states: {
        query: "",
        filters: initialFiltersState,
        isFilterOn: false,
    },
    actions: {
        setQuery: () => {},
        updateFilters: () => {},
        clearFilters: () => {},
    },
});

export function useProjectEmailSearch(): SearchContextProps {
    return useContext(ProjectEmailSearchContext);
}

export default function ProjectEmailSearchProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
    const [query, setQuery] = useState<string>("");
    const [filtersState, setFiltersState] = useState<IFiltersState>(initialFiltersState);

    const value = useMemo<SearchContextProps>(
        () => ({
            states: {
                query,
                filters: filtersState,
                isFilterOn: !Object.values(filtersState).every(
                    (v) => v === null || (Array.isArray(v) && v.length === 0) || v === false
                ),
            },
            actions: {
                setQuery,
                clearFilters: () => setFiltersState(initialFiltersState),
                updateFilters: (partial: Partial<IFiltersState>) => {
                    setFiltersState((prevState) => ({ ...prevState, ...partial }));
                },
            },
        }),
        [query, filtersState]
    );

    return <ProjectEmailSearchContext.Provider value={value}>{children}</ProjectEmailSearchContext.Provider>;
}
