import * as React from "react";

function RfiErrorIcon() {
    return (
        <svg
            width="99"
            height="62"
            viewBox="0 0 99 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="rfiErrorIcon"
        >
            <path
                d="M49.5 62C76.8381 62 99 50.6978 99 36.756C99 22.8141 76.8381 11.512 49.5 11.512C22.1619 11.512 0 22.8141 0 36.756C0 50.6978 22.1619 62 49.5 62Z"
                fill="#EBEBEB"
            />
            <path
                d="M80.2371 34.5652V39.4809C80.2371 41.481 77.842 43.2839 74.2713 43.8333L40.2618 48.9321C35.9653 49.58 31.5381 48.1574 30.3914 45.7488L21.2613 26.6915C21.1161 26.3957 21.029 26.114 21 25.8182V20.6067C21 21.0011 21.0871 21.3955 21.2758 21.7898L22.0161 23.3251L30.3769 40.8331C31.5381 43.2417 35.9508 44.6643 40.2618 44.0304L74.2567 38.9175C76.4631 38.5935 78.2049 37.7766 79.221 36.7484C79.8742 36.0864 80.2371 35.3399 80.2371 34.5652Z"
                fill="url(#paint0_linear_507_3-413984)"
            />
            <path
                d="M44.384 19.142L37.678 35.706L42.018 37.523L64.807 27.762L65.693 22.015L59.48 17.705L51.787 17.522L44.384 19.142Z"
                fill="url(#paint1_linear_507_3-404140)"
            />
            <path d="M59.524 31.6211V24.5081H45.27V30.6071L59.524 31.6211Z" fill="url(#paint2_linear_507_3-432479)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.9591 19.8038C55.8368 17.8882 49.842 17.9164 45.7632 19.8602C44.8342 20.2968 44.7326 21.0856 45.5309 21.5927C46.3147 22.1138 47.7082 22.1702 48.6372 21.7194C51.0758 20.5504 54.6756 20.5363 57.1577 21.6772C59.6253 22.8322 60.264 24.8182 58.6528 26.3676C57.8109 27.1845 55.9384 27.6352 53.3111 27.7197C52.2951 27.762 51.25 27.7338 50.2339 27.6775L49.3484 27.6071L48.9856 27.5648C47.7953 27.4239 46.6341 27.8606 46.3873 28.5226C46.1406 29.1846 46.9099 29.8466 48.1001 29.9734C49.4065 30.1283 51.3806 30.241 53.5869 30.1565C57.5496 30.0156 60.7285 29.255 62.4558 27.5789C65.1411 24.9872 64.096 21.6772 59.9737 19.7475L59.9591 19.8038ZM44.3552 33.1566L44.0213 33.058C42.6714 32.7341 41.0747 33.0158 40.3199 33.7482C39.507 34.537 39.9861 35.537 41.394 35.9878L41.7279 36.0864C43.0778 36.4103 44.6745 36.1286 45.4293 35.3962C46.2422 34.6074 45.7632 33.6074 44.3552 33.1566ZM60.9897 11.1555C65.2863 10.5076 69.7134 11.9443 70.8747 14.3528L79.9758 33.3961C81.1225 35.8047 78.5678 38.2837 74.2713 38.9316L40.2764 44.0304C35.9798 44.6784 31.5526 43.2417 30.3914 40.8331L21.2903 21.7899C20.1436 19.3813 22.6983 16.9023 26.9948 16.2544L60.9897 11.1555Z"
                fill="url(#paint3_linear_507_3-174547)"
            />
            <path
                style={{ mixBlendMode: "multiply" }}
                opacity="0.211"
                d="M53.7226 34.7347C43.9461 40.3996 22.9368 32.6126 13.1603 26.9392L48.5618 6.40198C58.3128 12.0754 63.4735 29.0613 53.7226 34.7347Z"
                fill="url(#paint4_radial_507_3-653648)"
            />
            <path
                d="M44.9454 2.34961V5.77247C44.9496 5.81515 44.9496 5.85815 44.9454 5.90083C44.7805 6.70129 44.246 7.37498 43.5062 7.71495L34.5898 12.8492C32.755 13.8799 30.5418 13.972 28.6285 13.0974L28.2197 12.8578C27.6662 12.601 27.281 12.0785 27.1978 11.4715C27.1936 11.4346 27.1936 11.3973 27.1978 11.3603V7.96311C27.2401 8.61502 27.6362 9.19058 28.2282 9.46061L28.4752 9.60608L28.637 9.70021C29.0786 9.9479 29.5599 10.1159 30.0592 10.1965C31.6043 10.4546 33.1909 10.1929 34.5727 9.45205L41.0109 5.72113L43.4976 4.28353C43.5632 4.24865 43.6258 4.20859 43.685 4.16373C44.3847 3.81359 44.8594 3.13027 44.9454 2.34961Z"
                fill="url(#paint5_linear_507_3-285019)"
            />
            <path
                d="M28.1431 12.37V15.8356C28.1473 15.8755 28.1473 15.9156 28.1431 15.9554C28.0145 16.7088 27.5236 17.3495 26.8316 17.6669C24.8312 18.6939 22.4619 18.6939 20.4615 17.6669C19.7695 17.3495 19.2786 16.7088 19.15 15.9554C19.1458 15.9156 19.1458 15.8755 19.15 15.8356V12.37C19.2171 13.098 19.6467 13.7422 20.2912 14.0814L20.47 14.1927C21.1665 14.5721 21.9304 14.8105 22.7183 14.8944C23.3405 14.9713 23.9697 14.9713 24.5918 14.8944C25.3797 14.8105 26.1436 14.5721 26.8401 14.1927L27.0189 14.0814C27.6569 13.7379 28.0795 13.0944 28.1431 12.37Z"
                fill="url(#paint6_linear_507_3-410872)"
            />
            <path
                d="M44.9454 2.34957C44.8594 3.13023 44.3847 3.81356 43.685 4.16369C43.6258 4.20856 43.5632 4.24862 43.4976 4.28349L41.0195 5.72109L34.5813 9.41778C33.1994 10.1586 31.6128 10.4203 30.0677 10.1623C29.5684 10.0816 29.0871 9.91363 28.6455 9.66594L28.4837 9.57181L28.2367 9.42634C27.6549 9.16211 27.2607 8.60235 27.2063 7.96307C27.3059 7.09233 27.8614 6.34161 28.6625 5.99492L37.5789 0.860626C39.4135 -0.172744 41.6294 -0.261805 43.5402 0.621025L43.949 0.852068C44.5266 1.13305 44.9077 1.70591 44.9454 2.34957Z"
                fill="url(#paint7_radial_507_3-053051)"
            />
            <path
                d="M28.1431 12.37C28.076 13.098 27.6464 13.7423 27.0019 14.0815L26.8231 14.1927C26.1266 14.5722 25.3627 14.8106 24.5748 14.8944C23.9526 14.9714 23.3234 14.9714 22.7013 14.8944C21.9134 14.8106 21.1495 14.5722 20.453 14.1927L20.2742 14.0815C19.6296 13.7423 19.2001 13.098 19.133 12.37C19.2061 11.5587 19.7115 10.8511 20.453 10.5217C22.4534 9.49472 24.8227 9.49472 26.8231 10.5217C27.5646 10.8511 28.0699 11.5587 28.1431 12.37Z"
                fill="url(#paint8_radial_507_3-007576)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_507_3-413984"
                    x1="21"
                    y1="20.785"
                    x2="80"
                    y2="39.285"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C6D7FC" />
                    <stop offset="0.367672" stopColor="#ADC6FB" />
                    <stop offset="1" stopColor="#4E7BEB" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_507_3-404140"
                    x1="447.341"
                    y1="788.961"
                    x2="4949.65"
                    y2="3216.57"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2A62DB" />
                    <stop offset="0.45" stopColor="#769ADA" />
                    <stop offset="1" stopColor="#CFE1FF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_507_3-432479"
                    x1="-419.524"
                    y1="302.754"
                    x2="1561.41"
                    y2="740.673"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4889F5" />
                    <stop offset="0.51" stopColor="#4889F5" />
                    <stop offset="1" stopColor="#88B5FF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_507_3-174547"
                    x1="21"
                    y1="19.785"
                    x2="80"
                    y2="35.285"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#9AC0FF" />
                </linearGradient>
                <radialGradient
                    id="paint4_radial_507_3-653648"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1365.06 -540.084) rotate(-41.6326) scale(2216.58 673.21)"
                >
                    <stop stopColor="#EA373E" />
                    <stop offset="0.2" stopColor="#DD1A22" stopOpacity="0.69" />
                    <stop offset="0.45" stopColor="#DD1A22" stopOpacity="0.32" />
                    <stop offset="0.63" stopColor="#DD1A22" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#DD1A22" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint5_linear_507_3-285019"
                    x1="47.949"
                    y1="573.968"
                    x2="540.177"
                    y2="573.968"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EB7D7C" />
                    <stop offset="1" stopColor="#720A05" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_507_3-410872"
                    x1="48.4218"
                    y1="317.006"
                    x2="314.974"
                    y2="317.006"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EC8281" />
                    <stop offset="1" stopColor="#E75048" />
                </linearGradient>
                <radialGradient
                    id="paint7_radial_507_3-053051"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(36.0758 5.13798) rotate(90) scale(5.12102 8.86955)"
                >
                    <stop stopColor="#F5C6C6" />
                    <stop offset="1" stopColor="#F2B6B6" />
                </radialGradient>
                <radialGradient
                    id="paint8_radial_507_3-007576"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(23.638 12.3518) rotate(90) scale(2.60034 4.50503)"
                >
                    <stop stopColor="#F2B8B7" />
                    <stop offset="1" stopColor="#F3BBBB" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default RfiErrorIcon;
