import { useCallback, useEffect, useState, FormEvent } from "react";
import { ITag, MessageBarType } from "office-ui-fabric-react";
import { EmailListItem } from "../models/EmailListResponse";
import { AppPage } from "../models/AppPage";
import { IHub } from "../models/Hub";
import { useAppState } from "../store/AppProvider";
import {
    EmailListItemWithSelectedStatus,
    FileMultipleEmailComponentProps,
} from "../components/fileMultipleEmail/FileMultipleEmailComponent";
import { Logger } from "../services/Logger";
import { EmailApiService } from "../services/NewformaApi/EmailApiService";
import { Authenticator } from "../services/Authenticator";
import { TranslateFunction } from "react-localize-redux";
import { ToastMessage } from "../models/ToastMessage";
import { useFileEmailHelpers } from "./useFileEmailsHelpers";
import { pluralizeSentence } from "../utils/helpers";
import { useAppService } from "../services/AppServiceProvider";

export interface UseFileMultipleEmailComponentProps {
    actions: {
        onExpiredSession: () => void;
        onShowToast: (message: ToastMessage | null, type: MessageBarType) => void;
        onSetNavigationPage: (page: AppPage) => void;
        showProgress: (message: string | null) => void;
        translate: TranslateFunction;
    };
    states: {
        deleteEmailAfterFiling: boolean;
    };
    services: {
        logger: Logger;
        emailApiService: EmailApiService;
        authenticator: Authenticator;
    };
}

// TODO: 45422: Revisit implementation of useFileEmail hook
const useFileEmails = (props: UseFileMultipleEmailComponentProps) => {
    const [isLoadingEmails, setIsLoadingEmails] = useState(false);
    const [isLoadingMoreEmails, setIsLoadingMoreEmails] = useState(false);
    const [isLoadingFolderName, setIsLoadingFolderName] = useState(false);
    const [isFiling, setIsFiling] = useState(false);
    const [loadedEmails, setLoadedEmails] = useState<EmailListItem[]>([]);
    const [emails, setEmails] = useState<EmailListItemWithSelectedStatus[]>([]);
    const [emailOffsetToken, setEmailOffsetToken] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [folderName, setFolderName] = useState<string>("");
    const [folderId, setFolderId] = useState<string | null>(null);
    const [currentEmailItem, setCurrentEmailItem] = useState<EmailListItem | null>(null);

    const { appState } = useAppState();
    const { selectedHub, selectedProject, supportedAddinItems, mailboxItem } = appState;

    const { actions, services, states } = props;
    const { onSetNavigationPage, translate, showProgress, onShowToast } = actions;
    const { emailApiService, logger } = services;
    const { deleteEmailAfterFiling } = states;
    const {
        services: { msGraphApiService },
    } = useAppService();

    const { handleApiError, moveSelectedEmailToTopOfList, selectedEmailsIds, getFilingMessage } =
        useFileEmailHelpers(props);

    useEffect(() => {
        onSetNavigationPage(AppPage.FileMultipleEmail);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const newFolderId = await msGraphApiService.getCurrentFolderId();
                const newCurrentEmailItem = await msGraphApiService.getCurrentMessageAsEmailListItem();
                const newFolderName = await msGraphApiService.getFolderName(newFolderId);
                setFolderName(newFolderName);
                setFolderId(newFolderId);
                setCurrentEmailItem(newCurrentEmailItem);
            } catch (error) {
                handleApiError(error, translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC") as string);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setIsLoadingFolderName(true);
            setEmails([]);
            setLoadedEmails([]);
            try {
                await loadEmails(false, false, null, null);
            } catch (error) {
                logger.error("error loading outlook context");
                handleApiError(error, translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC") as string);
            } finally {
                setIsLoadingFolderName(false);
            }
        })();
    }, [folderId]);

    useEffect(() => {
        (async () => {
            logger.info("Selected Email Changed");

            const newFolderId = await msGraphApiService.getCurrentFolderId();
            if (folderId !== newFolderId) {
                // Special case in old outlook desktop app, where addins do not re-render on folderChange, we must manually trigger this.
                setFolderId(newFolderId);
                const newFolderName = await msGraphApiService.getFolderName(newFolderId);
                setFolderName(newFolderName);
                return;
            }
            const newCurrentEmailItem = await msGraphApiService.getCurrentMessageAsEmailListItem();

            const mappedItems: EmailListItemWithSelectedStatus[] = loadedEmails.map((item) => ({
                ...item,
                isSelected:
                    item.id === currentEmailItem?.id
                        ? false
                        : emails.find((x) => x.id === item.id)?.isSelected || false,
            }));

            const newEmails = moveSelectedEmailToTopOfList(mappedItems, newCurrentEmailItem);
            setCurrentEmailItem(newCurrentEmailItem);
            setEmails(newEmails);
        })();
    }, [mailboxItem, loadedEmails]);

    const onSelectAllChanged = (event?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
        if (checked === undefined) {
            return;
        }

        const emailsDeepCopy: EmailListItemWithSelectedStatus[] = JSON.parse(JSON.stringify(emails));

        if (checked) {
            const emailIds: string[] = [];
            const checkedEmails = emailsDeepCopy.map((email) => {
                emailIds.push(email.id);
                return { ...email, isSelected: true };
            });
            setEmails(checkedEmails);
            return;
        }

        const uncheckedEmails = emailsDeepCopy.map((email) => ({ ...email, isSelected: false }));
        setEmails(uncheckedEmails);
        return;
    };

    const loadEmails = useCallback(
        async (
            isLoadingMore: boolean = false,
            isSearching: boolean,
            searchValue: string | null,
            offsetToken: string | null
        ): Promise<void> => {
            logger.info("Calling loadEmails:");
            setIsLoadingEmails(!isLoadingMore);
            setIsLoadingMoreEmails(isLoadingMore);
            try {
                const emailResponse = await msGraphApiService.getFolderEmails(
                    folderId || (await msGraphApiService.getCurrentFolderId()),
                    offsetToken,
                    searchValue
                );
                let mappedEmails = emailResponse.items.map((item) => ({
                    ...item,
                    isSelected: false,
                }));

                if (!isSearching) {
                    const newCurrentEmailItem = await msGraphApiService.getCurrentMessageAsEmailListItem();
                    mappedEmails = moveSelectedEmailToTopOfList(mappedEmails, newCurrentEmailItem);
                    if (isLoadingMore) {
                        setEmails((prevEmails) => {
                            const newEmails = mappedEmails.filter(
                                (newEmail) => !prevEmails.some((prevEmail) => prevEmail.id === newEmail.id)
                            );
                            return [...prevEmails, ...newEmails];
                        });
                    } else {
                        setEmails(mappedEmails);
                    }
                } else {
                    setEmails(mappedEmails);
                }

                setLoadedEmails((prevLoadedEmails) => {
                    const existingEmailIds = new Set(prevLoadedEmails.map((email) => email.id));
                    const newEmails = emailResponse.items.filter((email) => !existingEmailIds.has(email.id));
                    return [...prevLoadedEmails, ...newEmails];
                });
                setEmailOffsetToken(emailResponse.paging.offsetToken);
            } catch (error) {
                logger.error("error loading emails");
            } finally {
                setIsLoadingEmails(false);
                setIsLoadingMoreEmails(false);
            }
        },
        [folderId]
    );

    const onEmailSearchClear = async (): Promise<void> => {
        setEmails([]);
        setSearchTerm(null);
        setEmailOffsetToken(null);
        setLoadedEmails([]);

        await loadEmails(false, true, null, null);
    };

    const onEmailSearch = async (searchValue?: string): Promise<void> => {
        if (!searchValue) {
            await onEmailSearchClear();
            return;
        }
        setSearchTerm(searchValue);
        setIsLoadingEmails(true);
        setEmailOffsetToken(null);
        setEmails([]);
        setLoadedEmails([]);
        await loadEmails(false, true, searchValue, null);
    };

    const onEmailSelectionStateChanged = (id: string, checked: boolean): void => {
        const emailsDeepCopy: EmailListItemWithSelectedStatus[] = JSON.parse(JSON.stringify(emails));
        const updatedEmails = emailsDeepCopy.map((email) => {
            if (email.id === id) {
                return { ...email, isSelected: checked };
            }
            return email;
        });
        setEmails(updatedEmails);
    };

    const onFormSubmit = useCallback(async (): Promise<void> => {
        setIsFiling(true);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        const selectedEmailIds = selectedEmailsIds(emails);
        const numberToFile = selectedEmailIds.length;
        try {
            showProgress(getFilingMessage(emails));

            const failedIds = await emailApiService.fileMultipleEmails(
                selectedEmailIds,
                selectedProject as ITag,
                selectedHub as IHub,
                supportedAddinItems
            );
            const successfulIds = selectedEmailIds.filter((id) => !failedIds.includes(id));
            if (deleteEmailAfterFiling) {
                const remainingEmails = emails.filter((email) => !successfulIds.includes(email.id));
                const remainingLoadedEmails = loadedEmails.filter((email) => !successfulIds.includes(email.id));
                setEmails(remainingEmails);
                setLoadedEmails(remainingLoadedEmails);
            } else {
                await loadEmails(false, false, null, null);
                const allEmailsAfterSave = emails.map((email) =>
                    successfulIds.includes(email.id) ? { ...email, isFiled: true } : email
                );
                const allLoadedEmailsAfterSave = loadedEmails.map((email) =>
                    successfulIds.includes(email.id) ? { ...email, isFiled: true } : email
                );
                setEmails(allEmailsAfterSave);
                setLoadedEmails(allLoadedEmailsAfterSave);
            }

            showProgress(null);

            let toastMessage;
            if (failedIds.length) {
                const errorMessageTemplate = translate("FILE_MULTIPLE_EMAIL.FILING_ERROR") as string;
                const errorMessage = pluralizeSentence(errorMessageTemplate, "EMAIL", numberToFile, translate);
                toastMessage = errorMessage
                    .replace("[[count]]", `${failedIds.length.toString()}`)
                    .replace("[[total]]", numberToFile.toString());

                onShowToast({ message: toastMessage }, MessageBarType.error);
                return;
            }

            const messageTemplate = translate("FILE_MULTIPLE_EMAIL.FILING_SUCCESS") as string;
            const message = pluralizeSentence(messageTemplate, "EMAIL", numberToFile, translate);
            toastMessage = message
                .replace("[[count]]", `${numberToFile.toString()}`)
                .replace("[[projectName]]", `${selectedProject?.name as string}`);

            onShowToast({ message: toastMessage }, MessageBarType.success);

            // if we enabled delete filed emails, make sure to delete the current item last
            if (successfulIds && currentEmailItem && deleteEmailAfterFiling) {
                const itemsToDelete = successfulIds.filter((successfulId) => successfulId !== currentEmailItem.id);
                itemsToDelete.map(async (emailId) => {
                    try {
                        await msGraphApiService.moveMessage(emailId);
                    } catch (e) {
                        console.error("Error while moving email to bin");
                    }
                });

                await msGraphApiService.moveMessage(currentEmailItem.id);
            }
        } catch (error) {
            logger.error("failed filing multiple emails");
            handleApiError(error, "FILE_MULTIPLE_EMAIL.ERROR_FILING_EMAIL_GENERIC");
        } finally {
            setIsFiling(false);
        }
    }, [
        emailApiService,
        showProgress,
        deleteEmailAfterFiling,
        translate,
        onShowToast,
        logger,
        emails,
        loadedEmails,
        folderId,
        selectedProject,
        selectedHub,
        supportedAddinItems,
        selectedEmailsIds,
        setIsFiling,
        handleApiError,
        loadEmails,
    ]);

    return {
        actions: {
            onSelectAllChanged,
            loadEmails,
            onEmailSearchClear,
            onEmailSearch,
            onEmailSelectionStateChanged,
            onFormSubmit,
        },
        states: {
            isLoadingEmails,
            isLoadingMoreEmails,
            isLoadingFolderName,
            isFiling,
            loadedEmails,
            emails,
            emailOffsetToken,
            searchTerm,
            folderName,
            folderId,
            currentEmailItem,
        },
    };
};

export default useFileEmails;
