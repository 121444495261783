import * as React from "react";

const HubSwitchIcon = () => (
    <svg fontWeight="700" width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3H9L7.5 1" stroke="#0078D4" />
        <path d="M10 6.5H1L2.5 8.5" stroke="#0078D4" />
    </svg>
);

export default HubSwitchIcon;
