import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";
import { Label } from "office-ui-fabric-react";
import { WindowWrapper } from "../../services/WindowWrapper";
import { TranslationService } from "../../services/TranslationService";

export interface LoginRedirectComponentProps extends LocalizeContextProps {
    translationService: TranslationService;
    windowWrapper: WindowWrapper;
}

export interface LoginRedirectComponentState {
    error: string | null;
}

// this component is needed because of the issue described here https://github.com/OfficeDev/office-js/issues/382
// the dialog needs to be initiated in the same domain as the rest of the add-in otherwise you can't await Office.onReady() and dialog fails to close after redirect
class LoginRedirectComponent extends React.Component<LoginRedirectComponentProps, LoginRedirectComponentState> {
    constructor(props: LoginRedirectComponentProps, context: LoginRedirectComponentState) {
        super(props, context);
        props.translationService.getTranslations(this.props);

        this.state = {
            error: null,
        };
    }

    componentDidMount(): void {
        const redirectUrl = this.props.windowWrapper.getQueryParam("redirectUrl");
        if (!redirectUrl) {
            this.setState({ error: "AUTHENTICATION.LOGIN_ERROR" });
            return;
        }
        this.props.windowWrapper.assignLocation(redirectUrl);
    }

    render(): JSX.Element {
        return <>{this.state.error ? <Label>{this.props.translate(this.state.error)}</Label> : null}</>;
    }
}

export default withLocalize(LoginRedirectComponent);
