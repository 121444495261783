import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { ROUTE_PATH_NAMES } from "../../utils/constants";

export interface RedirectComponentProps {
    isLoggedIn: boolean;
    location?: string;
}

export class RedirectComponent extends React.Component<RedirectComponentProps, any> {
    constructor(props: any) {
        super(props);
    }

    render(): JSX.Element {
        const { isLoggedIn, children } = this.props;

        return (
            <Route
                render={() => (isLoggedIn ? children : <Redirect to={{ pathname: ROUTE_PATH_NAMES.LOGIN_PATH }} />)}
            />
        );
    }
}
