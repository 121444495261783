import * as React from "react";

function SubmittalSuccessIcon() {
    return (
        <svg
            width="99"
            height="60"
            viewBox="0 0 99 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="submittalSuccessIcon"
        >
            <g clipPath="url(#clip0_601_2-944879)">
                <path
                    d="M49.5 59.4821C76.8381 59.4821 99 48.1151 99 34.0931C99 20.0711 76.8381 8.7041 49.5 8.7041C22.1619 8.7041 0 20.0711 0 34.0931C0 48.1151 22.1619 59.4821 49.5 59.4821Z"
                    fill="#EEEEEE"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80.9383 24.8211C80.8509 25.5043 80.5016 26.1732 79.8611 26.7995L79.468 27.1838L68.099 38.3142C66.6579 39.7233 63.9649 40.5916 61.0389 40.5916L37.3257 40.5346C34.4288 40.5346 31.7358 39.6522 30.2946 38.2431L18.7364 27.0557L18.3434 26.6714C17.6592 26.0024 17.2953 25.2623 17.2516 24.5079C17.2225 23.6682 17.5864 22.8284 18.3434 22.0883L30.1054 10.5878C31.5611 9.16445 34.225 8.29622 37.151 8.28198L60.8642 8.33892C63.7611 8.33892 66.4541 9.22138 67.8953 10.6305L79.8465 22.2021C80.6617 23.0134 81.0256 23.9244 80.9383 24.8211Z"
                    fill="url(#paint0_linear_601_2-485979)"
                />
                <path
                    d="M41.023 25.9311V35.2831H68.448V14.7151L41.023 25.9311Z"
                    fill="url(#paint1_linear_601_2-766430)"
                />
                <path
                    d="M44.124 32.9909V27.1839V20.0239H33.614V32.8489L44.124 32.9909Z"
                    fill="url(#paint2_linear_601_2-259016)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.8465 22.2021L67.8953 10.6305C66.4541 9.22138 63.7611 8.33892 60.8642 8.33892L37.151 8.28198C34.225 8.29622 31.5611 9.16445 30.1054 10.5878L18.3434 22.0883C17.5864 22.8284 17.2225 23.6682 17.2516 24.5079C17.2953 25.2623 17.6592 26.0024 18.3434 26.6714L18.7364 27.0557L30.2946 38.2431C31.7358 39.6522 34.4288 40.5346 37.3257 40.5346L61.0389 40.5916C63.9649 40.5916 66.6579 39.7233 68.099 38.3142L79.468 27.1838L79.8611 26.7995C80.5016 26.1732 80.8509 25.5043 80.9383 24.8211C81.0256 23.9244 80.6617 23.0134 79.8465 22.2021ZM64.0813 19.7113L63.863 19.8536L45.6668 29.8027C44.8953 30.2297 43.7162 30.2724 42.8719 29.9451L42.6098 29.8027L34.3269 25.2765C33.4681 24.8068 33.4389 24.0098 34.2833 23.5116C35.0402 23.0704 36.2776 23.0277 37.1655 23.3693L37.3984 23.4974L44.1238 27.1838L60.7915 18.0603C61.6794 17.5763 63.0769 17.5906 63.9212 18.0887C64.6927 18.53 64.7509 19.2416 64.0813 19.7113Z"
                    fill="url(#paint3_linear_601_2-509218)"
                />
                <path
                    d="M80.9383 24.8212V29.5609C81.0256 30.4576 80.6763 31.3543 79.8611 32.1513L68.099 43.6518C66.6579 45.0609 63.9649 45.9292 61.0389 45.9292L37.3257 45.8722C34.4288 45.8722 31.7358 44.9898 30.2946 43.5807L18.3434 32.0232C17.6447 31.34 17.2807 30.5857 17.2516 29.8455V24.5081C17.2953 25.2624 17.6592 26.0026 18.3434 26.6715L18.7364 27.0558L30.2946 38.2432C31.7358 39.6523 34.4288 40.5347 37.3257 40.5347L61.0389 40.5917C63.9649 40.5917 66.6579 39.7234 68.099 38.3144L79.468 27.1839L79.8611 26.7996C80.5016 26.1734 80.8509 25.5044 80.9383 24.8212Z"
                    fill="url(#paint4_linear_601_2-474914)"
                />
                <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
                    <path
                        d="M47.7922 26.0468C40.4598 30.2664 25.3324 25.7132 18 21.5L32.5 9C43.5 9 55.1055 21.8399 47.7922 26.0468Z"
                        fill="url(#paint5_radial_601_2-734505)"
                    />
                </g>
                <path
                    d="M24.92 8.31562V10.7023H46.8597C48.1578 10.7023 49.2102 9.65367 49.2102 8.36017V6.75635H24.9392V8.31562"
                    fill="url(#paint6_linear_601_2-136339)"
                />
                <path
                    d="M46.4765 8.32194H24.92V1.56934C24.9163 1.46246 24.9359 1.35606 24.9775 1.25748C25.061 1.03597 25.2047 0.842028 25.3927 0.697416C26.0427 0.211688 26.8421 -0.0335687 27.6537 0.00369915C28.4629 -0.02997 29.2591 0.214997 29.9084 0.697416C30.0479 0.806229 30.1632 0.942774 30.2469 1.09837C30.3308 1.24153 30.377 1.40357 30.381 1.56934V5.17794H46.5148C47.3293 5.14153 48.1311 5.38909 48.7822 5.87802C49.0547 6.08885 49.2224 6.40676 49.2421 6.74994C49.2038 7.62822 47.9902 8.32194 46.4765 8.32194Z"
                    fill="url(#paint7_linear_601_2-825214)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_601_2-485979"
                    x1="17.4411"
                    y1="1623.76"
                    x2="6387.54"
                    y2="1623.76"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BABABA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_601_2-766430"
                    x1="861.99"
                    y1="1003.6"
                    x2="4284.33"
                    y2="1596.79"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2A62DB" />
                    <stop offset="1" stopColor="#DAE6FB" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_601_2-259016"
                    x1="34"
                    y1="24"
                    x2="43"
                    y2="28.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4E7BEC" />
                    <stop offset="1" stopColor="#7DA2F9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_601_2-509218"
                    x1="19.5"
                    y1="24"
                    x2="79.5"
                    y2="26"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEFEFF" />
                    <stop offset="1" stopColor="#98B6FA" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_601_2-474914"
                    x1="22"
                    y1="35"
                    x2="79"
                    y2="33"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CEDDFC" />
                    <stop offset="1" stopColor="#4E7BEB" />
                </linearGradient>
                <radialGradient
                    id="paint5_radial_601_2-734505"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(33.7663 16.0818) rotate(90) scale(11.1011 17.9433)"
                >
                    <stop stopColor="#93CE9A" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint6_linear_601_2-136339"
                    x1="1239.74"
                    y1="484.086"
                    x2="1239.74"
                    y2="266.421"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A4D0B1" />
                    <stop offset="1" stopColor="#58C576" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_601_2-825214"
                    x1="28"
                    y1="3.8753e-07"
                    x2="46"
                    y2="4"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CCE9D0" />
                    <stop offset="1" stopColor="#87C98E" />
                </linearGradient>
                <clipPath id="clip0_601_2-944879">
                    <rect width="99" height="59.482" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SubmittalSuccessIcon;
