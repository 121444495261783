import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { IHub } from "../models/Hub";
import { IPersonaProps, ITag } from "office-ui-fabric-react";
import { useAppState } from "../store/AppProvider";
import { useAppService } from "../services/AppServiceProvider";
import { Email } from "@newforma/platform-client-api-sdk";
import { WithNextPage } from "../services/PimTrackApi/types";
import { IFiltersState, useProjectEmailSearch } from "./useProjectEmailSearch";
import { EmailFiltersMapping } from "../mapping/EmailFiltersMapping";

interface ProjectEmailResults {
    data?: InfiniteData<WithNextPage<Email.SearchResponse>>;
    fetchNextPage: () => Promise<unknown>;
    isError: unknown;
    isFetchingNextPage: boolean;
    hasNextPage: boolean;
    isFetching: boolean;
}

export function useProjectEmail(): ProjectEmailResults {
    const {
        states: { query, filters: filter },
    } = useProjectEmailSearch();

    const { appState } = useAppState();
    const {
        services: { pimTrackEmailAPIService },
    } = useAppService();

    const {
        isFetching,
        isError,
        data,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage = false,
    } = useInfiniteQuery(
        [
            "projectEmail",
            query,
            Object.values(filter ?? {}),
            `selectedProject-${appState.selectedProject?.key}`,
            `selectedHub-${appState.selectedHub?.key}`,
        ],
        ({ pageParam = 1 }) =>
            pimTrackEmailAPIService.searchEmails({
                pageNumber: pageParam,
                query: query,
                selectedHub: appState.selectedHub as IHub,
                selectedProject: appState.selectedProject as ITag,
                filter: EmailFiltersMapping.toRequest(filter),
            }),
        {
            getNextPageParam: (lastPage) => lastPage.paging.nextPage ?? undefined,
            enabled: !!appState.selectedHub && !!appState.selectedProject,
        }
    );

    return {
        isFetching,
        isError,
        data,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    };
}
