import { Keyword } from "../Keyword";

export enum TeamMemberType {
    MEMBER = "member",
    GROUP = "group",
}

export interface TeamViewPaging {
    offsetToken: string;
}

export interface TeamMember {
    firstName: string;
    lastName: string;
    email: string;
    discipline?: Keyword;
    phoneNumber?: string;
}

export interface TeamGroup {
    name: string;
}

export interface TeamMemberView {
    nrn: string;
    type: TeamMemberType;
    details: TeamGroup | TeamMember;
}

export interface TeamViewResponse {
    items: TeamMemberView[];
    paging: TeamViewPaging;
}

export interface TeamMemberNormalized {
    displayName: string;
    nrn: string;
    email?: string;
    discipline?: string;
}
