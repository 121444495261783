import LoginDialog from "../components/login/LoginDialog";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "./AnalyticsManager";
import { UnauthenticatedApiClient } from "./UnauthenticatedApiClient";
import { OfficeWrapper } from "./OfficeWrapper";
import { StorageWrapper } from "./StorageWrapper";
import { LocalStorageKeys } from "../models/StorageKeys";
import { Logger } from "./Logger";
import { BimCredentials } from "../models/BimCredentials";
import { ConfigurationService } from "./ConfigurationService";
import { WindowWrapper } from "./WindowWrapper";

export class Authenticator {
    onAuthenticationError: ((errorMessage: string) => Promise<void>) | undefined;
    private refreshCredentialsPromise: Promise<BimCredentials> | null = null;

    constructor(
        private configService: ConfigurationService,
        private windowWrapper: WindowWrapper,
        private loginDialog: LoginDialog,
        private storageWrapper: StorageWrapper,
        private apiClient: UnauthenticatedApiClient,
        private officeWrapper: OfficeWrapper,
        private analyticsManager: AnalyticsManager,
        private logger: Logger
    ) {}

    getStoredAccessToken(): string | null {
        return this.storageWrapper.loadLocalStorage(LocalStorageKeys.accessToken);
    }

    getStoredIdToken(): string | null {
        return this.storageWrapper.loadLocalStorage(LocalStorageKeys.idToken);
    }

    getRefreshToken(): string | null {
        return this.storageWrapper.loadLocalStorage(LocalStorageKeys.refreshToken);
    }

    isUserLoggedIn(): boolean {
        return !!this.getRefreshToken();
    }

    async logoutFromApp(): Promise<void> {
        const idToken = this.storageWrapper.loadLocalStorage(LocalStorageKeys.idToken);
        const url = `${this.configService.bimAuthURL}/connect/endsession?id_token_hint=${idToken}`;
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.accessToken);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.refreshToken);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.idToken);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.graphToken);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.addInUser);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.userHubPreference);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.supportedFeatureFlags);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.hub);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.hubSubscriptionInfo);

        return this.windowWrapper.openUrlInFrame(url, this.windowWrapper.logoutFrameName);
    }

    async ensureLocalStorageAccessible(): Promise<void> {
        const accessToken = this.storageWrapper.loadLocalStorage(LocalStorageKeys.accessToken);
        if (!accessToken) {
            if (this.onAuthenticationError) {
                await this.onAuthenticationError("AUTHENTICATION.COOKIE_ERROR");
            }
            this.logger.error("Could not access local storage. Cookies MUST be enabled for this application to work.");
        }
    }

    async getAuthorizationCode(codeChallenge: string): Promise<string> {
        const paramsObj = {
            response_type: "code",
            scope: "BIMTrack_Api PIMTrack_Api External_Mail_Api External_Access_Token SubscriptionService_Api_Read openid profile email offline_access",
            redirect_uri: this.configService.redirectURL,
            client_id: this.configService.clientId,
            code_challenge: codeChallenge,
            code_challenge_method: "S256",
            acr_values: "idp:Microsoft",
        };
        const authorizeUrl = new URL(`${this.configService.bimAuthURL}/connect/authorize`);
        const urlSearchParams = new URLSearchParams(paramsObj);
        urlSearchParams.forEach(function (value, key) {
            authorizeUrl.searchParams.append(key, value);
        });

        return this.loginDialog.openAuthDialog(authorizeUrl.toString(), false);
    }

    async handleLogin(authorizationCode: string, codeVerifier: string): Promise<BimCredentials> {
        const credentials = await this.apiClient.login(authorizationCode, codeVerifier);
        this.storageWrapper.saveLocalStorage(LocalStorageKeys.idToken, credentials.id_token);
        this.storageWrapper.saveLocalStorage(LocalStorageKeys.accessToken, credentials.access_token);
        this.storageWrapper.saveLocalStorage(LocalStorageKeys.refreshToken, credentials.refresh_token);
        await this.ensureLocalStorageAccessible();
        const loggedInUsersCompany = this.officeWrapper.userProfileEmailDomain;
        this.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserData,
            AnalyticsActionType.Company,
            loggedInUsersCompany
        );
        this.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, AnalyticsActionType.Login);

        return credentials;
    }

    async refreshCredentials(): Promise<BimCredentials> {
        if (this.refreshCredentialsPromise) {
            return this.refreshCredentialsPromise;
        }

        try {
            const refreshToken = this.storageWrapper.loadLocalStorage(LocalStorageKeys.refreshToken);
            if (!refreshToken) {
                this.logger.error("Could not find a Refresh Token.");
                throw new Error("Could not get refreshed credentials, no Refresh Token is available");
            }
            this.refreshCredentialsPromise = this.apiClient.createRefreshCredentialsPromise(refreshToken);
            const refreshedCredentials = await this.refreshCredentialsPromise;
            this.storageWrapper.saveLocalStorage(LocalStorageKeys.idToken, refreshedCredentials.id_token);
            this.storageWrapper.saveLocalStorage(LocalStorageKeys.accessToken, refreshedCredentials.access_token);
            this.storageWrapper.saveLocalStorage(LocalStorageKeys.refreshToken, refreshedCredentials.refresh_token);
            return refreshedCredentials;
        } finally {
            this.refreshCredentialsPromise = null;
        }
    }
}
