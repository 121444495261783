import { ConfigurationService } from "./ConfigurationService";
import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { Logger } from "./Logger";
import { BimCredentials } from "../models/BimCredentials";
export class UnauthenticatedApiClient {
    constructor(
        private configService: ConfigurationService,
        private requestWrapper: HttpRequestWrapper,
        private logger: Logger
    ) {}

    login(authorizationCode: string, codeVerifier: string): Promise<BimCredentials> {
        const url = `${this.configService.bimAuthURL}/connect/token`;
        const headers = { "x-newforma-agent": this.getNewformaAgent() }; // not needed- might bring some value in the future ??
        const options = { contentType: "application/x-www-form-urlencoded" };
        const payload = `code=${authorizationCode}&client_id=${
            this.configService.clientId
        }&redirect_uri=${encodeURIComponent(
            this.configService.redirectURL
        )}&grant_type=authorization_code&code_verifier=${codeVerifier}&scope=SubscriptionService_Api_Read`;

        this.logger.info(
            `UnauthenticatedApiClient.login(): logging in to ${this.configService.bimAuthURL}/connect/token 
             using code: ${authorizationCode}`
        );

        return this.requestWrapper.post(url, undefined, headers, payload, options);
    }

    createRefreshCredentialsPromise(refreshToken: string): Promise<BimCredentials> {
        const url = `${this.configService.bimAuthURL}/connect/token`;
        const headers = { "x-newforma-agent": this.getNewformaAgent() };
        const options = { contentType: "application/x-www-form-urlencoded" };
        const payload = `refresh_token=${refreshToken}&client_id=${this.configService.clientId}&grant_type=refresh_token`;

        this.logger.info(
            `Getting Refresh Credentials by calling ${this.configService.bimAuthURL}/connect/token and passing
              refreshToken: ${refreshToken} and clientId: ${this.configService.clientId}`
        );

        return this.requestWrapper.post(url, undefined, headers, payload, options);
    }

    private getNewformaAgent(): string {
        return `Outlook Web Add-in/${this.configService.version}`;
    }
}
