import { TextField } from "office-ui-fabric-react";
import * as React from "react";
import { useCallback } from "react";
import LabelComponent from "../../label/LabelComponent";
import "./TextFieldComponent.less";

export interface TextFieldComponentProps {
    className?: string;
    required: boolean;
    label: string;
    value?: string;
    onChange(value?: string): void;
    onClick?: () => void;
    onBlur?: () => void;
    disabled: boolean;
    maxLength?: number;
    multiline?: boolean;
    resizable?: boolean;
    rows?: number;
    placeholder?: string;
    dataTestId?: string;
}

function TextFieldComponent(props: TextFieldComponentProps) {
    const onTextFieldRenderLabel = useCallback(
        () => <LabelComponent text={props.label} required={props.required} />,
        [props.label, props.required]
    );

    const onChange = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        props.onChange(newValue);
    };

    return (
        <TextField
            className={`${props.className ?? ""} textFieldComponent`}
            disabled={props.disabled}
            maxLength={props.maxLength}
            multiline={props.multiline}
            onChange={onChange}
            onClick={props.onClick}
            onBlur={props.onBlur}
            onRenderLabel={onTextFieldRenderLabel}
            placeholder={props.placeholder}
            resizable={props.resizable}
            rows={props.rows}
            value={props.value}
            data-testid={props.dataTestId}
        />
    );
}

export default TextFieldComponent;
