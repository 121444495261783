import { IPersonaProps } from "office-ui-fabric-react";
import { BimContactResponse, BimContactUser } from "../models/Bimtrack/BimContactResponse";

export class ContactMapping {
    static mapResponseToIPersonaProps(response: BimContactResponse): IPersonaProps {
        const fullName = `${response.User.FirstName || ""} ${response.User.LastName || ""}`.trim();
        const initial = `${response.User.FirstName?.charAt(0)?.toUpperCase() || ""}${
            response.User.LastName?.charAt(0)?.toUpperCase() || ""
        }`.trim();

        return {
            id: response.User.UserName,
            text: `${fullName === "" ? response.User.Email.toLowerCase() : fullName}`,
            secondaryText: fullName === "" ? undefined : response.User.Email.toLowerCase(),
            imageUrl: response.User.AvatarUrl ? response.User.AvatarUrl : undefined,
            imageInitials: initial === "" ? undefined : initial,
        };
    }

    static mapUserResponseToIPersonaProps(response: BimContactUser): IPersonaProps {
        const fullName = `${response.FirstName || ""} ${response.LastName || ""}`.trim();
        const initial = `${response.FirstName?.charAt(0)?.toUpperCase() || ""}${
            response.LastName?.charAt(0)?.toUpperCase() || ""
        }`.trim();

        return {
            id: response.Id.toString(),
            text: `${fullName === "" ? response.Email.toLowerCase() : fullName}`,
            secondaryText: fullName === "" ? undefined : response.Email.toLowerCase(),
            imageUrl: response.AvatarUrl ? response.AvatarUrl : undefined,
            imageInitials: initial === "" ? undefined : initial,
        };
    }
}
