import { IDropdownOption, IPersonaProps, ITag } from "office-ui-fabric-react";
import { FileWithId } from "../../shared/FileWithId";
import AttachmentDetails = Office.AttachmentDetails;

export interface RfiReviewResponseFieldForm {
    rfiId: ITag | null;
    action?: IDropdownOption;
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    response?: string;
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
}

export interface ValidRfiReviewResponseFieldForm {
    rfiId: ITag;
    action: IDropdownOption;
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    response?: string;
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
}

export const defaultClearedRfiReviewResponseFieldForm: RfiReviewResponseFieldForm = {
    rfiId: null,
    action: undefined,
    from: [],
    to: [],
    cc: [],
    response: "",
    attachments: [],
    selectedAttachmentIds: [],
};
