import { ITag } from "office-ui-fabric-react";
import { Logger } from "../Logger";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { ConfigurationService } from "../ConfigurationService";
import { Hub, IHub } from "../../models/Hub";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { UserInfo } from "../../models/PimModels";
import { ProjectEmailSettings } from "@newforma/platform-client-api-sdk/dist/email";
import { BimProjectIssuesDetail, ProjectStatus } from "../../models/BimProject";

export interface EmailProjectFilingAccess {
    projectId: number;
    projectName: string;
    projectNumber?: string;
    projectStatus: ProjectStatus;
}

export interface EmailProjectFilingAccessResponse {
    items: EmailProjectFilingAccess[];
}

export interface UserPreferenceType {
    TimeZoneId: string;
    TimeZoneNameIana: string;
    Language: string;
    DateFormat: string;
    DateFormatCulture: string;
    Id: number;
    AvatarUrl: string | null;
}

export class BimProjectsApiService {
    constructor(
        private logger: Logger,
        private configService: ConfigurationService,
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper
    ) {}

    async getHubsList(): Promise<Hub[]> {
        this.logger.info("Retrieving hubs list");
        const url = `${this.configService.bimApiURL}/v3/hubs/`;
        const options = { url: url, method: "GET" };

        const hubs = await this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );

        this.logger.info(`Fetched Hubs: ${JSON.stringify(hubs, null, 4)}`);
        return hubs;
    }

    async fetchProjectEmailSettings(hub: IHub, project: ITag): Promise<ProjectEmailSettings> {
        this.logger.info("Retrieving fetchProjectEmailSettings");
        const domain = this.newformaApiClient.mapDomainRegion(hub);
        const url = `${domain}/v1/hubs/${hub.key}/projects/${project.key}/emails/settings`;
        const options = { url: url, method: "GET" };

        const emailSettings = await this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );

        this.logger.info(`Fetched emailSettings: ${JSON.stringify(emailSettings, null, 4)}`);
        return emailSettings;
    }

    async getProjectsEmailFilingAccess(hub: IHub): Promise<EmailProjectFilingAccessResponse> {
        this.logger.info("Retrieving project email access");
        const domain = this.newformaApiClient.mapDomainRegion(hub);
        const url = `${domain}/v1/hubs/${hub.key}/projects/emailaccess`;
        const options = { url, method: "GET" };

        const emailProjectFilingAccess = await this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );

        this.logger.info(
            `Fetched EmailProjectFilingAccessResponse: ${JSON.stringify(emailProjectFilingAccess, null, 4)}`
        );
        return emailProjectFilingAccess;
    }

    async fetchUserPreferencesByHub(currentUser: UserInfo, hub: IHub): Promise<UserPreferenceType> {
        const domain = this.configService.bimApiURL;
        const url = `${domain}/v3/hubs/${hub.key}/users/${currentUser.name}`;
        const options = { url, method: "GET" };

        return this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getUserinfo(): Promise<UserInfo> {
        this.logger.info("Retrieving userinfo");
        const url = `${this.configService.bimAuthURL}/connect/userinfo`;
        const options = { url, method: "GET" };

        const userInfo = await this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );

        this.logger.info(`Fetched userInfo: ${JSON.stringify(userInfo, null, 4)}`);
        return userInfo;
    }

    async getProjectIssuesAttributes(hub: IHub | null, project: ITag | null): Promise<BimProjectIssuesDetail> {
        this.logger.info("Retrieving getProjectIssuesAttributes");
        const domain = this.configService.bimApiURL;
        const url = `${domain}/v3/hubs/${hub?.key}/projects/${project?.key}`;
        const options = { url, method: "GET" };

        return this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }
}
