export class UnauthorizedError extends Error {
    private static Status = 403;

    constructor() {
        super("Unauthorized filing error");
        this.name = "UnauthorizedError";

        Object.setPrototypeOf(this, UnauthorizedError);
    }

    static isInstanceOf(value: any): value is UnauthorizedError {
        return value.status === UnauthorizedError.Status;
    }
}
