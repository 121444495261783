import { AnalyticsManager } from "./../../services/AnalyticsManager";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { Logger } from "../../services/Logger";
import { ProjectsApiService } from "../../services/NewformaApi/ProjectsApiService";
import { ProjectBaseInformation } from "../../models/ProjectsResponse";
import { InternetHeader } from "../../models/InternetHeader";
import { v4 as uuidv4 } from "uuid";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import { ITag } from "office-ui-fabric-react";
import { IHub } from "../../models/Hub";
import EmailAddressDetails = Office.EmailAddressDetails;

export class SendAndFileHelpers {
    private lastSelectedProjectEmailAddress: string | null = null;
    private officeEnumRecipient = "externalUser";
    private officeEnumResponse = "none";
    private updateRecipientsWithProjectEmailAddressInProgress: Promise<void> | null = null;

    constructor(
        private officeWrapper: OfficeWrapper,
        private logger: Logger,
        private projectsApiService: ProjectsApiService,
        private emailApiService: EmailApiService,
        private analyticsManager: AnalyticsManager,
        private smartFilingManager: SmartFilingManager
    ) {}

    async updateRecipientsWithProjectEmailAddress(project: ProjectBaseInformation | null): Promise<void> {
        if (!this.updateRecipientsWithProjectEmailAddressInProgress) {
            this.logger.info("No updateRecipientsWithProjectEmailAddressInProgress found");

            this.updateRecipientsWithProjectEmailAddressInProgress = this.updateRecipientsWithProjectEmailAddressImpl(
                project
            )
                .catch((error) => {
                    this.logger.error(
                        `Failed to update recipient with project email address... ${JSON.stringify(error)}`
                    );
                })
                .finally(() => {
                    this.updateRecipientsWithProjectEmailAddressInProgress = null;
                });
            return;
        }

        this.updateRecipientsWithProjectEmailAddressInProgress = this.updateRecipientsWithProjectEmailAddressInProgress
            .then(() => this.updateRecipientsWithProjectEmailAddressImpl(project))
            .catch((error) => {
                this.logger.error(`Failed to update recipient with project email address... ${JSON.stringify(error)}`);
            });
    }

    async addUniqueEmailsTo(emails: EmailAddressDetails[]): Promise<void> {
        const currentRecipients = await this.officeWrapper.getToAsync();
        const filteredRecipients = currentRecipients.filter(
            (recipient) => !emails.some((email) => recipient.emailAddress === email.emailAddress)
        );

        await this.officeWrapper.setToAsync([...filteredRecipients, ...emails]);
    }

    async addUniqueEmailsCc(emails: EmailAddressDetails[]): Promise<void> {
        const currentRecipients = await this.officeWrapper.getCcAsync();
        const filteredRecipients = currentRecipients.filter(
            (recipient) => !emails.some((email) => recipient.emailAddress === email.emailAddress)
        );

        await this.officeWrapper.setCcAsync([...filteredRecipients, ...emails]);
    }

    private async updateRecipientsWithProjectEmailAddressImpl(project: ProjectBaseInformation | null): Promise<void> {
        let toRecipients = await this.officeWrapper.getToAsync();
        let ccRecipients = await this.officeWrapper.getCcAsync();

        if (!project) {
            this.logger.warning("No project found");
            await this.removeEmailAddressFromRecipients(ccRecipients, toRecipients);

            this.lastSelectedProjectEmailAddress = null;
            return;
        }

        const result = await this.removeEmailAddressFromRecipients(ccRecipients, toRecipients);
        ccRecipients = result.ccRecipients;
        toRecipients = result.toRecipients;

        const filingAddress = await this.projectsApiService.getProjectEmailFilingAddress(project.nrn);

        if (!filingAddress) {
            this.logger.info(`email filing address not set for project ${project.name}. returning`);
            return;
        }

        this.lastSelectedProjectEmailAddress = filingAddress;

        if (
            toRecipients.some((x) => x.emailAddress === filingAddress) ||
            ccRecipients.some((x) => x.emailAddress === filingAddress)
        ) {
            this.logger.info(`email filing address ${filingAddress} already in email recipients. returning`);
            return;
        }

        const emailDetailToAdd = {
            displayName: project.name,
            emailAddress: filingAddress,
            appointmentResponse: this.officeEnumResponse,
            recipientType: this.officeEnumRecipient,
        };
        ccRecipients.push(emailDetailToAdd);

        this.logger.info(`adding project email address ${filingAddress} to cc field`);
        await this.officeWrapper.setCcAsync(ccRecipients);
    }

    private async removeEmailAddressFromRecipients(
        ccRecipients: EmailAddressDetails[],
        toRecipients: EmailAddressDetails[]
    ): Promise<{ ccRecipients: EmailAddressDetails[]; toRecipients: EmailAddressDetails[] }> {
        let filteredCcRecipients: EmailAddressDetails[] = ccRecipients;
        let filteredToRecipients: EmailAddressDetails[] = toRecipients;

        if (ccRecipients.some((x) => x.emailAddress === this.lastSelectedProjectEmailAddress)) {
            this.logger.info(
                `removing project email address ${this.lastSelectedProjectEmailAddress} from cc recipients`
            );

            const filteredRecipients = ccRecipients.filter(
                (x) => x.emailAddress !== this.lastSelectedProjectEmailAddress
            );
            await this.officeWrapper.setCcAsync(filteredRecipients);
            filteredCcRecipients = filteredRecipients;
        }
        if (toRecipients.some((x) => x.emailAddress === this.lastSelectedProjectEmailAddress)) {
            this.logger.info(
                `removing project email address ${this.lastSelectedProjectEmailAddress} from to recipients`
            );

            const filteredRecipients = toRecipients.filter(
                (x) => x.emailAddress !== this.lastSelectedProjectEmailAddress
            );
            await this.officeWrapper.setToAsync(filteredRecipients);
            filteredToRecipients = filteredRecipients;
        }

        return {
            ccRecipients: filteredCcRecipients,
            toRecipients: filteredToRecipients,
        };
    }

    async sendAndFile(selectedHub: IHub, selectedProject: ITag): Promise<void> {
        const mailboxItem = this.officeWrapper.currentContextItem;

        let emailId = await this.officeWrapper.getInternetHeader(InternetHeader.EmailId);

        const hubId = selectedHub.key;
        const projectId = selectedProject.key;
        const preExistingEmailId = !!emailId;

        if (!preExistingEmailId) {
            this.logger.info("First time filing this email. Generating emailId...");
            emailId = uuidv4();
        }

        const headers = {
            "X-BimTrack-EmailId": emailId,
            "X-BimTrack-FilingLocation": `${hubId}:${projectId}`,
        };

        await this.officeWrapper.setInternetHeaders(headers);

        if (!preExistingEmailId) {
            const hub = selectedHub;
            const project = selectedProject;

            await this.emailApiService.markEmailForFiling(emailId as string, hub, project);
        }

        const projectBaseInfo: ProjectBaseInformation = {
            nrn: selectedProject.key.toString(),
            name: selectedProject.name,
        };

        await this.smartFilingManager.addToFiledHistory(
            projectBaseInfo,
            mailboxItem.conversationId,
            this.officeWrapper.userProfileEmailAddress
        );
    }
}
