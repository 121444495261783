import * as React from "react";

function RfiSuccessIcon() {
    return (
        <svg
            width="99"
            height="59"
            viewBox="0 0 99 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="rfiSuccessIcon"
        >
            <g clipPath="url(#clip0_504_14-541785)">
                <path
                    d="M49.5 59C76.8381 59 99 47.6978 99 33.756C99 19.8141 76.8381 8.51196 49.5 8.51196C22.1619 8.51196 0 19.8141 0 33.756C0 47.6978 22.1619 59 49.5 59Z"
                    fill="#EBEBEB"
                />
                <path
                    d="M80.2371 31.7801V36.6959C80.2371 38.696 77.842 40.4989 74.2713 41.0482L40.2618 46.1471C35.9653 46.795 31.5381 45.3724 30.3914 42.9638L21.2613 23.9065C21.1161 23.6107 21.029 23.329 21 23.0332V17.8217C21 18.216 21.0871 18.6104 21.2758 19.0048L22.0161 20.5401L30.3769 38.0481C31.5381 40.4566 35.9508 41.8792 40.2618 41.2454L74.2567 36.1325C76.4631 35.8085 78.2049 34.9916 79.221 33.9633C79.8742 33.3013 80.2371 32.5548 80.2371 31.7801Z"
                    fill="url(#paint0_linear_504_14-241135)"
                />
                <path
                    d="M44.384 16.3569L37.678 32.9209L42.018 34.7379L64.807 24.9769L65.693 19.2299L59.48 14.9199L51.787 14.7369L44.384 16.3569Z"
                    fill="url(#paint1_linear_504_14-014632)"
                />
                <path d="M59.524 28.836V21.723H45.27V27.822L59.524 28.836Z" fill="url(#paint2_linear_504_14-404979)" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M59.9591 17.0188C55.8368 15.1032 49.842 15.1314 45.7632 17.0751C44.8342 17.5118 44.7326 18.3006 45.5309 18.8076C46.3147 19.3288 47.7082 19.3851 48.6372 18.9344C51.0758 17.7653 54.6756 17.7512 57.1577 18.8921C59.6253 20.0471 60.264 22.0331 58.6528 23.5825C57.8109 24.3995 55.9384 24.8502 53.3111 24.9347C52.2951 24.977 51.25 24.9488 50.2339 24.8924L49.3484 24.822L48.9856 24.7798C47.7953 24.6389 46.6341 25.0756 46.3873 25.7376C46.1406 26.3996 46.9099 27.0616 48.1001 27.1883C49.4065 27.3433 51.3806 27.456 53.5869 27.3715C57.5496 27.2306 60.7285 26.47 62.4558 24.7939C65.1411 22.2022 64.096 18.8921 59.9737 16.9625L59.9591 17.0188ZM44.3552 30.3716L44.0213 30.273C42.6714 29.949 41.0747 30.2308 40.3199 30.9632C39.507 31.752 39.9861 32.752 41.394 33.2027L41.7279 33.3013C43.0778 33.6253 44.6745 33.3436 45.4293 32.6112C46.2422 31.8224 45.7632 30.8223 44.3552 30.3716ZM60.9897 8.37046C65.2863 7.72254 69.7134 9.15924 70.8747 11.5678L79.9758 30.6111C81.1225 33.0196 78.5678 35.4986 74.2713 36.1466L40.2764 41.2454C35.9798 41.8933 31.5526 40.4566 30.3914 38.0481L21.2903 19.0048C20.1436 16.5962 22.6983 14.1172 26.9948 13.4693L60.9897 8.37046Z"
                    fill="url(#paint3_linear_504_14-045949)"
                />
                <path
                    style={{ mixBlendMode: "multiply" }}
                    opacity="0.3"
                    d="M47.7919 26.0467C40.4595 30.2663 23.1551 21.6798 15.8227 17.4666L43.9214 4.98059C51.2346 9.19381 55.1052 21.8398 47.7919 26.0467Z"
                    fill="url(#paint4_radial_504_14-307904)"
                />
                <path
                    d="M24.9197 8.31562V10.7023H46.8594C48.1575 10.7023 49.2099 9.65367 49.2099 8.36017V6.75635H24.9389V8.31562"
                    fill="url(#paint5_linear_504_14-136822)"
                />
                <path
                    d="M46.4762 8.32194H24.9197V1.56934C24.916 1.46246 24.9356 1.35606 24.9772 1.25748C25.0607 1.03597 25.2045 0.842028 25.3924 0.697416C26.0424 0.211688 26.8418 -0.0335687 27.6534 0.00369915C28.4626 -0.02997 29.2588 0.214997 29.9081 0.697416C30.0476 0.806229 30.1629 0.942774 30.2466 1.09837C30.3306 1.24153 30.3767 1.40357 30.3807 1.56934V5.17794H46.5145C47.329 5.14153 48.1308 5.38909 48.7819 5.87802C49.0544 6.08885 49.2221 6.40676 49.2418 6.74994C49.2035 7.62822 47.9899 8.32194 46.4762 8.32194Z"
                    fill="url(#paint6_radial_504_14-834910)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_504_14-241135"
                    x1="21"
                    y1="18"
                    x2="80"
                    y2="36.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C6D7FC" />
                    <stop offset="0.367672" stopColor="#ADC6FB" />
                    <stop offset="1" stopColor="#4E7BEB" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_504_14-014632"
                    x1="447.341"
                    y1="786.176"
                    x2="4949.65"
                    y2="3213.79"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2A62DB" />
                    <stop offset="0.45" stopColor="#769ADA" />
                    <stop offset="1" stopColor="#CFE1FF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_504_14-404979"
                    x1="-419.524"
                    y1="299.969"
                    x2="1561.41"
                    y2="737.888"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4889F5" />
                    <stop offset="0.51" stopColor="#4889F5" />
                    <stop offset="1" stopColor="#88B5FF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_504_14-045949"
                    x1="21"
                    y1="17"
                    x2="80"
                    y2="32.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#9AC0FF" />
                </linearGradient>
                <radialGradient
                    id="paint4_radial_504_14-307904"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(915.568 253.794) rotate(-13.002) scale(1421.98 654.606)"
                >
                    <stop stopColor="#2DC24F" />
                    <stop offset="0.13" stopColor="#2DC24F" stopOpacity="0.79" />
                    <stop offset="0.42" stopColor="#2DC24F" stopOpacity="0.37" />
                    <stop offset="0.62" stopColor="#2DC24F" stopOpacity="0.1" />
                    <stop offset="1" stopColor="#2DC24F" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint5_linear_504_14-136822"
                    x1="1239.74"
                    y1="484.086"
                    x2="1239.74"
                    y2="266.421"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A4D0B1" />
                    <stop offset="1" stopColor="#58C576" />
                </linearGradient>
                <radialGradient
                    id="paint6_radial_504_14-834910"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(25 8) rotate(-9.46233) scale(24.3311 71.1123)"
                >
                    <stop stopColor="#BAE1C0" />
                    <stop offset="1" stopColor="#89CA90" />
                </radialGradient>
                <clipPath id="clip0_504_14-541785">
                    <rect width="99" height="59" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default RfiSuccessIcon;
