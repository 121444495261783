export class ExpiredSessionError extends Error {
    private static Message = "The session has expired";

    constructor() {
        super(ExpiredSessionError.Message);

        Object.setPrototypeOf(this, ExpiredSessionError);
    }

    static isInstanceOf(value: any): value is ExpiredSessionError {
        return value.message === ExpiredSessionError.Message;
    }
}

export class InvalidGraphSessionError extends Error {
    private static Message = "missing folderId or currentEmailItem";

    constructor() {
        super(InvalidGraphSessionError.Message);

        Object.setPrototypeOf(this, ExpiredSessionError);
    }

    static isInstanceOf(value: any): value is InvalidGraphSessionError {
        return value.message === InvalidGraphSessionError.Message;
    }
}
