import { IDropdownOption, IPersonaProps, ITag } from "office-ui-fabric-react";
import { FileWithId } from "../../shared/FileWithId";
import AttachmentDetails = Office.AttachmentDetails;

export interface LogIssueFieldForm {
    title: string;
    number?: string;
    description?: string;
    status?: IDropdownOption;
    type?: IDropdownOption;
    priority?: IDropdownOption;
    disciplines: ITag[];
    zone?: IDropdownOption;
    phase?: IDropdownOption;
    assignedTo: IPersonaProps[];
    dueDate?: Date;
    group: ITag[];
    teamsInvolved: ITag[];
    notify: IPersonaProps[];
    isRestricted?: boolean;
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
}

export interface ValidLogIssueFieldForm {
    title: string;
    number?: string;
    description?: string;
    status?: IDropdownOption;
    type?: IDropdownOption;
    priority?: IDropdownOption;
    disciplines: ITag[];
    zone?: IDropdownOption;
    phase?: IDropdownOption;
    assignedTo: IPersonaProps[];
    dueDate?: Date;
    group: ITag[];
    teamsInvolved: ITag[];
    notify: IPersonaProps[];
    isRestricted?: boolean;
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
}

export const defaultClearedLogIssueFieldForm: LogIssueFieldForm = {
    title: "",
    number: "",
    description: "",
    status: undefined,
    type: undefined,
    priority: undefined,
    disciplines: [],
    zone: undefined,
    phase: undefined,
    assignedTo: [],
    dueDate: undefined,
    group: [],
    teamsInvolved: [],
    notify: [],
    isRestricted: false,
    attachments: [],
    selectedAttachmentIds: [],
};
