import { Submittal } from "@newforma/platform-client-api-sdk";
import { BaseProjectItemMapping } from "./BaseProjectItemMapping";
import { ValidSubmittalReviewResponseFieldForm } from "../models/workflow/submittal/SubmittalReviewResponse";

export class SubmittalReviewResponseMapping extends BaseProjectItemMapping {
    static toRequest(
        submittalReviewerResponseForm: ValidSubmittalReviewResponseFieldForm,
        sessionIds: string[],
        version: string
    ): Submittal.ReviewResponseRequest {
        return {
            response: this.toIdValue(submittalReviewerResponseForm.response.key),
            remarks: submittalReviewerResponseForm.remarks?.trim() || undefined,
            from: submittalReviewerResponseForm.from.map((x) => this.mapContactWithId(x))[0],
            to: submittalReviewerResponseForm.to.map(this.mapContactWithId),
            cc: submittalReviewerResponseForm.cc.map(this.mapContactWithId),
            attachments: sessionIds && sessionIds.length ? { uploadIds: sessionIds } : undefined,
            version: version,
        };
    }
}
