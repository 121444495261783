import { Common, Utils } from "@newforma/platform-client-api-sdk";
import { IPersonaProps, ITag } from "office-ui-fabric-react";

export class BaseProjectItemMapping {
    protected static toIdValue(value: number | string): Common.IdValue {
        return { id: Number(value) };
    }

    protected static mapContact(value: IPersonaProps): Utils.Either<Common.ContactWithUserId, Common.ContactWithEmail> {
        if (value.id === value.text) {
            return { email: value.id as string };
        }

        return this.mapContactWithId(value);
    }

    protected static mapContactWithId(value: IPersonaProps): Common.ContactWithUserId {
        return { userId: value.id as string };
    }

    protected static mapSpecSection(
        value: ITag,
        specSectionOptions: Common.LocalizedValue[]
    ): Utils.Either<Common.IdValue, Common.CustomValue> {
        const foundSpecSection = specSectionOptions.find((option) => option.name === value.name);

        if (foundSpecSection) {
            return { id: foundSpecSection.id };
        }

        return { customName: value.name };
    }
}
