import * as React from "react";
import { useState } from "react";
import { DefaultButton } from "office-ui-fabric-react";
import "./EmailFilterComponent.less";
import { useProjectEmailSearch } from "../../../../hooks/useProjectEmailSearch";
import EmailFiltersModal from "./EmailFiltersModalComponent";
import { withLocalize } from "react-localize-redux";

const EmailFilter = withLocalize(({ translate }): React.ReactElement => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const hideModal = () => setIsModalOpen(false);
    const {
        states: { isFilterOn: filtersApplied },
    } = useProjectEmailSearch();

    return (
        <>
            <DefaultButton
                data-testid="newforma-project-email-filter-button"
                className={`newforma-search-filters-button ${filtersApplied ? "filters-applied" : ""}`}
                id="projectEmailFilterButton"
                primary={false}
                onClick={() => setIsModalOpen(true)}
                text={translate("PROJECT_EMAIL.FILTERS.BUTTON_LABEL") as string}
                iconProps={{ iconName: filtersApplied ? "FilterSolid" : "Filter" }}
            />
            <EmailFiltersModal isModalOpen={isModalOpen} hideModal={hideModal} />
        </>
    );
});

export default EmailFilter;
