import { IDropdownOption, IPersonaProps, ITag } from "office-ui-fabric-react";
import { FileWithId } from "../../shared/FileWithId";
import AttachmentDetails = Office.AttachmentDetails;

export interface LogRfiFieldForm {
    subject: string;
    number?: string;
    specSection: ITag | null;
    receivedDate?: Date;
    dueDate?: Date;
    urgent: boolean;
    type?: IDropdownOption;
    senderNumber: string;
    from: IPersonaProps[];
    to: IPersonaProps[];
    question: string;
    suggestions?: string;
    impactSchedule?: IDropdownOption;
    impactCost?: IDropdownOption;
    reasons: ITag[];
    discipline: ITag[];
    keywords: ITag[];
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
}

export interface ValidLogRfiFieldForm {
    subject: string;
    number?: string;
    specSection: ITag | null;
    receivedDate: Date;
    dueDate: Date;
    urgent: boolean;
    type?: IDropdownOption;
    senderNumber: string;
    from: IPersonaProps[];
    to: IPersonaProps[];
    question: string;
    suggestions?: string;
    impactSchedule?: IDropdownOption;
    impactCost?: IDropdownOption;
    reasons: ITag[];
    discipline: ITag[];
    keywords: ITag[];
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
}

export const defaultClearedLogRfiFieldForm: LogRfiFieldForm = {
    subject: "",
    number: "",
    specSection: null,
    receivedDate: undefined,
    dueDate: undefined,
    urgent: false,
    type: undefined,
    senderNumber: "",
    from: [],
    to: [],
    question: "",
    suggestions: "",
    impactCost: undefined,
    impactSchedule: undefined,
    reasons: [],
    discipline: [],
    keywords: [],
    attachments: [],
    selectedAttachmentIds: [],
};
