import { BimStatus } from "../models/Bimtrack/BimStatus";
import { IDropdownOption, ITag } from "office-ui-fabric-react";
import { BimPriority } from "../models/Bimtrack/BimPriority";
import { BimProjectAttributeWithColor } from "../models/Bimtrack/BimProjectAttributeWithColor";
import { BimProjectAttribute } from "../models/Bimtrack/BimProjectAttribute";

export class BimProjectAttributesMapping {
    static mapBimStatusResponseToIDropdownOption(response: BimStatus): IDropdownOption {
        return { text: response.Name, key: response.Id, data: response };
    }

    static mapBimPrioritiesResponseToIDropdownOption(response: BimPriority): IDropdownOption {
        return { text: response.Name, key: response.Id, data: response };
    }

    static mapBimProjectAttributeWithColorToIDropdownOption(response: BimProjectAttributeWithColor): IDropdownOption {
        return { text: response.Name, key: response.Id, data: response };
    }

    static mapBimProjectAttributeToITag(response: BimProjectAttribute): ITag {
        return { name: response.Name, key: response.Id.toString() };
    }

    static mapBimGroupToITag(response: string): ITag {
        return { name: response, key: response };
    }
}
