import { ProgressIndicator } from "office-ui-fabric-react";
import * as React from "react";
import { useProgressBarData } from "./useProgressBar";

export default function ProgressBar(): React.ReactElement | null {
    const { progressMessage } = useProgressBarData();

    if (!progressMessage) {
        return null;
    }

    return (
        <div className={"bimtrack-progress"}>
            <ProgressIndicator label={progressMessage} className="bimtrack-progressIndicator" />
        </div>
    );
}
