import { IBasePickerSuggestionsProps, ITag, TagPicker } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import LabelComponent from "../../label/LabelComponent";
import "../Common.less";
import { SHORT_FIELD_LENGTH } from "../fieldConstants";

export interface ProjectItemPickerComponentProps extends LocalizeContextProps {
    className?: string;
    disabled: boolean;
    inputPlaceholder: string;
    label: string;
    onChange: (selectedItems?: ITag[]) => void;
    onResolveSuggestions: (filter: string, selectedItems?: ITag[]) => Promise<ITag[]>;
    onEmptyInputFocus: (selectedItems?: ITag[]) => Promise<ITag[]>;
    required: boolean;
    selectedItems: ITag | null;
}

function ProjectItemPickerComponent(props: ProjectItemPickerComponentProps) {
    const picker = React.useRef<any>(null);

    const getPickerSuggestionProps = (): IBasePickerSuggestionsProps => ({
        suggestionsHeaderText: undefined,
        noResultsFoundText: props.translate("CHIP_PICKER.NO_RESULTS_FOUND") as string,
        loadingText: props.translate("CHIP_PICKER.LOADING") as string,
        showRemoveButtons: false,
    });

    const onRenderSuggestionsItem = (iTag: ITag) => <div className="dropdownSuggestedItem">{iTag.name}</div>;

    return (
        <div className={`${props.className ?? ""}`} data-testid={"projectItemPickerComponentContainer"}>
            <LabelComponent text={props.label} required={props.required} />
            <TagPicker
                componentRef={picker}
                className="projectItemPickerComponent"
                selectedItems={props.selectedItems ? [props.selectedItems] : []}
                pickerSuggestionsProps={getPickerSuggestionProps()}
                onResolveSuggestions={props.onResolveSuggestions}
                onChange={props.onChange}
                disabled={props.disabled}
                inputProps={{
                    maxLength: SHORT_FIELD_LENGTH,
                    placeholder: props.inputPlaceholder,
                }}
                resolveDelay={300}
                itemLimit={1}
                onBlur={() => {
                    if (picker.current) {
                        picker.current.input.current?._updateValue("");
                    }
                }}
                onEmptyInputFocus={props.onEmptyInputFocus}
                onRenderSuggestionsItem={onRenderSuggestionsItem}
            />
        </div>
    );
}

export default withLocalize(ProjectItemPickerComponent);
