import * as React from "react";
import { useEffect } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import WorkflowItemSuccessComponent from "../shared/itemSuccess/ItemSuccessComponent";
import { useWorkflowItem } from "../shared/itemSuccess/ItemContext";
import IssueComponent from "./IssueComponent";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import { DateHelpers } from "../../helpers/DateHelpers";
import { ITag, MessageBarType } from "office-ui-fabric-react";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { RelatedItemApiService } from "../../services/PimTrackApi/RelatedItemApiService";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { SupportedAddinItems } from "../../models/SupportedItems";
import "./IssueComponentWrapper.less";
import { BimProjectsApiService } from "../../services/BimApi/BimProjectsApiService";
import { IssueApiService } from "../../services/BimApi/IssueApiService";
import { ToastMessage } from "../../models/ToastMessage";

export interface IssueComponentWrapperProps extends LocalizeContextProps {
    formValidationHelpers: FormValidationHelpers;
    officeWrapper: OfficeWrapper;
    dateHelpers: DateHelpers;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    bimProjectsApiService: BimProjectsApiService;
    selectedProject: ITag | null;
    selectedHub: ITag | null;
    showProgress: (message: string | null) => void;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
    issueApiService: IssueApiService;
}

function IssueComponentWrapper(props: IssueComponentWrapperProps) {
    const {
        actions: { setSuccessInfo, setIsEmailFromDeletion },
        states: { successInfo, isEmailFromDeletion },
    } = useWorkflowItem();

    useEffect(() => {
        // Set successInfo to undefined when email itemId changed
        if (successInfo) {
            // If email was deleted previously reset IsEmailFromDeletion to false
            // So on the next props.officeWrapper.currentContextItem.itemId change, it will hide success screen
            if (isEmailFromDeletion) {
                setIsEmailFromDeletion(false);
            } else {
                setSuccessInfo(undefined);
            }
        }
    }, [props.officeWrapper.currentContextItem.itemId]);

    return (
        <div className="issuecomponent-wrapper">
            {successInfo ? (
                <WorkflowItemSuccessComponent successInfo={successInfo} />
            ) : (
                <>
                    <IssueComponent
                        formValidationHelpers={props.formValidationHelpers}
                        officeWrapper={props.officeWrapper}
                        dateHelpers={props.dateHelpers}
                        onShowToast={props.onShowToast}
                        selectedHub={props.selectedHub}
                        selectedProject={props.selectedProject}
                        bimProjectsApiService={props.bimProjectsApiService}
                        showProgress={props.showProgress}
                        emailApiService={props.emailApiService}
                        relatedItemApiService={props.relatedItemApiService}
                        officeRoamingSettings={props.officeRoamingSettings}
                        supportedAddinItems={props.supportedAddinItems}
                        issueApiService={props.issueApiService}
                        navigateBackToForm={() => setSuccessInfo(undefined)}
                    />
                </>
            )}
        </div>
    );
}

export default withLocalize(IssueComponentWrapper);
