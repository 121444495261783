export interface Aptrinsic {
    (...params: unknown[]): void;
    q?: unknown[][];
    p?: string;
    c?: {
        iframeModeEnabled: boolean;
    };
}
declare global {
    interface GainSightWindow extends Window {
        aptrinsic?: Aptrinsic;
    }
}
const GainSightWindow = window as unknown as GainSightWindow;
export default GainSightWindow;
