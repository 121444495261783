import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";
import { DatePicker } from "office-ui-fabric-react";
import "./TranslatedDatePickerComponent.less";

export interface TranslatedDatePickerComponentProps extends LocalizeContextProps {
    date?: Date;
    onDateChange: (date: Date | undefined) => void;
    placeholder?: string;
    isDisabled?: boolean;
    label?: string;
    required: boolean;
    className?: string;
    minDate?: Date;
    maxDate?: Date;
    showClearDateButton?: boolean;
}

export interface TranslatedDatePickerComponentState {
    selectedDate: Date | undefined;
}

class TranslatedDatePickerComponent extends React.Component<
    TranslatedDatePickerComponentProps,
    TranslatedDatePickerComponentState
> {
    constructor(props: TranslatedDatePickerComponentProps, context: TranslatedDatePickerComponentState) {
        super(props, context);

        this.state = {
            selectedDate: undefined,
        };
    }

    componentDidMount(): void {
        this.setState({ selectedDate: this.props.date });
    }

    async componentDidUpdate(
        prevProps: Readonly<TranslatedDatePickerComponentProps>,
        prevState: Readonly<TranslatedDatePickerComponentState>
    ): Promise<void> {
        if (prevProps.date !== this.props.date) {
            this.setState({ selectedDate: this.props.date });
        }
    }

    private getMonths(): string[] {
        return [
            this.props.translate("DATES.MONTHS.JANUARY") as string,
            this.props.translate("DATES.MONTHS.FEBRUARY") as string,
            this.props.translate("DATES.MONTHS.MARCH") as string,
            this.props.translate("DATES.MONTHS.APRIL") as string,
            this.props.translate("DATES.MONTHS.MAY") as string,
            this.props.translate("DATES.MONTHS.JUNE") as string,
            this.props.translate("DATES.MONTHS.JULY") as string,
            this.props.translate("DATES.MONTHS.AUGUST") as string,
            this.props.translate("DATES.MONTHS.SEPTEMBER") as string,
            this.props.translate("DATES.MONTHS.OCTOBER") as string,
            this.props.translate("DATES.MONTHS.NOVEMBER") as string,
            this.props.translate("DATES.MONTHS.DECEMBER") as string,
        ];
    }

    private getShortMonths(): string[] {
        return [
            this.props.translate("DATES.MONTHS_SHORT.JANUARY") as string,
            this.props.translate("DATES.MONTHS_SHORT.FEBRUARY") as string,
            this.props.translate("DATES.MONTHS_SHORT.MARCH") as string,
            this.props.translate("DATES.MONTHS_SHORT.APRIL") as string,
            this.props.translate("DATES.MONTHS_SHORT.MAY") as string,
            this.props.translate("DATES.MONTHS_SHORT.JUNE") as string,
            this.props.translate("DATES.MONTHS_SHORT.JULY") as string,
            this.props.translate("DATES.MONTHS_SHORT.AUGUST") as string,
            this.props.translate("DATES.MONTHS_SHORT.SEPTEMBER") as string,
            this.props.translate("DATES.MONTHS_SHORT.OCTOBER") as string,
            this.props.translate("DATES.MONTHS_SHORT.NOVEMBER") as string,
            this.props.translate("DATES.MONTHS_SHORT.DECEMBER") as string,
        ];
    }

    private getDays(): string[] {
        return [
            this.props.translate("DATES.DAYS.SUNDAY") as string,
            this.props.translate("DATES.DAYS.MONDAY") as string,
            this.props.translate("DATES.DAYS.TUESDAY") as string,
            this.props.translate("DATES.DAYS.WEDNESDAY") as string,
            this.props.translate("DATES.DAYS.THURSDAY") as string,
            this.props.translate("DATES.DAYS.FRIDAY") as string,
            this.props.translate("DATES.DAYS.SATURDAY") as string,
        ];
    }

    private getShortDays(): string[] {
        return [
            this.props.translate("DATES.DAYS_SHORT.SUNDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.MONDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.TUESDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.WEDNESDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.THURSDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.FRIDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.SATURDAY") as string,
        ];
    }

    private onDateChange(date: Date | null | undefined): void {
        if (!date) {
            return;
        }
        this.props.onDateChange(date);
        this.setState({ selectedDate: date });
    }

    private clearDate(): void {
        if (this.props.isDisabled) {
            return;
        }
        // there's an open PR for a fix for this from microsoft https://github.com/DiscipleTools/disciple-tools-theme/issues/643
        // workaround until fix is officially implemented
        this.props.onDateChange(undefined);
        this.setState({ selectedDate: undefined });
    }

    render(): JSX.Element {
        return (
            <div className={`newforma-translatedDatePickerComponent ${this.props.className}`}>
                <DatePicker
                    className="newforma-datePicker"
                    label={this.props.label}
                    isMonthPickerVisible={false}
                    onSelectDate={this.onDateChange.bind(this)}
                    value={this.state.selectedDate}
                    disabled={this.props.isDisabled}
                    allowTextInput={false}
                    disableAutoFocus={false}
                    strings={{
                        days: this.getDays(),
                        shortDays: this.getShortDays(),
                        goToToday: this.props.translate("DATES.GO_TO_TODAY") as string,
                        months: this.getMonths(),
                        shortMonths: this.getShortMonths(),
                    }}
                    placeholder={this.props.placeholder}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    isRequired={this.props.required}
                />

                {!!this.props.showClearDateButton ? (
                    <div className="newforma-dateClear" onClick={this.clearDate.bind(this)}>
                        {this.props.translate("ACTION_ITEM.CLEAR_DATE") as string}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withLocalize(TranslatedDatePickerComponent);
