import * as React from "react";
import { LaunchDarklyProvider } from "@bimone/bimtrack-core";
import { useAppService } from "../../services/AppServiceProvider";
import { useAppState } from "../../store/AppProvider";
import FeatureFlagContainer from "./FeatureFlagContainer";

export interface FeatureFlagContainerProps {
    children: React.ReactNode;
}
function FeatureFlagProvider(props: FeatureFlagContainerProps) {
    const {
        services: { configService },
    } = useAppService();
    const {
        appState: { selectedHub, addinUserInfo },
    } = useAppState();

    return (
        <LaunchDarklyProvider
            clientSideId={configService.launchDarklyClientSideId}
            hubName={selectedHub?.name}
            userEmail={addinUserInfo?.email}
        >
            <FeatureFlagContainer>{props.children}</FeatureFlagContainer>
        </LaunchDarklyProvider>
    );
}

export default FeatureFlagProvider;
