import * as React from "react";
import "./AttachmentsComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Label } from "office-ui-fabric-react";
import AttachmentDetails = Office.AttachmentDetails;
import AttachmentComponent from "./attachment/AttachmentComponent";
import Dropzone, { DropEvent } from "react-dropzone";
import { FileWithId } from "../../../models/shared/FileWithId";

export interface AttachmentsComponentProps extends LocalizeContextProps {
    attachments: (AttachmentDetails | FileWithId)[];
    disabled: boolean;
    onAttachmentSelectionChange: (attachmentsIds: string[]) => void;
    onAttachmentsAdded?: (attachments: FileWithId[]) => void;
    allowFileUploads?: boolean;
}

export interface AttachmentsComponentState {
    selectedAttachmentIds: string[];
    uploadError: string | null;
}

class AttachmentsComponent extends React.Component<AttachmentsComponentProps, AttachmentsComponentState> {
    constructor(props: AttachmentsComponentProps, context: AttachmentsComponentState) {
        super(props, context);

        this.state = {
            selectedAttachmentIds: [],
            uploadError: null,
        };
    }

    componentDidUpdate(prevProps: AttachmentsComponentProps, prevState: AttachmentsComponentState) {
        const currentAttachments = this.props.attachments;
        const previousAttachments = prevProps.attachments;

        if (currentAttachments !== prevProps.attachments) {
            // add newly uploaded attachments to selectedId array
            if (previousAttachments.length !== currentAttachments.length) {
                const newlyAddedAttachments = currentAttachments
                    .slice(previousAttachments.length)
                    .map((attachment) => attachment.id);
                this.setState({
                    selectedAttachmentIds: [...prevState.selectedAttachmentIds, ...newlyAddedAttachments],
                });
            }
        }
    }

    onDropAccepted(files: File[], event: DropEvent): void {
        this.setState({ uploadError: null });

        if (!files.length) {
            return;
        }

        const existingNames = this.props.attachments.map((x) => x.name.toLowerCase());
        if (files.some((file) => existingNames.includes(file.name.toLowerCase()))) {
            this.setState({ uploadError: this.props.translate("SHARED.ATTACHMENTS.DUPLICATE_NAME") as string });
            return;
        }

        if (this.props.onAttachmentsAdded) {
            const filesWithId = files.map((file) => ({ file: file, id: file.name, name: file.name }));
            this.props.onAttachmentsAdded(filesWithId);
        }
    }

    private renderAttachments(): JSX.Element {
        const attachmentComponents = this.props.attachments.map((attachment, index) => (
            <AttachmentComponent
                key={`attachmentComponent${index}${attachment.id}`}
                name={attachment.name}
                exchangeId={attachment.id}
                onSelectionStateChanged={this.onAttachmentSelectionStateChanged.bind(this)}
                disabled={this.props.disabled}
            />
        ));

        return <>{attachmentComponents}</>;
    }

    private onAttachmentSelectionStateChanged(id: string, isSelected: boolean): void {
        const currentAttachments = this.state.selectedAttachmentIds;

        if (isSelected) {
            currentAttachments.push(id);
            this.setState({ selectedAttachmentIds: currentAttachments });
            this.props.onAttachmentSelectionChange(currentAttachments);
            return;
        }

        const filteredAttachments = currentAttachments.filter((attachmentId) => attachmentId !== id);

        const uniqueId = Array.from(new Set(filteredAttachments));
        this.setState({ selectedAttachmentIds: Array.from(uniqueId) });
        this.props.onAttachmentSelectionChange(uniqueId);
    }

    render(): JSX.Element {
        return (
            <>
                {this.props.attachments.length ? (
                    <div className="newforma-attachmentsContainer newforma-aiSpacing">
                        <Label>{this.props.translate("SHARED.ATTACHMENTS.ATTACHMENTS_LABEL") as string}</Label>
                        {this.renderAttachments()}
                    </div>
                ) : null}
                {this.props.allowFileUploads ? (
                    <>
                        {this.state.uploadError ? (
                            <div className="newforma-uploadError">{this.state.uploadError}</div>
                        ) : null}
                        <Dropzone
                            onDropAccepted={this.onDropAccepted.bind(this)}
                            multiple={true}
                            noClick={false}
                            noDrag={false}
                            noKeyboard={true}
                            minSize={1}
                            preventDropOnDocument={true}
                            disabled={this.props.disabled}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "newforma-dropzone" })}>
                                    <input {...getInputProps()} />
                                    <p className="newforma-dropzoneHint">
                                        {this.props.translate("SHARED.ATTACHMENTS.DRAG_AND_DROP_HINT") as string}
                                    </p>
                                </div>
                            )}
                        </Dropzone>
                    </>
                ) : null}
            </>
        );
    }
}

export default withLocalize(AttachmentsComponent);
