import { DefaultButton, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, TranslateFunction, withLocalize } from "react-localize-redux";
import { AppPage } from "../../models/AppPage";
import { EmailListItem } from "../../models/EmailListResponse";
import { Logger } from "../../services/Logger";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import EmailBodyComponent from "./EmailBodyComponent";
import "./FileMultipleEmailComponent.less";
import { Authenticator } from "../../services/Authenticator";
import useFileEmails from "../../hooks/useFileEmails";
import { ToastMessage } from "../../models/ToastMessage";
import { useFileEmailHelpers } from "../../hooks/useFileEmailsHelpers";

export interface EmailListItemWithSelectedStatus extends EmailListItem {
    isSelected: boolean;
}

export interface FileMultipleEmailComponentProps extends LocalizeContextProps {
    actions: {
        onExpiredSession: () => void;
        onShowToast: (message: ToastMessage | null, type: MessageBarType) => void;
        onSetNavigationPage: (page: AppPage) => void;
        showProgress: (message: string | null) => void;
        translate: TranslateFunction;
    };
    states: {
        deleteEmailAfterFiling: boolean;
    };
    services: {
        logger: Logger;
        emailApiService: EmailApiService;
        authenticator: Authenticator;
    };
}

function FileMultipleEmailComponent(props: FileMultipleEmailComponentProps): JSX.Element {
    const { states, actions } = useFileEmails(props);
    const {
        isLoadingFolderName,
        isLoadingEmails,
        emailOffsetToken,
        searchTerm,
        isFiling,
        folderName,
        isLoadingMoreEmails,
        emails,
        folderId,
    } = states;
    const {
        onSelectAllChanged,
        loadEmails,
        onEmailSearchClear,
        onEmailSearch,
        onEmailSelectionStateChanged,
        onFormSubmit,
    } = actions;

    const { getButtonText, isFormValid, areAllItemsSelected } = useFileEmailHelpers(props);
    return (
        <div className="newforma-fileMultipleEmailComponent">
            <div className="newforma-fileMultipleEmailComponentForm">
                <EmailBodyComponent
                    isLoadingFolderName={isLoadingFolderName}
                    isLoadingEmails={isLoadingEmails}
                    emailOffsetToken={emailOffsetToken || ""}
                    searchTerm={searchTerm || ""}
                    isFiling={isFiling}
                    folderName={folderName}
                    isLoadingMoreEmails={isLoadingMoreEmails}
                    emails={emails}
                    onSelectAllChanged={onSelectAllChanged}
                    areAllItemsSelected={areAllItemsSelected}
                    loadEmails={() => loadEmails(true, false, searchTerm || null, emailOffsetToken || null)}
                    onEmailSearchClear={onEmailSearchClear}
                    onEmailSearch={onEmailSearch}
                    onEmailSelectionStateChanged={onEmailSelectionStateChanged}
                    folderId={folderId}
                />
            </div>
            <div id="footer" key="footer" className="newforma-footer">
                <DefaultButton
                    data-testid="newforma-footerButton"
                    className="newforma-footerButton"
                    id="fileMultipleEmailsButton"
                    primary={true}
                    onClick={onFormSubmit}
                    text={getButtonText(emails)}
                    disabled={!isFormValid(emails) || isFiling}
                />
            </div>
        </div>
    );
}

export default withLocalize(FileMultipleEmailComponent);
