import { Request } from "aws-sign-web";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { ProjectItemFeature } from "./types";

export enum Method {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
}

export class BaseApiService {
    protected readonly apiVersion = "v1";
    protected readonly bimApiVersion = "v3";

    constructor(protected newformaApiClient: NewformaApiClient) {}

    protected getOptions(url: string, method: Method): Request {
        return {
            hostname: this.newformaApiClient.getHostName(),
            url,
            method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Accept-Language": Office.context.displayLanguage,
            },
        };
    }

    protected basePimUrl(domain: string, hubId: string, projectId: string, projectItemFeature: ProjectItemFeature) {
        return new URL(
            `${this.apiVersion}/hubs/${hubId}/projects/${projectId}/${projectItemFeature}`,
            domain
        ).toString();
    }

    protected baseBimUrl(domain: string, hubId: string, projectId: string) {
        return new URL(`${this.bimApiVersion}/hubs/${hubId}/projects/${projectId}`, domain).toString();
    }
}
