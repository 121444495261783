import { Common } from "@newforma/platform-client-api-sdk";
import { AttachmentDetailsMetadata } from "../models/AttachmentDetailsMetadata";

export class UploadFileMapping {
    static toFileMetadata(attachment: AttachmentDetailsMetadata): Common.FileMetadata {
        return {
            fileName: attachment.fileName,
            sizeInBytes: attachment.file.size,
        };
    }

    static toUploadRequest(fileMetadatum: Common.FileMetadata[]): Common.UploadRequest {
        return {
            files: fileMetadatum,
        };
    }
}
