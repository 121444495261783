import * as React from "react";
import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

export interface ProgressBarActionsContextProps {
    showProgress: (message: string | null) => void;
}

export interface ProgressBarDataContextProps {
    progressMessage: string | null;
}

const ProgressBarActionsContext = createContext<ProgressBarActionsContextProps>({
    showProgress: () => {},
});

const ProgressBarDataContext = createContext<ProgressBarDataContextProps>({
    progressMessage: null,
});

export default function ProgressBarProvider({ children }: PropsWithChildren<unknown>) {
    const [progressMessage, setProgressMessage] = useState<string | null>(null);

    const data = useMemo<ProgressBarDataContextProps>(() => ({ progressMessage }), [progressMessage]);
    const actions = useMemo<ProgressBarActionsContextProps>(() => ({ showProgress: setProgressMessage }), []);

    return (
        <ProgressBarDataContext.Provider value={data}>
            <ProgressBarActionsContext.Provider value={actions}>{children}</ProgressBarActionsContext.Provider>
        </ProgressBarDataContext.Provider>
    );
}

export const useProgressBar = (): ProgressBarActionsContextProps => useContext(ProgressBarActionsContext);
export const useProgressBarData = (): ProgressBarDataContextProps => useContext(ProgressBarDataContext);
