import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";
import { Checkbox } from "office-ui-fabric-react";
import "./AttachmentComponent.less";

export interface ActionItemAttachmentComponentProps extends LocalizeContextProps {
    name: string;
    exchangeId: string;
    onSelectionStateChanged: (id: string, isSelected: boolean) => void;
    disabled: boolean;
}

export interface ActionItemAttachmentComponentState {
    isSelected: boolean;
}

class AttachmentComponent extends React.Component<
    ActionItemAttachmentComponentProps,
    ActionItemAttachmentComponentState
> {
    private attachmentNameWithoutExtension: string = "";
    private attachmentExtension: string = "";

    constructor(props: ActionItemAttachmentComponentProps, context: ActionItemAttachmentComponentState) {
        super(props, context);

        this.state = { isSelected: true };

        this.splitFilenameIntoParts();
    }

    private splitFilenameIntoParts(): void {
        const attachmentFullName = this.props.name;
        const extensionIndex = attachmentFullName.lastIndexOf(".");

        if (extensionIndex > -1) {
            this.attachmentNameWithoutExtension = attachmentFullName.substr(0, extensionIndex);
            this.attachmentExtension = attachmentFullName.substr(extensionIndex);
            return;
        }

        this.attachmentNameWithoutExtension = attachmentFullName;
    }

    private onSelectionChange(event?: React.FormEvent<HTMLElement>, checked?: boolean) {
        if (checked === undefined || checked === null) {
            return;
        }

        this.setState({ isSelected: checked });
        this.props.onSelectionStateChanged(this.props.exchangeId, checked);
    }

    render(): JSX.Element {
        return (
            <div className={"newforma-attachmentComponent"}>
                <Checkbox
                    className="newforma-attachmentCheckbox"
                    checked={this.state.isSelected}
                    onChange={this.onSelectionChange.bind(this)}
                    disabled={this.props.disabled}
                />
                <div className="newforma-attachmentName">{this.attachmentNameWithoutExtension}</div>
                <div className="newforma-attachmentExtension">{this.attachmentExtension}</div>
            </div>
        );
    }
}

export default withLocalize(AttachmentComponent);
