import { useId } from "@uifabric/react-hooks";
import { DirectionalHint, Icon, ITag, TagPicker, TooltipHost } from "office-ui-fabric-react";
import * as React from "react";
import { useCallback } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { FormValidationHelpers } from "../../../../helpers/FormValidationHelpers";
import LabelComponent from "../../label/LabelComponent";
import SuggestedItemWithSubtextComponent from "../../pickerSuggestedItems/suggestedItemWithSubtext/SuggestedItemWithSubtextComponent";
import { MULTI_DROPDOWN_ONE_ITEM, SHORT_FIELD_LENGTH } from "../fieldConstants";
import "./SpecSectionComponent.less";

export interface SpecSectionComponentProps extends LocalizeContextProps {
    className?: string;
    disabled: boolean;
    formValidationHelpers: FormValidationHelpers;
    items: ITag[];
    onChange: (specSectionName: ITag | null) => void;
    required: boolean;
    selectedItem: ITag | null;
}

function SpecSectionComponent(props: SpecSectionComponentProps) {
    const tooltipId = useId("specSectionTooltipId");
    const picker = React.useRef<any>(null);

    const filteredItems = useCallback(
        (filter: string) =>
            props.items.filter(
                (x) =>
                    x.name.toLowerCase().includes(filter.toLowerCase().trim()) ||
                    x.key.toString().toLowerCase().includes(filter.toLowerCase().trim())
            ),
        [props.items]
    );

    const onEmptyFilteredItems = useCallback((_?: ITag[]) => props.items, [props.items]);

    const onSpecSectionSelected = (items?: ITag[]) => {
        props.onChange(!items ? null : items[0]);
    };

    const onCreateGenericItem = (input: string): ITag => {
        const formattedString = input.trim();
        return { name: formattedString, key: formattedString };
    };

    const onRenderSuggestedSpecs = ({ name, key }: ITag): JSX.Element => (
        <SuggestedItemWithSubtextComponent primaryText={name} subtext={key.toString()} />
    );

    const hint = useCallback(
        () => (
            <span className="newforma-hintText">
                <span>{props.translate("RFI.SPEC_SECTION.INFO_TEXT1") as string}</span>
                <span>{props.translate("RFI.SPEC_SECTION.INFO_TEXT2") as string}</span>
            </span>
        ),
        [props.translate]
    );

    return (
        <div className={`specSectionComponent ${props.className ?? ""}`} data-testid={"specSectionComponent"}>
            <div className="headerContainer">
                <LabelComponent text={props.translate("RFI.SPEC_SECTION.LABEL") as string} required={props.required} />
                <TooltipHost content={hint()} directionalHint={DirectionalHint.topAutoEdge} id={tooltipId}>
                    <Icon iconName="Info" className="hintIcon" aria-describedby={tooltipId} />
                </TooltipHost>
            </div>
            <TagPicker
                componentRef={picker}
                className="specSectionPicker"
                createGenericItem={onCreateGenericItem}
                disabled={props.disabled}
                itemLimit={MULTI_DROPDOWN_ONE_ITEM}
                onChange={onSpecSectionSelected}
                onEmptyResolveSuggestions={onEmptyFilteredItems}
                onRenderSuggestionsItem={onRenderSuggestedSpecs}
                onResolveSuggestions={filteredItems}
                onValidateInput={props.formValidationHelpers.isSpecSectionValid}
                resolveDelay={300}
                selectedItems={props.selectedItem ? [props.selectedItem] : []}
                inputProps={{
                    placeholder: props.translate("RFI.SPEC_SECTION.PICKER_PLACEHOLDER") as string,
                    maxLength: SHORT_FIELD_LENGTH,
                }}
                onBlur={() => {
                    if (picker.current) {
                        picker.current.input.current?._updateValue("");
                    }
                }}
                pickerSuggestionsProps={{
                    suggestionsHeaderText: undefined,
                    noResultsFoundText: props.translate("RFI.SPEC_SECTION.PICKER_NO_SPEC_SECTIONS") as string,
                }}
            />
        </div>
    );
}

export default withLocalize(SpecSectionComponent);
