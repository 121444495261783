import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { ProjectEmail } from "../ProjectEmailComponent";
import * as React from "react";
import "./ProjectEmailListItemComponent.less";
import { Persona, PersonaSize } from "office-ui-fabric-react";
import AttachmentIcon from "./AttachmentIcon";
import ProjectEmailActions from "../ProjectEmailActions/ProjectEmailActions";
import { convert } from "html-to-text";

export interface ProjectEmailListItemComponentProps extends LocalizeContextProps {
    item: ProjectEmail;
}

const getInitials = (user: { name?: string; email: string }): string => {
    let initials: string;

    if (!user.name || user.name.length === 0) {
        initials = user.email.at(0) ?? "";
    } else {
        initials = convert(user.name)
            .split(" ")
            .map((w) => w.at(0))
            .join("");
    }

    return initials.toUpperCase().substring(0, 2);
};

function ProjectEmailListItemComponent({ item }: ProjectEmailListItemComponentProps) {
    return (
        <div className="newforma-projectEmailListItemComponent" data-testid="projectEmailListItemComponent">
            <div className="newforma-projectEmailListRowAvatar">
                <Persona size={PersonaSize.size32} imageInitials={getInitials(item.from)}></Persona>
            </div>
            <div className="newforma-projectEmailListRowDetails">
                <div className="newforma-projectEmailListRowDetailTopRow">
                    <div
                        className="newforma-from"
                        dangerouslySetInnerHTML={{ __html: item.from?.name || item.from?.email || "" }}
                    ></div>
                    <div className="newforma-sentDate">{item.date}</div>
                </div>

                <div className="newforma-subject" dangerouslySetInnerHTML={{ __html: item.subject }} />

                <div className="newforma-preview-wrapper">
                    <div className="newforma-preview" dangerouslySetInnerHTML={{ __html: item.body }} />
                </div>
                <div className="newforma-flexRow">
                    {item.hasAttachment && <AttachmentIcon />}
                    <ProjectEmailActions item={item} />
                </div>
            </div>
        </div>
    );
}

export default withLocalize(ProjectEmailListItemComponent);
