import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./ProjectEmailSearchComponent.less";
import EmailFilter from "./Filter/EmailFilterComponent";
import { useProjectEmailSearch } from "../../../hooks/useProjectEmailSearch";
import DebouncedSearchComponent from "../../debouncedSearch/DebouncedSearchComponent";

function ProjectEmailSearchComponent(props: LocalizeContextProps) {
    const {
        actions: { setQuery },
    } = useProjectEmailSearch();

    return (
        <div className="newforma-searchFiltersBox">
            <DebouncedSearchComponent
                className="newforma-searchBox"
                onTextChange={setQuery}
                placeholder={props.translate("PROJECT_EMAIL.SEARCH_PLACEHOLDER") as string}
                dataTestId={"projectEmailSearchBox"}
            />
            <EmailFilter />
        </div>
    );
}

export default withLocalize(ProjectEmailSearchComponent);
