import { IPickerItemProps, ITag, TagItem, TagItemSuggestion, TagPicker } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { ProjectStatus } from "../../../models/BimProject";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import { IHub } from "../../../models/Hub";
import { ProjectBaseInformation } from "../../../models/ProjectsResponse";
import { BimProjectsApiService } from "../../../services/BimApi/BimProjectsApiService";
import { Logger } from "../../../services/Logger";
import { SmartFilingManager } from "../../../services/SmartFiling/SmartFilingManager";
import { SuggestedProject } from "../../../services/SmartFiling/SuggestedProject";
import {
    normalizeAccessToBaseInfo,
    normalizeEmailAccessProjectsToITag,
    normalizeSuggestedProjectToITag,
    filterLowPriorityProjects,
} from "../../../utils/normalizer";
import "./ProjectPickerComponent.less";

export interface ProjectPickerComponentProps extends LocalizeContextProps {
    onExpiredSession: () => void;
    bimProjectsApiService: BimProjectsApiService;
    hub: IHub | null;
    onProjectSelected: (project: ITag | null) => ITag | null;
    selectedProject: ITag | null;
    disabled: boolean;
    projects: ITag[];
    smartFilingManager: SmartFilingManager;
    logger: Logger;
}
export interface ProjectPickerComponentState {
    tagPickerKey: number;
    selectedProject: ITag | null;
    displayedProjects: BimProjectITag[];
    suggestedProjectKeys: string[];
    pickerKey: number;
}

export interface BimProjectITag extends ITag {
    projectNumber: string;
    projectStatus?: ProjectStatus;
}

function ProjectPickerComponent(props: ProjectPickerComponentProps) {
    const [projectPickerComponentState, setProjectPickerComponentState] = React.useState<ProjectPickerComponentState>({
        tagPickerKey: 0,
        selectedProject: null,
        displayedProjects: [],
        pickerKey: 0,
        suggestedProjectKeys: [],
    });

    const onEmptyInputFocus = (): Promise<ITag[]> => getSuggestProjects(props, setProjectPickerComponentState);

    const onProjectSelected = (selectedItem?: ITag): ITag | null => {
        if (!selectedItem) {
            clearProject();
            return null;
        }
        props.onProjectSelected(selectedItem);

        return selectedItem;
    };

    const combineProjectNameAndNumber = (projectName: string, projectNumber?: string) =>
        `${projectName}${projectNumber ? ` (# ${projectNumber})` : ""}`;

    const onRenderItem = (itemProps: IPickerItemProps<ITag>) => {
        const { item } = itemProps as unknown as IPickerItemProps<BimProjectITag>;

        return <TagItem {...itemProps}>{combineProjectNameAndNumber(item.name, item.projectNumber)}</TagItem>;
    };

    const onProjectChange = (items?: ITag[]): void => {
        if (!items?.length) {
            clearProject();
        }
    };

    const clearProject = (): void => {
        props.onProjectSelected(null);
    };

    const onRenderSuggestionsItem = (projectITag: ITag): JSX.Element => {
        const isSuggestedItem = projectPickerComponentState.suggestedProjectKeys.some(
            (suggestedProjectKey) => suggestedProjectKey === projectITag.key
        );
        const isLastSuggestedItem = projectITag.key === projectPickerComponentState.suggestedProjectKeys.slice(-1)[0];

        const displayedProject = projectPickerComponentState.displayedProjects.find(
            (project) => project.key === projectITag.key
        );

        return (
            <div
                className={`${
                    isSuggestedItem
                        ? isLastSuggestedItem
                            ? "newforma-lastProjectNameSuggestedItem"
                            : "newforma-projectNameSuggestedItem"
                        : "bimone-projectNameItem"
                }`}
                title={combineProjectNameAndNumber(projectITag.name, displayedProject?.projectNumber)}
            >
                <TagItemSuggestion>
                    <span
                        className={`${
                            isSuggestedItem ? "newforma-projectNameSuggestedItemValue" : "bimone-projectNameItemValue"
                        }`}
                    >
                        {projectITag.name}
                    </span>
                    {displayedProject && displayedProject.projectNumber ? <br /> : null}
                    {displayedProject && displayedProject.projectNumber ? (
                        <span className="bimtrack-projectNameListItemSubheader">
                            # {displayedProject.projectNumber}
                        </span>
                    ) : null}
                </TagItemSuggestion>
            </div>
        );
    };

    React.useEffect(() => {
        setProjectPickerComponentState((prevState) => ({
            ...prevState,
            tagPickerKey: projectPickerComponentState.tagPickerKey + 1,
        }));

        getSuggestProjects(props, setProjectPickerComponentState);
    }, [props.hub?.name, props.hub?.key]);

    const isAnySuggestedItem = projectPickerComponentState.suggestedProjectKeys.length > 0;

    return (
        <div data-testid="pim-projectPickerComponent" className={"pim-projectPickerComponent"}>
            <li className="pim-projectHeaderContainer">
                <TagPicker
                    key={projectPickerComponentState.tagPickerKey}
                    className="pim-projectPicker"
                    selectedItems={props.selectedProject ? [props.selectedProject] : []}
                    pickerSuggestionsProps={{
                        suggestionsHeaderText: isAnySuggestedItem
                            ? (props.translate("SHARED.SUGGESTED_PROJECTS.SUGGESTED_PROJECTS_TITLE") as string)
                            : undefined,
                        noResultsFoundText: props.translate("SHARED.SUGGESTED_PROJECTS.NO_PROJECTS") as string,
                        suggestionsItemClassName: "newforma-projectCallout",
                    }}
                    onRenderSuggestionsItem={onRenderSuggestionsItem}
                    disabled={props.disabled}
                    itemLimit={1}
                    onResolveSuggestions={(filter) => onProjectFiltered(filter, projectPickerComponentState)}
                    onItemSelected={onProjectSelected}
                    onChange={onProjectChange}
                    onEmptyInputFocus={onEmptyInputFocus}
                    inputProps={{
                        placeholder: props.translate("SHARED.SUGGESTED_PROJECTS.PLACEHOLDER") as string,
                        className: "pim-projectPlaceholder",
                    }}
                    pickerCalloutProps={{ calloutWidth: 280 }}
                    resolveDelay={300}
                    onRenderItem={onRenderItem}
                />
            </li>
        </div>
    );
}

export const onProjectFiltered = (filter: string, projectPickerComponentState: ProjectPickerComponentState): ITag[] => {
    if (!filter || filter === "") {
        return projectPickerComponentState.displayedProjects;
    }

    return projectPickerComponentState.displayedProjects.filter(
        (project) =>
            project.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
            project.projectNumber.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
};

export const getSuggestProjects = async (
    props: ProjectPickerComponentProps,
    setProjectPickerComponentState: (value: React.SetStateAction<ProjectPickerComponentState>) => void
): Promise<ITag[]> => {
    if (!props.hub) {
        setProjectPickerComponentState((prevState) => ({
            ...prevState,
            projects: [],
        }));
        return [];
    }

    try {
        // Step 1: Get the projects in the hub and project access
        const { items: projectsWithEmailAccess } = await props.bimProjectsApiService.getProjectsEmailFilingAccess(
            props.hub
        );

        // Step 2: get normalized projectBaseInfo and feed to suggestion algorithm
        const projectBaseInfo: ProjectBaseInformation[] = normalizeAccessToBaseInfo(
            projectsWithEmailAccess,
            props.translate
        );
        const totalSuggestionResults: SuggestedProject[] = await props.smartFilingManager.getSuggestedProjects(
            projectBaseInfo
        );
        const topSuggestedProjects: SuggestedProject[] = totalSuggestionResults?.slice(0, 3) || [];

        // Step 3: get normalized project ITags to feed component
        const topSuggestedProjectsITag: BimProjectITag[] = normalizeSuggestedProjectToITag(topSuggestedProjects);
        const projectsWithEmailAccessITag: BimProjectITag[] = normalizeEmailAccessProjectsToITag(
            projectsWithEmailAccess,
            props.translate
        );
        const allProjects: BimProjectITag[] = [...topSuggestedProjectsITag, ...projectsWithEmailAccessITag];

        // Step 4: get low priority projects and sort them alphabetically
        const lowPriorityProjects = filterLowPriorityProjects(allProjects, topSuggestedProjectsITag, props.translate);

        const displayedProjects = [...topSuggestedProjectsITag, ...lowPriorityProjects];

        // Step 5: Set to state
        setProjectPickerComponentState((prevState) => ({
            ...prevState,
            projects: projectsWithEmailAccess,
            displayedProjects,
            suggestedProjectKeys: topSuggestedProjectsITag.map((project) => project.key.toString()),
        }));

        return displayedProjects;
    } catch (error) {
        if (ExpiredSessionError.isInstanceOf(error)) {
            props.onExpiredSession();
        }
        setProjectPickerComponentState((prevState) => ({
            ...prevState,
            projects: [],
        }));
        props.logger.error(`getSuggestProjects: ${JSON.stringify(error)}`);
        throw new Error();
    }
};

export default withLocalize(ProjectPickerComponent);
