import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";

export interface MessageDialogProps extends LocalizeContextProps {
    onConfirmButtonClicked: () => void;
    onDismiss: () => void;
    hidden: boolean;
    title: string;
    subText: string;
}

class MessageDialogComponent extends React.Component<MessageDialogProps, any> {
    render(): JSX.Element {
        return (
            <Dialog
                hidden={this.props.hidden}
                onDismiss={this.props.onDismiss}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.props.title,
                    subText: this.props.subText,
                    styles: { title: { fontSize: 18 } },
                }}
                modalProps={{
                    titleAriaId: "1",
                    subtitleAriaId: "1",
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={this.props.onConfirmButtonClicked}
                        text={this.props.translate("APP.SETTINGS.YES") as string}
                    />
                    <DefaultButton
                        onClick={this.props.onDismiss}
                        text={this.props.translate("APP.SETTINGS.NO") as string}
                    />
                </DialogFooter>
            </Dialog>
        );
    }
}

export default withLocalize(MessageDialogComponent);
