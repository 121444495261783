/* istanbul ignore file */
// https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// https://github.com/react-ga/react-ga#reactgaeventargs
// update with analytics https://confluence.newforma.com/display/ENG/Outlook+Web+Addin+Analytics
import * as ReactGA from "react-ga";
import { SuggestedProject } from "./SmartFiling/SuggestedProject";
import { ga } from "react-ga";

export enum AnalyticsActionType {
    Company = "CompanyDomain",
    EmailMarkedForFiling = "EmailMarkedForFileOnSend",
    FileEmail = "FileEmail",
    FileEmailAsActionItem = "FileEmailAsActionItem",
    FileEmailAsSubmittal = "FileEmailAsSubmittal",
    FileEmailAsSubmittalReviewResponse = "FileEmailAsSubmittalReviewResponse",
    FileEmailAsRfi = "FileEmailAsRfi",
    FileEmailAsRfiReviewResponse = "FileEmailAsRfiReviewResponse",
    FileMultipleEmail = "FileMultipleEmail",
    FiledWithConversation = "EmailFiledWithFileConversationEnabled",
    FiledWithDelete = "EmailFiledWithDeleteWhenFiledEnabled",
    Login = "Login",
    Logout = "Logout",
    ProjectSearched = "Project-Searched",
    ProjectEmailSearched = "Project-EmailSearched",
    ProjectTeamAddedCc = "ProjectTeam-AddedCc",
    ProjectTeamAddedTo = "ProjectTeam-AddedTo",
    ProjectTeamAddNew = "ProjectTeam-AddNew",
    ProjectTeamAddNewViewed = "ProjectTeam-AddNewViewed",
    ProjectTeamViewed = "ProjectTeam-Viewed",
    ProjectTeamSearched = "ProjectTeam-Searched",
    RfiNumber = "RfiNumberModified",
    SubmittalNumber = "SubmittalNumberModified",
    SuggestedProjectRanking = "SuggestedProjectRanking",
    SuggestionTypes = "SuggestionTypes",
    SettingsDeleteEmailChanged = "Setting-DeleteEmailChanged",
    SettingsFileConversationChanged = "Setting-FileConversationChanged",
    UserMenuCustomerSupport = "UserMenu-CustomerSupport",
    UserMenuSettingsOpened = "UserMenu-SettingsOpened",
    UserMenuPrivacyPolicy = "UserMenu-PrivacyPolicy",
    UserMenuHelp = "UserMenu-Help",
    UserMenuFeedback = "UserMenu-Feedback",
    UserMenuTermsOfUse = "UserMenu-TermsOfUse",
    UserMenuSearch = "UserMenu-Search",
}

export enum AnalyticsCategoryType {
    SmartFiling = "SmartFiling",
    UserActions = "UserActions",
    EmailFiling = "EmailFiling",
    UserData = "UserData",
}

export class AnalyticsManager {
    initialize(trackingId: string) {
        // ReactGA.initialize(trackingId, { useExistingGa: true });
        // need to call ga directly because of bug where chrome won't save cookies in iframe
        // https://github.com/react-ga/react-ga/issues/457
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
        // ga("create", trackingId, "auto", { cookieFlags: "SameSite=None; Secure" });
    }

    pageview(path: string) {
        // ReactGA.pageview(path);
    }

    recordEvent(category: AnalyticsCategoryType, action: AnalyticsActionType, label: string = ""): void {
        // ReactGA.event({ category: category, action: action, label: label });
    }

    recordSmartFilingEvents(suggestedProject?: SuggestedProject, suggestedProjectIndex?: number): void {
        // if (suggestedProject) {
        //     const rankedTypes = suggestedProject.rankedTypes;
        //     rankedTypes.forEach((type) => {
        //         this.recordEvent(AnalyticsCategoryType.SmartFiling, AnalyticsActionType.SuggestionTypes, type);
        //     });
        // }
        // if (suggestedProjectIndex !== undefined) {
        //     this.recordEvent(
        //         AnalyticsCategoryType.SmartFiling,
        //         AnalyticsActionType.SuggestedProjectRanking,
        //         suggestedProjectIndex.toString()
        //     );
        // } else {
        //     this.recordEvent(
        //         AnalyticsCategoryType.SmartFiling,
        //         AnalyticsActionType.SuggestedProjectRanking,
        //         "Not a Suggested Project"
        //     );
        // }
    }
}
