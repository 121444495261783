import { IBasePickerSuggestionsProps, ITag, TagPicker, ValidationState } from "office-ui-fabric-react";
import * as React from "react";
import { useCallback } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import LabelComponent from "../../label/LabelComponent";
import { SHORT_FIELD_LENGTH } from "../fieldConstants";
import "../Common.less";

export interface GroupPickerComponentProps extends LocalizeContextProps {
    allowCustomInput: boolean;
    className?: string;
    disabled: boolean;
    inputPlaceholder: string;
    items: ITag[];
    label: string;
    onChange: (selectedItems: ITag[]) => void;
    required: boolean;
    selectedItems: ITag[];
}

function GroupPickerComponent(props: GroupPickerComponentProps) {
    const picker = React.useRef<any>(null);

    const getPickerSuggestionProps = (): IBasePickerSuggestionsProps => ({
        suggestionsHeaderText: undefined,
        noResultsFoundText: props.translate("CHIP_PICKER.NO_RESULTS_FOUND") as string,
        loadingText: props.translate("CHIP_PICKER.LOADING") as string,
        showRemoveButtons: false,
    });

    const removeSelectedItems = (items: ITag[], selectedItems?: ITag[]) =>
        items.filter((item) => !selectedItems?.map((selectedItem) => selectedItem.key).includes(item.key));

    const filteredItems = useCallback(
        (inputValue: string, selectedItems?: ITag[]) => {
            const newItems = removeSelectedItems(props.items, selectedItems).filter((item) =>
                item.name.includes(inputValue)
            );

            if (inputValue.trim().length > 0) {
                newItems.unshift({ name: inputValue, key: inputValue });
            }

            return newItems;
        },
        [props.items]
    );

    const onEmptyFilteredItem = useCallback(
        (selectedItem?: ITag[]) => removeSelectedItems(props.items, selectedItem),
        [props.items]
    );

    const onValidateInput = (input: string): ValidationState => {
        if (!input.trim() || props.selectedItems.some((item) => item.key === input.trim())) {
            return ValidationState.invalid;
        }
        return ValidationState.valid;
    };

    const onCreateGenericItem = (input: string): ITag => {
        const formattedInput = input.trim();

        return {
            name: formattedInput,
            key: formattedInput,
        };
    };

    const onRenderSuggestionsItem = (iTag: ITag) => <div className="dropdownSuggestedItem">{iTag.name}</div>;

    const onChange = (selectedItems: ITag[] | undefined) => {
        props.onChange(!selectedItems ? [] : selectedItems);
    };

    return (
        <div className={`${props.className ?? ""}`} data-testid={"groupPickerComponentContainer"}>
            <LabelComponent text={props.label} required={props.required} />
            <TagPicker
                componentRef={picker}
                className="groupPickerComponent"
                selectedItems={props.selectedItems}
                pickerSuggestionsProps={getPickerSuggestionProps()}
                onResolveSuggestions={filteredItems}
                onChange={onChange}
                disabled={props.disabled}
                inputProps={{
                    maxLength: SHORT_FIELD_LENGTH,
                    placeholder: props.inputPlaceholder,
                }}
                resolveDelay={300}
                onEmptyInputFocus={onEmptyFilteredItem}
                onBlur={() => {
                    if (picker.current) {
                        picker.current.input.current?._updateValue("");
                    }
                }}
                onValidateInput={props.allowCustomInput ? onValidateInput : undefined}
                createGenericItem={props.allowCustomInput ? onCreateGenericItem : undefined}
                onRenderSuggestionsItem={onRenderSuggestionsItem}
            />
        </div>
    );
}

export default withLocalize(GroupPickerComponent);
