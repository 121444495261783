import * as React from "react";
import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react";

export interface RadioGroupComponentProps {
    className?: string;
    label: string;
    options: IChoiceGroupOption[];
    selectedKey: string;
    onChange: (option: IChoiceGroupOption | undefined) => void;
    disabled: boolean;
}

function RadioGroupComponent(props: RadioGroupComponentProps) {
    return (
        <ChoiceGroup
            className={`${props.className ?? ""} radioGroupComponent`}
            label={props.label}
            options={props.options}
            selectedKey={props.selectedKey}
            onChange={(_, option) => {
                props.onChange(option);
            }}
            disabled={props.disabled}
        />
    );
}

export default RadioGroupComponent;
