import { Logger } from "../Logger";
import { NewformaApiClient } from "./NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { EmailListResponse } from "../../models/EmailListResponse";
import { Request } from "aws-sign-web";
import { FileToProjectResponse } from "../../models/FileToProjectResponse";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../AnalyticsManager";
import { OfficeRoamingSettings } from "../OfficeRoamingSettings";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { OfficeWrapper } from "../OfficeWrapper";
import { SmartFilingManager } from "../SmartFiling/SmartFilingManager";
import { ITag } from "office-ui-fabric-react";
import { ProjectBaseInformation } from "../../models/ProjectsResponse";
import { ConfigurationService } from "../ConfigurationService";
import { UnauthorizedError } from "../../models/shared/UnauthorizedError";
import { ConflictError } from "../../models/shared/ConflictError";
import { IHub } from "../../models/Hub";
import { Email } from "@newforma/platform-client-api-sdk";
import { SupportedAddinItems } from "../../models/SupportedItems";
import { PostFilingAction } from "@newforma/platform-client-api-sdk/dist/email/FileEmail";
import limitFactory = require("promise-limit");

export class EmailApiService {
    private emailListPageSize = 50;
    constructor(
        private config: ConfigurationService,
        private logger: Logger,
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private analyticsManager: AnalyticsManager,
        private officeRoamingSettings: OfficeRoamingSettings,
        private officeWrapper: OfficeWrapper,
        private smartFilingManager: SmartFilingManager
    ) {}

    async getEmails(offsetToken: string | null): Promise<EmailListResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/email/folders/inbox/messages`;

        const params = {
            pageSize: offsetToken ? this.emailListPageSize + 1 : this.emailListPageSize,
            offsetToken: `${offsetToken || ""}`,
        };
        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
            params: params,
        };

        this.logger.info("Fetching Emails");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, params)
        );
    }

    async fileMultipleEmails(
        emailIds: string[],
        project: ITag,
        hub: IHub,
        supportedFilingOptions: SupportedAddinItems | null
    ): Promise<string[]> {
        const limit = limitFactory(10);
        const fileConversation = this.officeRoamingSettings.getFileEmailConversation();
        const deleteAfterFiling = supportedFilingOptions?.canDeleteEmail
            ? this.officeRoamingSettings.getDeleteEmailAfterFiling()
            : false;

        const failedIds: string[] = [];
        const fileEmailPromises = emailIds.map((emailId) =>
            limit(() =>
                this.makeFilingRequest(
                    emailId,
                    project.key.toString(),
                    PostFilingAction.none,
                    fileConversation,
                    hub
                ).catch((error) => {
                    this.logger.error(`email with id ${emailId} failed to file`);
                    failedIds.push(emailId);
                    if (
                        ExpiredSessionError.isInstanceOf(error) ||
                        UnauthorizedError.isInstanceOf(error) ||
                        ConflictError.isInstanceOf(error)
                    ) {
                        this.logger.error(`FileMultipleEmails Error: ${JSON.stringify(error)}`);
                        throw error;
                    }

                    return "";
                })
            )
        );

        await Promise.all(fileEmailPromises);

        this.analyticsManager.recordEvent(
            AnalyticsCategoryType.EmailFiling,
            AnalyticsActionType.FileMultipleEmail,
            `number filed: ${emailIds.length.toString()}`
        );

        if (fileConversation) {
            this.analyticsManager.recordEvent(
                AnalyticsCategoryType.EmailFiling,
                AnalyticsActionType.FiledWithConversation
            );
        }
        if (deleteAfterFiling) {
            this.analyticsManager.recordEvent(AnalyticsCategoryType.EmailFiling, AnalyticsActionType.FiledWithDelete);
        }

        try {
            const projectAsAny = project as any;
            this.analyticsManager.recordSmartFilingEvents(projectAsAny.suggestedProject, projectAsAny.suggestionIndex);
            const mailboxItem = this.officeWrapper.currentContextItem;
            await this.smartFilingManager.addToFiledHistory(
                { name: project.name, nrn: project.key } as ProjectBaseInformation,
                mailboxItem.conversationId as string,
                mailboxItem.sender.emailAddress as string
            );
        } catch {
            this.logger.error("failed to update smart filing history");
        }

        return failedIds;
    }

    async fileEmail(emailId: string, project: ITag, hub: IHub): Promise<{}> {
        this.logger.info(`File email with id: ${emailId}`);
        const fileConversation = this.officeRoamingSettings.getFileEmailConversation();

        return this.makeFilingRequest(emailId, project.key.toString(), PostFilingAction.none, fileConversation, hub);
    }

    async fileToProject(projectId: string, messageId: string): Promise<FileToProjectResponse> {
        this.logger.info("Filing single email");
        const postFilingAction = this.officeRoamingSettings.getPostFilingAction();
        const fileConversation = this.officeRoamingSettings.getFileEmailConversation();
        const deleteAfterFiling = this.officeRoamingSettings.getDeleteEmailAfterFiling();

        const result = await this.makeFilingRequest(
            messageId,
            projectId,
            postFilingAction,
            fileConversation,
            {} as IHub
        );
        if (fileConversation) {
            this.analyticsManager.recordEvent(
                AnalyticsCategoryType.EmailFiling,
                AnalyticsActionType.FiledWithConversation
            );
        }
        if (deleteAfterFiling) {
            this.analyticsManager.recordEvent(AnalyticsCategoryType.EmailFiling, AnalyticsActionType.FiledWithDelete);
        }
        return result;
    }

    async markEmailForFiling(emailId: string, selectedHub: IHub, selectedProject: ITag): Promise<any> {
        this.logger.info("Marking email to be filed on send");
        const fileConversation = this.officeRoamingSettings.getFileEmailConversation();
        const payload = JSON.stringify({
            emailId,
            postFiling: {
                fileEntireConversation: fileConversation,
            },
        });

        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = `${domain}/v1/hubs/${selectedHub.key}/projects/${selectedProject.key}/emails/queue`;

        const options: Request = {
            hostname: this.config.bimApiURL,
            url,
            method: "POST",
            body: payload,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions: Request) => {
            const result = await this.requestWrapper.post(url, undefined, signedOptions.headers, payload);
            if (fileConversation) {
                this.analyticsManager.recordEvent(
                    AnalyticsCategoryType.EmailFiling,
                    AnalyticsActionType.FiledWithConversation
                );
            }
            return result;
        });
    }

    private async makeFilingRequest(
        messageId: string,
        projectId: string,
        postFilingAction: Email.PostFilingAction,
        fileConversation: boolean,
        hub: IHub
    ): Promise<FileToProjectResponse> {
        const payload: Email.FileEmailRequest = {
            messageId,
            postFiling: {
                action: postFilingAction,
                fileEntireConversation: fileConversation,
            },
        };

        const endpointDomain = this.newformaApiClient.mapDomainRegion(hub as IHub);

        const url = `${endpointDomain}/v1/hubs/${hub.key}/projects/${projectId}/emails`;

        const options = {
            hostname: this.newformaApiClient.getHostName(),
            url: url,
            method: "POST",
            body: payload,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.post(url, undefined, signedOptions.headers, JSON.stringify(payload))
        );
    }
}
