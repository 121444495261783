import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { DateHelpers } from "../../helpers/DateHelpers";
import { DefaultButton, IChoiceGroupOption, ITag, MessageBarType } from "office-ui-fabric-react";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { RelatedItemApiService } from "../../services/PimTrackApi/RelatedItemApiService";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { SupportedAddinItems } from "../../models/SupportedItems";
import useLogIssue from "../../hooks/useLogIssue";
import TextFieldComponent from "../shared/field/inputText/TextFieldComponent";
import {
    CUSTOM_ATTRIBUTE_TEXT_FIELD_LENGTH,
    DEFAULT_TEXT_FIELD_ROWS,
    LONG_FIELD_LENGTH,
    MULTI_DROPDOWN_ONE_ITEM,
    SHORT_FIELD_LENGTH,
} from "../shared/field/fieldConstants";
import DropdownComponent from "../shared/field/dropdown/DropdownComponent";
import RadioGroupComponent from "../shared/field/radioGroup/RadioGroupComponent";
import "./IssueComponent.less";
import ChipPickerComponent from "../shared/field/chipPicker/ChipPickerComponent";
import ContactDropdownComponent from "../shared/field/contactDropdown/ContactDropdownComponent";
import TranslatedDatePickerComponent from "../shared/translatedDatePicker/TranslatedDatePickerComponent";
import { BimProjectsApiService } from "../../services/BimApi/BimProjectsApiService";
import GroupPickerComponent from "../shared/field/groupPicker/GroupPickerComponent";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import { BimProjectCustomAttribute } from "../../models/Bimtrack/BimProjectCustomAttribute";
import { BimProjectCustomAttributeType } from "../../models/Bimtrack/BimProjectCustomAttributeType";
import { IssueApiService } from "../../services/BimApi/IssueApiService";
import { ToastMessage } from "../../models/ToastMessage";

export interface IssueComponentProps extends LocalizeContextProps {
    formValidationHelpers: FormValidationHelpers;
    officeWrapper: OfficeWrapper;
    dateHelpers: DateHelpers;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    bimProjectsApiService: BimProjectsApiService;
    selectedProject: ITag | null;
    selectedHub: ITag | null;
    showProgress: (message: string | null) => void;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
    issueApiService: IssueApiService;
    navigateBackToForm?: () => void;
}

function IssueComponent(props: IssueComponentProps) {
    const {
        logIssueForm,
        updateField,
        priorityLabel,
        disciplineLabel,
        disciplinePlaceholderLabel,
        zoneLabel,
        phaseLabel,
        teamLabel,
        teamPlaceholderLabel,
        notifyLabel,
        notifyPlaceholderLabel,
        statusOptions,
        typeOptions,
        priorityOptions,
        disciplineOptions,
        zoneOptions,
        phaseOptions,
        userOptions,
        groupOptions,
        teamsInvolvedOptions,
        customAttributes,
        getIDropdownOptionsForCustomAttribute,
        modifyCustomAttribute,
        currentOptionCustomValues,
        isLogIssueFormValid,
        submitLogIssue,
        isFieldDisabled,
        subMenuOptions,
    } = useLogIssue({
        officeWrapper: props.officeWrapper,
        dateHelpers: props.dateHelpers,
        bimProjectsApiService: props.bimProjectsApiService,
        selectedHub: props.selectedHub,
        selectedProject: props.selectedProject,
        translate: props.translate,
        onShowToast: props.onShowToast,
        showProgress: props.showProgress,
        emailApiService: props.emailApiService,
        relatedItemApiService: props.relatedItemApiService,
        officeRoamingSettings: props.officeRoamingSettings,
        supportedAddinItems: props.supportedAddinItems,
        issueApiService: props.issueApiService,
        navigateBackToForm: props.navigateBackToForm,
    });

    const unrestrictedOption: IChoiceGroupOption = {
        key: "unrestricted",
        text: props.translate("ISSUE.VISIBILITY.UNRESTRICTED") as string,
    };
    const restrictedOption: IChoiceGroupOption = {
        key: "restricted",
        text: props.translate("ISSUE.VISIBILITY.RESTRICTED") as string,
    };
    const visibilityOptions = [unrestrictedOption, restrictedOption];

    const createCustomField = (projectCustomAttribute: BimProjectCustomAttribute) => {
        if (projectCustomAttribute.Type === BimProjectCustomAttributeType.Text) {
            return (
                <TextFieldComponent
                    className={`newforma-formSpacing ${projectCustomAttribute.Name}Field`}
                    disabled={isFieldDisabled}
                    maxLength={CUSTOM_ATTRIBUTE_TEXT_FIELD_LENGTH}
                    onChange={(value) => modifyCustomAttribute(projectCustomAttribute, value)}
                    label={projectCustomAttribute.Name}
                    required={false}
                />
            );
        } else if (projectCustomAttribute.Type === BimProjectCustomAttributeType.Predefined) {
            return (
                <DropdownComponent
                    className={`newforma-formSpacing ${projectCustomAttribute.Name}Field`}
                    disabled={isFieldDisabled || projectCustomAttribute.ProjectCustomAttributeValues?.length === 0}
                    placeholder={props.translate("SHARED.ATTRIBUTES.NOT_SET") as string}
                    onChange={(option) => modifyCustomAttribute(projectCustomAttribute, option)}
                    label={projectCustomAttribute.Name}
                    options={getIDropdownOptionsForCustomAttribute(projectCustomAttribute)}
                    selectedOption={
                        currentOptionCustomValues.find((x) => x.id === projectCustomAttribute.Id)?.currentOption
                    }
                    required={false}
                />
            );
        }
    };

    return (
        <div className="issueComponent" data-testid="issueComponent">
            <div className="issueComponentForm">
                <TextFieldComponent
                    className="newforma-formSpacing issueTitleField"
                    label={props.translate("ISSUE.TITLE.LABEL") as string}
                    placeholder={props.translate("ISSUE.TITLE.PLACEHOLDER") as string}
                    value={logIssueForm.title}
                    onChange={(newValue) => updateField({ title: newValue })}
                    maxLength={SHORT_FIELD_LENGTH}
                    required
                    disabled={isFieldDisabled}
                />
                <TextFieldComponent
                    className="newforma-formSpacing issueDescriptionField"
                    label={props.translate("SHARED.ATTRIBUTES.DESCRIPTION.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.DESCRIPTION.PLACEHOLDER") as string}
                    multiline
                    resizable
                    value={logIssueForm.description}
                    onChange={(newValue) => updateField({ description: newValue })}
                    maxLength={LONG_FIELD_LENGTH}
                    rows={DEFAULT_TEXT_FIELD_ROWS}
                    required={false}
                    disabled={isFieldDisabled}
                />
                <DropdownComponent
                    className="newforma-formSpacing issueStatusField"
                    options={statusOptions}
                    label={props.translate("ISSUE.STATUS.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.NOT_SET") as string}
                    disabled={isFieldDisabled || !statusOptions.length}
                    onChange={(option) => updateField({ status: option })}
                    selectedOption={logIssueForm.status}
                    required={false}
                />
                <DropdownComponent
                    className="newforma-formSpacing issueTypeField"
                    options={typeOptions}
                    label={props.translate("SHARED.ATTRIBUTES.TYPE.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.NOT_SET") as string}
                    disabled={isFieldDisabled || !typeOptions.length}
                    onChange={(option) => updateField({ type: option })}
                    selectedOption={logIssueForm.type}
                    required={false}
                />
                <DropdownComponent
                    className="newforma-formSpacing issuePriorityField"
                    options={priorityOptions}
                    label={priorityLabel}
                    placeholder={props.translate("SHARED.ATTRIBUTES.NOT_SET") as string}
                    disabled={isFieldDisabled || !priorityOptions.length}
                    onChange={(option) => updateField({ priority: option })}
                    selectedOption={logIssueForm.priority}
                    required={false}
                />
                <ChipPickerComponent
                    className="newforma-formSpacing issueDisciplinesField"
                    required={false}
                    disabled={isFieldDisabled || !disciplineOptions.length}
                    items={disciplineOptions}
                    onChange={(selectedItems) => updateField({ disciplines: selectedItems })}
                    label={disciplineLabel}
                    inputPlaceholder={disciplinePlaceholderLabel}
                    allowCustomInput={false}
                    selectedItems={logIssueForm.disciplines}
                />
                <DropdownComponent
                    className="newforma-formSpacing issueZoneField"
                    options={zoneOptions}
                    label={zoneLabel}
                    placeholder={props.translate("SHARED.ATTRIBUTES.NOT_SET") as string}
                    disabled={isFieldDisabled || !zoneOptions.length}
                    onChange={(option) => updateField({ zone: option })}
                    selectedOption={logIssueForm.zone}
                    required={false}
                />
                <DropdownComponent
                    className="newforma-formSpacing issuePhaseField"
                    options={phaseOptions}
                    label={phaseLabel}
                    placeholder={props.translate("SHARED.ATTRIBUTES.NOT_SET") as string}
                    disabled={isFieldDisabled || !phaseOptions.length}
                    onChange={(option) => updateField({ phase: option })}
                    selectedOption={logIssueForm.phase}
                    required={false}
                />
                <ContactDropdownComponent
                    className="newforma-formSpacing issueAssignedToField"
                    label={props.translate("ISSUE.ASSIGNED_TO.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.NOT_SET") as string}
                    formValidationHelpers={props.formValidationHelpers}
                    required={false}
                    items={userOptions}
                    itemLimit={MULTI_DROPDOWN_ONE_ITEM}
                    disabled={isFieldDisabled || !userOptions.length}
                    selectedContacts={logIssueForm.assignedTo}
                    onChange={(selectedItem) => updateField({ assignedTo: selectedItem })}
                    allowCustomInput={false}
                />
                <TranslatedDatePickerComponent
                    className="newforma-formSpacing issueDatePicker issueDueDateField"
                    date={logIssueForm.dueDate}
                    label={props.translate("SHARED.ATTRIBUTES.DUE_DATE.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.DUE_DATE.PLACEHOLDER") as string}
                    onDateChange={(date) => updateField({ dueDate: date })}
                    isDisabled={isFieldDisabled}
                    required={false}
                    showClearDateButton={false}
                />
                <GroupPickerComponent
                    className="newforma-formSpacing issueGroupField"
                    items={groupOptions}
                    label={props.translate("ISSUE.GROUP.LABEL") as string}
                    inputPlaceholder={props.translate("ISSUE.GROUP.PLACEHOLDER") as string}
                    disabled={isFieldDisabled || !groupOptions.length}
                    onChange={(selectedItems) =>
                        updateField({
                            group: selectedItems.length === 0 ? [] : [selectedItems[selectedItems.length - 1]],
                        })
                    }
                    selectedItems={logIssueForm.group}
                    allowCustomInput
                    required={false}
                />
                <ChipPickerComponent
                    className="newforma-formSpacing issueTeamsInvolvedField"
                    items={teamsInvolvedOptions}
                    label={teamLabel}
                    inputPlaceholder={teamPlaceholderLabel}
                    disabled={isFieldDisabled || !teamsInvolvedOptions.length}
                    onChange={(selectedItems) => updateField({ teamsInvolved: selectedItems })}
                    selectedItems={logIssueForm.teamsInvolved}
                    allowCustomInput={false}
                    required={false}
                />
                <ContactDropdownComponent
                    className="newforma-formSpacing issueNotifyField"
                    label={notifyLabel}
                    placeholder={notifyPlaceholderLabel}
                    formValidationHelpers={props.formValidationHelpers}
                    required={false}
                    items={userOptions}
                    disabled={isFieldDisabled || !userOptions.length}
                    selectedContacts={logIssueForm.notify}
                    onChange={(selectedContacts) => updateField({ notify: selectedContacts })}
                    allowCustomInput={false}
                />
                {customAttributes.length > 0 &&
                    customAttributes.map((customAttribute) => {
                        {
                            return createCustomField(customAttribute);
                        }
                    })}
                <RadioGroupComponent
                    className="newforma-formSpacing issueVisibilityField"
                    label={props.translate("ISSUE.VISIBILITY.LABEL") as string}
                    options={visibilityOptions}
                    selectedKey={logIssueForm.isRestricted ? "restricted" : "unrestricted"}
                    onChange={(option) => updateField({ isRestricted: option?.key === "restricted" })}
                    disabled={isFieldDisabled}
                />
                <AttachmentsComponent
                    attachments={logIssueForm.attachments}
                    disabled={isFieldDisabled}
                    onAttachmentSelectionChange={(attachmentsIds) =>
                        updateField({ selectedAttachmentIds: attachmentsIds })
                    }
                    allowFileUploads={false}
                />
            </div>

            <div id="footer" key="footer" className="newforma-footer">
                <DefaultButton
                    key="logIssueSubmitButton"
                    className="newforma-footerButton"
                    id="logIssueButton"
                    primary={true}
                    split={true}
                    menuProps={subMenuOptions}
                    onClick={() => submitLogIssue({ logNewItem: false })}
                    text={props.translate("ISSUE.SUBMIT_LOG_BUTTON") as string}
                    disabled={isFieldDisabled || !isLogIssueFormValid}
                />
            </div>
        </div>
    );
}

export default withLocalize(IssueComponent);
