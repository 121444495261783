import { LocalStorageKeys } from "../models/StorageKeys";
import { StorageWrapper } from "./StorageWrapper";
import { TokenInfo } from "../models/PimModels";
import { NewformaApiClient } from "./NewformaApi/NewformaApiClient";
import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { Request } from "aws-sign-web";
import { Logger } from "./Logger";
import { ConfigurationService } from "./ConfigurationService";

export class MsGraphTokenService {
    constructor(
        private storageWrapper: StorageWrapper,
        private newformaApiClient: NewformaApiClient,
        private httpRequestWrapper: HttpRequestWrapper,
        private logger: Logger,
        private config: ConfigurationService
    ) {}

    async getGraphToken(): Promise<string | null> {
        let graphToken = this.getStoredGraphToken();
        const isGraphTokenExpired = graphToken && this.isTokenExpired(graphToken);

        if (!graphToken || isGraphTokenExpired) {
            const { external_provider_access_token } = await this.fetchNewGraphCredentials();
            graphToken = external_provider_access_token;
        }
        return graphToken;
    }

    private getStoredGraphToken(): string | null {
        return this.storageWrapper.loadLocalStorage(LocalStorageKeys.graphToken);
    }

    isTokenExpired(token: string): boolean {
        const payloadBase64 = token.split(".")[1];
        const decodedJson = Buffer.from(payloadBase64, "base64").toString();
        const decoded = JSON.parse(decodedJson);
        const exp = decoded.exp;
        const isExpired = Date.now() >= exp * 1000;
        return isExpired;
    }

    async fetchNewGraphCredentials(): Promise<TokenInfo> {
        this.logger.info("--- retrieving new MS Graph API access token --- ");
        const url = `${this.config.bimAuthURL}/connect/token`;

        const accessToken = this.storageWrapper.loadLocalStorage(LocalStorageKeys.accessToken);

        const optPayload = `client_id=${this.config.externalTokenClientId}&grant_type=external&scopes=External_Mail_Api&token=${accessToken}`;
        const optOptions: Request = {
            url: url,
            method: "POST",
            body: optPayload,
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };
        const result: TokenInfo = await this.newformaApiClient.makeRequest(optOptions, (signedOptions) =>
            this.httpRequestWrapper.post(url, undefined, signedOptions.headers, optPayload)
        );

        this.storageWrapper.saveLocalStorage(LocalStorageKeys.graphToken, result.external_provider_access_token);
        this.logger.info("--- Graph Credentials Updated --- ");
        return result;
    }
}
