import { IContextualMenuProps, IDropdownOption, IPersonaProps, ITag, MessageBarType } from "office-ui-fabric-react";
import { OfficeWrapper } from "../services/OfficeWrapper";
import { DateHelpers } from "../helpers/DateHelpers";
import { ValueListApiService } from "../services/PimTrackApi/ValueListApiService";
import { useEffect, useMemo, useState } from "react";
import { TranslateFunction } from "react-localize-redux";
import { ActionListType, ProjectItemFeature, ValueListType } from "../services/PimTrackApi/types";
import { SuccessInfo } from "../components/shared/itemSuccess/ItemSuccessComponent";
import { ContactMapping } from "../mapping/ContactMapping";
import { ValueListMapping } from "../mapping/ValueListMapping";
import { IHub } from "../models/Hub";
import { FileUploadApiService } from "../services/PimTrackApi/FileUploadApiService";
import { Common } from "@newforma/platform-client-api-sdk";
import { LogSubmittalMapping } from "../mapping/LogSubmittalMapping";
import { SubmittalApiService } from "../services/PimTrackApi/SubmittalApiService";
import { EmailApiService } from "../services/NewformaApi/EmailApiService";
import { RelatedItemApiService } from "../services/PimTrackApi/RelatedItemApiService";
import { RelatedItemMapping } from "../mapping/RelatedItemMapping";
import {
    defaultClearedLogSubmittalFieldForm,
    LogSubmittalFieldForm,
    ValidLogSubmittalFieldForm,
} from "../models/workflow/submittal/LogSubmittal";
import { useWorkflowItem } from "../components/shared/itemSuccess/ItemContext";
import { OfficeRoamingSettings } from "../services/OfficeRoamingSettings";
import { SupportedAddinItems } from "../models/SupportedItems";
import { ToastMessage } from "../models/ToastMessage";
import { FileWithId } from "../models/shared/FileWithId";
import { BimProjectsApiService } from "../services/BimApi/BimProjectsApiService";
import { BimProjectSystemAttributeName } from "../models/Bimtrack/BimProjectSystemAttributeName";
import { BimProjectSystemAttributeType } from "../models/Bimtrack/BimProjectSystemAttributeType";
import { INVALID_BAD_REQUEST_MESSAGES, InvalidBadRequests } from "../utils/constants";
import { pluralizeSentence } from "../utils/helpers";
import { useAppService } from "../services/AppServiceProvider";

const DEFAULT_SELECTED_PURPOSE = 1;
const DEFAULT_DUE_DATE_OFF_SET_DAY = 14;

interface UseLogSubmittalProps {
    officeWrapper: OfficeWrapper;
    dateHelpers: DateHelpers;
    valueListApiServices: ValueListApiService;
    selectedHub: IHub | null;
    selectedProject: ITag | null;
    translate: TranslateFunction;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    showProgress: (message: string | null) => void;
    submittalApiService: SubmittalApiService;
    fileUploadApiService: FileUploadApiService;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    bimProjectsApiService: BimProjectsApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
    navigateBackToForm?: () => void;
}

const useLogSubmittal = (props: UseLogSubmittalProps) => {
    const [logSubmittalForm, setLogSubmittalForm] = useState<LogSubmittalFieldForm>(
        defaultClearedLogSubmittalFieldForm
    );
    const [isValueListLoading, setIsValueListLoading] = useState<boolean>(true);
    const [disciplineLabel, setDisciplineLabel] = useState<string>(
        props.translate("SHARED.ATTRIBUTES.DISCIPLINE.LABEL") as string
    );
    const [disciplinePlaceholderLabel, setDisciplinePlaceholderLabel] = useState<string>(
        props.translate("SHARED.ATTRIBUTES.DISCIPLINE.PLACEHOLDER_DEFAULT") as string
    );
    const [specSectionOptions, setSpecSectionOptions] = useState<ITag[]>([]);
    const [rawSpecSectionData, setRawSpecSectionData] = useState<Common.LocalizedValue[]>([]);
    const [purposeOptions, setPurposeOptions] = useState<IDropdownOption[]>([]);
    const [userOptions, setUserOptions] = useState<IPersonaProps[]>([]);
    const [disciplineOptions, setDisciplineOptions] = useState<ITag[]>([]);
    const [keywordOptions, setKeywordOptions] = useState<ITag[]>([]);
    const isProjectSelected = !!props.selectedHub && !!props.selectedProject;
    const [isRevisionFieldDisplayed, setIsRevisionFieldDisplayed] = useState<boolean>(false);
    const [isRevisionButtonDisplayed, setIsRevisionButtonDisplayed] = useState<boolean>(false);
    const revisionButtonText = isRevisionFieldDisplayed
        ? (props.translate("SUBMITTALS.SPEC_SECTION.REMOVE_REVISION") as string)
        : (props.translate("SUBMITTALS.SPEC_SECTION.ADD_REVISION") as string);
    const [isFiling, setIsFiling] = useState<boolean>(false);
    const [attachmentSessionIds, setAttachmentSessionIds] = useState<string[]>([]);
    const [isLoadingProjectFail, setIsLoadingProjectFail] = useState<boolean>(false);
    const {
        actions: { setSuccessInfo, setIsEmailFromDeletion },
    } = useWorkflowItem();
    const {
        services: { msGraphApiService },
    } = useAppService();

    useEffect(() => {
        if (!isFiling) {
            if (isProjectSelected) {
                setIsValueListLoading(true);
                props.showProgress(props.translate("SHARED.LOAD_PROJECT_MESSAGE") as string);
                initialize()
                    .catch(() => {
                        setIsLoadingProjectFail(true);
                        props.onShowToast(
                            { message: props.translate("SHARED.LOAD_PROJECT_ERROR_MESSAGE") as string },
                            MessageBarType.error
                        );
                    })
                    .finally(() => {
                        props.showProgress(null);
                        setIsValueListLoading(false);
                    });
            } else {
                clearForm();
            }
        }
    }, [isProjectSelected, props.officeWrapper.currentContextItem.itemId]);

    const autoNumberPrefix = useMemo(
        (): string => (logSubmittalForm.specSection ? `${logSubmittalForm.specSection.name} - ` : ""),
        [logSubmittalForm.specSection]
    );

    const clearForm = () => {
        setLogSubmittalForm(defaultClearedLogSubmittalFieldForm);
        setAttachmentSessionIds([]);
        setDisciplineLabel(props.translate("SHARED.ATTRIBUTES.DISCIPLINE.LABEL") as string);
        setDisciplinePlaceholderLabel(props.translate("SHARED.ATTRIBUTES.DISCIPLINE.PLACEHOLDER_DEFAULT") as string);
    };

    const initialize = async () => {
        const [specSections, purposes, keywords, disciplines, bimtrackContacts] = await getValuesList();

        const projectDetails = await props.bimProjectsApiService.getProjectIssuesAttributes(
            props.selectedHub,
            props.selectedProject
        );

        const mappedUser =
            bimtrackContacts
                ?.map(ContactMapping.mapResponseToIPersonaProps)
                .sort((contact1, contact2) => (contact1.text || "").localeCompare(contact2.text || "")) || [];
        const mappedPurposes = purposes?.items.map(ValueListMapping.mapResponseToIDropdownOption) || [];

        setRawSpecSectionData(specSections?.items || []);
        setSpecSectionOptions(specSections?.items.map(ValueListMapping.mapResponseToITagWithDescription) || []);
        setPurposeOptions(mappedPurposes);
        setKeywordOptions(keywords?.items.map(ValueListMapping.mapResponseToITag) || []);
        setDisciplineOptions(disciplines?.map(ValueListMapping.mapBimProjectAttributeToITag) || []);
        setUserOptions(mappedUser);

        const attachmentsNoCloud = props.officeWrapper
            .getCurrentEmailAttachmentDetails()
            .filter((attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud);
        const attachmentIds = attachmentsNoCloud.map((attachment) => attachment.id);
        const description = await props.officeWrapper.getCurrentEmailBody();
        const subject = await props.officeWrapper.getCurrentEmailSubject();
        const fromEmailFromOfficeWrapper = props.officeWrapper.getSenderEmailAddress()?.toLowerCase();
        const toFromOfficeWrapper = props.officeWrapper
            .getCurrentEmailToRecipients()
            .map((recipient) => recipient.emailAddress.toLowerCase());
        const from = toFromOfficeWrapper
            ? [
                  mappedUser.find(
                      (personaProp) =>
                          personaProp.text === fromEmailFromOfficeWrapper ||
                          personaProp.secondaryText === fromEmailFromOfficeWrapper
                  ) ||
                      <IPersonaProps>{
                          text: fromEmailFromOfficeWrapper,
                          key: fromEmailFromOfficeWrapper,
                          id: fromEmailFromOfficeWrapper,
                      },
              ]
            : [];
        const to = mappedUser.filter((personaProp) =>
            toFromOfficeWrapper.some((email) => email === personaProp.text || email === personaProp.secondaryText)
        );
        const receivedDate = props.officeWrapper.getCurrentEmailDate();
        const dueDate = props.dateHelpers.getDateWithOffset(receivedDate, DEFAULT_DUE_DATE_OFF_SET_DAY);
        const defaultPurposeOption =
            mappedPurposes.find((purpose) => purpose.key === DEFAULT_SELECTED_PURPOSE) || mappedPurposes[0];

        setLogSubmittalForm(() => ({
            ...defaultClearedLogSubmittalFieldForm,
            subject,
            description,
            purpose: defaultPurposeOption,
            from,
            to,
            receivedDate,
            dueDate,
            selectedAttachmentIds: attachmentIds,
            attachments: attachmentsNoCloud,
        }));

        projectDetails.ProjectSystemAttributeNames.map((systemAttributeName) => {
            setLabelForProjectAttribute(systemAttributeName);
        });
    };

    const setLabelForProjectAttribute = (systemAttributeName: BimProjectSystemAttributeName) => {
        const languageCode = props.officeWrapper.getClientDisplayLanguage().slice(0, 2);
        const getAttributeTranslation = (defaultTransKey: string): string => {
            const translation = systemAttributeName.Translations.find((trans) => trans.Language === languageCode);

            if (!translation) {
                return props.translate(defaultTransKey) as string;
            }

            return translation.Name;
        };

        switch (systemAttributeName.Type) {
            case BimProjectSystemAttributeType.Discipline: {
                const translation = getAttributeTranslation("SHARED.ATTRIBUTES.DISCIPLINE.LABEL");
                setDisciplineLabel(translation);
                setDisciplinePlaceholderLabel(
                    props.translate("SHARED.ATTRIBUTES.DISCIPLINE.PLACEHOLDER_CUSTOM", {
                        attributeName: translation.toLowerCase(),
                    }) as string
                );
                break;
            }
        }
    };

    const getValuesList = async () =>
        props.selectedHub && props.selectedProject
            ? Promise.all([
                  props.valueListApiServices.getPimTrackValueList(
                      ProjectItemFeature.Submittals,
                      props.selectedHub,
                      props.selectedProject,
                      ValueListType.SpecSections
                  ),
                  props.valueListApiServices.getPimTrackValueList(
                      ProjectItemFeature.Submittals,
                      props.selectedHub,
                      props.selectedProject,
                      ValueListType.Actions,
                      ActionListType.Receive
                  ),
                  props.valueListApiServices.getPimTrackValueList(
                      ProjectItemFeature.Submittals,
                      props.selectedHub,
                      props.selectedProject,
                      ValueListType.Keywords
                  ),
                  props.valueListApiServices.getBimTrackDiscipline(props.selectedHub, props.selectedProject),
                  props.valueListApiServices.getBimTrackUser(props.selectedHub, props.selectedProject),
              ])
            : Promise.all([]);

    const handleRevisionFieldDisplay = (display: boolean) => {
        setIsRevisionFieldDisplayed(display);

        if (!display) {
            setLogSubmittalForm((prev) => ({
                ...prev,
                revisionNumber: "",
            }));
        }
    };

    const handleRevisionButtonDisplay = (display: boolean) => {
        setIsRevisionButtonDisplayed(display);
    };

    const handleNumberClick = () => {
        setLogSubmittalForm((prev) => ({
            ...prev,
            number: `${!!prev.number ? prev.number : autoNumberPrefix}`,
        }));
    };

    const handleNumberBlur = () => {
        if (logSubmittalForm.number?.trim() === autoNumberPrefix.trim()) {
            setLogSubmittalForm((prev) => ({
                ...prev,
                number: "",
            }));
        }
    };

    const isLogSubmittalFormValid = useMemo(
        (): boolean =>
            !(
                !logSubmittalForm.subject.trim() ||
                !logSubmittalForm.receivedDate ||
                !logSubmittalForm.dueDate ||
                !logSubmittalForm.specSection ||
                !logSubmittalForm.purpose ||
                logSubmittalForm.from.length === 0 ||
                logSubmittalForm.to.length === 0
            ),
        [logSubmittalForm]
    );

    const submitLogSubmittal = async ({ logNewItem = false }) => {
        const steps: SuccessInfo["steps"] = [];
        let submittalId = "";
        let emailDeleted = false;
        const internetMessageId = props.officeWrapper.currentContextItem.internetMessageId;
        const currentMessageId = props.officeWrapper.getCurrentMessageId();

        const selectedFiles = logSubmittalForm.attachments.filter((x) =>
            logSubmittalForm.selectedAttachmentIds.includes(x.id)
        );
        if (!initialRequirementsMet(selectedFiles) || !validateLogSubmittalFieldForm(logSubmittalForm)) {
            return;
        }

        setIsFiling(true);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        const hasSelectedAttachment = !!selectedFiles.length;
        const totalSteps = hasSelectedAttachment ? 4 : 3;
        let stepCounter = 0;
        let attachmentSessionIdList: string[] = [];

        try {
            if (hasSelectedAttachment) {
                attachmentSessionIdList = await handleAttachmentsForSubmittal(totalSteps, ++stepCounter, selectedFiles);
                setAttachmentSessionIds(attachmentSessionIdList);
            }

            props.showProgress(
                props.translate("SUBMITTALS.PROGRESS.FILING_SUBMITTAL", {
                    totalSteps: totalSteps,
                    step: ++stepCounter,
                }) as string
            );

            submittalId = (
                await props.submittalApiService.logSubmittal(
                    props.selectedHub as IHub,
                    props.selectedProject as ITag,
                    LogSubmittalMapping.toRequest(
                        logSubmittalForm,
                        rawSpecSectionData,
                        attachmentSessionIdList,
                        autoNumberPrefix,
                        isRevisionFieldDisplayed
                    )
                )
            ).id;

            steps.push({
                isSuccess: true,
                message: props.translate("SUBMITTALS.SUCCESS.LOG_SUBMITTAL_PARTIAL") as string,
            });
        } catch (e) {
            handleLogSubmittalError(e);

            setIsFiling(false);
            props.showProgress(null);

            return;
        }

        const emailFiled = await fileEmailSucceeded(totalSteps, ++stepCounter, currentMessageId, logNewItem, steps);

        if (emailFiled) {
            await linkSubmittalToEmailStep(steps, totalSteps, ++stepCounter, submittalId, internetMessageId);
            emailDeleted = await deleteEmailAfterFilingStep(logNewItem, currentMessageId);
        }

        setIsFiling(false);
        displaySuccessStep(logNewItem, emailDeleted, submittalId, steps);
    };

    const validateLogSubmittalFieldForm = (form: LogSubmittalFieldForm): form is ValidLogSubmittalFieldForm =>
        isLogSubmittalFormValid;

    const initialRequirementsMet = (selectedFiles: (Office.AttachmentDetails | FileWithId)[]): boolean => {
        if (!(props.selectedHub && props.selectedProject)) {
            return false;
        }

        if (logSubmittalForm.selectedAttachmentIds.length > FileUploadApiService.maxAllowedFile) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.TOO_MANY_FILES") as string },
                MessageBarType.error
            );
            return false;
        }

        if (new Set(selectedFiles.map(({ name }) => name.toLowerCase())).size < selectedFiles.length) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.DUPLICATE_NAME") as string },
                MessageBarType.error
            );
            return false;
        }

        return true;
    };

    const handleAttachmentsForSubmittal = async (
        totalSteps: number,
        stepCounter: number,
        selectedFiles: (Office.AttachmentDetails | FileWithId)[]
    ): Promise<string[]> => {
        const progressMessageTemplate = props.translate("SHARED.PROGRESS.FILE_UPLOADING", {
            totalSteps: totalSteps,
            step: stepCounter,
        }) as string;

        const progressMessage = pluralizeSentence(
            progressMessageTemplate,
            "ATTACHMENT",
            selectedFiles.length,
            props.translate
        );

        props.showProgress(progressMessage);

        if (attachmentSessionIds.length) {
            return attachmentSessionIds;
        }

        const attachmentsList = await props.fileUploadApiService.uploadFile(
            props.selectedHub as IHub,
            props.selectedProject as ITag,
            selectedFiles,
            ProjectItemFeature.Submittals
        );

        return attachmentsList;
    };

    const handleLogSubmittalError = (e: any) => {
        if (e.status === 409) {
            props.onShowToast(
                { message: props.translate("SUBMITTALS.ERRORS.NUMBER_CONFLICT") as string },
                MessageBarType.error
            );
        } else if (e.status === 403) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.NOT_AUTHORIZED") as string },
                MessageBarType.error
            );
        } else if (e.status === 400 && e.responseText.includes(Common.CommonErrorCode.INVALID_EMAIL)) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.INVALID_EMAIL") as string },
                MessageBarType.error
            );
        } else if (e.status === 400) {
            const message = getErrorMessage(e.responseText);
            props.onShowToast({ message }, MessageBarType.error);
        } else {
            setAttachmentSessionIds([]);
            props.onShowToast(
                { message: props.translate("SUBMITTALS.ERRORS.LOG_SUBMITTAL_FAILED_GENERIC") as string },
                MessageBarType.error
            );
        }
    };

    const getErrorMessage = (responseText: string) => {
        const responseErrorTitle = JSON.parse(responseText).title;

        let foundErrorMessage;

        INVALID_BAD_REQUEST_MESSAGES.forEach((errorMessageObj) => {
            if (errorMessageObj.error.includes(responseErrorTitle)) {
                foundErrorMessage = errorMessageObj;
            }
        });

        if (!foundErrorMessage) {
            return responseErrorTitle;
        }

        const translationKey = (foundErrorMessage as InvalidBadRequests).id;

        return props.translate(`SHARED.ERRORS.${translationKey}`);
    };

    const fileEmailSucceeded = async (
        totalSteps: number,
        stepCounter: number,
        currentMessageId: string,
        logNewItem: boolean,
        steps: { isSuccess: boolean; message: string }[]
    ) => {
        props.showProgress(
            props.translate("SHARED.PROGRESS.FILING_EMAIL", {
                totalSteps: totalSteps,
                step: stepCounter,
            }) as string
        );

        try {
            await props.emailApiService.fileEmail(
                currentMessageId,
                props.selectedProject as ITag,
                props.selectedHub as IHub
            );
        } catch (e) {
            steps.push(
                { isSuccess: false, message: props.translate("SUBMITTALS.ERRORS.EMAIL_FILING") as string },
                {
                    isSuccess: false,
                    message: props.translate("SUBMITTALS.ERRORS.SUBMITTAL_LINKING_TO_EMAIL") as string,
                }
            );
            return false;
        }

        return true;
    };

    const linkSubmittalToEmailStep = async (
        steps: { isSuccess: boolean; message: string }[],
        totalSteps: number,
        stepCounter: number,
        submittalId: string,
        internetMessageId: string
    ) => {
        steps.push({ isSuccess: true, message: props.translate("SUBMITTALS.SUCCESS.FILE_EMAIL") as string });

        try {
            props.showProgress(
                props.translate("SUBMITTALS.PROGRESS.LINKING_SUBMITTAL_TO_EMAIL", {
                    totalSteps: totalSteps,
                    step: stepCounter,
                }) as string
            );
            await props.relatedItemApiService.linkItems(
                props.selectedHub as IHub,
                props.selectedProject as ITag,
                {
                    id: submittalId,
                    type: Common.ProjectItemType.Submittal,
                },
                RelatedItemMapping.mapToRelatedItemRequest([
                    {
                        id: internetMessageId,
                        type: Common.ProjectItemType.Email,
                    },
                ])
            );
        } catch (e) {
            steps.push({
                isSuccess: false,
                message: props.translate("SUBMITTALS.ERRORS.SUBMITTAL_LINKING_TO_EMAIL") as string,
            });
        }
    };

    const deleteEmailAfterFilingStep = async (logNewItem: boolean, currentMessageId: string) => {
        try {
            if (
                !logNewItem &&
                props.supportedAddinItems?.canDeleteEmail &&
                props.officeRoamingSettings.getDeleteEmailAfterFiling()
            ) {
                await msGraphApiService.moveMessage(currentMessageId);
                setIsEmailFromDeletion(true);
                return true;
            }
        } catch (e) {
            console.error("Error while moving email to bin");
        }

        return false;
    };

    const displaySuccessStep = (
        logNewItem: boolean,
        emailDeleted: boolean,
        submittalId: string,
        steps: { isSuccess: boolean; message: string }[]
    ) => {
        props.showProgress(null);

        if (logNewItem && steps.every(({ isSuccess }) => isSuccess)) {
            props.onShowToast(
                {
                    message: props.translate("SUBMITTALS.SUCCESS.LOG_SUBMITTAL") as string,
                    link: props.submittalApiService.getSubmittalUrl(
                        submittalId,
                        props.selectedHub as IHub,
                        props.selectedProject as ITag
                    ),
                    linkText: props.translate("SHARED.OPEN_IN_BIMTRACK") as string,
                },
                MessageBarType.success
            );
        } else {
            setSuccessInfo({
                message: steps.every(({ isSuccess }) => isSuccess)
                    ? (props.translate("SUBMITTALS.SUCCESS.LOG_SUBMITTAL") as string)
                    : (props.translate("SUBMITTALS.ERRORS.LOG_SUBMITTAL_PARTIAL_FAILED") as string),
                redirectUrl: props.submittalApiService.getSubmittalUrl(
                    submittalId,
                    props.selectedHub as IHub,
                    props.selectedProject as ITag
                ),
                projectItemFeature: ProjectItemFeature.Submittals,
                steps,
                navigateBackToForm: props.navigateBackToForm,
                displayNewButton: !emailDeleted,
            });
        }
    };

    const updateField = (updatedField: Partial<LogSubmittalFieldForm>) => {
        setLogSubmittalForm((prevState) => ({ ...prevState, ...updatedField }));
    };

    const subMenuOptions = useMemo(
        (): IContextualMenuProps => ({
            items: [
                {
                    key: props.translate("SHARED.SUBMIT_BUTTON.LOG_AND_FILE_ANOTHER") as string,
                    text: props.translate("SHARED.SUBMIT_BUTTON.LOG_AND_FILE_ANOTHER") as string,
                    onClick: () => {
                        submitLogSubmittal({ logNewItem: true });
                    },
                },
            ],
            calloutProps: {
                className: "splitMenuPropStyle",
            },
        }),
        [props.translate, logSubmittalForm]
    );

    return {
        actions: {
            handleRevisionFieldDisplay,
            handleRevisionButtonDisplay,
            handleNumberClick,
            handleNumberBlur,
            submitLogSubmittal,
        },
        logSubmittalForm,
        updateField,
        disciplineLabel,
        disciplinePlaceholderLabel,
        specSectionOptions,
        purposeOptions,
        userOptions,
        disciplineOptions,
        keywordOptions,
        isRevisionFieldDisplayed,
        isRevisionButtonDisplayed,
        revisionButtonText,
        isLogSubmittalFormValid,
        autoNumberPrefix,
        isFieldDisabled: isFiling || !isProjectSelected || isValueListLoading || isLoadingProjectFail,
        subMenuOptions,
    };
};

export default useLogSubmittal;
