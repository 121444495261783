import * as OfficeHelpers from "@microsoft/office-js-helpers";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { LocalizeProvider } from "react-localize-redux";
import AppComponent from "../app";
import LoginRedirectComponent from "../components/login/LoginRedirectComponent";
import { DateHelpers } from "../helpers/DateHelpers";
import { FormValidationHelpers } from "../helpers/FormValidationHelpers";
import { SendAndFileHelpers } from "../helpers/SendAndFile/SendAndFileHelpers";
import { AppProvider } from "../store/AppProvider";
import { AnalyticsManager } from "./AnalyticsManager";
import { AppServiceProvider } from "./AppServiceProvider";
import { Authenticator } from "./Authenticator";
import { BimProjectsApiService } from "./BimApi/BimProjectsApiService";
import { IssueApiService } from "./BimApi/IssueApiService";
import { ConfigurationService } from "./ConfigurationService";
import { CustomerSupportEmailService } from "./CustomerSupportEmailService";
import { Logger } from "./Logger";
import { EmailApiService } from "./NewformaApi/EmailApiService";
import { NewformaApiClient } from "./NewformaApi/NewformaApiClient";
import { ProjectsApiService } from "./NewformaApi/ProjectsApiService";
import { OfficeRoamingSettings } from "./OfficeRoamingSettings";
import { OfficeWrapper } from "./OfficeWrapper";
import { FileUploadApiService } from "./PimTrackApi/FileUploadApiService";
import { RelatedItemApiService } from "./PimTrackApi/RelatedItemApiService";
import { RfiApiService } from "./PimTrackApi/RfiApiService";
import { SubmittalApiService } from "./PimTrackApi/SubmittalApiService";
import { ValueListApiService } from "./PimTrackApi/ValueListApiService";
import { SmartFilingManager } from "./SmartFiling/SmartFilingManager";
import { StorageWrapper } from "./StorageWrapper";
import { SubscriptionApiService } from "./SubscriptionApiService/SubscriptionApiService";
import { TranslationService } from "./TranslationService";
import { UnauthenticatedApiClient } from "./UnauthenticatedApiClient";
import { WindowWrapper } from "./WindowWrapper";
import GainSight from "./gainSight/GainSight";
import Toast from "../components/shared/toast/Toast";
import ProgressBar from "../components/shared/progressBar/ProgressBar";
import ProgressBarProvider from "../components/shared/progressBar/useProgressBar";
import ToastProvider from "../components/shared/toast/useToast";

export class EntryPoint {
    private isLoginRedirect = false;
    constructor(
        private authenticator: Authenticator,
        private apiClient: NewformaApiClient,
        private unauthenticatedApiClient: UnauthenticatedApiClient,
        private analyticsManager: AnalyticsManager,
        private smartFilingManager: SmartFilingManager,
        private officeWrapper: OfficeWrapper,
        private officeRoamingSettings: OfficeRoamingSettings,
        private configService: ConfigurationService,
        private storageWrapper: StorageWrapper,
        private windowWrapper: WindowWrapper,
        private formValidationHelpers: FormValidationHelpers,
        private translationService: TranslationService,
        private customerSupportEmailService: CustomerSupportEmailService,
        private logger: Logger,
        private projectsApiService: ProjectsApiService,
        private bimProjectsApiService: BimProjectsApiService,
        private sendAndFileHelpers: SendAndFileHelpers,
        private dateHelpers: DateHelpers,
        private rfiApiService: RfiApiService,
        private emailApiService: EmailApiService,
        private gainSightAnalytics: GainSight,
        private valueListApiServices: ValueListApiService,
        private fileUploadApiService: FileUploadApiService,
        private relatedItemApiService: RelatedItemApiService,
        private subscriptionApiService: SubscriptionApiService,
        private submittalApiService: SubmittalApiService,
        private issueApiService: IssueApiService
    ) {
        initializeFileTypeIcons("https://spoprod-a.akamaihd.net/files/fabric/assets/item-types-fluent/");
        this.initializeAddin();
    }

    private initializeAddin() {
        const locationHash = this.windowWrapper.locationHash();
        this.isLoginRedirect = locationHash.startsWith("#loginrouting");

        if (this.isLoginRedirect) {
            this.render();
            return;
        }

        if (OfficeHelpers.Authenticator.isAuthDialog()) {
            // This fires when App.refreshToken() uses displayInIframe: true with Office.context.ui.displayDialogAsync
            return;
        }

        // Init GainSight
        this.gainSightAnalytics.initialize();

        this.render();
    }

    render(): void {
        ReactDOM.render(
            <LocalizeProvider>
                {this.isLoginRedirect ? (
                    <LoginRedirectComponent
                        translationService={this.translationService}
                        windowWrapper={this.windowWrapper}
                    />
                ) : (
                    <AppProvider
                        authenticator={this.authenticator}
                        officeRoamingSettings={this.officeRoamingSettings}
                        officeWrapper={this.officeWrapper}
                        storageWrapper={this.storageWrapper}
                        translationService={this.translationService}
                        logger={this.logger}
                    >
                        <AppServiceProvider
                            logger={this.logger}
                            authenticator={this.authenticator}
                            configurationService={this.configService}
                        >
                            <ProgressBarProvider>
                                <ToastProvider>
                                    <Toast />
                                    <ProgressBar />
                                    <AppComponent
                                        officeRoamingSettings={this.officeRoamingSettings}
                                        authenticator={this.authenticator}
                                        apiClient={this.apiClient}
                                        unauthenticatedApiClient={this.unauthenticatedApiClient}
                                        analyticsManager={this.analyticsManager}
                                        smartFilingManager={this.smartFilingManager}
                                        officeWrapper={this.officeWrapper}
                                        configService={this.configService}
                                        storageWrapper={this.storageWrapper}
                                        windowWrapper={this.windowWrapper}
                                        formValidationHelpers={this.formValidationHelpers}
                                        translationService={this.translationService}
                                        customerSupportEmailService={this.customerSupportEmailService}
                                        logger={this.logger}
                                        projectsApiService={this.projectsApiService}
                                        bimProjectsApiService={this.bimProjectsApiService}
                                        sendAndFileHelpers={this.sendAndFileHelpers}
                                        dateHelpers={this.dateHelpers}
                                        rfiApiService={this.rfiApiService}
                                        emailApiService={this.emailApiService}
                                        valueListApiServices={this.valueListApiServices}
                                        fileUploadApiService={this.fileUploadApiService}
                                        relatedItemApiService={this.relatedItemApiService}
                                        subscriptionApiService={this.subscriptionApiService}
                                        submittalApiService={this.submittalApiService}
                                        issueApiService={this.issueApiService}
                                    />
                                </ToastProvider>
                            </ProgressBarProvider>
                        </AppServiceProvider>
                    </AppProvider>
                )}
            </LocalizeProvider>,
            document.getElementById("container")
        );
    }
}
