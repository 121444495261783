import { UserPreferenceType } from "../BimApi/BimProjectsApiService";
import { UserInfo } from "./../../models/PimModels";

export interface gainSightUserIdentityProps {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    signUpDate: string;
    lang: string;
    title: string;
    timeZone: string;
    countryCode: string | number;
}

export const getGainSightUserIdentity = (user: UserInfo) => ({
    id: user.name,
    email: user.email,
    firstName: user.given_name,
    lastName: user.family_name,
    countryCode: user.country, // not Code
    lang: user.Language,
    timeZone: user.TimeZoneNameIana,
});

export type UserInfoAll = UserInfo & UserPreferenceType;
