import { renderToStaticMarkup } from "react-dom/server";
import { LocalizeContextProps } from "react-localize-redux";
import { Logger } from "./Logger";
const englishTranslations = require("../i18n/en.json");
const frenchTranslations = require("../i18n/fr.json");

export enum LanguageCode {
    English = "en",
    French = "fr",
}

export const DefaultLocales = {
    [LanguageCode.English]: "en-CA",
    [LanguageCode.French]: "fr-CA",
};

export class TranslationService {
    constructor(private logger: Logger) {}
    getTranslations(props: LocalizeContextProps) {
        // NOTE: For help with i18n, see https://ryandrewjohnson.github.io/react-localize-redux-docs/
        props.initialize({
            languages: [
                { name: "English", code: LanguageCode.English },
                { name: "French", code: LanguageCode.French },
            ],
            options: {
                renderToStaticMarkup,
                defaultLanguage: LanguageCode.English,
            },
        });
        let language: string = LanguageCode.English;
        try {
            language = Office.context.displayLanguage.substr(0, 2);
        } catch (error) {
            this.logger.error(
                `Failed to load translation strings. Returning english strings.\n${JSON.stringify(error)}`
            );
            props.addTranslationForLanguage(englishTranslations, LanguageCode.English);
            return;
        }
        switch (language) {
            case LanguageCode.English:
                props.addTranslationForLanguage(englishTranslations, language);
                break;
            case LanguageCode.French:
                props.addTranslationForLanguage(frenchTranslations, LanguageCode.French);
                break;
            default:
                this.logger.info(`unsupported language ${language} defaulting to english`);
                language = LanguageCode.English;
                props.addTranslationForLanguage(englishTranslations, language);
                break;
        }
        this.logger.info(`setting language to ${language}`);
        props.setActiveLanguage(language);
    }
}
