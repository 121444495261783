import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import "./AttachmentIcon.less";

function AttachmentIcon() {
    return (
        <div className="attachment-icon-container newforma-flexCentered">
            <Icon iconName="Attach" />
        </div>
    );
}

export default AttachmentIcon;
