import { DefaultButton, Label, Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { SupportedAddinItems } from "../../../models/SupportedItems";
import "./SupportedFunctionalities.less";
import { useAppState } from "../../../store/AppProvider";

interface SupportedFunctionalitiesProps extends LocalizeContextProps {
    children: string | JSX.Element | JSX.Element[];
    isFilingOptionSupportedOnSelectedScreen: boolean;
    supportedFilingOptions: SupportedAddinItems | null;
}

const SupportedFunctionalities = ({
    children,
    translate,
    supportedFilingOptions,
    isFilingOptionSupportedOnSelectedScreen,
}: SupportedFunctionalitiesProps) => {
    const {
        actions: { setSelectedHub },
    } = useAppState();

    const ExpiredSubscriptionView = () => (
        <div className="supportedFilingOptions-unsubscribed">
            <img src="assets/warning-empty-state.svg" alt="Newforma Konekt empty hubs" />
            <div className="supportedFilingOptions-content">
                <div>
                    <Label className="supportedFilingOptions-title">{translate("SUBSCRIPTION.EXPIRED")}</Label>
                </div>
                <div>
                    <Label className="supportedFilingOptions-message">
                        {translate("SUBSCRIPTION.EXPIRED_SUB") as string}
                    </Label>
                </div>
            </div>
            <div id="footer" key="footer" className="newforma-footer">
                <DefaultButton
                    className="hub-selector-dashboard-button-signout"
                    data-testid="newforma-footerButton"
                    onClick={() => setSelectedHub(null)}
                    text={translate("APP.COMMANDBAR.CHANGE_HUB") as string}
                />
            </div>
        </div>
    );

    const UnsubscribedView = () => (
        <div className="supportedFilingOptions-unsubscribed">
            <img src="assets/warning-empty-state.svg" alt="Newforma Konekt empty hubs" />
            <div className="supportedFilingOptions-content">
                <div>
                    <Label className="supportedFilingOptions-title">{translate("SUBSCRIPTION.EMAIL")}</Label>
                </div>
                <div>
                    <Label className="supportedFilingOptions-message">
                        {translate("SUBSCRIPTION.MESSAGE") as string}
                    </Label>
                </div>
            </div>
        </div>
    );

    if (!supportedFilingOptions) {
        return (
            <div className="spinner-wrapper">
                <Spinner size={SpinnerSize.large} />
            </div>
        );
    }

    if (supportedFilingOptions?.isSubscriptionExpired && supportedFilingOptions?.isSubscriptionExpired === true) {
        return <ExpiredSubscriptionView />;
    }

    if (supportedFilingOptions && !isFilingOptionSupportedOnSelectedScreen) {
        return <UnsubscribedView />;
    }

    return <>{children}</>;
};

export default withLocalize(SupportedFunctionalities);
