import { Common, Submittal } from "@newforma/platform-client-api-sdk";
import { DateHelpers } from "../helpers/DateHelpers";
import { BaseProjectItemMapping } from "./BaseProjectItemMapping";
import { ValidLogSubmittalFieldForm } from "../models/workflow/submittal/LogSubmittal";

export class LogSubmittalMapping extends BaseProjectItemMapping {
    static toRequest(
        logSubmittalFieldForm: ValidLogSubmittalFieldForm,
        specSectionOptions: Common.LocalizedValue[],
        sessionIds: string[],
        autoNumberPrefix: string,
        autoRevisionFieldEnabled: boolean
    ): Submittal.LogRequest {
        const submittalNumber = logSubmittalFieldForm.number?.trim();

        return {
            subject: logSubmittalFieldForm.subject.trim(),
            specSection: this.mapSpecSection(logSubmittalFieldForm.specSection, specSectionOptions),
            receivedDate: DateHelpers.getStartOfDay(logSubmittalFieldForm.receivedDate || new Date()),
            dueDate: DateHelpers.getStartOfDay(logSubmittalFieldForm.dueDate || new Date()),
            purpose: this.toIdValue(logSubmittalFieldForm.purpose.key),
            from: logSubmittalFieldForm.from.map((x) => this.mapContact(x))[0],
            to: logSubmittalFieldForm.to.map(this.mapContactWithId),
            number: !!submittalNumber && submittalNumber !== autoNumberPrefix.trim() ? submittalNumber : undefined,
            revisionNumber: logSubmittalFieldForm.revisionNumber?.trim() || undefined,
            ...(autoRevisionFieldEnabled && !logSubmittalFieldForm.revisionNumber && { autoRevisionNumber: true }),
            description: logSubmittalFieldForm.description.trim() || undefined,
            senderNumber: logSubmittalFieldForm.senderNumber.trim() || undefined,
            disciplines: logSubmittalFieldForm.disciplines.map((x) => this.toIdValue(x.key)),
            keywords: logSubmittalFieldForm.keywords.map((x) =>
                x.key === x.name ? { customName: x.name } : this.toIdValue(x.key)
            ),
            attachments: sessionIds && sessionIds.length ? { uploadIds: sessionIds } : undefined,
        };
    }
}
