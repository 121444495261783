import * as React from "react";
import "./EmailListRowItemComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Checkbox, DirectionalHint, HoverCard, HoverCardType } from "office-ui-fabric-react";
import { EmailIdentifier } from "../../../models/EmailListResponse";
import { DateTime } from "@bimone/bimtrack-dates";

export interface EmailListRowItemComponentProps extends LocalizeContextProps {
    className?: string;
    disabled: boolean;
    subject: string;
    from: EmailIdentifier;
    preview: string;
    id: string;
    isFiled: boolean;
    isSelected: boolean;
    sentDateTime: string;
    categories: string[];
    onSelectionStateChanged: (id: string, checked: boolean) => void;
}

export interface EmailListRowItemComponentState {
    isSelected: boolean;
}

export const defaultEmailListRowItemComponentState: EmailListRowItemComponentState = {
    isSelected: false,
};

class EmailListRowItemComponent extends React.Component<
    EmailListRowItemComponentProps,
    EmailListRowItemComponentState
> {
    constructor(props: EmailListRowItemComponentProps, context: EmailListRowItemComponentState) {
        super(props, context);

        this.state = { ...defaultEmailListRowItemComponentState, isSelected: this.props.isSelected };
    }

    componentDidUpdate(
        prevProps: Readonly<EmailListRowItemComponentProps>,
        prevState: Readonly<EmailListRowItemComponentState>,
        snapshot?: any
    ) {
        if (prevProps.isSelected !== this.props.isSelected) {
            this.setState({ isSelected: this.props.isSelected });
        }
    }

    private onSelectionChange(event?: React.FormEvent<HTMLElement>, checked?: boolean) {
        if (checked === undefined || checked === null) {
            return;
        }
        this.setState({ isSelected: checked });
        this.props.onSelectionStateChanged(this.props.id, checked);
    }

    private static getDate(date: string) {
        const basicFormat = "YYYY-MM-DD";
        return DateTime.fromFormat(date, basicFormat).format(basicFormat);
    }

    private renderFiledHint(): JSX.Element {
        const hoverText = this.props.categories[0] || this.props.translate("FILE_MULTIPLE_EMAIL.FILED_BY_BIM_TRACK");

        return <div className="newforma-hintText">{hoverText}</div>;
    }

    render(): JSX.Element {
        return (
            <div className="newforma-emailListRowItemComponent">
                <div className="newforma-checkboxContainer">
                    <Checkbox
                        className="newforma-check"
                        checked={this.state.isSelected}
                        onChange={this.onSelectionChange.bind(this)}
                        disabled={this.props.disabled}
                    />
                </div>
                <div className="newforma-emailListRowDetails">
                    <div className="newforma-emailListRowDetailTopRow">
                        <div className="newforma-from">{`${this.props.from.name}`}</div>
                        <div className="newforma-sentDate">{`${EmailListRowItemComponent.getDate(
                            this.props.sentDateTime
                        )}`}</div>
                    </div>

                    <div className="newforma-subject">{this.props.subject}</div>

                    <div className="newforma-preview-wrapper">
                        <div className="newforma-preview">{`${this.props.preview}`}</div>
                        {this.props.isFiled ? (
                            <HoverCard
                                plainCardProps={{
                                    onRenderPlainCard: this.renderFiledHint.bind(this),
                                    directionalHint: DirectionalHint.rightCenter,
                                    gapSpace: 5,
                                    className: "newforma-hintText-body",
                                }}
                                instantOpenOnClick
                                type={HoverCardType.plain}
                                className="newforma-mailCheck"
                            >
                                <img src="/assets/filed-email.svg" />
                            </HoverCard>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(EmailListRowItemComponent);
