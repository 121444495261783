import { Checkbox, DefaultButton, ITag, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { DateHelpers } from "../../helpers/DateHelpers";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import useLogRfi from "../../hooks/useLogRfi";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { IHub } from "../../models/Hub";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { FileUploadApiService } from "../../services/PimTrackApi/FileUploadApiService";
import { RelatedItemApiService } from "../../services/PimTrackApi/RelatedItemApiService";
import { RfiApiService } from "../../services/PimTrackApi/RfiApiService";
import { ValueListApiService } from "../../services/PimTrackApi/ValueListApiService";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import ChipPickerComponent from "../shared/field/chipPicker/ChipPickerComponent";
import ContactDropdownComponent from "../shared/field/contactDropdown/ContactDropdownComponent";
import DropdownComponent from "../shared/field/dropdown/DropdownComponent";
import {
    DEFAULT_TEXT_FIELD_ROWS,
    LONG_FIELD_LENGTH,
    MULTI_DROPDOWN_ONE_ITEM,
    SHORT_FIELD_LENGTH,
} from "../shared/field/fieldConstants";
import TextFieldComponent from "../shared/field/inputText/TextFieldComponent";
import SpecSectionComponent from "../shared/field/specSection/SpecSectionComponent";
import TranslatedDatePickerComponent from "../shared/translatedDatePicker/TranslatedDatePickerComponent";
import "./RfiComponent.less";
import "./Common.less";
import { SupportedAddinItems } from "../../models/SupportedItems";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { ToastMessage } from "../../models/ToastMessage";
import { BimProjectsApiService } from "../../services/BimApi/BimProjectsApiService";

export interface RfiComponentProps extends LocalizeContextProps {
    formValidationHelpers: FormValidationHelpers;
    officeWrapper: OfficeWrapper;
    dateHelpers: DateHelpers;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    selectedHub: IHub | null;
    selectedProject: ITag | null;
    valueListApiServices: ValueListApiService;
    showProgress: (message: string | null) => void;
    rfiApiService: RfiApiService;
    fileUploadApiService: FileUploadApiService;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    bimProjectsApiService: BimProjectsApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
    navigateBackToForm?: () => void;
}

function RfiComponent(props: RfiComponentProps) {
    const {
        logRfiForm,
        disciplineLabel,
        disciplinePlaceholderLabel,
        typeOptions,
        impactCostOptions,
        impactScheduleOptions,
        reasonOptions,
        keywordOptions,
        disciplineOptions,
        userOptions,
        specSectionOptions,
        isLogRfiFormValid,
        submitLogRfi,
        isFieldDisabled,
        updateField,
        subMenuOptions,
    } = useLogRfi({
        officeWrapper: props.officeWrapper,
        dateHelpers: props.dateHelpers,
        valueListApiServices: props.valueListApiServices,
        selectedHub: props.selectedHub,
        selectedProject: props.selectedProject,
        translate: props.translate,
        onShowToast: props.onShowToast,
        showProgress: props.showProgress,
        rfiApiService: props.rfiApiService,
        fileUploadApiService: props.fileUploadApiService,
        emailApiService: props.emailApiService,
        relatedItemApiService: props.relatedItemApiService,
        bimProjectsApiService: props.bimProjectsApiService,
        officeRoamingSettings: props.officeRoamingSettings,
        supportedAddinItems: props.supportedAddinItems,
        navigateBackToForm: props.navigateBackToForm,
    });

    return (
        <div className="rfiComponent" data-testid="rfiComponent">
            <div className="rfiComponentForm">
                <TextFieldComponent
                    className="newforma-formSpacing rfiSubjectField"
                    label={props.translate("SHARED.ATTRIBUTES.SUBJECT.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.SUBJECT.PLACEHOLDER") as string}
                    required
                    value={logRfiForm.subject}
                    onChange={(newValue) => {
                        updateField({ subject: newValue });
                    }}
                    maxLength={SHORT_FIELD_LENGTH}
                    disabled={isFieldDisabled}
                />
                <TextFieldComponent
                    className="newforma-formSpacing rfiNumberField"
                    label={props.translate("RFI.NUMBER.LABEL") as string}
                    placeholder={props.translate("RFI.NUMBER.PLACEHOLDER") as string}
                    required={false}
                    value={logRfiForm.number}
                    onChange={(newValue) => {
                        updateField({ number: newValue });
                    }}
                    maxLength={SHORT_FIELD_LENGTH}
                    disabled={isFieldDisabled}
                />
                <SpecSectionComponent
                    className="newforma-formSpacing rfiSpecSectionField"
                    required={false}
                    disabled={isFieldDisabled || !specSectionOptions.length}
                    formValidationHelpers={props.formValidationHelpers}
                    selectedItem={logRfiForm.specSection}
                    items={specSectionOptions}
                    onChange={(specSectionName) => {
                        updateField({ specSection: specSectionName });
                    }}
                />
                <TranslatedDatePickerComponent
                    date={logRfiForm.receivedDate}
                    label={props.translate("SHARED.ATTRIBUTES.RECEIVED_DATE_LABEL") as string}
                    onDateChange={(date) => {
                        updateField({ receivedDate: date });
                    }}
                    className="newforma-formSpacing rfiDatePickers rfiReceivedDate"
                    isDisabled={isFieldDisabled}
                    required
                    showClearDateButton={false}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                />
                <Checkbox
                    className="rfiUrgentCheckbox"
                    label={props.translate("RFI.URGENT") as string}
                    checked={logRfiForm.urgent}
                    onChange={(_, checked) => {
                        updateField({ urgent: !!checked });
                    }}
                    disabled={isFieldDisabled}
                />
                <TranslatedDatePickerComponent
                    date={logRfiForm.dueDate}
                    label={props.translate("SHARED.ATTRIBUTES.DUE_DATE.LABEL") as string}
                    onDateChange={(date) => {
                        updateField({ dueDate: date });
                    }}
                    className="newforma-formSpacing rfiDatePickers rfiDueDate"
                    isDisabled={isFieldDisabled}
                    required
                    showClearDateButton={false}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                />
                <DropdownComponent
                    className="rfiTypeField newforma-formSpacing"
                    options={typeOptions}
                    label={props.translate("SHARED.ATTRIBUTES.TYPE.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.TYPE.PLACEHOLDER") as string}
                    disabled={isFieldDisabled || !typeOptions.length}
                    onChange={(option) => {
                        updateField({ type: option });
                    }}
                    selectedOption={logRfiForm.type}
                    required={false}
                />
                <TextFieldComponent
                    className="rfiSenderNumberField newforma-formSpacing"
                    label={props.translate("SHARED.ATTRIBUTES.SENDER_NUMBER.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.SENDER_NUMBER.PLACEHOLDER") as string}
                    required={false}
                    value={logRfiForm.senderNumber}
                    onChange={(newValue) => {
                        updateField({ senderNumber: newValue });
                    }}
                    maxLength={SHORT_FIELD_LENGTH}
                    disabled={isFieldDisabled}
                />
                <ContactDropdownComponent
                    className="rfiFromField newforma-formSpacing"
                    label={props.translate("SHARED.FROM_AND_TO.FROM_LABEL") as string}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                    formValidationHelpers={props.formValidationHelpers}
                    required
                    items={userOptions}
                    itemLimit={MULTI_DROPDOWN_ONE_ITEM}
                    disabled={isFieldDisabled || !userOptions.length}
                    selectedContacts={logRfiForm.from}
                    onChange={(selectedItem) => {
                        updateField({ from: selectedItem });
                    }}
                    allowCustomInput
                />
                <ContactDropdownComponent
                    className="rfiToField newforma-formSpacing"
                    label={props.translate("SHARED.FROM_AND_TO.TO_LABEL") as string}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                    formValidationHelpers={props.formValidationHelpers}
                    required
                    items={userOptions}
                    disabled={isFieldDisabled || !userOptions.length}
                    selectedContacts={logRfiForm.to}
                    onChange={(selectedItem) => {
                        updateField({ to: selectedItem });
                    }}
                />
                <TextFieldComponent
                    className="newforma-formSpacing rfiQuestionField"
                    label={props.translate("RFI.QUESTION.LABEL") as string}
                    placeholder={props.translate("RFI.QUESTION.PLACEHOLDER") as string}
                    multiline
                    resizable
                    value={logRfiForm.question}
                    onChange={(newValue) => {
                        updateField({ question: newValue });
                    }}
                    maxLength={LONG_FIELD_LENGTH}
                    rows={DEFAULT_TEXT_FIELD_ROWS}
                    required
                    disabled={isFieldDisabled}
                />
                <TextFieldComponent
                    className="newforma-formSpacing rfiSuggestionField"
                    label={props.translate("RFI.SUGGESTIONS.LABEL") as string}
                    placeholder={props.translate("RFI.SUGGESTIONS.PLACEHOLDER") as string}
                    multiline
                    resizable
                    value={logRfiForm.suggestions}
                    onChange={(newValue) => {
                        updateField({ suggestions: newValue });
                    }}
                    maxLength={LONG_FIELD_LENGTH}
                    rows={DEFAULT_TEXT_FIELD_ROWS}
                    required={false}
                    disabled={isFieldDisabled}
                />
                <DropdownComponent
                    className="newforma-formSpacing rfiImpactScheduleField"
                    options={impactScheduleOptions}
                    placeholder={props.translate("RFI.IMPACT.PLACEHOLDER") as string}
                    label={props.translate("RFI.IMPACT.SCHEDULE_LABEL") as string}
                    disabled={isFieldDisabled || !impactScheduleOptions.length}
                    onChange={(option) => {
                        updateField({ impactSchedule: option });
                    }}
                    selectedOption={logRfiForm.impactSchedule}
                    required={false}
                />
                <DropdownComponent
                    className="newforma-formSpacing rfiImpactCostField"
                    options={impactCostOptions}
                    placeholder={props.translate("RFI.IMPACT.PLACEHOLDER") as string}
                    label={props.translate("RFI.IMPACT.COST_LABEL") as string}
                    disabled={isFieldDisabled || !impactCostOptions.length}
                    onChange={(option) => {
                        updateField({ impactCost: option });
                    }}
                    selectedOption={logRfiForm.impactCost}
                    required={false}
                />
                <ChipPickerComponent
                    className="newforma-formSpacing rfiReasonsField"
                    required={false}
                    disabled={isFieldDisabled || !reasonOptions.length}
                    items={reasonOptions}
                    onChange={(selectedItems) => {
                        updateField({
                            reasons: selectedItems,
                        });
                    }}
                    label={props.translate("RFI.REASON.LABEL") as string}
                    inputPlaceholder={props.translate("RFI.REASON.PLACEHOLDER") as string}
                    allowCustomInput={false}
                    selectedItems={logRfiForm.reasons}
                />
                <ChipPickerComponent
                    className="newforma-formSpacing rfiDisciplinesField"
                    required={false}
                    disabled={isFieldDisabled || !disciplineOptions.length}
                    items={disciplineOptions}
                    onChange={(selectedItems) => {
                        updateField({ discipline: selectedItems });
                    }}
                    label={disciplineLabel}
                    inputPlaceholder={disciplinePlaceholderLabel}
                    allowCustomInput={false}
                    selectedItems={logRfiForm.discipline}
                />
                <ChipPickerComponent
                    className="newforma-formSpacing rfiKeywordsField"
                    required={false}
                    disabled={isFieldDisabled || !keywordOptions.length}
                    items={keywordOptions}
                    onChange={(selectedItems) => {
                        updateField({ keywords: selectedItems });
                    }}
                    label={props.translate("SHARED.ATTRIBUTES.KEYWORDS.LABEL") as string}
                    inputPlaceholder={props.translate("SHARED.ATTRIBUTES.KEYWORDS.PLACEHOLDER") as string}
                    allowCustomInput
                    selectedItems={logRfiForm.keywords}
                />
                <AttachmentsComponent
                    attachments={logRfiForm.attachments}
                    disabled={isFieldDisabled}
                    onAttachmentSelectionChange={(attachmentIds) => {
                        updateField({ selectedAttachmentIds: attachmentIds });
                    }}
                    allowFileUploads={false}
                />
            </div>

            <div id="footer" key="footer" className="newforma-footer">
                <DefaultButton
                    key="logRfiSubmitButton"
                    className="newforma-footerButton"
                    id="logRfiButton"
                    primary
                    split
                    menuProps={subMenuOptions}
                    onClick={() => submitLogRfi({ logNewItem: false })}
                    text={props.translate("RFI.SUBMIT_BUTTON.LOG") as string}
                    disabled={isFieldDisabled || !isLogRfiFormValid}
                />
            </div>
        </div>
    );
}

export default withLocalize(RfiComponent);
