import { Rfi, Submittal } from "@newforma/platform-client-api-sdk";
import { ITag } from "office-ui-fabric-react";

export class ProjectItemMapping {
    static mapToITag(item: Rfi.RfiItem | Submittal.SubmittalItem): ITag {
        return {
            key: item.id,
            name: `${item.number} - ${item.subject}`,
        };
    }
}
