export enum SessionStorageKeys {
    urlLocation = "location",
}

export enum LocalStorageKeys {
    loginUrl = "loginUrl",
    refreshToken = "refreshToken",
    accessToken = "accessToken",
    externalToken = "externalToken",
    idToken = "idToken",
    graphToken = "graphToken",
    hub = "hub",
    addInUser = "addInUser",
    userHubPreference = "userHubPreference",
    hubSubscriptionInfo = "hubSubscriptionInfo",
    supportedFeatureFlags = "supportedFeatureFlags",
}
