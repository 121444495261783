import * as React from "react";
import { useAppService } from "../../services/AppServiceProvider";
import { useAppState } from "../../store/AppProvider";
import { SupportedFeatureFlags } from "../../models/SupportedItems";
import { useEffect } from "react";
import { useLaunchDarkly } from "@bimone/bimtrack-core";

export interface FeatureFlagProviderProps {
    children: React.ReactNode;
}

function FeatureFlagContainer(props: FeatureFlagProviderProps) {
    const {
        services: { subscriptionApiService, logger },
    } = useAppService();
    const {
        appState: { selectedHub },
        actions: { setSupportedFeatureFlags, setSupportedAddinItems },
    } = useAppState();
    const { isInitialized } = useLaunchDarkly();

    useEffect(() => {
        if (!selectedHub || !isInitialized) {
            return;
        }

        const fetchedSupportedFeatureFlags: SupportedFeatureFlags = {
            // add flags here
        };

        const getSupportedAddinItems = async () => {
            try {
                const newSupportedAddinItems = await subscriptionApiService.getSupportedAddinItems(
                    fetchedSupportedFeatureFlags,
                    selectedHub
                );

                setSupportedAddinItems(newSupportedAddinItems);
                setSupportedFeatureFlags(fetchedSupportedFeatureFlags);
            } catch (error) {
                logger.error(`${error}`);
            }
        };

        getSupportedAddinItems();
    }, [selectedHub, isInitialized]);

    return <React.Fragment>{props.children}</React.Fragment>;
}

export default FeatureFlagContainer;
