import * as React from "react";

function FileBackIcon() {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill={"inherited"} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_502_2-502140)">
                <path d="M3.37 12.57C3.09 12.57 2.87 12.35 2.87 12.07V1.96997C2.87 1.68997 3.09 1.46997 3.37 1.46997C3.65 1.46997 3.87 1.68997 3.87 1.96997V12.07C3.87 12.35 3.65 12.57 3.37 12.57ZM1 10.92C0.72 10.92 0.5 10.7 0.5 10.42V3.61997C0.5 3.33997 0.72 3.11997 1 3.11997C1.28 3.11997 1.5 3.33997 1.5 3.61997V10.43C1.5 10.71 1.28 10.93 1 10.93V10.92Z" />
                <path d="M15.71 10.78C15.71 10.95 15.58 11.1 15.41 11.12H13.22V12.71C13.22 12.71 13.2 12.8 13.17 12.84L13.13 12.88C13.01 13 12.81 13.02 12.68 12.91L12.64 12.88L12.05 12.29L10.78 11.02C10.78 11.02 10.75 10.99 10.74 10.97C10.74 10.96 10.74 10.95 10.72 10.94C10.72 10.94 10.7 10.9 10.7 10.89C10.7 10.89 10.68 10.81 10.68 10.78C10.68 10.76 10.68 10.74 10.69 10.73V10.71C10.69 10.71 10.69 10.69 10.7 10.68V10.66C10.7 10.66 10.71 10.64 10.72 10.63V10.61C10.72 10.61 10.74 10.59 10.74 10.58C10.75 10.58 10.75 10.57 10.75 10.57L10.78 10.53L12.64 8.67995C12.78 8.53995 13 8.53995 13.13 8.67995C13.18 8.72995 13.21 8.78995 13.22 8.85995V10.43H15.37C15.56 10.43 15.71 10.59 15.71 10.78Z" />
                <path d="M15.45 5.2L15.43 5.12L15.4 5.04L15.37 5L15.32 4.94L11.25 0.87L11.2 0.83L11.11 0.79L11.06 0.77H11.01L10.98 0.75H6.74C5.9 0.83 5.23 1.54 5.23 2.4V11.78C5.31 12.62 6.02 13.29 6.88 13.29H9.81C9.59 12.99 9.42 12.65 9.29 12.29H6.79C6.47 12.23 6.23 11.96 6.23 11.63V2.31C6.28 1.99 6.56 1.75 6.88 1.75H10.42V5.38C10.47 5.61 10.68 5.79 10.92 5.79H14.46V6.51C14.81 6.59 15.15 6.72 15.46 6.89V5.2H15.45ZM11.41 4.79V2.46L13.74 4.79H11.41Z" />
            </g>
            <defs>
                <clipPath id="clip0_502_2-502140">
                    <rect width="16" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default FileBackIcon;
