import * as React from "react";
import { useEffect } from "react";
import { DefaultButton, Modal, IconButton, IIconProps, Checkbox, Label, MessageBarType } from "office-ui-fabric-react";
import TranslatedDatePickerComponent from "../../../shared/translatedDatePicker/TranslatedDatePickerComponent";
import ContactDropdownComponent from "../../../shared/field/contactDropdown/ContactDropdownComponent";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { MULTI_DROPDOWN_ONE_ITEM } from "../../../shared/field/fieldConstants";
import { FormValidationHelpers } from "../../../../helpers/FormValidationHelpers";
import "./EmailFilterComponent.less";
import useFiltersModal from "../../../../hooks/useFiltersModal";
import { useToast } from "../../../shared/toast/useToast";
import { useProgressBar } from "../../../shared/progressBar/useProgressBar";

interface FiltersModalProps extends LocalizeContextProps, React.FunctionComponent {
    isModalOpen: boolean;
    hideModal: () => void;
}

const EmailFiltersModal = withLocalize((props: FiltersModalProps): React.ReactElement => {
    const { isModalOpen, hideModal, translate } = props;

    const {
        states: { formState, loadingUsers, errorLoadingUsers, receivedDatesMinMax, projectUsers },
        actions: { applyFilters, clearFilters, updateFormState, closeModal },
    } = useFiltersModal({ hideModal: hideModal });

    const { showToast } = useToast();
    const { showProgress } = useProgressBar();

    useEffect(() => {
        if (!errorLoadingUsers) {
            return;
        }

        showToast({ message: translate("SHARED.LOAD_PROJECT_ERROR_MESSAGE") as string }, MessageBarType.error);
    }, [errorLoadingUsers]);

    useEffect(() => {
        if (loadingUsers) {
            showProgress(translate("SHARED.LOAD_PROJECT_MESSAGE") as string);
        } else {
            showProgress(null);
        }
    }, [loadingUsers]);

    return (
        <Modal
            isOpen={isModalOpen}
            onDismiss={closeModal}
            isBlocking={true}
            scrollableContentClassName="filters-modal-scrollable"
            containerClassName={"filters-modal"}
        >
            <header>
                <div className="formSpacing title-container">
                    <span className="title" data-testid="newforma-project-email-filter-modal-title">
                        {translate("PROJECT_EMAIL.FILTERS.MODAL.LABEL") as string}
                    </span>
                    <IconButton
                        iconProps={{ iconName: "Cancel" } as IIconProps}
                        ariaLabel="Close popup modal"
                        onClick={closeModal}
                        data-testid="newforma-project-email-filter-modal-close-button"
                        data-is-modal-open={isModalOpen}
                    />
                </div>
            </header>

            <section>
                <div data-testid="newforma-project-email-filter-modal-sender">
                    <ContactDropdownComponent
                        className="formSpacing"
                        label={translate("SHARED.FROM_AND_TO.FROM_LABEL") as string}
                        placeholder={translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                        formValidationHelpers={{
                            validateEmailInput: FormValidationHelpers.validateEmailInput,
                        }}
                        required={false}
                        items={projectUsers}
                        itemLimit={MULTI_DROPDOWN_ONE_ITEM}
                        disabled={!projectUsers.length}
                        selectedContacts={formState.selectedFromUsers}
                        onChange={(selectedItem) => {
                            updateFormState({ selectedFromUsers: selectedItem });
                        }}
                        allowCustomInput
                    />
                </div>
                <div data-testid="newforma-project-email-filter-modal-receivers">
                    <ContactDropdownComponent
                        className="formSpacing"
                        label={translate("SHARED.FROM_AND_TO.TO_LABEL") as string}
                        placeholder={translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                        formValidationHelpers={{
                            validateEmailInput: FormValidationHelpers.validateEmailInput,
                        }}
                        required={false}
                        items={projectUsers}
                        disabled={!projectUsers.length}
                        selectedContacts={formState.selectedToUsers}
                        onChange={(selectedItem) => {
                            updateFormState({ selectedToUsers: selectedItem });
                        }}
                    />
                </div>

                <div className="formSpacing">
                    <Label>{translate("SHARED.ATTRIBUTES.RECEIVED_DATE_LABEL") as string}</Label>

                    <div className="received-date">
                        <div
                            className="flex-child"
                            data-testid="newforma-project-email-filter-modal-received-date-from"
                        >
                            <TranslatedDatePickerComponent
                                {...(formState.receivedDateFrom && {
                                    date: formState.receivedDateFrom,
                                })}
                                onDateChange={(date) => updateFormState({ receivedDateFrom: date ?? null })}
                                className=""
                                required={false}
                                placeholder={translate("PROJECT_EMAIL.FILTERS.MODAL.DATE.FROM_LABEL") as string}
                                showClearDateButton={formState.receivedDateFrom !== null}
                                maxDate={receivedDatesMinMax.from.max}
                            />
                        </div>

                        <div className="flex-child" data-testid="newforma-project-email-filter-modal-received-date-to">
                            <TranslatedDatePickerComponent
                                {...(formState.receivedDateTo && {
                                    date: formState.receivedDateTo,
                                })}
                                onDateChange={(date) => {
                                    if (!date) {
                                        updateFormState({ receivedDateTo: null });
                                        return;
                                    }

                                    const endOfDay = new Date(date);
                                    endOfDay.setHours(23, 59, 59, 999);
                                    updateFormState({ receivedDateTo: endOfDay });
                                }}
                                className=""
                                required={false}
                                placeholder={translate("PROJECT_EMAIL.FILTERS.MODAL.DATE.TO_LABEL") as string}
                                showClearDateButton={formState.receivedDateTo !== null}
                                minDate={receivedDatesMinMax.to.min}
                                maxDate={receivedDatesMinMax.to.max}
                            />
                        </div>
                    </div>
                </div>

                <div data-testid="newforma-project-email-filters-has-attachment">
                    <Checkbox
                        className="formSpacing attachments"
                        label={translate("PROJECT_EMAIL.FILTERS.MODAL.HAS_ATTACHMENTS_LABEL") as string}
                        checked={formState.hasAttachments}
                        onChange={(_, checked) => {
                            updateFormState({ hasAttachments: checked });
                        }}
                    />
                </div>
            </section>

            <footer className="buttons">
                <DefaultButton
                    text={translate("PROJECT_EMAIL.FILTERS.MODAL.CLEAR_ALL_LABEL") as string}
                    className={"button button-unselected"}
                    onClick={clearFilters}
                    data-testid="newforma-project-email-filter-modal-clear-button"
                    allowDisabledFocus
                />
                <DefaultButton
                    text={translate("PROJECT_EMAIL.FILTERS.MODAL.APPLY_FILTERS_LABEL") as string}
                    className={"button button-selected"}
                    data-testid="newforma-project-email-filter-modal-apply-button"
                    onClick={applyFilters}
                    allowDisabledFocus
                />
            </footer>
        </Modal>
    );
});

export default EmailFiltersModal;
