import { LocalStorageKeys, SessionStorageKeys } from "../models/StorageKeys";
import { Logger } from "./Logger";

/* istanbul ignore file */
export class StorageWrapper {
    constructor(private logger: Logger) {}

    saveSessionStorage(key: SessionStorageKeys, value: string): void {
        try {
            sessionStorage.setItem(key, value);
        } catch {
            this.logger.error(`Failed to save sessionStorage. key: ${key}, value: ${value}`);
        }
    }

    loadSessionStorage(key: SessionStorageKeys): string | null {
        try {
            return sessionStorage.getItem(key);
        } catch {
            this.logger.error(`Failed to retrieve sessionStorage. key: ${key}`);
            return null;
        }
    }

    saveLocalStorage(key: LocalStorageKeys, value: string): void {
        try {
            localStorage.setItem(key, value);
        } catch {
            this.logger.error(`Failed to save localStorage. key: ${key}, value: ${value}`);
        }
    }

    loadLocalStorage(key: LocalStorageKeys): string | null {
        try {
            return localStorage.getItem(key);
        } catch {
            this.logger.error(`Failed to retrieve localStorage. key: ${key}`);
            return null;
        }
    }

    removeLocalStorageItem(key: LocalStorageKeys): void {
        try {
            localStorage.removeItem(key);
        } catch {
            this.logger.error(`Failed to remove localStorage key: ${key}`);
        }
    }
}
