export class ConflictError extends Error {
    private static Status = 409;

    constructor() {
        super("Conflict error");
        this.name = "ConflictError";

        Object.setPrototypeOf(this, ConflictError);
    }

    static isInstanceOf(value: any): value is ConflictError {
        return value.status === ConflictError.Status;
    }
}
