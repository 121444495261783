import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Label } from "office-ui-fabric-react";
import * as React from "react";
import "./EmptyPageResultComponent.less";

export interface EmptyPageResultComponentProps extends LocalizeContextProps {
    isProjectSelected: boolean;
}

function EmptyPageResultComponent(props: EmptyPageResultComponentProps) {
    const noProjectSelectedSVGPath = "assets/no-filed-emails-colored.svg";
    const defaultEmptyResultPath = "assets/no-filed-emails.svg";

    return (
        <div className="projectEmail-emptyPageResult" data-testid="projectEmail-emptyPageResult">
            <img
                src={!props.isProjectSelected ? noProjectSelectedSVGPath : defaultEmptyResultPath}
                alt="Newforma Konekt no emails"
            />
            <div className="projectEmail-emptyPageResult-content">
                <div>
                    <Label className="projectEmail-emptyPageResult-title">
                        {!props.isProjectSelected
                            ? props.translate("PROJECT_EMAIL.NO_PROJECT_SELECTED")
                            : props.translate("PROJECT_EMAIL.NO_EMAILS_FILED")}
                        {}
                    </Label>
                </div>
                <div>
                    <Label className="projectEmail-emptyPageResult-message">
                        {props.translate("PROJECT_EMAIL.WILL_SHOW_UP") as string}
                    </Label>
                </div>
            </div>
        </div>
    );
}
export default withLocalize(EmptyPageResultComponent);
