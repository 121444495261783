export const ValueListType = {
    SpecSections: "specsections",
    Keywords: "keywords",
    Disciplines: "disciplines",
    Reasons: "reasons",
    Types: "types",
    Actions: "actions",
    ImpactSchedules: "impactschedules",
    ImpactCosts: "impactcosts",
    Status: "status",
    Priorities: "priorities",
    Zones: "zones",
    Phases: "phases",
    Teams: "teams",
} as const;

export type ValueListType = (typeof ValueListType)[keyof typeof ValueListType];

export const ActionListType = {
    Receive: "receive",
    ReviewResponse: "reviewresponse",
} as const;

export type ActionListType = (typeof ActionListType)[keyof typeof ActionListType];

export const ProjectItemFeature = {
    RFIs: "rfis",
    Submittals: "submittals",
    Issues: "issues",
    Emails: "emails",
} as const;

export type ProjectItemFeature = (typeof ProjectItemFeature)[keyof typeof ProjectItemFeature];

export interface PagingResponse {
    totalCount: number;
}

export type WithNextPage<T extends { paging: PagingResponse }> = T & {
    paging: {
        nextPage: number | undefined;
    };
};
