/* istanbul ignore file */

import { DefaultButton, FontIcon, Icon } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { ProjectItemFeature } from "../../../services/PimTrackApi/types";
import "./ItemSuccessComponent.less";
import RfiErrorIcon from "../icons/blankSlates/RfiErrorIcon";
import RfiSuccessIcon from "../icons/blankSlates/RfiSuccessIcon";
import SubmittalErrorIcon from "../icons/blankSlates/SubmittalErrorIcon";
import SubmittalSuccessIcon from "../icons/blankSlates/SubmittalSuccessIcon";
import IssueErrorIcon from "../icons/blankSlates/IssueErrorIcon";
import IssueSuccessIcon from "../icons/blankSlates/IssueSuccessIcon";

export interface SuccessInfo {
    redirectUrl: string;
    projectItemFeature: ProjectItemFeature;
    message: string;
    steps?: {
        isSuccess: boolean;
        message: string;
    }[];
    navigateBackToForm?: () => void;
    displayNewButton?: boolean;
}

export interface ItemSuccessComponentProps extends LocalizeContextProps {
    successInfo: SuccessInfo;
}

function ItemSuccessComponent(props: ItemSuccessComponentProps) {
    const isAllSuccess = props.successInfo.steps?.every((x) => x.isSuccess);
    let SuccessIcon;
    let ErrorIcon;
    let createNewItem;

    switch (props.successInfo.projectItemFeature) {
        case ProjectItemFeature.RFIs:
            SuccessIcon = <RfiSuccessIcon />;
            ErrorIcon = <RfiErrorIcon />;
            createNewItem = props.translate("RFI.SUBMIT_BUTTON.LOG") as string;
            break;
        case ProjectItemFeature.Submittals:
            SuccessIcon = <SubmittalSuccessIcon />;
            ErrorIcon = <SubmittalErrorIcon />;
            createNewItem = props.translate("SUBMITTALS.SUBMIT_BUTTON.LOG") as string;
            break;
        case ProjectItemFeature.Issues:
            SuccessIcon = <IssueSuccessIcon />;
            ErrorIcon = <IssueErrorIcon />;
            createNewItem = props.translate("ISSUE.SUBMIT_LOG_BUTTON") as string;
            break;
    }

    return (
        <div className="itemSuccessComponentContainer" data-testid="itemSuccessComponentContainer">
            <div className="itemIcon">{isAllSuccess ? SuccessIcon : ErrorIcon}</div>
            <div className="itemMessage" data-testid={"itemMessage"}>
                {props.successInfo.message}
            </div>

            {!isAllSuccess && (
                <div className="itemErrorContainer" data-testid={"itemErrorContainer"}>
                    {props.successInfo.steps?.map((x) => (
                        <div className="errorBody" key={x.message} data-testid={"errorBody"}>
                            <Icon iconName={x.isSuccess ? "CheckCircle" : "CloseCircle"} />
                            <div dangerouslySetInnerHTML={{ __html: x.message }} data-testid={"errorBodyMessage"} />
                        </div>
                    ))}
                </div>
            )}

            <a href={props.successInfo.redirectUrl} className="redirectLink" target="_blank" data-testid="redirectLink">
                <div>{props.translate("SHARED.OPEN_IN_BIMTRACK")}</div>
                <FontIcon className="redirectLinkIcon" iconName="OpenExternal" />
            </a>

            {props.successInfo.displayNewButton && (
                <div id="footer" key="footer" className="newItem">
                    <DefaultButton
                        className="hub-selector-dashboard-button-signout"
                        data-testid="newforma-footerButton"
                        onClick={props.successInfo.navigateBackToForm}
                        text={createNewItem}
                    />
                </div>
            )}
        </div>
    );
}

export default withLocalize(ItemSuccessComponent);
