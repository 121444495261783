import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { MessageBarType } from "office-ui-fabric-react";
import ProgressComponent from "../shared/progress/ProgressComponent";
import "./ProjectEmailComponent.less";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useAppState } from "../../store/AppProvider";
import ProjectEmailEmptyPageResult from "./EmptyPageResultComponent/EmptyPageResultComponent";
import ProjectEmailListComponent from "./ProjectEmailListComponent";
import { useProjectEmail } from "../../hooks/useProjectEmail";
import ProjectEmailSearchComponent from "./ProjectEmailSearch/ProjectEmailSearchComponent";
import { useProjectEmailSearch } from "../../hooks/useProjectEmailSearch";
import { useToast } from "../shared/toast/useToast";

export interface ProjectEmail {
    from: {
        name?: string;
        email: string;
    };
    subject: string;
    body: string;
    date: string;
    messageId: string;
    hasAttachment: boolean;
}

function ProjectEmailComponent(props: LocalizeContextProps) {
    const { appState } = useAppState();
    const { ref, inView } = useInView();
    const {
        states: { query },
    } = useProjectEmailSearch();

    const { data, fetchNextPage, isError, isFetching, isFetchingNextPage, hasNextPage } = useProjectEmail();
    const hasEmptyPageResult = data !== undefined && data.pages.length === 1 && data.pages[0].hits.length === 0;
    const { showToast } = useToast();

    useEffect(() => {
        if (inView && hasNextPage && !isFetching && appState.selectedProject && appState.selectedHub) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetching, appState.selectedProject, appState.selectedHub, query]);

    useEffect(() => {
        if (isError) {
            showToast(
                { message: props.translate("FILE_MULTIPLE_EMAIL.ERROR_LOADING_EMAILS") as string },
                MessageBarType.error
            );
        }
    }, [isError]);

    if (!appState.selectedHub) {
        return <></>;
    }

    if (!appState.selectedProject) {
        return <ProjectEmailEmptyPageResult isProjectSelected={false} />;
    }

    return (
        <>
            <ProjectEmailSearchComponent />
            <div className="newforma-projectEmailComponent">
                {data && (
                    <ProjectEmailListComponent data={data} displayEmptyPageResult={hasEmptyPageResult && !isFetching} />
                )}
                {(isFetchingNextPage || isFetching) && (
                    <ProgressComponent message={props.translate("FILE_MULTIPLE_EMAIL.LOADING_EMAILS") as string} />
                )}

                <div ref={ref} data-testid="ProjectEmailInViewRef" />
                {inView && !hasNextPage && !isFetching && !hasEmptyPageResult && (
                    <div data-testid="ProjectEmailEndMessageDiv" className="newforma-endInfiniteScrollingMessage">
                        {props.translate("FILE_MULTIPLE_EMAIL.END_OF_LIST") as string}
                    </div>
                )}
            </div>
        </>
    );
}

export default withLocalize(ProjectEmailComponent);
