import * as React from "react";

function CloseCircleIcon() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill={"inherited"}
            xmlns="http://www.w3.org/2000/svg"
            data-testid="closeCircleIcon"
        >
            <g clipPath="url(#clip0_507_39-733800)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5ZM11.5104 5.5L12.5711 6.56066L10.0962 9.03553L12.5711 11.5104L11.5104 12.5711L9.03553 10.0962L6.56066 12.5711L5.5 11.5104L7.97487 9.03553L5.5 6.56066L6.56066 5.5L9.03553 7.97487L11.5104 5.5Z"
                    fill="#DF1616"
                />
            </g>
            <defs>
                <clipPath id="clip0_507_39-733800">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CloseCircleIcon;
