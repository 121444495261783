export default class FiledProject {
    nrn: string; // id of last project filed to
    d: Date; // Date of last filing operation
    t: string[] = []; // Thread id
    s: string; // Sender email address
    c: number; // count (filing)
    constructor(id: string, conversationId: string, sender: string) {
        this.nrn = id;
        this.t.push(conversationId);
        this.s = sender;
        this.d = new Date();
        this.c = 0;
    }
}
