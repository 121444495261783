import { SearchBox, Async } from "office-ui-fabric-react";
import * as React from "react";
import { useState, useEffect } from "react";

interface DebouncedSearchComponentProps {
    onTextChange: (text: string) => void;
    onClear?: () => void;
    placeholder: string;
    debounceTime?: number;
    dataTestId?: string;
    className?: string;
}

export const DEBOUNCE_TIME = 750;

function DebouncedSearchComponent({
    onTextChange,
    placeholder,
    dataTestId,
    debounceTime = DEBOUNCE_TIME,
    onClear,
    className,
}: DebouncedSearchComponentProps) {
    const [searchTerm, setSearchTerm] = useState("");
    const [lastValidSearchTerm, setLastValidSearchTerm] = useState("");
    const async = React.useRef(new Async());

    useEffect(() => () => async.current.dispose(), []);

    const debouncedSearch = React.useCallback(
        async.current.debounce((newValue) => {
            if (isValidSearchTerm(newValue)) {
                onTextChange(newValue);
                setLastValidSearchTerm(newValue.trim());
            }
        }, debounceTime),
        []
    );

    const onChange = (newValue: string) => {
        if (isValidSearchTerm(newValue)) {
            setSearchTerm(newValue);
            debouncedSearch(newValue);
            setLastValidSearchTerm(newValue);
        }
    };

    const isValidSearchTerm = (newValue: string) => {
        const trimmedNewValue = newValue.trim();
        const trimmedLastValid = lastValidSearchTerm.trim();

        if (trimmedNewValue.length > 0 && trimmedNewValue !== trimmedLastValid) {
            return true;
        }
        return false;
    };

    const onSearch = () => {
        if (isValidSearchTerm(searchTerm)) {
            onTextChange(searchTerm);
            setLastValidSearchTerm(searchTerm);
        }
    };

    const onSearchClear = () => {
        if (onClear) {
            onClear();
        } else {
            onTextChange("");
        }
        setSearchTerm("");
        setLastValidSearchTerm("");
    };

    return (
        <SearchBox
            data-testid={dataTestId}
            placeholder={placeholder}
            onChange={(e) => onChange(e?.target.value || "")}
            onSearch={() => {
                onSearch();
            }}
            value={searchTerm}
            onClear={() => {
                onSearchClear();
            }}
            className={className}
        />
    );
}

export default DebouncedSearchComponent;
