import { DefaultButton, Dropdown, ITag, MessageBarType, ResponsiveMode } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { DateHelpers } from "../../helpers/DateHelpers";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import useLogSubmittal from "../../hooks/useLogSubmittal";
import { IHub } from "../../models/Hub";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { FileUploadApiService } from "../../services/PimTrackApi/FileUploadApiService";
import { SubmittalApiService } from "../../services/PimTrackApi/SubmittalApiService";
import { ValueListApiService } from "../../services/PimTrackApi/ValueListApiService";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import ChipPickerComponent from "../shared/field/chipPicker/ChipPickerComponent";
import ContactDropdownComponent from "../shared/field/contactDropdown/ContactDropdownComponent";
import {
    DEFAULT_TEXT_FIELD_ROWS,
    LONG_FIELD_LENGTH,
    MULTI_DROPDOWN_ONE_ITEM,
    SHORT_FIELD_LENGTH,
} from "../shared/field/fieldConstants";
import TextFieldComponent from "../shared/field/inputText/TextFieldComponent";
import SpecSectionComponent from "../shared/field/specSection/SpecSectionComponent";
import LabelComponent from "../shared/label/LabelComponent";
import TranslatedDatePickerComponent from "../shared/translatedDatePicker/TranslatedDatePickerComponent";
import "./SubmittalComponent.less";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { RelatedItemApiService } from "../../services/PimTrackApi/RelatedItemApiService";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { SupportedAddinItems } from "../../models/SupportedItems";
import { ToastMessage } from "../../models/ToastMessage";
import { BimProjectsApiService } from "../../services/BimApi/BimProjectsApiService";

export interface SubmittalComponentProps extends LocalizeContextProps {
    formValidationHelpers: FormValidationHelpers;
    officeWrapper: OfficeWrapper;
    dateHelpers: DateHelpers;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    selectedHub: IHub | null;
    selectedProject: ITag | null;
    valueListApiServices: ValueListApiService;
    showProgress: (message: string | null) => void;
    submittalApiService: SubmittalApiService;
    fileUploadApiService: FileUploadApiService;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    bimProjectsApiService: BimProjectsApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
    navigateBackToForm?: () => void;
}

function SubmittalComponent(props: SubmittalComponentProps) {
    const {
        actions: {
            handleRevisionFieldDisplay,
            handleRevisionButtonDisplay,
            handleNumberClick,
            handleNumberBlur,
            submitLogSubmittal,
        },
        logSubmittalForm,
        updateField,
        disciplineLabel,
        disciplinePlaceholderLabel,
        specSectionOptions,
        purposeOptions,
        userOptions,
        disciplineOptions,
        keywordOptions,
        isRevisionFieldDisplayed,
        isRevisionButtonDisplayed,
        revisionButtonText,
        isLogSubmittalFormValid,
        autoNumberPrefix,
        isFieldDisabled,
        subMenuOptions,
    } = useLogSubmittal({
        officeWrapper: props.officeWrapper,
        dateHelpers: props.dateHelpers,
        valueListApiServices: props.valueListApiServices,
        selectedHub: props.selectedHub,
        selectedProject: props.selectedProject,
        translate: props.translate,
        onShowToast: props.onShowToast,
        showProgress: props.showProgress,
        submittalApiService: props.submittalApiService,
        fileUploadApiService: props.fileUploadApiService,
        emailApiService: props.emailApiService,
        relatedItemApiService: props.relatedItemApiService,
        bimProjectsApiService: props.bimProjectsApiService,
        officeRoamingSettings: props.officeRoamingSettings,
        supportedAddinItems: props.supportedAddinItems,
        navigateBackToForm: props.navigateBackToForm,
    });

    return (
        <div className="submittalComponent" data-testid="submittalComponent">
            <div className="submittalComponentForm">
                <TextFieldComponent
                    className="newforma-formSpacing submittalSubject"
                    label={props.translate("SHARED.ATTRIBUTES.SUBJECT.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.SUBJECT.PLACEHOLDER") as string}
                    required
                    value={logSubmittalForm.subject}
                    onChange={(newValue) => {
                        updateField({ subject: newValue });
                    }}
                    maxLength={SHORT_FIELD_LENGTH}
                    disabled={isFieldDisabled}
                />

                <SpecSectionComponent
                    className="newforma-formSpacing submittalSpecSection"
                    required
                    disabled={isFieldDisabled || !specSectionOptions.length}
                    formValidationHelpers={props.formValidationHelpers}
                    selectedItem={logSubmittalForm.specSection}
                    items={specSectionOptions}
                    onChange={(newValue) => {
                        updateField({ specSection: newValue });
                    }}
                />

                <div
                    className={`${isRevisionFieldDisplayed ? "flexRow" : ""} ${
                        isRevisionButtonDisplayed ? "" : "newforma-formSpacing"
                    }`}
                >
                    <TextFieldComponent
                        className={`submittalNumber ${isRevisionFieldDisplayed ? "rowSpacing" : ""}`}
                        label={props.translate("SUBMITTALS.SPEC_SECTION.NUMBER_LABEL") as string}
                        placeholder={props.translate("SUBMITTALS.SPEC_SECTION.AUTO_NUMBER") as string}
                        required={false}
                        value={logSubmittalForm.number}
                        onChange={(newValue) => {
                            updateField({ number: newValue });
                            if (!newValue || newValue.trim() === autoNumberPrefix.trim()) {
                                handleRevisionFieldDisplay(false);
                                handleRevisionButtonDisplay(false);
                            } else {
                                handleRevisionButtonDisplay(true);
                            }
                        }}
                        onClick={handleNumberClick}
                        onBlur={handleNumberBlur}
                        disabled={isFieldDisabled}
                    />

                    <TextFieldComponent
                        className={`rowSpacing submittalRevisionNumber ${isRevisionFieldDisplayed ? "" : "hidden"}`}
                        label={props.translate("SUBMITTALS.SPEC_SECTION.REVISION") as string}
                        placeholder={props.translate("SUBMITTALS.SPEC_SECTION.AUTO_NUMBER") as string}
                        required={false}
                        value={logSubmittalForm.revisionNumber}
                        onChange={(newValue) => {
                            updateField({ revisionNumber: newValue });
                        }}
                        disabled={isFieldDisabled}
                    />
                </div>

                <LabelComponent
                    className={`submittal-noBottomPadding fieldButton ${isRevisionButtonDisplayed ? "" : "hidden"}`}
                    onClick={() => {
                        handleRevisionFieldDisplay(!isRevisionFieldDisplayed);
                    }}
                    text={revisionButtonText}
                />

                <TranslatedDatePickerComponent
                    date={logSubmittalForm.receivedDate}
                    label={props.translate("SHARED.ATTRIBUTES.RECEIVED_DATE_LABEL") as string}
                    onDateChange={(date) => {
                        updateField({ receivedDate: date });
                    }}
                    className="newforma-formSpacing submittalDatePickers submittalReceivedDate"
                    isDisabled={isFieldDisabled}
                    required
                    showClearDateButton={false}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                />

                <TranslatedDatePickerComponent
                    date={logSubmittalForm.dueDate}
                    label={props.translate("SHARED.ATTRIBUTES.DUE_DATE.LABEL") as string}
                    onDateChange={(date) => {
                        updateField({ dueDate: date });
                    }}
                    className="newforma-formSpacing submittalDatePickers submittalDueDate"
                    isDisabled={isFieldDisabled}
                    required
                    showClearDateButton={false}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                />

                <Dropdown
                    className="submittalPurposeDropdown newforma-formSpacing"
                    options={purposeOptions}
                    placeholder={props.translate("SHARED.ATTRIBUTES.TYPE.PLACEHOLDER") as string}
                    label={props.translate("SHARED.ATTRIBUTES.PURPOSE_PICKER_LABEL") as string}
                    responsiveMode={ResponsiveMode.large}
                    disabled={isFieldDisabled || !purposeOptions.length}
                    onChange={(_, option) => {
                        updateField({ purpose: option });
                    }}
                    selectedKey={logSubmittalForm.purpose?.key}
                    required={false}
                />
                <TextFieldComponent
                    className="submittalSenderNumber newforma-formSpacing"
                    label={props.translate("SHARED.ATTRIBUTES.SENDER_NUMBER.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.SENDER_NUMBER.PLACEHOLDER") as string}
                    required={false}
                    value={logSubmittalForm.senderNumber}
                    onChange={(newValue) => {
                        updateField({ senderNumber: newValue });
                    }}
                    maxLength={SHORT_FIELD_LENGTH}
                    disabled={isFieldDisabled}
                />

                <ContactDropdownComponent
                    className="submittalFromField newforma-formSpacing"
                    label={props.translate("SHARED.FROM_AND_TO.FROM_LABEL") as string}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                    formValidationHelpers={props.formValidationHelpers}
                    required
                    items={userOptions}
                    itemLimit={MULTI_DROPDOWN_ONE_ITEM}
                    disabled={isFieldDisabled || !userOptions.length}
                    selectedContacts={logSubmittalForm.from}
                    onChange={(selectedItem) => {
                        updateField({ from: selectedItem });
                    }}
                    allowCustomInput
                />

                <ContactDropdownComponent
                    className="submittalToField newforma-formSpacing"
                    label={props.translate("SHARED.FROM_AND_TO.TO_LABEL") as string}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                    formValidationHelpers={props.formValidationHelpers}
                    required
                    items={userOptions}
                    disabled={isFieldDisabled || !userOptions.length}
                    selectedContacts={logSubmittalForm.to}
                    onChange={(selectedItem) => {
                        updateField({ to: selectedItem });
                    }}
                />

                <TextFieldComponent
                    className="newforma-formSpacing submittalDescription"
                    label={props.translate("SHARED.ATTRIBUTES.DESCRIPTION.LABEL") as string}
                    placeholder={props.translate("SHARED.ATTRIBUTES.DESCRIPTION.PLACEHOLDER") as string}
                    multiline
                    resizable
                    value={logSubmittalForm.description}
                    onChange={(newValue) => {
                        updateField({ description: newValue });
                    }}
                    maxLength={LONG_FIELD_LENGTH}
                    rows={DEFAULT_TEXT_FIELD_ROWS}
                    required={false}
                    disabled={isFieldDisabled}
                />

                <ChipPickerComponent
                    className="newforma-formSpacing submittalDisciplines"
                    required={false}
                    disabled={isFieldDisabled || !disciplineOptions.length}
                    items={disciplineOptions}
                    onChange={(selectedItems) => {
                        updateField({ disciplines: selectedItems });
                    }}
                    label={disciplineLabel}
                    inputPlaceholder={disciplinePlaceholderLabel}
                    allowCustomInput={false}
                    selectedItems={logSubmittalForm.disciplines}
                />

                <ChipPickerComponent
                    className="newforma-formSpacing submittalKeywords"
                    required={false}
                    disabled={isFieldDisabled || !keywordOptions.length}
                    items={keywordOptions}
                    onChange={(selectedItems) => {
                        updateField({ keywords: selectedItems });
                    }}
                    label={props.translate("SHARED.ATTRIBUTES.KEYWORDS.LABEL") as string}
                    inputPlaceholder={props.translate("SHARED.ATTRIBUTES.KEYWORDS.PLACEHOLDER") as string}
                    allowCustomInput
                    selectedItems={logSubmittalForm.keywords}
                />

                <AttachmentsComponent
                    attachments={logSubmittalForm.attachments}
                    disabled={isFieldDisabled}
                    onAttachmentSelectionChange={(attachmentsIds) => {
                        updateField({ selectedAttachmentIds: attachmentsIds });
                    }}
                    allowFileUploads={false}
                />
            </div>

            <div id="footer" key="footer" className="newforma-footer">
                <DefaultButton
                    key="logSubmittalSubmitButton"
                    className="newforma-footerButton"
                    id="logSubmittalButton"
                    primary
                    split
                    menuProps={subMenuOptions}
                    onClick={() => submitLogSubmittal({ logNewItem: false })}
                    text={props.translate("SUBMITTALS.SUBMIT_BUTTON.LOG") as string}
                    disabled={isFieldDisabled || !isLogSubmittalFormValid}
                />
            </div>
        </div>
    );
}

export default withLocalize(SubmittalComponent);
