import * as React from "react";

function CheckCircleIcon() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill={"inherited"}
            xmlns="http://www.w3.org/2000/svg"
            data-testid="checkCircleIcon"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 0.25C10.3947 0.25 11.7442 0.577177 12.9613 1.19613C13.3305 1.38389 13.4776 1.83541 13.2899 2.20462C13.1021 2.57384 12.6506 2.72094 12.2814 2.53318C11.2737 2.02074 10.157 1.75 9 1.75C4.99594 1.75 1.75 4.99594 1.75 9C1.75 13.0041 4.99594 16.25 9 16.25C13.0041 16.25 16.25 13.0041 16.25 9C16.25 8.62608 16.2218 8.25598 16.1659 7.89175C16.1032 7.48232 16.3842 7.09954 16.7937 7.03679C17.2031 6.97404 17.5859 7.25509 17.6486 7.66452C17.716 8.10388 17.75 8.54994 17.75 9C17.75 13.8325 13.8325 17.75 9 17.75C4.16751 17.75 0.25 13.8325 0.25 9C0.25 4.16751 4.16751 0.25 9 0.25ZM17.5303 2.46967C17.7966 2.73594 17.8208 3.1526 17.6029 3.44621L17.5303 3.53033L8.814 12.2467L5.46967 8.90232C5.17678 8.60943 5.17678 8.13456 5.46967 7.84166C5.73594 7.5754 6.1526 7.55119 6.44621 7.76905L6.53033 7.84166L8.814 10.125L16.4697 2.46967C16.7626 2.17678 17.2374 2.17678 17.5303 2.46967Z"
                    fill="#20C443"
                />
            </g>
        </svg>
    );
}

export default CheckCircleIcon;
