import { DefaultButton, ITag, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";
import { useState } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import useRfiReviewResponse from "../../hooks/useRfiReviewResponse";
import { IHub } from "../../models/Hub";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { FileUploadApiService } from "../../services/PimTrackApi/FileUploadApiService";
import { RelatedItemApiService } from "../../services/PimTrackApi/RelatedItemApiService";
import { RfiApiService } from "../../services/PimTrackApi/RfiApiService";
import { ValueListApiService } from "../../services/PimTrackApi/ValueListApiService";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import ContactDropdownComponent from "../shared/field/contactDropdown/ContactDropdownComponent";
import DropdownComponent from "../shared/field/dropdown/DropdownComponent";
import { DEFAULT_TEXT_FIELD_ROWS, LONG_FIELD_LENGTH, MULTI_DROPDOWN_ONE_ITEM } from "../shared/field/fieldConstants";
import TextFieldComponent from "../shared/field/inputText/TextFieldComponent";
import ProjectItemPickerComponent from "../shared/field/projectItemPicker/ProjectItemPickerComponent";
import "./Common.less";
import "./RfiReviewResponseComponent.less";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { SupportedAddinItems } from "../../models/SupportedItems";
import { ToastMessage } from "../../models/ToastMessage";

export interface RfiReviewResponseComponentProps extends LocalizeContextProps {
    officeWrapper: OfficeWrapper;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    selectedHub: IHub | null;
    selectedProject: ITag | null;
    showProgress: (message: string | null) => void;
    rfiApiService: RfiApiService;
    formValidationHelpers: FormValidationHelpers;
    valueListApiServices: ValueListApiService;
    fileUploadApiService: FileUploadApiService;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
}

function RfiReviewResponseComponent(props: RfiReviewResponseComponentProps) {
    const [isCcOpen, setIsCcOpen] = useState<boolean>(false);

    const {
        rfiReviewResponseForm,
        isFieldDisabled,
        userOptions,
        actionOptions,
        onForwardRfiInputChange,
        isRfiReviewResponseFormValid,
        submitReviewResponse,
        updateField,
    } = useRfiReviewResponse({
        officeWrapper: props.officeWrapper,
        valueListApiServices: props.valueListApiServices,
        selectedHub: props.selectedHub,
        selectedProject: props.selectedProject,
        translate: props.translate,
        onShowToast: props.onShowToast,
        showProgress: props.showProgress,
        rfiApiService: props.rfiApiService,
        fileUploadApiService: props.fileUploadApiService,
        emailApiService: props.emailApiService,
        relatedItemApiService: props.relatedItemApiService,
        setIsCcOpen,
        officeRoamingSettings: props.officeRoamingSettings,
        supportedAddinItems: props.supportedAddinItems,
    });

    return (
        <div className={"rfiReviewResponseComponent"} data-testid="rfiReviewResponseComponent">
            <div className={"rfiReviewResponseForm"}>
                <ProjectItemPickerComponent
                    className="newforma-formSpacing rfiForwardedRfiField"
                    required
                    disabled={isFieldDisabled}
                    onChange={(selectedItems) => {
                        updateField({ rfiId: selectedItems ? selectedItems[0] : null });
                    }}
                    onResolveSuggestions={(filter, _) => onForwardRfiInputChange(filter)}
                    onEmptyInputFocus={() => onForwardRfiInputChange()}
                    label={props.translate("RFI.RFI_DROPDOWN.LABEL") as string}
                    inputPlaceholder={props.translate("RFI.RFI_DROPDOWN.PLACEHOLDER") as string}
                    selectedItems={rfiReviewResponseForm.rfiId}
                />

                <DropdownComponent
                    className="newforma-formSpacing rfiActionField"
                    options={actionOptions}
                    placeholder={props.translate("RFI.ACTION.PLACEHOLDER") as string}
                    label={props.translate("RFI.ACTION.LABEL") as string}
                    disabled={isFieldDisabled || !actionOptions.length}
                    onChange={(option) => {
                        updateField({ action: option });
                    }}
                    selectedOption={rfiReviewResponseForm.action}
                    required
                />

                <ContactDropdownComponent
                    className="rfiFromField newforma-formSpacing"
                    label={props.translate("SHARED.FROM_AND_TO.FROM_LABEL") as string}
                    placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                    formValidationHelpers={props.formValidationHelpers}
                    required
                    items={userOptions}
                    itemLimit={MULTI_DROPDOWN_ONE_ITEM}
                    disabled={isFieldDisabled || !userOptions.length}
                    selectedContacts={rfiReviewResponseForm.from}
                    onChange={(selectedItem) => {
                        updateField({ from: selectedItem });
                    }}
                />

                <div className="rfiToFiedRow">
                    <ContactDropdownComponent
                        className="rfiToField newforma-formSpacing"
                        label={props.translate("SHARED.FROM_AND_TO.TO_LABEL") as string}
                        placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                        formValidationHelpers={props.formValidationHelpers}
                        required
                        items={userOptions}
                        disabled={isFieldDisabled || !userOptions.length}
                        selectedContacts={rfiReviewResponseForm.to}
                        onChange={(selectedItem) => {
                            updateField({ to: selectedItem });
                        }}
                    />
                    {!isCcOpen && (
                        <div
                            className="ccButton"
                            data-testid="ccButton"
                            onClick={() => {
                                setIsCcOpen(!isCcOpen);
                            }}
                        >
                            {props.translate("SHARED.FROM_AND_TO.ADD_CC") as string}
                        </div>
                    )}
                </div>

                {isCcOpen && (
                    <ContactDropdownComponent
                        className="rfiCcField newforma-formSpacing"
                        label={props.translate("SHARED.FROM_AND_TO.CC_LABEL") as string}
                        placeholder={props.translate("SHARED.DROPDOWN.PLACEHOLDER") as string}
                        formValidationHelpers={props.formValidationHelpers}
                        required={false}
                        items={userOptions}
                        disabled={isFieldDisabled || !userOptions.length}
                        selectedContacts={rfiReviewResponseForm.cc}
                        onChange={(selectedItem) => {
                            updateField({ cc: selectedItem });
                        }}
                    />
                )}

                <TextFieldComponent
                    className="newforma-formSpacing rfiResponseField"
                    label={props.translate("RFI.RESPONSE.LABEL") as string}
                    placeholder={props.translate("RFI.RESPONSE.PLACEHOLDER") as string}
                    multiline
                    resizable
                    value={rfiReviewResponseForm.response}
                    onChange={(newValue) => {
                        updateField({ response: newValue });
                    }}
                    maxLength={LONG_FIELD_LENGTH}
                    rows={DEFAULT_TEXT_FIELD_ROWS}
                    required={false}
                    disabled={isFieldDisabled}
                />

                <AttachmentsComponent
                    attachments={rfiReviewResponseForm.attachments}
                    disabled={isFieldDisabled}
                    onAttachmentSelectionChange={(attachmentIds) => {
                        updateField({ selectedAttachmentIds: attachmentIds });
                    }}
                    allowFileUploads={false}
                />
            </div>

            <div id="footer" key="footer" className="newforma-footer">
                <DefaultButton
                    key="rfiReviewResponseSubmitButton"
                    className="newforma-footerButton"
                    id="rfiReviewResponseButton"
                    primary={true}
                    onClick={submitReviewResponse}
                    text={props.translate("RFI.SUBMIT_BUTTON.RESPONSE") as string}
                    disabled={isFieldDisabled || !isRfiReviewResponseFormValid}
                />
            </div>
        </div>
    );
}

export default withLocalize(RfiReviewResponseComponent);
