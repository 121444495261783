import { Common, Submittal } from "@newforma/platform-client-api-sdk";
import { IDropdownOption, IPersonaProps, ITag, MessageBarType } from "office-ui-fabric-react";
import React, { useEffect, useMemo, useState } from "react";
import { TranslateFunction } from "react-localize-redux";
import { SuccessInfo } from "../components/shared/itemSuccess/ItemSuccessComponent";
import { ContactMapping } from "../mapping/ContactMapping";
import { ProjectItemMapping } from "../mapping/ProjectItemMapping";
import { RelatedItemMapping } from "../mapping/RelatedItemMapping";
import { SubmittalReviewResponseMapping } from "../mapping/SubmittalReviewResponseMapping";
import { ValueListMapping } from "../mapping/ValueListMapping";
import { IHub } from "../models/Hub";
import { EmailApiService } from "../services/NewformaApi/EmailApiService";
import { OfficeWrapper } from "../services/OfficeWrapper";
import { FileUploadApiService } from "../services/PimTrackApi/FileUploadApiService";
import { RelatedItemApiService } from "../services/PimTrackApi/RelatedItemApiService";
import { SubmittalApiService } from "../services/PimTrackApi/SubmittalApiService";
import { ActionListType, ProjectItemFeature, ValueListType } from "../services/PimTrackApi/types";
import { ValueListApiService } from "../services/PimTrackApi/ValueListApiService";
import {
    defaultClearedSubmittalReviewerResponseFieldForm,
    SubmittalReviewResponseFieldForm,
    ValidSubmittalReviewResponseFieldForm,
} from "../models/workflow/submittal/SubmittalReviewResponse";
import { SupportedAddinItems } from "../models/SupportedItems";
import { OfficeRoamingSettings } from "../services/OfficeRoamingSettings";
import { useWorkflowItem } from "../components/shared/itemSuccess/ItemContext";
import { ToastMessage } from "../models/ToastMessage";
import { useAppService } from "../services/AppServiceProvider";
import { pluralizeSentence } from "../utils/helpers";

export interface UseSubmittalReviewResponseProps {
    officeWrapper: OfficeWrapper;
    valueListApiServices: ValueListApiService;
    selectedHub: IHub | null;
    selectedProject: ITag | null;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    showProgress: (message: string | null) => void;
    submittalApiService: SubmittalApiService;
    translate: TranslateFunction;
    fileUploadApiService: FileUploadApiService;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    setIsCcOpen: React.Dispatch<React.SetStateAction<boolean>>;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
}

const useSubmittalReviewResponse = (props: UseSubmittalReviewResponseProps) => {
    const [submittalReviewerResponseForm, setSubmittalReviewerResponseForm] =
        useState<SubmittalReviewResponseFieldForm>(defaultClearedSubmittalReviewerResponseFieldForm);
    const [isValueListLoading, setIsValueListLoading] = useState<boolean>(true);
    const [responseOptions, setResponseOptions] = useState<IDropdownOption[]>([]);
    const [userOptions, setUserOptions] = useState<IPersonaProps[]>([]);
    const [isFilling, setIsFilling] = useState<boolean>(false);
    const isProjectSelected = !!props.selectedHub && !!props.selectedProject;
    const [attachmentSessionIds, setAttachmentSessionIds] = useState<string[]>([]);
    const [isLoadingProjectFail, setIsLoadingProjectFail] = useState<boolean>(false);
    const {
        actions: { setSuccessInfo, setIsEmailFromDeletion },
    } = useWorkflowItem();
    const {
        services: { msGraphApiService },
    } = useAppService();

    useEffect(() => {
        if (!isFilling) {
            if (isProjectSelected) {
                setIsValueListLoading(true);
                props.showProgress(props.translate("SHARED.LOAD_PROJECT_MESSAGE") as string);
                initialize()
                    .catch(() => {
                        setIsLoadingProjectFail(true);
                        props.onShowToast(
                            { message: props.translate("SHARED.LOAD_PROJECT_ERROR_MESSAGE") as string },
                            MessageBarType.error
                        );
                    })
                    .finally(() => {
                        props.showProgress(null);
                        setIsValueListLoading(false);
                    });
            } else {
                clearForm();
            }
        }
    }, [isProjectSelected, props.officeWrapper.currentContextItem.itemId]);

    const initialize = async () => {
        const [reviewResponseOption, bimtrackContacts] = await getValuesList();

        const mapperUser = bimtrackContacts
            .map(ContactMapping.mapResponseToIPersonaProps)
            .sort((contact1, contact2) => (contact1.text || "").localeCompare(contact2.text || ""));

        setResponseOptions(reviewResponseOption.items.map(ValueListMapping.mapResponseToIDropdownOption));
        setUserOptions(mapperUser);

        const attachmentsNoCloud = props.officeWrapper
            .getCurrentEmailAttachmentDetails()
            .filter((attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud);
        const attachmentIds = attachmentsNoCloud.map((attachment) => attachment.id);
        const remarks = await props.officeWrapper.getCurrentEmailBody();
        const fromEmailFromOfficeWrapper = props.officeWrapper.getSenderEmailAddress()?.toLowerCase();
        const toFromOfficeWrapper = props.officeWrapper
            .getCurrentEmailToRecipients()
            .map((recipient) => recipient.emailAddress.toLowerCase());
        const ccFromOfficeWrapper = props.officeWrapper
            .getCurrentEmailCcRecipients()
            .map((recipient) => recipient.emailAddress.toLowerCase());
        const foundFromUser =
            fromEmailFromOfficeWrapper &&
            mapperUser.find(
                (personaProp) =>
                    personaProp.text === fromEmailFromOfficeWrapper ||
                    personaProp.secondaryText === fromEmailFromOfficeWrapper
            );
        const from = foundFromUser ? [foundFromUser] : [];
        const to = mapperUser.filter((personaProp) =>
            toFromOfficeWrapper.some((email) => email === personaProp.text || email === personaProp.secondaryText)
        );
        const cc = mapperUser.filter((personaProp) =>
            ccFromOfficeWrapper.some((email) => email === personaProp.text || email === personaProp.secondaryText)
        );

        props.setIsCcOpen(!!cc.length);

        setSubmittalReviewerResponseForm(() => ({
            ...defaultClearedSubmittalReviewerResponseFieldForm,
            remarks,
            from,
            to,
            cc,
            selectedAttachmentIds: attachmentIds,
            attachments: attachmentsNoCloud,
        }));
    };

    const getValuesList = async () =>
        props.selectedHub && props.selectedProject
            ? Promise.all([
                  props.valueListApiServices.getPimTrackValueList(
                      ProjectItemFeature.Submittals,
                      props.selectedHub,
                      props.selectedProject,
                      ValueListType.Actions,
                      ActionListType.ReviewResponse
                  ),
                  props.valueListApiServices.getBimTrackUser(props.selectedHub, props.selectedProject),
              ])
            : Promise.resolve([]);

    const clearForm = () => {
        setSubmittalReviewerResponseForm(defaultClearedSubmittalReviewerResponseFieldForm);
    };

    const onForwardSubmittalInputChange = async (input?: string): Promise<ITag[]> => {
        if (props.selectedHub && props.selectedProject) {
            const searchRequest: Submittal.SearchRequest = {
                query: input || undefined,
                filter: {
                    statuses: [{ id: Common.ProjectItemStatus.Forwarded }],
                },
                highlight: {
                    openDelimiter: "",
                    closeDelimiter: "",
                },
            };

            try {
                const response = await props.submittalApiService.searchSubmittal(
                    props.selectedHub,
                    props.selectedProject,
                    searchRequest
                );

                return response.items.map(ProjectItemMapping.mapToITag);
            } catch (e) {
                props.onShowToast(
                    { message: props.translate("SUBMITTALS.ERRORS.FAIL_FETCH_SUBMITTAL_LIST") as string },
                    MessageBarType.error
                );
            }

            return [];
        }

        return [];
    };

    const submitSubmittalReviewResponse = async () => {
        let isSubmittalSubmitReviewResponseError = false;
        let isFillingEmailError = false;

        const steps: SuccessInfo["steps"] = [];
        const internetMessageId = props.officeWrapper.currentContextItem.internetMessageId;
        const currentMessageId = props.officeWrapper.getCurrentMessageId();

        if (
            !validateSubmittalReviewResponseFieldForm(submittalReviewerResponseForm) ||
            !(props.selectedHub && props.selectedProject)
        ) {
            return;
        }

        const submittalId = submittalReviewerResponseForm.submittalId.key.toString();

        if (submittalReviewerResponseForm.selectedAttachmentIds.length > FileUploadApiService.maxAllowedFile) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.TOO_MANY_FILES") as string },
                MessageBarType.error
            );
            return;
        }

        const selectedFiles = submittalReviewerResponseForm.attachments.filter((x) =>
            submittalReviewerResponseForm.selectedAttachmentIds.includes(x.id)
        );

        if (new Set(selectedFiles.map(({ name }) => name.toLowerCase())).size < selectedFiles.length) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.DUPLICATE_NAME") as string },
                MessageBarType.error
            );
            return;
        }

        setIsFilling(true);

        const hasSelectedAttachment = !!selectedFiles.length;
        const totalSteps = hasSelectedAttachment ? 4 : 3;
        let stepCounter = 0;

        try {
            if (hasSelectedAttachment) {
                const progressMessageTemplate = props.translate("SHARED.PROGRESS.FILE_UPLOADING", {
                    totalSteps: totalSteps,
                    step: ++stepCounter,
                }) as string;

                const progressMessage = pluralizeSentence(
                    progressMessageTemplate,
                    "ATTACHMENT",
                    selectedFiles.length,
                    props.translate
                );

                props.showProgress(progressMessage);
            }

            const attachmentSessionIdList: string[] = attachmentSessionIds.length
                ? attachmentSessionIds
                : await props.fileUploadApiService.uploadFile(
                      props.selectedHub,
                      props.selectedProject,
                      selectedFiles,
                      ProjectItemFeature.Submittals
                  );

            setAttachmentSessionIds(attachmentSessionIdList);

            props.showProgress(
                props.translate("SHARED.PROGRESS.FILING_REVIEW_RESPONSE", {
                    totalSteps: totalSteps,
                    step: ++stepCounter,
                }) as string
            );

            const { version } = await props.submittalApiService.getSubmittalDetail(
                props.selectedHub,
                props.selectedProject,
                submittalId
            );

            await props.submittalApiService.submitReviewResponse(
                props.selectedHub,
                props.selectedProject,
                submittalId,
                SubmittalReviewResponseMapping.toRequest(
                    submittalReviewerResponseForm,
                    attachmentSessionIdList,
                    version
                )
            );

            steps.push({
                isSuccess: true,
                message: props.translate("SUBMITTALS.SUCCESS.SUBMITTAL_REVIEW_RESPONSE_PARTIAL") as string,
            });
        } catch (e) {
            if ((e as any).status === 409) {
                props.onShowToast(
                    { message: props.translate("SUBMITTALS.ERRORS.CONFLICT") as string },
                    MessageBarType.error
                );
                setIsFilling(false);
            } else if ((e as any).status === 403) {
                props.onShowToast(
                    { message: props.translate("SHARED.ERRORS.NOT_AUTHORIZED") as string },
                    MessageBarType.error
                );
                setIsFilling(false);
            } else if (
                (e as any).status === 400 &&
                (e as any).responseText.includes(Common.CommonErrorCode.INVALID_EMAIL)
            ) {
                props.onShowToast(
                    { message: props.translate("SHARED.ERRORS.INVALID_EMAIL") as string },
                    MessageBarType.error
                );
                setIsFilling(false);
            } else {
                setAttachmentSessionIds([]);
                props.onShowToast(
                    {
                        message: props.translate(
                            "SUBMITTALS.ERRORS.SUBMITTAL_REVIEW_RESPONSE_FAILED_GENERIC"
                        ) as string,
                    },
                    MessageBarType.error
                );
            }
            isSubmittalSubmitReviewResponseError = true;
            setIsFilling(false);
            props.showProgress(null);
        }

        if (!isSubmittalSubmitReviewResponseError) {
            props.showProgress(
                props.translate("SHARED.PROGRESS.FILING_EMAIL", {
                    totalSteps: totalSteps,
                    step: ++stepCounter,
                }) as string
            );

            try {
                await props.emailApiService.fileEmail(currentMessageId, props.selectedProject, props.selectedHub);
            } catch (e) {
                steps.push(
                    { isSuccess: false, message: props.translate("SUBMITTALS.ERRORS.EMAIL_FILING") as string },
                    {
                        isSuccess: false,
                        message: props.translate("SUBMITTALS.ERRORS.SUBMITTAL_LINKING_TO_EMAIL") as string,
                    }
                );
                isFillingEmailError = true;
            }

            if (!isFillingEmailError) {
                steps.push({ isSuccess: true, message: props.translate("SUBMITTALS.SUCCESS.FILE_EMAIL") as string });

                // Wait 2 sec before calling link submittal to email
                // This is to prevent calling the linking right after filling, sometimes it can get 404 if calling right after filling email
                await new Promise((r) => setTimeout(r, 2000));

                try {
                    props.showProgress(
                        props.translate("SUBMITTALS.PROGRESS.LINKING_SUBMITTAL_TO_EMAIL", {
                            totalSteps: totalSteps,
                            step: ++stepCounter,
                        }) as string
                    );
                    await props.relatedItemApiService.linkItems(
                        props.selectedHub,
                        props.selectedProject,
                        {
                            id: submittalId,
                            type: Common.ProjectItemType.Submittal,
                        },
                        RelatedItemMapping.mapToRelatedItemRequest([
                            {
                                id: internetMessageId,
                                type: Common.ProjectItemType.Email,
                            },
                        ])
                    );
                } catch (e) {
                    steps.push({
                        isSuccess: false,
                        message: props.translate("SUBMITTALS.ERRORS.SUBMITTAL_LINKING_TO_EMAIL") as string,
                    });
                }

                try {
                    if (
                        props.supportedAddinItems?.canDeleteEmail &&
                        props.officeRoamingSettings.getDeleteEmailAfterFiling()
                    ) {
                        await msGraphApiService.moveMessage(currentMessageId);
                        setIsEmailFromDeletion(true);
                    }
                } catch (e) {
                    console.error("Error while moving email to bin");
                }
            }

            setIsFilling(false);
            props.showProgress(null);

            setSuccessInfo({
                message: steps.every(({ isSuccess }) => isSuccess)
                    ? (props.translate("SUBMITTALS.SUCCESS.SUBMITTAL_REVIEW_RESPONSE") as string)
                    : (props.translate("SUBMITTALS.ERRORS.SUBMIT_REVIEW_RESPONSE_PARTIAL_FAILED") as string),
                redirectUrl: props.submittalApiService.getSubmittalUrl(
                    submittalId,
                    props.selectedHub,
                    props.selectedProject
                ),
                projectItemFeature: ProjectItemFeature.Submittals,
                steps,
            });
        }
    };

    const validateSubmittalReviewResponseFieldForm = (
        form: SubmittalReviewResponseFieldForm
    ): form is ValidSubmittalReviewResponseFieldForm => isSubmittalReviewResponseFormValid;

    const isSubmittalReviewResponseFormValid = useMemo(
        (): boolean =>
            !(
                !submittalReviewerResponseForm.submittalId ||
                !submittalReviewerResponseForm.response ||
                submittalReviewerResponseForm.to.length === 0 ||
                submittalReviewerResponseForm.from.length === 0
            ),
        [submittalReviewerResponseForm]
    );

    const updateField = (updatedField: Partial<SubmittalReviewResponseFieldForm>) => {
        setSubmittalReviewerResponseForm((prevState) => ({ ...prevState, ...updatedField }));
    };

    return {
        submittalReviewerResponseForm,
        updateField,
        userOptions,
        responseOptions,
        onForwardSubmittalInputChange,
        isSubmittalReviewResponseFormValid,
        submitSubmittalReviewResponse,
        isFieldDisabled: isFilling || !isProjectSelected || isValueListLoading || isLoadingProjectFail,
    };
};

export default useSubmittalReviewResponse;
