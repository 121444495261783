import { SuccessInfo } from "./ItemSuccessComponent";
import * as React from "react";
import { createContext, useContext, useMemo, useState } from "react";

export interface WorkflowItemContextType {
    states: {
        successInfo?: SuccessInfo;
        isEmailFromDeletion: boolean;
    };
    actions: {
        setSuccessInfo: React.Dispatch<React.SetStateAction<SuccessInfo | undefined>>;
        setIsEmailFromDeletion: React.Dispatch<React.SetStateAction<boolean>>;
    };
}

export const ItemContext = createContext<WorkflowItemContextType>({
    states: {
        successInfo: undefined,
        isEmailFromDeletion: false,
    },
    actions: {
        setSuccessInfo: () => [],
        setIsEmailFromDeletion: () => [],
    },
});

export interface WorkflowItemProps {
    children: React.ReactNode;
    defaultValue?: WorkflowItemContextType;
}

export const WorkflowItemProvider = ({ children, defaultValue }: WorkflowItemProps) => {
    const [successInfo, setSuccessInfo] = useState<SuccessInfo | undefined>(undefined);
    const [isEmailFromDeletion, setIsEmailFromDeletion] = useState<boolean>(false);

    const workflowItemValue = useMemo(
        () => ({
            states: { successInfo, isEmailFromDeletion },
            actions: { setSuccessInfo, setIsEmailFromDeletion },
            ...defaultValue,
        }),
        [successInfo, isEmailFromDeletion, setSuccessInfo, setIsEmailFromDeletion]
    );

    return <ItemContext.Provider value={workflowItemValue}>{children}</ItemContext.Provider>;
};

export function useWorkflowItem(): WorkflowItemContextType {
    return useContext(ItemContext);
}
