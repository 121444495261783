import { createHashHistory } from "history";
import { ITag } from "office-ui-fabric-react";
import * as React from "react";
import { createContext, useContext, useState, useMemo, useEffect } from "react";
import { AppPage } from "../models/AppPage";
import { IHub } from "../models/Hub";
import { UserInfo } from "../models/PimModels";
import { SupportedAddinItems, SupportedFeatureFlags } from "../models/SupportedItems";
import { MailboxItem, OfficeWrapper } from "../services/OfficeWrapper";
import { LocalStorageKeys, SessionStorageKeys } from "../models/StorageKeys";
import { Authenticator } from "../services/Authenticator";
import { OfficeRoamingSettings } from "../services/OfficeRoamingSettings";
import { StorageWrapper } from "../services/StorageWrapper";
import { TranslationService } from "../services/TranslationService";
import { ROUTE_PATH_NAMES } from "../utils/constants";
import GainSightWindow, { Aptrinsic } from "../services/gainSight/gainSightWindow";
import { Logger } from "../services/Logger";

export interface AppProviderState {
    appState: {
        isLoggedIn: boolean;
        deleteEmailAfterFilingSetting: boolean;
        fileEmailConversationSetting: boolean;
        mailboxItem: MailboxItem | null;
        entryPointUrl: string;
        navigationPage: AppPage;
        hubs?: IHub[];
        selectedHub: IHub | null;
        addinUserInfo: UserInfo | null;
        locationPath: string;
        selectedProject: ITag | null;
        supportedFeatureFlags: SupportedFeatureFlags | null;
        supportedAddinItems: SupportedAddinItems | null;
    };
    actions: {
        setDeleteEmailAfterFilingSetting: React.Dispatch<React.SetStateAction<boolean>>;
        setFileEmailConversationSetting: React.Dispatch<React.SetStateAction<boolean>>;
        setMailboxItem: React.Dispatch<React.SetStateAction<MailboxItem | null>>;
        setEntryPointUrl: React.Dispatch<React.SetStateAction<string>>;
        setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
        setSelectedHub: React.Dispatch<React.SetStateAction<IHub | null>>;
        setNavigationPage: React.Dispatch<React.SetStateAction<AppPage>>;
        setHubs: React.Dispatch<React.SetStateAction<IHub[] | undefined>>;
        setAddinUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
        setSupportedFeatureFlags: React.Dispatch<React.SetStateAction<SupportedFeatureFlags | null>>;
        setSupportedAddinItems: React.Dispatch<React.SetStateAction<SupportedAddinItems | null>>;
        handleSelectedProject: (newSelectedProject: ITag | null) => ITag | null;
        handleLocationPath: (location: string) => void;
    };
}

const DEFAULT_VALUE: AppProviderState = {
    appState: {
        isLoggedIn: false,
        deleteEmailAfterFilingSetting: false,
        fileEmailConversationSetting: false,
        mailboxItem: null,
        entryPointUrl: "",
        navigationPage: AppPage.FileEmail,
        hubs: undefined,
        selectedHub: null,
        addinUserInfo: null,
        locationPath: "",
        selectedProject: null,
        supportedFeatureFlags: null,
        supportedAddinItems: null,
    },

    actions: {
        setDeleteEmailAfterFilingSetting: () => {},
        setFileEmailConversationSetting: () => {},
        setMailboxItem: () => {},
        setEntryPointUrl: () => {},
        setIsLoggedIn: () => {},
        setSelectedHub: () => {},
        setNavigationPage: () => {},
        setHubs: () => {},
        setAddinUserInfo: () => {},
        setSupportedFeatureFlags: () => {},
        setSupportedAddinItems: () => {},
        handleSelectedProject: (_newSelectedProject: ITag | null) => null,
        handleLocationPath: (_location: string) => {},
    },
};

const AppContext = createContext<AppProviderState>(DEFAULT_VALUE);

interface AppProviderProps {
    children: React.ReactNode;
    authenticator: Authenticator;
    officeRoamingSettings: OfficeRoamingSettings;
    officeWrapper: OfficeWrapper;
    storageWrapper: StorageWrapper;
    translationService: TranslationService;
    logger: Logger;
}

export const AppProvider = (props: AppProviderProps) => {
    const history = createHashHistory();

    const [deleteEmailAfterFilingSetting, setDeleteEmailAfterFilingSetting] = useState<boolean>(
        props.officeRoamingSettings.getDeleteEmailAfterFiling()
    );
    const [fileEmailConversationSetting, setFileEmailConversationSetting] = useState<boolean>(
        props.officeRoamingSettings.getFileEmailConversation()
    );
    const [mailboxItem, setMailboxItem] = useState<MailboxItem | null>(props.officeWrapper.currentContextItem);
    const [entryPointUrl, setEntryPointUrl] = useState<string>(
        props.storageWrapper.loadSessionStorage(SessionStorageKeys.urlLocation) || ""
    );
    const [locationPath, setLocationPath] = useState<string>(history.location.pathname.toLowerCase());
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(props.authenticator.isUserLoggedIn());
    const [selectedHub, setSelectedHub] = useState<IHub | null>(null);
    const [navigationPage, setNavigationPage] = useState<AppPage>(AppPage.FileEmail);
    const [hubs, setHubs] = useState<IHub[] | undefined>(undefined);
    const [selectedProject, setSelectedProject] = useState<ITag | null>(null);
    const [addinUserInfo, setAddinUserInfo] = useState<UserInfo | null>(null);
    const [supportedFeatureFlags, setSupportedFeatureFlags] = useState<SupportedFeatureFlags | null>(null);
    const [supportedAddinItems, setSupportedAddinItems] = useState<SupportedAddinItems | null>(null);

    // Handles
    const handleSelectedProject = (newSelectedProject: ITag | null): ITag | null => {
        if (!newSelectedProject) {
            setSelectedProject(null);
            return null;
        }
        setSelectedProject(newSelectedProject);
        return newSelectedProject;
    };

    const handleLocationPath = (location: string): void => {
        if (!location) {
            setLocationPath(ROUTE_PATH_NAMES.ROOT);
        }

        setLocationPath(location);
    };

    const getCachedHub = (): IHub =>
        JSON.parse(props.storageWrapper.loadLocalStorage(LocalStorageKeys.hub) as string) as IHub;

    useEffect(() => {
        setSelectedHub(getCachedHub());
    }, []);

    // On selected hub update
    useEffect(() => {
        if (!selectedHub) {
            setSupportedAddinItems(null);
            props.storageWrapper.removeLocalStorageItem(LocalStorageKeys.hub);
            props.storageWrapper.removeLocalStorageItem(LocalStorageKeys.supportedFeatureFlags);
            props.storageWrapper.removeLocalStorageItem(LocalStorageKeys.hubSubscriptionInfo);
            handleSelectedProject(null);
            return;
        }

        props.storageWrapper.saveLocalStorage(LocalStorageKeys.hub, JSON.stringify(selectedHub));
        reinitializeGainSight();
    }, [selectedHub]);

    const reinitializeGainSight = () => {
        const storedUserHub = props.storageWrapper.loadLocalStorage(LocalStorageKeys.hub) as string;

        const parsedAddinUserHub: IHub = JSON.parse(storedUserHub);

        const addinHubIdentity = {
            id: parsedAddinUserHub.key,
            hubId: parsedAddinUserHub.key,
            name: parsedAddinUserHub.name,
            hubDataCenter: parsedAddinUserHub.regionSummary?.Location,
        };

        (GainSightWindow.aptrinsic as Aptrinsic)("set", "globalContext", addinHubIdentity);
        props.logger.info("Re-initialized GainSight globalContext");
    };

    const appProviderValue: AppProviderState = useMemo(
        () => ({
            appState: {
                deleteEmailAfterFilingSetting,
                fileEmailConversationSetting,
                mailboxItem,
                entryPointUrl,
                locationPath,
                isLoggedIn,
                selectedHub,
                navigationPage,
                hubs,
                selectedProject,
                addinUserInfo,
                supportedFeatureFlags,
                supportedAddinItems,
            },
            actions: {
                setDeleteEmailAfterFilingSetting,
                setFileEmailConversationSetting,
                setMailboxItem,
                setEntryPointUrl,
                setIsLoggedIn,
                setSelectedHub,
                setNavigationPage,
                setHubs,
                setAddinUserInfo,
                setSupportedFeatureFlags,
                setSupportedAddinItems,
                handleSelectedProject,
                handleLocationPath,
            },
        }),
        [
            deleteEmailAfterFilingSetting,
            fileEmailConversationSetting,
            mailboxItem,
            entryPointUrl,
            locationPath,
            isLoggedIn,
            selectedHub,
            navigationPage,
            hubs,
            selectedProject,
            addinUserInfo,
            supportedFeatureFlags,
            supportedAddinItems,
            setDeleteEmailAfterFilingSetting,
            setFileEmailConversationSetting,
            setMailboxItem,
            setEntryPointUrl,
            setLocationPath,
            setIsLoggedIn,
            setNavigationPage,
            setHubs,
            setAddinUserInfo,
            setSupportedFeatureFlags,
            setSupportedAddinItems,
            handleSelectedProject,
        ]
    );

    return <AppContext.Provider value={appProviderValue}>{props.children}</AppContext.Provider>;
};

export function useAppState(): AppProviderState {
    const contextData = useContext(AppContext);
    return useMemo(() => ({ ...contextData }), [contextData]);
}
