import { Logger } from "../Logger";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { ConfigurationService } from "../ConfigurationService";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { PimTrackRegion, SubscriptionApi } from "../../models/PimModels";
import { IHub } from "../../models/Hub";
import { StorageWrapper } from "../StorageWrapper";
import { LocalStorageKeys } from "../../models/StorageKeys";
import { NotSupportedValueError } from "../../components/common/errors/NotSupportedValueError";
import { addMinutesToCurrentTime } from "../../utils/helpers";
import { EXPIRY_TIME_FOR_CACHE, SubscriptionFunctionalities } from "../../utils/constants";
import { SupportedAddinItems, SupportedFeatureFlags } from "../../models/SupportedItems";

export interface HubSubscriptionInfo {
    id: string;
    hubHashId: string;
    startDate: string;
    endDate: string;
    limitUsers: number;
    limitProjects: number;
    limitStorage: number;
    createdBy: string;
    creationDate: string;
    modifiedBy: string;
    modificationDate: string;
    packageId: string;
    isTrial: boolean;
    modules: HubModules[];
    functionalities: HubFunctionalities[];
    isExpired: boolean;
}

export interface HubModules {
    id: string;
    name: string;
}
export interface StoredHubSubscriptionInfo {
    hubSubscriptionInfo: HubSubscriptionInfo;
    expiryTime: number;
}

export interface HubFunctionalities {
    id: string;
}
export class SubscriptionApiService {
    constructor(
        private logger: Logger,
        private configService: ConfigurationService,
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private storageWrapper: StorageWrapper
    ) {}

    async fetchSubscriptionByHubId(domain: string, hub: IHub): Promise<HubSubscriptionInfo | null> {
        try {
            this.logger.info(`Retrieving Hub Subscription info for hub: ${hub.name}`);
            const url = `${domain}/v1/subscriptions/${hub.key}`;
            const options = { url, method: "GET" };

            return this.newformaApiClient.makeRequest(options, async (signedOptions) =>
                this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
            );
        } catch (error) {
            this.logger.error(JSON.stringify(error));
            return null;
        }
    }

    async getHubSubscriptionInfo(hub: IHub): Promise<HubSubscriptionInfo | null> {
        let storedHubSubscriptionInfo = this.getStoredHubSubscriptionInfo();

        if (!storedHubSubscriptionInfo) {
            this.logger.info("no storedHubSubscriptionInfo");
            const domain = this.mapSubscriptionApiDomainToHubRegion(hub);
            const fetchedHubSubscriptionInfo = await this.fetchSubscriptionByHubId(domain, hub);

            if (!fetchedHubSubscriptionInfo) {
                return null;
            }

            this.storeHubSubscriptionInfo(fetchedHubSubscriptionInfo);

            storedHubSubscriptionInfo = fetchedHubSubscriptionInfo;
        }

        return storedHubSubscriptionInfo;
    }

    getStoredHubSubscriptionInfo(): HubSubscriptionInfo | null {
        const storedHubSubscriptionInfoStringified = this.storageWrapper.loadLocalStorage(
            LocalStorageKeys.hubSubscriptionInfo
        );

        if (!storedHubSubscriptionInfoStringified) {
            return null;
        }

        const { hubSubscriptionInfo, expiryTime }: StoredHubSubscriptionInfo = JSON.parse(
            storedHubSubscriptionInfoStringified
        );

        if (Date.now() > expiryTime) {
            this.logger.error("-- hubSubscriptionInfo expired --");
            this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.hubSubscriptionInfo);
            return null;
        }

        return hubSubscriptionInfo;
    }

    storeHubSubscriptionInfo(hubSubscriptionInfo: HubSubscriptionInfo): void {
        this.logger.info(`Storing Hub Subscription info for hub: ${hubSubscriptionInfo.hubHashId}`);
        const expiryTime = addMinutesToCurrentTime(EXPIRY_TIME_FOR_CACHE);
        const cachedValue: StoredHubSubscriptionInfo = { hubSubscriptionInfo, expiryTime };
        this.storageWrapper.saveLocalStorage(LocalStorageKeys.hubSubscriptionInfo, JSON.stringify(cachedValue));
    }

    getSubscriptionServiceUrlDomainByRegion(region: PimTrackRegion): string {
        const subscriptionApiInfo = this.configService.subscriptionServiceUrl.find(
            (subscriptionApi: SubscriptionApi) => subscriptionApi.region === region
        );

        return subscriptionApiInfo?.url ?? "";
    }

    getSupportedAddinItems = async (
        supportedFeatureFlags: SupportedFeatureFlags,
        selectedHub: IHub
    ): Promise<SupportedAddinItems> => {
        const isEmailActive = await this.isServiceSubscribed(
            SubscriptionFunctionalities.email.emailManagement,
            selectedHub
        );

        const isRFIActive = await this.isServiceSubscribed(SubscriptionFunctionalities.rfi.workflow, selectedHub);

        const isDeleteEmailActive =
            !(await this.isServiceSubscribed(SubscriptionFunctionalities.email.noDeleteEmail, selectedHub)) &&
            (await this.isServiceSubscribed(SubscriptionFunctionalities.email.emailManagement, selectedHub));
        const isIssueActive = await this.isServiceSubscribed(
            SubscriptionFunctionalities.email.emailManagement,
            selectedHub
        );
        const isSubscriptionExpired = await this.isSubscriptionExpired(selectedHub);

        return {
            canUseEmail: isEmailActive,
            canUseWorkflow: isRFIActive,
            canDeleteEmail: isDeleteEmailActive,
            canUseIssues: isIssueActive,
            isSubscriptionExpired,
        };
    };

    isServiceSubscribed = async (functionalityId: string, selectedHub: IHub): Promise<boolean> => {
        try {
            const hubSubscriptionInfo = await this.getHubSubscriptionInfo(selectedHub);

            if (!hubSubscriptionInfo) {
                this.logger.info("No subscription for this hub");
                return false;
            }

            const { functionalities: subscribedFunctionalities } = hubSubscriptionInfo;
            const subscribedFunctionalitiesIds = subscribedFunctionalities.map((functionality) =>
                functionality.id.toLocaleLowerCase()
            );
            return subscribedFunctionalitiesIds.includes(functionalityId.toLocaleLowerCase());
        } catch (error) {
            this.logger.error(`isServiceSubscribed: ${JSON.stringify(error)}`);
            return false;
        }
    };

    async isSubscriptionExpired(selectedHub: IHub): Promise<boolean> {
        try {
            const hubSubscriptionInfo = await this.getHubSubscriptionInfo(selectedHub);

            return !!hubSubscriptionInfo?.isExpired;
        } catch (error) {
            this.logger.error(`Error while checking subscription status: ${error}`);
            return true;
        }
    }

    mapSubscriptionApiDomainToHubRegion(hub: IHub): string {
        // Production...
        if (this.configService.bimAuthURL.includes("bimtrackapp.co")) {
            switch (hub.regionSummary?.Location) {
                case "USEast":
                    return this.getSubscriptionServiceUrlDomainByRegion("us-east-1");
                case "CanadaEast":
                case "CanadaEastLocal":
                    return this.getSubscriptionServiceUrlDomainByRegion("ca-central-1");
                case "NorthEurope":
                case "Europe":
                    return this.getSubscriptionServiceUrlDomainByRegion("eu-central-1");
                default:
                    this.logger.error("NotSupportedValueError:");
                    throw new NotSupportedValueError();
            }

            // Dev/Stage
        } else {
            switch (hub.regionSummary?.Location) {
                case "USEast":
                case "CanadaEast":
                case "CanadaEastLocal":
                    return this.getSubscriptionServiceUrlDomainByRegion("us-east-1");
                case "NorthEurope":
                case "Europe":
                    return this.getSubscriptionServiceUrlDomainByRegion("eu-central-1");
                default:
                    throw new NotSupportedValueError();
            }
        }
    }
}
