import { IDropdownOption, IPersonaProps, ITag } from "office-ui-fabric-react";
import { FileWithId } from "../../shared/FileWithId";
import AttachmentDetails = Office.AttachmentDetails;

export interface LogSubmittalFieldForm {
    subject: string;
    specSection: ITag | null;
    number?: string;
    revisionNumber?: string;
    receivedDate?: Date;
    dueDate?: Date;
    purpose?: IDropdownOption;
    senderNumber: string;
    from: IPersonaProps[];
    to: IPersonaProps[];
    description: string;
    disciplines: ITag[];
    keywords: ITag[];
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
    isRevisionFieldDisplayed: boolean;
    isRevisionButtonDisplayed: boolean;
}

export interface ValidLogSubmittalFieldForm {
    subject: string;
    specSection: ITag;
    number?: string;
    revisionNumber?: string;
    receivedDate?: Date;
    dueDate?: Date;
    purpose: IDropdownOption;
    senderNumber: string;
    from: IPersonaProps[];
    to: IPersonaProps[];
    description: string;
    disciplines: ITag[];
    keywords: ITag[];
    attachments: (AttachmentDetails | FileWithId)[];
    selectedAttachmentIds: string[];
    isRevisionFieldDisplayed: boolean;
    isRevisionButtonDisplayed: boolean;
}

export const defaultClearedLogSubmittalFieldForm: LogSubmittalFieldForm = {
    subject: "",
    specSection: null,
    number: "",
    revisionNumber: "",
    receivedDate: undefined,
    dueDate: undefined,
    purpose: undefined,
    senderNumber: "",
    from: [],
    to: [],
    description: "",
    disciplines: [],
    keywords: [],
    attachments: [],
    selectedAttachmentIds: [],
    isRevisionFieldDisplayed: false,
    isRevisionButtonDisplayed: false,
};
