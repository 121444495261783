import { SuggestedProject } from "./SuggestedProject";
import FiledProject from "../../models/FiledProject";

export class ProjectCollection<T extends SuggestedProject | FiledProject> {
    private projectReference: {
        [key: string]: T;
    } = {};
    private projectKeys: Set<string>;

    constructor(projects: Array<T> = []) {
        this.projectKeys = new Set(projects.map((project: any) => project.nrn));
        projects.forEach((project) => (this.projectReference[project.nrn] = project));
    }

    get size(): number {
        return this.projectKeys.size;
    }

    get(id: string): T {
        return this.projectReference[id];
    }

    delete(id: string): boolean {
        delete this.projectReference[id];
        return this.projectKeys.delete(id);
    }

    has(id: string): boolean {
        return this.projectKeys.has(id) ? !!this.projectReference[id] : false;
    }

    add(project: T): Set<string> {
        this.projectReference[project.nrn] = project;
        return this.projectKeys.add(project.nrn);
    }

    toArray(): T[] {
        return Object.values(this.projectReference);
    }
}
