import * as EmailValidator from "email-validator";
import { IPersonaProps, ValidationState } from "office-ui-fabric-react";
import { WorkflowActionContact } from "../models/Contact";
import { TeamMemberNormalized } from "../models/projectTeam/TeamViewResponse";

export class FormValidationHelpers {
    static validateEmailInput(input: string): ValidationState {
        if (!EmailValidator.validate(input)) {
            return ValidationState.invalid;
        }

        return ValidationState.valid;
    }

    validateEmailInput(input: string): ValidationState {
        return FormValidationHelpers.validateEmailInput(input);
    }

    static areAssigneesValid(assignees: IPersonaProps[], required: boolean): boolean {
        if (!assignees.length) {
            return !required;
        }

        const invalidAssignees = assignees.filter((persona) => {
            if (!persona.text || !!(persona as any).data?.nrn) {
                return false;
            }
            return this.validateEmailInput(persona.text) !== ValidationState.valid;
        });

        return !invalidAssignees.length;
    }

    areAssigneesValid(assignees: IPersonaProps[], required: boolean): boolean {
        return FormValidationHelpers.areAssigneesValid(assignees, required);
    }

    static getPersonaPropsFromTeamMembers(teamMembers: TeamMemberNormalized[]): IPersonaProps[] {
        return teamMembers.map((member) => ({
            text: member.displayName,
            secondaryText: member.email,
            showSecondaryText: !!member.displayName,
            data: member,
        }));
    }

    getPersonaPropsFromTeamMembers(teamMembers: TeamMemberNormalized[]): IPersonaProps[] {
        return FormValidationHelpers.getPersonaPropsFromTeamMembers(teamMembers);
    }

    static mapPersonalPropsToContact(items: IPersonaProps[]): WorkflowActionContact[] {
        return items
            .filter((item) => !!item.text)
            .map((item) => {
                const email = (item as any).data ? (item as any).data.email : item.text?.trim();
                return { email };
            });
    }

    mapPersonalPropsToContact(items: IPersonaProps[]): WorkflowActionContact[] {
        return FormValidationHelpers.mapPersonalPropsToContact(items);
    }

    static isSpecSectionValid(input: string): ValidationState {
        if (input.match(/^\d{2} \d{2} \d{2}.*$/)) {
            return ValidationState.valid;
        }

        return ValidationState.invalid;
    }

    isSpecSectionValid(input: string): ValidationState {
        return FormValidationHelpers.isSpecSectionValid(input);
    }
}
