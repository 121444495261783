import { SuggestedProject, Suggestion } from "./SuggestedProject";
import FiledProject from "../../models/FiledProject";
import { OfficeRoamingSettings } from "../OfficeRoamingSettings";
import { ProjectCollection } from "./ProjectCollection";
import { OfficeWrapper } from "../OfficeWrapper";
import { Logger } from "../Logger";
import { ProjectBaseInformation } from "../../models/ProjectsResponse";

const sortBy = require("array-sort-by");

export class SmartFilingManager {
    private MIN_VALID_CHAR_LENGTH = 2;
    private officeRoamingSettings: OfficeRoamingSettings;
    private fullProjectListReference: { [key: string]: ProjectBaseInformation } = {};

    private _filedProjectCollection: ProjectCollection<FiledProject>;

    constructor(private officeWrapper: OfficeWrapper, private logger: Logger) {
        this._filedProjectCollection = new ProjectCollection<FiledProject>();
        this.officeRoamingSettings = new OfficeRoamingSettings(officeWrapper, logger);
    }

    get filedProjects(): FiledProject[] {
        return this._filedProjectCollection.toArray();
    }

    async addToFiledHistory(project: ProjectBaseInformation, conversationId: string, sender: string): Promise<void> {
        // Find filed project in history collection. Add if it doesn't exist
        // NOTE: If email thread has been filed to multiple projects, suggest in order by date/time filed - higher weight for most recent project thread was filed to
        this.logger.info("Adding to filed history");

        let filedProject: FiledProject | undefined;
        if (this._filedProjectCollection.has(project.nrn)) {
            filedProject = this._filedProjectCollection.get(project.nrn);
            const uniquifiedThreads = new Set(filedProject.t);
            uniquifiedThreads.add(conversationId);
            filedProject.t = Array.from(uniquifiedThreads);
            filedProject.d = new Date(); // update the last filed date
        } else {
            filedProject = new FiledProject(project.nrn, conversationId, sender);
        }
        filedProject.c++; // increment reference count before saving
        this._filedProjectCollection.add(filedProject);

        await this.officeRoamingSettings.saveWithRetry(
            {
                // NOTE: only pick latest 20 filed projects to set in Office Roaming Context due to 32KB size limit
                filedProjects: this._filedProjectCollection.toArray().reverse().slice(0, 20),
            },
            true
        );
    }

    private async getEmailBody() {
        return this.officeWrapper.getCurrentEmailBody();
    }

    private async loadFiledProjectHistory(): Promise<void> {
        let filedProjects = this.officeRoamingSettings.getFiledProjects() || [];
        filedProjects = filedProjects.filter((storedProject) => !!this.fullProjectListReference[storedProject.nrn]);

        this._filedProjectCollection = new ProjectCollection<FiledProject>(filedProjects);
    }

    private setProjectItemsWeight(
        projects: ProjectBaseInformation[],
        body: string,
        subject: string
    ): SuggestedProject[] {
        const suggestedProjects = new ProjectCollection<SuggestedProject>();
        if (!this.officeWrapper.currentContextItem) {
            return [];
        }

        for (const project of projects) {
            const suggestion = new Suggestion();
            const lowerCaseProjectName = project.name.toLocaleLowerCase();
            let projectMatch = false;
            const projectNameParts = lowerCaseProjectName.split(" - ");
            for (const namePart of projectNameParts) {
                if (namePart && namePart.length >= this.MIN_VALID_CHAR_LENGTH) {
                    const trimmedNamedPart = namePart.trim();
                    if (body && (body.includes(trimmedNamedPart) || trimmedNamedPart.includes(body))) {
                        projectMatch = !!suggestion.bodyKeywordMatch();
                    }
                    if (subject && (subject.includes(trimmedNamedPart) || trimmedNamedPart.includes(subject))) {
                        projectMatch = !!suggestion.subjectKeywordMatch();
                    }
                }
            }

            if (projectMatch) {
                let suggestedProject = suggestedProjects.get(project.nrn);
                if (!suggestedProject) {
                    suggestedProject = new SuggestedProject(project, suggestion);
                    suggestedProjects.add(suggestedProject);
                }
            }
            this.adjustFiledProjectSuggestionWeight(project, suggestedProjects);
        }

        const suggestedProjectsList = suggestedProjects.toArray();
        this.logSuggestedProjectRankings(suggestedProjectsList);

        return suggestedProjectsList;
    }

    private adjustFiledProjectSuggestionWeight(
        project: ProjectBaseInformation,
        suggestedProjects: ProjectCollection<SuggestedProject>
    ) {
        const filedProject = this._filedProjectCollection.get(project.nrn);
        if (!filedProject) {
            return;
        }

        let suggestion = new Suggestion();
        let suggestedProject = suggestedProjects.get(filedProject.nrn);

        if (suggestedProject) {
            suggestion = suggestedProject.suggestion;
            suggestedProject.filingFrequency = filedProject.c;
            suggestedProject.dateFiled = filedProject.d;
        } else {
            suggestedProject = new SuggestedProject(filedProject, suggestion);

            if (suggestedProjects.has(project.nrn)) {
                suggestion = suggestedProjects.get(project.nrn).suggestion;
            }
            suggestedProject = new SuggestedProject(filedProject, suggestion);
            suggestedProject.name = project.name;
            suggestedProject.number = project.number as string;
        }

        if (filedProject.t?.includes(this.officeWrapper.currentContextItem.conversationId)) {
            suggestion.isThread();
        }

        const senderAddress = this.officeWrapper.getSenderEmailAddress()?.toLowerCase();
        const userAddress = this.officeWrapper.userProfileEmailAddress.toLowerCase();
        if (senderAddress && senderAddress !== userAddress) {
            const userDomain = this.officeWrapper.userProfileEmailDomain;
            const senderDomain = this.officeWrapper.getContextMailboxItemSenderEmailDomain();

            const senderMatch = filedProject?.s === senderAddress;
            if (senderMatch) {
                if (userDomain === senderDomain) {
                    suggestion.isInternalSender();
                } else {
                    suggestion.isExternalSender();
                }
            }
        }

        suggestion.isRecent(); // if it's in the FiledProjects it's recent
        suggestedProject.suggestion = suggestion;
        suggestedProjects.add(suggestedProject);
    }

    private logSuggestedProjectRankings(projectsArray: SuggestedProject[]) {
        const logFormattedSuggestionsList = projectsArray.map((project) => {
            const projectData: { [key: string]: { [key: string]: any } } = {};
            projectData[project.name] = {
                "Suggestion Types Hit": project.rankedTypes,
                "Total Suggestion Weight": project.suggestionWeight,
                "Last Filed Date": project.dateFiled?.toISOString(),
            };
            return projectData;
        });

        this.logger.info(`Suggestions List Raw: ${JSON.stringify(projectsArray, null, 2)}`);
        this.logger.info(`Suggestions List: ${JSON.stringify(logFormattedSuggestionsList, null, 2)}`);
    }

    async getSuggestedProjects(projects: ProjectBaseInformation[]): Promise<SuggestedProject[]> {
        // the current Office.mailbox.item is null if a subfolder is selected
        if (!this.officeWrapper.currentContextItem) {
            return [];
        }
        for (const project of projects) {
            this.fullProjectListReference[project.nrn] = project;
        }

        await this.loadFiledProjectHistory();
        const body = (await this.getEmailBody()) as string;
        const subject = (await this.officeWrapper.getCurrentEmailSubject()) || "";
        const suggestedProjects = this.setProjectItemsWeight(
            projects,
            body.toLocaleLowerCase(),
            subject.toLocaleLowerCase()
        );

        if (suggestedProjects.length) {
            sortBy(suggestedProjects, (item: SuggestedProject) => [
                -item.suggestionWeight,
                -(item.dateFiled || Number.MAX_SAFE_INTEGER),
            ]);
        }

        this.logger.info(`${suggestedProjects.length} project suggestions`);
        return suggestedProjects;
    }
}
