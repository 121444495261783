import { Logger } from "./Logger";

export class WindowWrapper {
    constructor(private logger: Logger) {}
    readonly logoutFrameName = "logoutFrame";

    assignLocation(url: string): void {
        window.location.assign(url);
    }

    base64Decode(base64encodedString: string): string {
        return window.atob(base64encodedString);
    }

    locationHash(): string {
        return window.location.hash;
    }

    locationOrigin(): string {
        return window.location.origin;
    }

    queryParams(): string {
        return window.location.search;
    }

    getLogoutFrame(): Window | undefined {
        // @ts-ignore
        return window.frames[this.logoutFrameName];
    }

    open(url: string): void {
        window.open(url);
    }

    openUrlInFrame(url: string, frameName: string): void {
        try {
            window.open(url, frameName);
        } catch (error) {
            this.logger.error(`There was an error with framedWindow.location.assign(url) operation: ${error}`);
        }
    }

    getQueryParam(param: string): string | null {
        const paramString = this.queryParams();
        if (!paramString) {
            return null;
        }
        const urlSearchParams = new URLSearchParams(paramString);
        if (!urlSearchParams.has(param)) {
            return null;
        }
        return urlSearchParams.get(param);
    }

    getAppVersion(): string {
        return window.navigator.appVersion;
    }

    copyTextToClipboard(text: string): void {
        if ((window as any).clipboardData && (window as any).clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            try {
                (window as any).clipboardData.clearData("Text");
                return (window as any).clipboardData.setData("Text", text);
            } catch (error) {
                this.logger.warning(`Copying text to IE clipboard failed: ${error}`);
            }
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            const textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand("copy"); // Security exception may be thrown by some browsers.
            } catch (error) {
                this.logger.warning(`Copy to clipboard failed: ${error}`);
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }
}
