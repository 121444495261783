import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";

interface CustomCheckboxProp extends LocalizeContextProps {
    id: string;
    className: string;
    onSelectAllChanged(event?: React.FormEvent<HTMLElement>, checked?: boolean): void;
    areAllItemsSelected: boolean;
}

const SelectAllLabel = (checkboxProp: CustomCheckboxProp): JSX.Element => {
    const { id, className, onSelectAllChanged, areAllItemsSelected, translate } = checkboxProp;
    return (
        <div className="newforma-selectEmailHeader-checkbox-wrapper">
            <span
                data-testid="selectAll-checkbox"
                id={id}
                className={className}
                onClick={(event) => onSelectAllChanged(event, !areAllItemsSelected)}
            >
                {areAllItemsSelected
                    ? (translate("FILE_MULTIPLE_EMAIL.DESELECT_ALL") as string)
                    : (translate("FILE_MULTIPLE_EMAIL.SELECT_ALL") as string)}
            </span>
        </div>
    );
};

export default withLocalize(SelectAllLabel);
