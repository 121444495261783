import * as React from "react";

function IssueSuccessIcon() {
    return (
        <svg width="132" height="75" viewBox="0 0 132 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M66 75C102.451 75 132 59.7298 132 40.893C132 22.0562 102.451 6.786 66 6.786C29.5492 6.786 0 22.0562 0 40.893C0 59.7298 29.5492 75 66 75Z"
                fill="#F6F6F6"
            />
            <path
                d="M82.3419 16.719C82.3469 16.648 82.3469 16.578 82.3419 16.507C82.3469 16.454 82.3469 16.4 82.3419 16.347C82.3149 16.275 82.2839 16.205 82.2489 16.136L82.1639 16.009V15.95C82.0919 15.861 82.0149 15.777 81.9339 15.696C81.8679 15.636 81.7969 15.583 81.7209 15.536C81.6639 15.482 81.6009 15.433 81.5329 15.392L81.4059 15.316C81.1369 15.16 80.8519 15.035 80.5539 14.945C80.4869 14.923 80.4189 14.906 80.3499 14.894L80.1789 14.852L79.8899 14.784C79.7319 14.753 79.5729 14.73 79.4129 14.717H78.2379L77.8709 14.759H77.4879L77.0879 14.86L76.6369 15.004L76.4579 15.08L76.0829 15.283L65.8549 20.942C65.2429 21.218 64.8269 21.798 64.7649 22.462V30.149C64.8289 29.483 65.2479 28.902 65.8639 28.628L75.8869 22.851L76.2619 22.648C76.6439 22.489 77.0449 22.375 77.4539 22.31H77.4969C77.9209 22.235 78.3519 22.207 78.7829 22.226C79.1839 22.24 79.5839 22.294 79.9749 22.386H80.1449C80.5179 22.479 80.8759 22.621 81.2099 22.809C81.3219 22.875 81.4299 22.948 81.5329 23.028L81.7379 23.189C81.8509 23.286 81.9509 23.396 82.0359 23.518C82.0379 23.535 82.0379 23.552 82.0359 23.569C82.1169 23.68 82.1799 23.802 82.2229 23.932C82.2659 24.06 82.2889 24.194 82.2909 24.329V16.643L82.3419 16.719Z"
                fill="url(#paint0_linear_328_3821-499166)"
            />
            <path
                d="M63.241 34.879L63.292 34.955C63.396 35.106 63.479 35.271 63.539 35.445C63.592 35.62 63.621 35.802 63.624 35.985V27.927C63.612 27.852 63.592 27.778 63.564 27.708C63.529 27.609 63.486 27.513 63.437 27.42C63.4 27.362 63.36 27.306 63.317 27.251V27.175L63.275 27.116C63.183 27 63.083 26.89 62.977 26.787L62.917 26.736L62.636 26.525C62.555 26.456 62.469 26.391 62.381 26.331L62.168 26.153C61.836 25.974 61.488 25.824 61.129 25.706L61.001 25.655L60.737 25.587L60.286 25.478H60.09C59.941 25.446 59.79 25.424 59.638 25.41H58.37C58.205 25.402 58.04 25.402 57.876 25.41H57.382L56.888 25.495H56.564L56.351 25.545L55.815 25.723H55.755C55.488 25.825 55.229 25.946 54.98 26.086C54.148 26.463 53.583 27.252 53.498 28.155V35.926C53.585 35.021 54.154 34.231 54.989 33.857C55.242 33.718 55.504 33.594 55.772 33.485H55.832C56.07 33.401 56.326 33.325 56.59 33.257H56.743C57.007 33.198 57.279 33.147 57.595 33.113H57.714C57.996 33.079 58.281 33.062 58.565 33.063H58.693C58.983 33.063 59.272 33.063 59.545 33.113H59.673C59.894 33.113 60.107 33.181 60.32 33.223L60.771 33.333L61.163 33.451C61.524 33.571 61.874 33.721 62.21 33.899C62.358 33.991 62.501 34.093 62.636 34.203L62.917 34.414C63.039 34.559 63.147 34.715 63.241 34.879Z"
                fill="url(#paint1_linear_328_3821-142683)"
            />
            <path
                d="M100.167 10.367C100.167 10.62 99.579 10.721 99.519 10.975C99.45 11.239 99.35 11.494 99.221 11.735L81.261 46.459C80.722 47.403 79.925 48.176 78.962 48.689C78.499 48.949 78.018 49.175 77.522 49.365H77.412C76.961 49.53 76.5 49.669 76.032 49.778L75.759 49.846C75.27 49.956 74.776 50.041 74.278 50.099H74.048C73.537 50.15 73.017 50.184 72.498 50.184H72.268C71.74 50.184 71.212 50.184 70.684 50.091H70.462C70.054 50.04 69.662 49.964 69.27 49.888C68.878 49.812 68.717 49.762 68.419 49.686C68.121 49.61 67.797 49.517 67.499 49.407L67.107 49.263C66.659 49.091 66.223 48.888 65.804 48.655L23.147 24.582C22.985 24.489 22.849 24.388 22.695 24.287C22.358 24.071 22.042 23.825 21.75 23.552C21.566 23.371 21.392 23.179 21.231 22.977C21.086 22.79 20.956 22.592 20.839 22.386C20.724 22.166 20.635 21.934 20.575 21.694L20.524 21.533C20.473 21.297 20.302 21.271 20.302 21.035V28.172C20.302 28.348 20.32 28.523 20.354 28.696V28.822L20.405 28.991C20.405 29.059 20.405 29.135 20.464 29.211C20.518 29.372 20.586 29.527 20.669 29.676C20.78 29.873 20.905 30.062 21.043 30.242C21.2 30.444 21.374 30.633 21.563 30.807C21.854 31.081 22.171 31.327 22.508 31.542L22.627 31.635L22.994 31.922L65.915 56.418C66.337 56.653 66.775 56.859 67.226 57.034L67.61 57.178L68.044 57.338L68.538 57.456C68.81 57.524 69.083 57.6 69.389 57.651C69.509 57.651 69.611 57.718 69.73 57.735C70.011 57.786 70.292 57.82 70.582 57.853H70.897C71.229 57.853 71.57 57.904 71.91 57.921H74.661C74.967 57.882 75.274 57.831 75.581 57.769L75.879 57.701L76.151 57.634L76.551 57.541C76.884 57.448 77.207 57.338 77.522 57.22H77.642C78.131 56.99 78.601 56.722 79.047 56.418C80.012 55.908 80.812 55.138 81.355 54.196L99.758 18.932C100.011 18.464 100.145 17.942 100.15 17.411V10.324L100.167 10.367Z"
                fill="url(#paint2_radial_328_3821-334714)"
            />
            <path
                d="M88.176 5.231C89.957 4.923 91.778 4.923 93.558 5.231C94.916 5.456 96.225 5.913 97.425 6.583C99.775 7.934 100.788 9.961 99.783 11.904L81.372 47.126C80.833 48.07 80.036 48.843 79.072 49.356C74.947 51.438 70.066 51.438 65.94 49.356L22.994 24.793C22.021 24.282 21.21 23.513 20.652 22.572C19.153 19.751 21.912 16.786 26.817 15.916L88.176 5.231ZM80.639 18.56C82.002 17.774 81.993 16.507 80.639 15.722C79.211 15.012 77.543 14.95 76.066 15.553L75.717 15.73L66.426 21.06C65.08 21.837 65.089 23.104 66.426 23.881C67.844 24.599 69.509 24.667 70.982 24.067L71.348 23.89L80.588 18.56M55.295 30.326C57.362 31.364 59.803 31.364 61.87 30.326C63.675 29.287 63.658 27.598 61.87 26.55C59.813 25.511 57.378 25.511 55.321 26.55C53.515 27.598 53.507 29.287 55.321 30.326"
                fill="url(#paint3_radial_328_3821-096695)"
            />
            <path
                d="M81.4571 17.8C81.2471 18.124 80.9531 18.387 80.6051 18.56L71.3911 23.89L71.0331 24.059C69.5581 24.664 67.8891 24.596 66.4681 23.873C66.1201 23.698 65.8251 23.433 65.6171 23.104C65.8311 22.787 66.1241 22.531 66.4681 22.361L75.7001 17.031L76.0491 16.846C77.5321 16.249 79.2021 16.317 80.6311 17.031C80.9691 17.211 81.2541 17.476 81.4571 17.8ZM63.0711 29.11C62.8091 29.631 62.3891 30.056 61.8701 30.326C59.8001 31.365 57.3561 31.365 55.2871 30.326C54.7581 30.055 54.3311 29.623 54.0691 29.093C54.3301 28.565 54.7541 28.134 55.2781 27.86C57.3361 26.812 59.7781 26.812 61.8361 27.86C62.3741 28.132 62.8071 28.571 63.0711 29.11Z"
                fill="#4688ED"
            />
            <path
                d="M58.969 26.047C51.637 30.266 34.332 21.68 27 17.467L55.099 4.981C62.412 9.194 66.282 21.84 58.969 26.047Z"
                fill="url(#paint4_radial_328_3821-577146)"
            />
            <path
                d="M36.0969 8.316V10.702H58.0369C59.3349 10.702 60.3869 9.654 60.3869 8.36V6.756H36.1159V8.316"
                fill="url(#paint5_linear_328_3821-886870)"
            />
            <path
                d="M57.6529 8.32199H36.0969V1.56899C36.0929 1.46199 36.1129 1.35599 36.1549 1.25699C36.2379 1.03599 36.3819 0.841995 36.5699 0.696995C37.2199 0.211995 38.0189 -0.0340052 38.8309 0.00399481C39.6399 -0.0300052 40.4359 0.214995 41.0849 0.696995C41.2249 0.805995 41.3399 0.942995 41.4239 1.09799C41.5079 1.24199 41.5539 1.40399 41.5579 1.56899V5.17799H57.6919C58.5059 5.14199 59.3079 5.38899 59.9589 5.87799C60.2319 6.08899 60.3989 6.40699 60.4189 6.74999C60.3809 7.62799 59.1669 8.32199 57.6529 8.32199Z"
                fill="url(#paint6_radial_328_3821-062187)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_328_3821-499166"
                    x1="76.4492"
                    y1="28.28"
                    x2="66.3066"
                    y2="10.1947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#808080" />
                    <stop offset="0.18" stopColor="#848484" />
                    <stop offset="0.55" stopColor="#C2C2C2" />
                    <stop offset="0.81" stopColor="#E8E8E8" />
                    <stop offset="0.95" stopColor="#F7F7F7" />
                    <stop offset="1" stopColor="#F7F7F7" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_328_3821-142683"
                    x1="58.5569"
                    y1="32.7248"
                    x2="58.5569"
                    y2="17.9179"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#808080" />
                    <stop offset="0.6" stopColor="#D3D3D3" />
                    <stop offset="0.89" stopColor="#F7F7F7" />
                    <stop offset="1" stopColor="#F7F7F7" />
                </linearGradient>
                <radialGradient
                    id="paint2_radial_328_3821-334714"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(27.4615 22.8) scale(57.4017 96.3115)"
                >
                    <stop stopColor="#CAD8EC" />
                    <stop offset="0.6" stopColor="#9CBCEC" />
                    <stop offset="0.72" stopColor="#99BAEC" />
                    <stop offset="0.8" stopColor="#90B5EC" />
                    <stop offset="0.87" stopColor="#81ACEC" />
                    <stop offset="0.93" stopColor="#6C9FED" />
                    <stop offset="0.98" stopColor="#518EED" />
                    <stop offset="1" stopColor="#4688ED" />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_328_3821-096695"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(57.9071 -0.994098) scale(132.272 230.238)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#72A3ED" />
                </radialGradient>
                <radialGradient
                    id="paint4_radial_328_3821-577146"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(41.1473 14.6265) rotate(-8.12972) scale(13.9964 10.7501)"
                >
                    <stop stopColor="#2DC24F" stopOpacity="0.3" />
                    <stop offset="0.13" stopColor="#2DC24F" stopOpacity="0.24" />
                    <stop offset="0.42" stopColor="#2DC24F" stopOpacity="0.11" />
                    <stop offset="0.62" stopColor="#2DC24F" stopOpacity="0.03" />
                    <stop offset="1" stopColor="#2DC24F" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint5_linear_328_3821-886870"
                    x1="48.2452"
                    y1="11.5296"
                    x2="48.2452"
                    y2="9.35296"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A4D0B1" />
                    <stop offset="1" stopColor="#58C576" />
                </linearGradient>
                <radialGradient
                    id="paint6_radial_328_3821-062187"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(28.6997 -8.71548) scale(43.0173 125.745)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#58C576" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default IssueSuccessIcon;
