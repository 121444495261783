import { Rfi } from "@newforma/platform-client-api-sdk";
import { BaseProjectItemMapping } from "./BaseProjectItemMapping";
import { ValidRfiReviewResponseFieldForm } from "../models/workflow/rfi/RfiReviewResponse";

export class RfiReviewResponseMapping extends BaseProjectItemMapping {
    static toRequest(
        reviewerResponseForm: ValidRfiReviewResponseFieldForm,
        sessionIds: string[],
        version: string
    ): Rfi.ReviewResponseRequest {
        return {
            action: this.toIdValue(reviewerResponseForm.action.key),
            response: reviewerResponseForm.response?.trim() || undefined,
            from: reviewerResponseForm.from.map((x) => this.mapContactWithId(x))[0],
            to: reviewerResponseForm.to.map(this.mapContactWithId),
            cc: reviewerResponseForm.cc.map(this.mapContactWithId),
            attachments: sessionIds && sessionIds.length ? { uploadIds: sessionIds } : undefined,
            version: version,
        };
    }
}
