import { PimTrackApi, SubscriptionApi } from "../models/PimModels";
import { Logger } from "./Logger";
export class ConfigurationService {
    private appConfig: any;

    constructor(private logger: Logger) {}

    async initConfig() {
        try {
            const data = await fetch(`${window.location.origin}/config/default.json`);

            this.appConfig = await data.json();

            this.logger.info(
                `Configuration: hostNameWithProtocol = ${this.appConfig.__HOST_NAME_WITH_PROTOCOL__}; originURL = ${this.appConfig.__ORIGIN_URL__}; redirectURL = ${this.appConfig.__REDIRECT_URL__}; webAppURL = ${this.appConfig.__WEB_APP_URL__};`
            );
        } catch (error) {
            throw new Error("Failed loading configuration file");
        }
    }

    get clientId(): string {
        return this.appConfig.__CLIENT_ID__;
    }

    get externalTokenClientId(): string {
        return this.appConfig.__EXTERNAL_TOKEN_CLIENT_ID__;
    }

    get bimApiURL(): string {
        return this.appConfig.__BIM_API_URL__;
    }

    get bimAuthURL(): string {
        return this.appConfig.__BIM_AUTH_URL__;
    }

    get originURL(): string {
        return this.appConfig.__ORIGIN_URL__;
    }

    get redirectURL(): string {
        return this.appConfig.__REDIRECT_URL__;
    }

    get hostNameWithProtocol(): PimTrackApi[] {
        return this.appConfig.__HOST_NAME_WITH_PROTOCOL__;
    }

    get version(): string {
        return this.appConfig.__VERSION__;
    }

    get webAppURL(): string {
        return this.appConfig.__WEB_APP_URL__;
    }

    get email(): string {
        return this.appConfig.__PROJECT_EMAIL_ADDRESS_DOMAIN__;
    }

    get environment(): string {
        return this.appConfig.__ENVIRONMENT__;
    }

    get gainSightProductKey(): string {
        return this.appConfig.__GAINSIGHT_PRODUCT_ID__;
    }

    get launchDarklyClientSideId(): string {
        return this.appConfig.__LAUNCH_DARKLY_CLIENT_SIDE_ID;
    }

    get subscriptionServiceUrl(): SubscriptionApi[] {
        return this.appConfig.__SUBSCRIPTION_SERVICE_URL__;
    }
}
