import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Label, MessageBar, MessageBarType, Spinner, SpinnerSize } from "office-ui-fabric-react";
import { Authenticator } from "../../services/Authenticator";
import { Redirect } from "react-router-dom";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { UnauthenticatedApiClient } from "../../services/UnauthenticatedApiClient";
import "./LoginComponent.less";
import { Logger } from "../../services/Logger";
import { AnalyticsManager } from "../../services/AnalyticsManager";
import * as crypto from "crypto";
import { BimProjectsApiService } from "../../services/BimApi/BimProjectsApiService";
import { UserInfo } from "../../models/PimModels";

export interface LoginComponentProps extends LocalizeContextProps {
    handleDiagnostic: () => Promise<string>;
    onApiLogin: (matchingUser: boolean, userInfo: UserInfo) => void;
    authenticator: Authenticator;
    redirectUrl: string;
    isLoggedIn: boolean;
    officeWrapper: OfficeWrapper;
    apiClient: UnauthenticatedApiClient;
    logger: Logger;
    analyticsManager: AnalyticsManager;
    bimProjectApiService: BimProjectsApiService;
}

export interface LoginComponentState {
    lastError: string | null;
    authorizationCode: string | null;
    isLoggingInInProgress: boolean;
}

class LoginComponent extends React.Component<LoginComponentProps, LoginComponentState> {
    constructor(props: LoginComponentProps, context: LoginComponentState) {
        super(props, context);

        this.state = {
            lastError: null,
            authorizationCode: null,
            isLoggingInInProgress: false,
        };
    }

    componentDidMount(): void {
        this.props.logger.info("LoginComponent mounted");
    }

    private async handleLogin(): Promise<void> {
        this.setState({ isLoggingInInProgress: true });
        await this.completeLogin();
        this.setState({ isLoggingInInProgress: false });
    }

    private async completeLogin(): Promise<void> {
        try {
            const codeVerifier = this.getCodeVerifier();
            const authorizationCode = await this.props.authenticator.getAuthorizationCode(
                this.getCodeChallenge(codeVerifier)
            );
            this.setState({ authorizationCode: authorizationCode });

            await this.props.authenticator.handleLogin(authorizationCode, codeVerifier);
            const addinUserInfo = await this.props.bimProjectApiService.getUserinfo();
            const outlookUser = this.props.officeWrapper.userProfileEmailAddress;

            this.props.logger.info(`BimTrack User: ${addinUserInfo.email}`);

            const isMismatchingUser = addinUserInfo.email.toLowerCase() === outlookUser.toLowerCase();

            this.props.onApiLogin(isMismatchingUser, addinUserInfo);
        } catch (error) {
            this.setState({ isLoggingInInProgress: false });
        }
    }

    private getCodeVerifier(): string {
        return crypto
            .pseudoRandomBytes(64)
            .toString("base64")
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");
    }

    private getCodeChallenge(codeVerifier: string): string {
        return crypto
            .createHash("sha256")
            .update(codeVerifier)
            .digest("base64")
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");
    }

    private getErrorBarComponent(): JSX.Element {
        return (
            <MessageBar
                className="newforma-messageBar"
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.onErrorBarComponentDismissed.bind(this)}
            >
                {this.state.lastError}
            </MessageBar>
        );
    }

    private onErrorBarComponentDismissed(): void {
        this.setState({ lastError: null });
    }

    render(): JSX.Element {
        if (!this.props.isLoggedIn && this.state.isLoggingInInProgress) {
            return (
                <div className="spinner-wrapper">
                    <Spinner size={SpinnerSize.large} />
                </div>
            );
        }

        return (
            <div className="newforma-loginPage">
                {this.props.isLoggedIn ? <Redirect to={this.props.redirectUrl} /> : null}
                {this.state.lastError ? this.getErrorBarComponent() : null}
                <img src="assets/logo-full.svg" className="newforma-welcomeLogo" alt="BimTrack logo" />
                <div className="newforma-loginContent">
                    <div className="newforma-loginMarketingContainer">
                        <Label className="ms-font-m">
                            {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_1") as string}
                        </Label>
                    </div>
                    <div
                        data-testid="bimtrack-loginButton"
                        className="bimtrack-loginButton"
                        onClick={this.handleLogin.bind(this)}
                    >
                        <img src="assets/ms-logo.svg" alt="Microsoft Logo" />
                        <Label>{this.props.translate("AUTHENTICATION.SIGNIN") as string}</Label>
                    </div>
                    <div className="bimtrack-helpLink">
                        <a
                            onClick={() => this.props.handleDiagnostic()}
                            href={this.props.translate("URLS.HELP_AND_SUPPORT") as string}
                            target="_blank"
                        >
                            {this.props.translate("AUTHENTICATION.HELP") as string}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(LoginComponent);
