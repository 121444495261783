import { DirectionalHint, HoverCard, HoverCardType, Icon, Label } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { SuggestedProject } from "../../services/SmartFiling/SuggestedProject";
import "./SendAndFileComponent.less";

interface renderAutoSuggestedLabelProps extends LocalizeContextProps {
    suggestedProjects: SuggestedProject[];
}

function AutoSuggestedLabel(props: renderAutoSuggestedLabelProps): JSX.Element | null {
    const { suggestedProjects, translate } = props;

    if (suggestedProjects.length <= 0) {
        return null;
    }

    const renderFiledHint = (): JSX.Element => (
        <div className={"info-container-icon-hovercard"}>
            {translate("SEND_AND_FILE.HINT_TEXT_HOVER_INFO") as string}
        </div>
    );

    return (
        <div className="info-container">
            <Label className="newforma-send-and-file-header-sub">
                {translate("SEND_AND_FILE.HINT_TEXT_INFO") as string}
            </Label>
            <HoverCard
                plainCardProps={{
                    onRenderPlainCard: renderFiledHint,
                    directionalHint: DirectionalHint.bottomCenter,
                    gapSpace: 5,
                }}
                instantOpenOnClick
                type={HoverCardType.plain}
                className="newforma-mailCheck"
            >
                <Icon iconName="StatusCircleQuestionMark" className="info-container-icon" />
            </HoverCard>
        </div>
    );
}

export default withLocalize(AutoSuggestedLabel);
