import { Rfi } from "@newforma/platform-client-api-sdk";
import { Request } from "aws-sign-web";
import { ITag } from "office-ui-fabric-react";
import { IHub } from "../../models/Hub";
import { ConfigurationService } from "../ConfigurationService";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Logger } from "../Logger";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { BaseApiService, Method } from "./BaseApiService";
import { ProjectItemFeature } from "./types";

export class RfiApiService extends BaseApiService {
    constructor(
        private logger: Logger,
        protected newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private configService: ConfigurationService
    ) {
        super(newformaApiClient);
    }

    async logRfi(selectedHub: IHub, selectedProject: ITag, body: Rfi.LogRequest): Promise<Rfi.LogResponse> {
        this.logger.info("Log a Rfi");
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildLogRfiEndpoint(domain, selectedHub.key.toString(), selectedProject.key.toString());

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Post), body: JSON.stringify(body) },
            async (signedOptions: Request) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    async submitReviewResponse(
        selectedHub: IHub,
        selectedProject: ITag,
        rfiId: string,
        body: Rfi.ReviewResponseRequest
    ): Promise<Rfi.ReviewResponseResponse> {
        this.logger.info(`Submit Rfi review response: ${rfiId}`);
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildRfiReviewResponseEndpoint(
            domain,
            selectedHub.key.toString(),
            selectedProject.key.toString(),
            rfiId
        );

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Put), body: JSON.stringify(body) },
            async (signedOptions: Request) =>
                this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    async searchRfi(
        selectedHub: IHub,
        selectedProject: ITag,
        seachRequest: Rfi.SearchRequest
    ): Promise<Rfi.SearchResponse> {
        this.logger.info(`Search Rfi: ${seachRequest.query}`);
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildSearchRfiEndpoint(domain, selectedHub.key.toString(), selectedProject.key.toString());

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Post), body: JSON.stringify(seachRequest) },
            async (signedOptions: Request) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    async getRfiDetail(selectedHub: IHub, selectedProject: ITag, rfiId: string): Promise<Rfi.DetailsResponse> {
        this.logger.info(`Get Rfi Detail: ${rfiId}`);
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildRfiWithIdEndpoint(
            domain,
            selectedHub.key.toString(),
            selectedProject.key.toString(),
            rfiId
        );

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Get) },
            async (signedOptions: Request) =>
                this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers)
        );
    }

    getRfiUrl(rfiId: string, selectedHub: IHub, selectedProject: ITag): string {
        const bimUrl = new URL(this.configService.webAppURL);

        return new URL(
            `${bimUrl.protocol}//${selectedHub.name}.${bimUrl.hostname}/projects/${selectedProject.key}/${ProjectItemFeature.RFIs}/${rfiId}`
        ).toString();
    }

    private buildLogRfiEndpoint(domain: string, hubId: string, projectId: string): string {
        return new URL(`${this.basePimUrl(domain, hubId, projectId, ProjectItemFeature.RFIs)}/log`).toString();
    }

    private buildRfiWithIdEndpoint(domain: string, hubId: string, projectId: string, rfiId: string | number): string {
        return new URL(`${this.basePimUrl(domain, hubId, projectId, ProjectItemFeature.RFIs)}/${rfiId}`).toString();
    }

    private buildRfiReviewResponseEndpoint(domain: string, hubId: string, projectId: string, rfiId: string): string {
        return new URL(`${this.buildRfiWithIdEndpoint(domain, hubId, projectId, rfiId)}/reviewresponse`).toString();
    }
    private buildSearchRfiEndpoint(domain: string, hubId: string, projectId: string): string {
        return new URL(`${this.basePimUrl(domain, hubId, projectId, ProjectItemFeature.RFIs)}/search`).toString();
    }
}
