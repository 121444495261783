import {
    CompactPeoplePicker,
    DirectionalHint,
    HoverCard,
    HoverCardType,
    IBasePickerSuggestionsProps,
    IPeoplePickerItemSelectedProps,
    IPersonaProps,
    PeoplePickerItem,
    Persona,
    PersonaSize,
    ValidationState,
} from "office-ui-fabric-react";
import * as React from "react";
import { useCallback } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { FormValidationHelpers } from "../../../../helpers/FormValidationHelpers";
import LabelComponent from "../../label/LabelComponent";
import "./ContactDropdownComponent.less";

export interface ContactFieldComponentProps extends LocalizeContextProps {
    allowCustomInput?: boolean;
    className?: string;
    disabled: boolean;
    formValidationHelpers: Pick<FormValidationHelpers, "validateEmailInput">;
    itemLimit?: number;
    items: IPersonaProps[];
    label: string;
    onChange: (people: IPersonaProps[]) => void;
    placeholder?: string;
    required: boolean;
    selectedContacts: IPersonaProps[];
}

function ContactDropdownComponent(props: ContactFieldComponentProps) {
    const picker = React.useRef<any>(null);

    const getPickerSuggestionProps = (): IBasePickerSuggestionsProps => ({
        suggestionsHeaderText: undefined,
        noResultsFoundText: props.translate("ACTION_ITEM.NO_RESULTS_FOUND") as string,
        loadingText: props.translate("ACTION_ITEM.LOADING") as string,
        showRemoveButtons: false,
    });

    const removeSelectedItems = (items: IPersonaProps[], selectedItems?: IPersonaProps[]) =>
        items.filter((item) => !selectedItems?.map((selectedItem) => selectedItem.id).includes(item.id));

    const filteredItems = useCallback(
        (inputValue: string, selectItems?: IPersonaProps[]) =>
            removeSelectedItems(props.items, selectItems).filter(
                (x) => (x.text || "").includes(inputValue) || (x.secondaryText || "").includes(inputValue)
            ),
        [props.items]
    );

    const onEmptyFilteredItems = useCallback(
        (selectItems?: IPersonaProps[]) => removeSelectedItems(props.items, selectItems),
        [props.items]
    );

    const onSelectedItemChange = (items?: IPersonaProps[]) => {
        if (!items) {
            props.onChange([]);
            return;
        }
        props.onChange(items);
    };

    const onCreateGenericItem = (input: string): IPersonaProps => ({
        text: input.trim(),
        id: input.trim(),
    });

    const renderContactCard = (personaProps: IPersonaProps): JSX.Element => {
        const updatedPersonaProps: IPersonaProps = {
            ...personaProps,
            text: personaProps.secondaryText || personaProps.text,
            size: PersonaSize.size24,
            className: "assigneeSuggestionsItem",
        };
        return <Persona {...updatedPersonaProps} />;
    };

    const onRenderItem = (itemProp: any): JSX.Element => {
        const updatedProps: IPeoplePickerItemSelectedProps = {
            ...itemProp,
            item: {
                ...itemProp.item,
                showSecondaryText: false,
                onRenderPrimaryText: () => itemProp.item.text, // this is needed to prevent the default behavior of showing a tooltip for overflow
            },
        };
        return (
            <HoverCard
                plainCardProps={{
                    onRenderPlainCard: renderContactCard,
                    renderData: itemProp.item,
                    directionalHint: DirectionalHint.topLeftEdge,
                    gapSpace: 5,
                }}
                instantOpenOnClick
                type={HoverCardType.plain}
                key={`hoverCard-${updatedProps.key}`}
            >
                <PeoplePickerItem {...updatedProps} />
            </HoverCard>
        );
    };

    const onValidateInput = (input: string): ValidationState => {
        if (
            !input.trim() ||
            props.selectedContacts.some((x) => x.id === input) ||
            props.formValidationHelpers.validateEmailInput(input) === ValidationState.invalid
        ) {
            return ValidationState.invalid;
        }

        return ValidationState.valid;
    };

    const onRenderSuggestionsItem = (iPersonaProps: IPersonaProps) => (
        <div className="contactDropdownSuggestedItem">
            <Persona
                text={iPersonaProps.text}
                imageUrl={iPersonaProps.imageUrl}
                imageInitials={iPersonaProps.imageInitials}
                size={PersonaSize.size32}
            />
        </div>
    );

    return (
        <div className={`${props.className ?? ""}`} data-testid={"contactFieldComponent"}>
            <LabelComponent text={props.label} required={props.required} />
            <CompactPeoplePicker
                componentRef={picker}
                className="contactFieldPicker"
                pickerSuggestionsProps={getPickerSuggestionProps()}
                selectedItems={props.selectedContacts}
                onResolveSuggestions={filteredItems}
                onEmptyResolveSuggestions={onEmptyFilteredItems}
                onChange={onSelectedItemChange}
                onValidateInput={props.allowCustomInput ? onValidateInput : undefined}
                itemLimit={props.itemLimit}
                resolveDelay={300}
                createGenericItem={props.allowCustomInput ? onCreateGenericItem : undefined}
                disabled={props.disabled}
                onRenderItem={onRenderItem}
                onRenderSuggestionsItem={onRenderSuggestionsItem}
                onBlur={() => {
                    if (picker.current) {
                        picker.current.input.current?._updateValue("");
                    }
                }}
                inputProps={{
                    placeholder: props.placeholder,
                }}
            />
        </div>
    );
}

export default withLocalize(ContactDropdownComponent);
