import * as React from "react";

function SubmittalErrorIcon() {
    return (
        <svg
            width="99"
            height="60"
            viewBox="0 0 99 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="submittalErrorIcon"
        >
            <path
                d="M49.5 59.4821C76.8381 59.4821 99 48.1151 99 34.0931C99 20.0711 76.8381 8.7041 49.5 8.7041C22.1619 8.7041 0 20.0711 0 34.0931C0 48.1151 22.1619 59.4821 49.5 59.4821Z"
                fill="#EEEEEE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M80.9383 24.8211C80.8509 25.5043 80.5016 26.1732 79.8611 26.7995L79.468 27.1838L68.099 38.3142C66.6579 39.7233 63.9649 40.5916 61.0389 40.5916L37.3257 40.5346C34.4288 40.5346 31.7358 39.6522 30.2946 38.2431L18.7364 27.0557L18.3434 26.6714C17.6592 26.0024 17.2953 25.2623 17.2516 24.5079C17.2225 23.6682 17.5864 22.8284 18.3434 22.0883L30.1054 10.5878C31.5611 9.16445 34.225 8.29622 37.151 8.28198L60.8642 8.33892C63.7611 8.33892 66.4541 9.22138 67.8953 10.6305L79.8465 22.2021C80.6617 23.0134 81.0256 23.9244 80.9383 24.8211Z"
                fill="url(#paint0_linear_601_90-995151)"
            />
            <path d="M41.023 25.9311V35.2831H68.448V14.7151L41.023 25.9311Z" fill="url(#paint1_linear_601_90-039314)" />
            <path
                d="M44.124 32.9909V27.1839V20.0239H33.614V32.8489L44.124 32.9909Z"
                fill="url(#paint2_linear_601_90-623839)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M79.8465 22.2021L67.8953 10.6305C66.4541 9.22138 63.7611 8.33892 60.8642 8.33892L37.151 8.28198C34.225 8.29622 31.5611 9.16445 30.1054 10.5878L18.3434 22.0883C17.5864 22.8284 17.2225 23.6682 17.2516 24.5079C17.2953 25.2623 17.6592 26.0024 18.3434 26.6714L18.7364 27.0557L30.2946 38.2431C31.7358 39.6522 34.4288 40.5346 37.3257 40.5346L61.0389 40.5916C63.9649 40.5916 66.6579 39.7233 68.099 38.3142L79.468 27.1838L79.8611 26.7995C80.5016 26.1732 80.8509 25.5043 80.9383 24.8211C81.0256 23.9244 80.6617 23.0134 79.8465 22.2021ZM64.0813 19.7113L63.863 19.8536L45.6668 29.8027C44.8953 30.2297 43.7162 30.2724 42.8719 29.9451L42.6098 29.8027L34.3269 25.2765C33.4681 24.8068 33.4389 24.0098 34.2833 23.5116C35.0402 23.0704 36.2776 23.0277 37.1655 23.3693L37.3984 23.4974L44.1238 27.1838L60.7915 18.0603C61.6794 17.5763 63.0769 17.5906 63.9212 18.0887C64.6927 18.53 64.7509 19.2416 64.0813 19.7113Z"
                fill="url(#paint3_linear_601_90-527050)"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
                <path
                    d="M44.7922 28.0468C37.4598 32.2664 22.3324 27.7132 15 23.5L29.5 11C40.5 11 52.1055 23.8399 44.7922 28.0468Z"
                    fill="url(#paint4_radial_601_90-508102)"
                    fillOpacity="0.5"
                />
            </g>
            <path
                d="M80.9383 24.8212V29.5609C81.0256 30.4576 80.6763 31.3543 79.8611 32.1513L68.099 43.6518C66.6579 45.0609 63.9649 45.9292 61.0389 45.9292L37.3257 45.8722C34.4288 45.8722 31.7358 44.9898 30.2946 43.5807L18.3434 32.0232C17.6447 31.34 17.2807 30.5857 17.2516 29.8455V24.5081C17.2953 25.2624 17.6592 26.0026 18.3434 26.6715L18.7364 27.0558L30.2946 38.2432C31.7358 39.6523 34.4288 40.5347 37.3257 40.5347L61.0389 40.5917C63.9649 40.5917 66.6579 39.7234 68.099 38.3144L79.468 27.1839L79.8611 26.7996C80.5016 26.1734 80.8509 25.5044 80.9383 24.8212Z"
                fill="url(#paint5_linear_601_90-569648)"
            />
            <path
                d="M42.8124 2.34961V5.77247C42.8166 5.81515 42.8166 5.85815 42.8124 5.90083C42.6475 6.70129 42.113 7.37498 41.3732 7.71495L32.4568 12.8492C30.622 13.8799 28.4088 13.972 26.4955 13.0974L26.0867 12.8578C25.5332 12.601 25.148 12.0785 25.0648 11.4715C25.0606 11.4346 25.0606 11.3973 25.0648 11.3603V7.96311C25.1072 8.61502 25.5032 9.19058 26.0952 9.46061L26.3422 9.60608L26.504 9.70021C26.9456 9.9479 27.4269 10.1159 27.9262 10.1965C29.4713 10.4546 31.0579 10.1929 32.4397 9.45205L38.8779 5.72113L41.3647 4.28353C41.4302 4.24865 41.4928 4.20859 41.552 4.16373C42.2517 3.81359 42.7264 3.13027 42.8124 2.34961Z"
                fill="url(#paint6_linear_601_90-649949)"
            />
            <path
                d="M26.0101 12.3701V15.8358C26.0143 15.8756 26.0143 15.9157 26.0101 15.9556C25.8815 16.7089 25.3905 17.3496 24.6986 17.667C22.6982 18.694 20.3289 18.694 18.3285 17.667C17.6365 17.3496 17.1456 16.7089 17.017 15.9556C17.0128 15.9157 17.0128 15.8756 17.017 15.8358V12.3701C17.0841 13.0981 17.5137 13.7423 18.1582 14.0815L18.337 14.1928C19.0335 14.5722 19.7974 14.8106 20.5853 14.8945C21.2074 14.9715 21.8367 14.9715 22.4588 14.8945C23.2467 14.8106 24.0106 14.5722 24.7071 14.1928L24.8859 14.0815C25.5239 13.738 25.9465 13.0946 26.0101 12.3701Z"
                fill="url(#paint7_linear_601_90-602867)"
            />
            <path
                d="M42.8124 2.34969C42.7264 3.13036 42.2517 3.81368 41.552 4.16381C41.4928 4.20868 41.4302 4.24874 41.3646 4.28361L38.8864 5.72121L32.4483 9.41791C31.0664 10.1588 29.4798 10.4205 27.9347 10.1624C27.4354 10.0817 26.9541 9.91375 26.5125 9.66606L26.3507 9.57194L26.1037 9.42646C25.5219 9.16223 25.1277 8.60248 25.0733 7.96319C25.1729 7.09245 25.7284 6.34174 26.5295 5.99504L35.4459 0.860748C37.2805 -0.172622 39.4964 -0.261683 41.4072 0.621147L41.816 0.852191C42.3935 1.13317 42.7747 1.70603 42.8124 2.34969Z"
                fill="#F4C0C0"
            />
            <path
                d="M26.0101 12.37C25.943 13.098 25.5134 13.7423 24.8689 14.0815L24.6901 14.1927C23.9936 14.5722 23.2297 14.8106 22.4418 14.8944C21.8196 14.9714 21.1904 14.9714 20.5683 14.8944C19.7804 14.8106 19.0165 14.5722 18.32 14.1927L18.1412 14.0815C17.4966 13.7423 17.0671 13.098 17 12.37C17.0731 11.5587 17.5785 10.8511 18.32 10.5217C20.3204 9.49472 22.6897 9.49472 24.6901 10.5217C25.4316 10.8511 25.9369 11.5587 26.0101 12.37Z"
                fill="#F2B7B7"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_601_90-995151"
                    x1="17.4411"
                    y1="1623.76"
                    x2="6387.54"
                    y2="1623.76"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BABABA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_601_90-039314"
                    x1="861.99"
                    y1="1003.6"
                    x2="4284.33"
                    y2="1596.79"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2A62DB" />
                    <stop offset="1" stopColor="#DAE6FB" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_601_90-623839"
                    x1="34"
                    y1="24"
                    x2="43"
                    y2="28.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4E7BEC" />
                    <stop offset="1" stopColor="#7DA2F9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_601_90-527050"
                    x1="19.5"
                    y1="24"
                    x2="79.5"
                    y2="26"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEFEFF" />
                    <stop offset="1" stopColor="#98B6FA" />
                </linearGradient>
                <radialGradient
                    id="paint4_radial_601_90-508102"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(30.7663 18.0818) rotate(90) scale(11.1011 17.9433)"
                >
                    <stop stopColor="#E75048" />
                    <stop offset="0.677083" stopColor="#D9D9D9" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint5_linear_601_90-569648"
                    x1="22"
                    y1="35"
                    x2="79"
                    y2="33"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CEDDFC" />
                    <stop offset="1" stopColor="#4E7BEB" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_601_90-649949"
                    x1="45.816"
                    y1="573.968"
                    x2="538.044"
                    y2="573.968"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EA7674" />
                    <stop offset="1" stopColor="#E75048" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_601_90-602867"
                    x1="46.2888"
                    y1="317.006"
                    x2="312.841"
                    y2="317.006"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EA7674" />
                    <stop offset="1" stopColor="#E75048" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default SubmittalErrorIcon;
