import { DefaultButton, ITag, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { SendAndFileHelpers } from "../../helpers/SendAndFile/SendAndFileHelpers";
import useSendAndFileHook from "../../hooks/useSendAndFileHook";
import { AppPage } from "../../models/AppPage";
import { IHub } from "../../models/Hub";
import { ToastMessage } from "../../models/ToastMessage";
import { BimProjectsApiService } from "../../services/BimApi/BimProjectsApiService";
import { ConfigurationService } from "../../services/ConfigurationService";
import { Logger } from "../../services/Logger";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import { StorageWrapper } from "../../services/StorageWrapper";
import RenderAutoSuggestedLabel from "./AutoSuggestedLabel";
import "./SendAndFileComponent.less";

export interface SendAndFileComponentProps extends LocalizeContextProps {
    sendAndFileHelpers: SendAndFileHelpers;
    smartFilingManager: SmartFilingManager;
    officeWrapper: OfficeWrapper;
    logger: Logger;
    bimProjectsApiService: BimProjectsApiService;
    storageWrapper: StorageWrapper;
    selectedHub: IHub | null;
    configService: ConfigurationService;
    selectedProject: ITag | null;
    onProjectSelected: (selectedProject: ITag | null) => ITag | null;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    onSetNavigationPage: (page: AppPage) => void;
    onExpiredSession: () => void;
}

function SendAndFileComponent(props: SendAndFileComponentProps) {
    const { suggestedProjects, isFiling, actions, isSubmitDisabled } = useSendAndFileHook(props);

    const { handleSubmitSend, isFormValid } = actions;

    return (
        <div className="newforma-sendAndFileComponent">
            <div className="newforma-sendAndFileContainer">
                <RenderAutoSuggestedLabel suggestedProjects={suggestedProjects} />
                <DefaultButton
                    className="newforma-send-and-file-submitButton"
                    id="fileMultipleEmailsButton"
                    data-testid="newforma-send-and-file-submitButton"
                    primary={true}
                    onClick={handleSubmitSend}
                    text={props.translate("SEND_AND_FILE.BUTTON_LABEL") as string}
                    disabled={!isFormValid() || isFiling || isSubmitDisabled}
                />
            </div>
        </div>
    );
}

export default withLocalize(SendAndFileComponent);
