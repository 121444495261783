import { useEffect, useMemo, useState } from "react";
import { OfficeWrapper } from "../services/OfficeWrapper";
import { DateHelpers } from "../helpers/DateHelpers";
import { IHub } from "../models/Hub";
import { IContextualMenuProps, IDropdownOption, IPersonaProps, ITag, MessageBarType } from "office-ui-fabric-react";
import { TranslateFunction } from "react-localize-redux";
import { EmailApiService } from "../services/NewformaApi/EmailApiService";
import { RelatedItemApiService } from "../services/PimTrackApi/RelatedItemApiService";
import { OfficeRoamingSettings } from "../services/OfficeRoamingSettings";
import { SupportedAddinItems } from "../models/SupportedItems";
import { useWorkflowItem } from "../components/shared/itemSuccess/ItemContext";
import { ContactMapping } from "../mapping/ContactMapping";
import { unionBy } from "lodash";
import { BimProjectsApiService } from "../services/BimApi/BimProjectsApiService";
import {
    defaultClearedLogIssueFieldForm,
    LogIssueFieldForm,
    ValidLogIssueFieldForm,
} from "../models/workflow/issue/LogIssue";
import { BimProjectCustomAttribute } from "../models/Bimtrack/BimProjectCustomAttribute";
import { BimProjectAttributesMapping } from "../mapping/BimProjectAttributesMapping";
import { BimProjectSystemAttributeName } from "../models/Bimtrack/BimProjectSystemAttributeName";
import { BimProjectSystemAttributeType } from "../models/Bimtrack/BimProjectSystemAttributeType";
import { BimProjectCustomAttributeType } from "../models/Bimtrack/BimProjectCustomAttributeType";
import { SuccessInfo } from "../components/shared/itemSuccess/ItemSuccessComponent";
import { ProjectItemFeature } from "../services/PimTrackApi/types";
import { IssueApiService } from "../services/BimApi/IssueApiService";
import { LogIssueMapping } from "../mapping/LogIssueMapping";
import { Common } from "@newforma/platform-client-api-sdk";
import { RelatedItemMapping } from "../mapping/RelatedItemMapping";
import { ToastMessage } from "../models/ToastMessage";
import { FileWithId } from "../models/shared/FileWithId";
import { useAppService } from "../services/AppServiceProvider";
import { pluralizeSentence } from "../utils/helpers";

interface CurrentOptionCustomValue {
    id: number;
    currentOption: IDropdownOption;
}

export interface CustomAttributeValue {
    ProjectAttributeId: number;
    Value?: string;
    ProjectAttributeValueIds?: number[];
}

interface UseLogIssueProps {
    officeWrapper: OfficeWrapper;
    dateHelpers: DateHelpers;
    bimProjectsApiService: BimProjectsApiService;
    selectedHub: IHub | null;
    selectedProject: ITag | null;
    translate: TranslateFunction;
    onShowToast: (toastMessage: ToastMessage | null, toastType: MessageBarType) => void;
    showProgress: (message: string | null) => void;
    emailApiService: EmailApiService;
    relatedItemApiService: RelatedItemApiService;
    officeRoamingSettings: OfficeRoamingSettings;
    supportedAddinItems: SupportedAddinItems | null;
    issueApiService: IssueApiService;
    navigateBackToForm?: () => void;
}

const maxAllowedFile = 100;

const useLogIssue = (props: UseLogIssueProps) => {
    const {
        services: { msGraphApiService },
    } = useAppService();
    const [logIssueForm, setLogIssueForm] = useState<LogIssueFieldForm>(defaultClearedLogIssueFieldForm);
    const [isValueListLoading, setIsValueListLoading] = useState<boolean>(true);
    const [priorityLabel, setPriorityLabel] = useState<string>(props.translate("ISSUE.PRIORITY.LABEL") as string);
    const [disciplineLabel, setDisciplineLabel] = useState<string>(
        props.translate("SHARED.ATTRIBUTES.DISCIPLINE.LABEL") as string
    );
    const [disciplinePlaceholderLabel, setDisciplinePlaceholderLabel] = useState<string>(
        props.translate("SHARED.ATTRIBUTES.DISCIPLINE.PLACEHOLDER_DEFAULT") as string
    );
    const [zoneLabel, setZoneLabel] = useState<string>(props.translate("ISSUE.ZONE.LABEL") as string);
    const [phaseLabel, setPhaseLabel] = useState<string>(props.translate("ISSUE.PHASE.LABEL") as string);
    const [teamLabel, setTeamLabel] = useState<string>(
        props.translate("SHARED.ATTRIBUTES.TEAMS_INVOLVED.LABEL") as string
    );
    const [teamPlaceholderLabel, setTeamPlaceholderLabel] = useState<string>(
        props.translate("SHARED.ATTRIBUTES.TEAMS_INVOLVED.PLACEHOLDER_DEFAULT") as string
    );
    const [notifyLabel, setNotifyLabel] = useState<string>(props.translate("SHARED.ATTRIBUTES.NOTIFY.LABEL") as string);
    const [notifyPlaceholderLabel, setNotifyPlaceholderLabel] = useState<string>(
        props.translate("SHARED.ATTRIBUTES.NOTIFY.PLACEHOLDER_DEFAULT") as string
    );
    const [statusOptions, setStatusOptions] = useState<IDropdownOption[]>([]);
    const [typeOptions, setTypeOptions] = useState<IDropdownOption[]>([]);
    const [priorityOptions, setPriorityOptions] = useState<IDropdownOption[]>([]);
    const [disciplineOptions, setDisciplineOptions] = useState<ITag[]>([]);
    const [zoneOptions, setZoneOptions] = useState<IDropdownOption[]>([]);
    const [phaseOptions, setPhaseOptions] = useState<IDropdownOption[]>([]);
    const [userOptions, setUserOptions] = useState<IPersonaProps[]>([]);
    const [groupOptions, setGroupOptions] = useState<ITag[]>([]);
    const [teamsInvolvedOptions, setTeamsInvolvedOptions] = useState<ITag[]>([]);
    const [isFiling, setIsFiling] = useState<boolean>(false);
    const isProjectSelected = !!props.selectedHub && !!props.selectedProject;
    const [attachmentSessionIds, setAttachmentSessionIds] = useState<string[]>([]);
    const [isLoadingProjectFail, setIsLoadingProjectFail] = useState<boolean>(false);
    const [customAttributes, setCustomAttributes] = useState<BimProjectCustomAttribute[]>([]);
    const [customAttributeValues, setCustomAttributeValues] = useState<CustomAttributeValue[]>([]);
    const [currentOptionCustomValues, setCurrentOptionCustomValues] = useState<CurrentOptionCustomValue[]>([]);
    const {
        actions: { setSuccessInfo, setIsEmailFromDeletion },
    } = useWorkflowItem();

    useEffect(() => {
        if (!isFiling) {
            if (isProjectSelected) {
                setIsValueListLoading(true);
                props.showProgress(props.translate("SHARED.LOAD_PROJECT_MESSAGE") as string);
                initialize()
                    .catch(() => {
                        setIsLoadingProjectFail(true);
                        props.onShowToast(
                            { message: props.translate("SHARED.LOAD_PROJECT_ERROR_MESSAGE") as string },
                            MessageBarType.error
                        );
                    })
                    .finally(() => {
                        props.showProgress(null);
                        setIsValueListLoading(false);
                    });
            } else {
                clearForm();
            }
        }
    }, [isProjectSelected, props.officeWrapper.currentContextItem.itemId]);

    const modifyCustomAttribute = (
        attribute: BimProjectCustomAttribute,
        newValue: (string | undefined) | (IDropdownOption | undefined)
    ) => {
        // first we update the array we'll pass to the backend when logging the Issue
        const itemIndex = customAttributeValues.findIndex((x) => x.ProjectAttributeId === attribute.Id);
        const itemsCopy = customAttributeValues;
        const itemToUpdate = customAttributeValues[itemIndex];
        if (attribute.Type === BimProjectCustomAttributeType.Text) {
            itemToUpdate.Value = newValue as string;
        } else {
            itemToUpdate.ProjectAttributeValueIds = [(newValue as IDropdownOption).key as number];
        }
        itemsCopy[itemIndex] = itemToUpdate;
        setCustomAttributeValues(itemsCopy);

        // then we update the selected value if attribute is a custom dropdown
        if (attribute.Type === BimProjectCustomAttributeType.Predefined) {
            const optionIndex = currentOptionCustomValues.findIndex((x) => x.id === attribute.Id);
            const optionsCopy = [...currentOptionCustomValues];
            const optionToUpdate = optionsCopy[itemIndex];
            optionToUpdate.currentOption = newValue as IDropdownOption;
            optionsCopy[optionIndex] = optionToUpdate;
            setCurrentOptionCustomValues(optionsCopy);
        }
    };

    const clearForm = () => {
        setLogIssueForm(defaultClearedLogIssueFieldForm);
        setAttachmentSessionIds([]);
        setCustomAttributes([]);
        setCustomAttributeValues([]);
        setCurrentOptionCustomValues([]);

        setPriorityLabel(props.translate("ISSUE.PRIORITY.LABEL") as string);
        setDisciplineLabel(props.translate("SHARED.ATTRIBUTES.DISCIPLINE.LABEL") as string);
        setDisciplinePlaceholderLabel(props.translate("SHARED.ATTRIBUTES.DISCIPLINE.PLACEHOLDER_DEFAULT") as string);
        setZoneLabel(props.translate("ISSUE.ZONE.LABEL") as string);
        setPhaseLabel(props.translate("ISSUE.PHASE.LABEL") as string);
        setTeamLabel(props.translate("SHARED.ATTRIBUTES.TEAMS_INVOLVED.LABEL") as string);
        setTeamPlaceholderLabel(props.translate("SHARED.ATTRIBUTES.TEAMS_INVOLVED.PLACEHOLDER_DEFAULT") as string);
        setNotifyLabel(props.translate("SHARED.ATTRIBUTES.NOTIFY.LABEL") as string);
        setNotifyPlaceholderLabel(props.translate("SHARED.ATTRIBUTES.NOTIFY.PLACEHOLDER_DEFAULT") as string);
    };

    const getProjectIssuesDetail = async () =>
        props.bimProjectsApiService.getProjectIssuesAttributes(props.selectedHub, props.selectedProject);

    const initialize = async () => {
        const projectIssuesDetail = await getProjectIssuesDetail();

        const noneOption = {
            Color: props.translate("RFI.NO_OPTION") as string,
            Name: props.translate("RFI.NO_OPTION") as string,
            Id: -1,
        };

        // Add no option
        projectIssuesDetail.Zones?.push(noneOption);
        projectIssuesDetail.Phases?.push(noneOption);

        const mapperUser =
            projectIssuesDetail.Users.map(ContactMapping.mapUserResponseToIPersonaProps).sort((contact1, contact2) =>
                (contact1.text || "").localeCompare(contact2.text || "")
            ) || [];

        const mappedStatuses =
            projectIssuesDetail.Statuses?.map(BimProjectAttributesMapping.mapBimStatusResponseToIDropdownOption) || [];
        const mappedPriorities =
            projectIssuesDetail.Priorities?.map(
                BimProjectAttributesMapping.mapBimPrioritiesResponseToIDropdownOption
            ) || [];
        const mappedTypes =
            projectIssuesDetail.Types?.map(
                BimProjectAttributesMapping.mapBimProjectAttributeWithColorToIDropdownOption
            ) || [];

        const mappedZones =
            projectIssuesDetail.Zones?.map(
                BimProjectAttributesMapping.mapBimProjectAttributeWithColorToIDropdownOption
            ) || [];
        const mappedPhases =
            projectIssuesDetail.Phases?.map(
                BimProjectAttributesMapping.mapBimProjectAttributeWithColorToIDropdownOption
            ) || [];

        setStatusOptions(mappedStatuses);
        setTypeOptions(mappedTypes);
        setPriorityOptions(mappedPriorities);
        setDisciplineOptions(
            projectIssuesDetail.Disciplines?.map(BimProjectAttributesMapping.mapBimProjectAttributeToITag) || []
        );
        setZoneOptions(mappedZones);
        setPhaseOptions(mappedPhases);
        setUserOptions(mapperUser);
        setGroupOptions(projectIssuesDetail.IssuesGroups?.map(BimProjectAttributesMapping.mapBimGroupToITag) || []);
        setTeamsInvolvedOptions(
            projectIssuesDetail.Teams?.map(BimProjectAttributesMapping.mapBimProjectAttributeToITag) || []
        );

        const attachmentsNoCloud = props.officeWrapper
            .getCurrentEmailAttachmentDetails()
            .filter((attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud);
        const attachmentIds = attachmentsNoCloud.map((attachment) => attachment.id);

        const title = await props.officeWrapper.getCurrentEmailSubject();
        const description = await props.officeWrapper.getCurrentEmailBody();
        const currentEmailFromOfficeWrapper = props.officeWrapper.userProfileEmailAddress;
        const toFromOfficeWrapper = props.officeWrapper
            .getCurrentEmailToRecipients()
            .map((recipient) => recipient.emailAddress.toLowerCase());
        const ccFromOfficeWrapper = props.officeWrapper
            .getCurrentEmailCcRecipients()
            .map((recipient) => recipient.emailAddress.toLowerCase());
        const assignedTo = currentEmailFromOfficeWrapper
            ? [
                  mapperUser.find(
                      (personaProp) =>
                          personaProp.text === currentEmailFromOfficeWrapper ||
                          personaProp.secondaryText === currentEmailFromOfficeWrapper
                  ) ||
                      <IPersonaProps>{
                          text: currentEmailFromOfficeWrapper,
                          key: currentEmailFromOfficeWrapper,
                          id: currentEmailFromOfficeWrapper,
                      },
              ]
            : [];
        const matchingToUsers = mapperUser.filter((personaProp) =>
            toFromOfficeWrapper.some((email) => email === personaProp.text || email === personaProp.secondaryText)
        );
        const matchingCcUsers = mapperUser.filter((personaProp) =>
            ccFromOfficeWrapper.some((email) => email === personaProp.text || email === personaProp.secondaryText)
        );
        const notify = unionBy(matchingCcUsers, matchingToUsers, "text");

        const dueDate = props.dateHelpers.getDateWithOffset(
            props.officeWrapper.getCurrentEmailDate(),
            projectIssuesDetail.ProjectSettings.DefaultDueDateDays
        );

        const defaultZoneKey = projectIssuesDetail.ProjectSettings.DefaultProjectZoneId;
        const defaultPhaseKey = projectIssuesDetail.ProjectSettings.DefaultProjectPhaseId;

        setLogIssueForm(() => ({
            ...defaultClearedLogIssueFieldForm,
            title,
            description,
            status: mappedStatuses.find(
                (status) => status.key === projectIssuesDetail.ProjectSettings.DefaultProjectIssueStatusId
            ),
            priority: mappedPriorities.find(
                (priority) => priority.key === projectIssuesDetail.ProjectSettings.DefaultProjectIssuePriorityId
            ),
            type: mappedTypes.find(
                (type) => type.key === projectIssuesDetail.ProjectSettings.DefaultProjectIssueTypeId
            ),
            zone: defaultZoneKey ? mappedZones.find((zone) => zone.key === defaultZoneKey) : undefined,
            phase: defaultPhaseKey ? mappedPhases.find((phase) => phase.key === defaultPhaseKey) : undefined,
            assignedTo,
            dueDate,
            notify,
            selectedAttachmentIds: attachmentIds,
            attachments: attachmentsNoCloud,
        }));

        projectIssuesDetail.ProjectSystemAttributeNames.map((systemAttributeName) => {
            setLabelForProjectAttribute(systemAttributeName);
        });

        setCustomAttributes(projectIssuesDetail.ProjectCustomAttributes);

        const currentOptionValues: CurrentOptionCustomValue[] = [];
        const attributeValues: CustomAttributeValue[] = [];
        projectIssuesDetail.ProjectCustomAttributes.map((customAtt) => {
            if (customAtt.Type === BimProjectCustomAttributeType.Text) {
                attributeValues.unshift({ ProjectAttributeId: customAtt.Id, Value: "" });
            }
        });
        projectIssuesDetail.ProjectSettings.DefaultProjectCustomAttributes.map((defaultCustomAttributeValue) => {
            const attribute = projectIssuesDetail.ProjectCustomAttributes.find(
                (x) => x.Id === defaultCustomAttributeValue.CustomAttributeId
            );
            const attributeValue = attribute?.ProjectCustomAttributeValues?.find(
                (x) => x.Id === defaultCustomAttributeValue.CustomAttributeValueId
            );
            if (attribute && attributeValue) {
                const option =
                    BimProjectAttributesMapping.mapBimProjectAttributeWithColorToIDropdownOption(attributeValue);
                currentOptionValues.unshift({ id: attribute.Id, currentOption: option });
                attributeValues.unshift({
                    ProjectAttributeId: attribute.Id,
                    ProjectAttributeValueIds: [attributeValue.Id],
                });
            }
        });
        setCurrentOptionCustomValues(currentOptionValues);
        setCustomAttributeValues(attributeValues);
    };

    const setLabelForProjectAttribute = (systemAttributeName: BimProjectSystemAttributeName) => {
        const languageCode = props.officeWrapper.getClientDisplayLanguage().slice(0, 2);
        const getAttributeTranslation = (defaultTransKey: string): string => {
            const translation = systemAttributeName.Translations.find((trans) => trans.Language === languageCode);

            if (!translation) {
                return props.translate(defaultTransKey) as string;
            }

            return translation.Name;
        };

        switch (systemAttributeName.Type) {
            case BimProjectSystemAttributeType.Discipline: {
                const translation = getAttributeTranslation("SHARED.ATTRIBUTES.DISCIPLINE.LABEL");
                setDisciplineLabel(translation);
                setDisciplinePlaceholderLabel(
                    props.translate("SHARED.ATTRIBUTES.DISCIPLINE.PLACEHOLDER_CUSTOM", {
                        attributeName: translation.toLowerCase(),
                    }) as string
                );
                break;
            }
            case BimProjectSystemAttributeType.Priority: {
                const translation = getAttributeTranslation("ISSUE.PRIORITY.LABEL");
                setPriorityLabel(translation);
                break;
            }
            case BimProjectSystemAttributeType.Zone: {
                const translation = getAttributeTranslation("ISSUE.ZONE.LABEL");
                setZoneLabel(translation);
                break;
            }
            case BimProjectSystemAttributeType.Phase: {
                const translation = getAttributeTranslation("ISSUE.PHASE.LABEL");
                setPhaseLabel(translation);
                break;
            }
            case BimProjectSystemAttributeType.Team: {
                const translation = getAttributeTranslation("SHARED.ATTRIBUTES.TEAMS_INVOLVED.LABEL");
                setTeamLabel(translation);
                setDisciplinePlaceholderLabel(
                    props.translate("SHARED.ATTRIBUTES.TEAMS_INVOLVED.PLACEHOLDER_CUSTOM", {
                        attributeName: translation.toLowerCase(),
                    }) as string
                );
                break;
            }
            case BimProjectSystemAttributeType.Notify: {
                const translation = getAttributeTranslation("SHARED.ATTRIBUTES.NOTIFY.LABEL");
                setNotifyLabel(translation);
                setDisciplinePlaceholderLabel(
                    props.translate("SHARED.ATTRIBUTES.NOTIFY.PLACEHOLDER_CUSTOM", {
                        attributeName: translation.toLowerCase(),
                    }) as string
                );
                break;
            }
        }
    };

    const submitLogIssue = async ({ logNewItem = false }) => {
        const steps: SuccessInfo["steps"] = [];
        let issueId: string | undefined;
        let issueNumber: number | undefined;
        let emailDeleted = false;
        const internetMessageId = props.officeWrapper.currentContextItem.internetMessageId;
        const currentMessageId = props.officeWrapper.getCurrentMessageId();

        const selectedFiles = logIssueForm.attachments.filter((x) => logIssueForm.selectedAttachmentIds.includes(x.id));
        if (!initialRequirementsMet(selectedFiles)) {
            return;
        }

        setIsFiling(true);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        const hasSelectedAttachment = !!selectedFiles.length;
        const totalSteps = hasSelectedAttachment ? 4 : 3;
        let stepCounter = 0;

        try {
            props.showProgress(
                props.translate("ISSUE.PROGRESS.CREATING_ISSUE", {
                    totalSteps: totalSteps,
                    step: ++stepCounter,
                }) as string
            );

            const issue = await props.issueApiService.logIssue(
                props.selectedHub as IHub,
                props.selectedProject as ITag,
                LogIssueMapping.toRequest(logIssueForm, customAttributeValues)
            );
            issueId = issue.Id;
            issueNumber = issue.Number;

            steps.push({
                isSuccess: true,
                message: props.translate("ISSUE.SUCCESS.LOG_ISSUE_PARTIAL") as string,
            });
        } catch (e) {
            handleLogIssueError(e);

            setIsFiling(false);
            props.showProgress(null);

            return;
        }

        if (hasSelectedAttachment) {
            await handleAttachmentsForIssue(totalSteps, ++stepCounter, selectedFiles, issueId);
        }

        const emailFiled = await fileEmailSucceeded(totalSteps, ++stepCounter, currentMessageId, logNewItem, steps);

        if (emailFiled) {
            await linkIssueToEmailStep(steps, totalSteps, ++stepCounter, issueId as string, internetMessageId);
            emailDeleted = await deleteEmailAfterFilingStep(logNewItem, currentMessageId);
        }

        setIsFiling(false);
        displaySuccessStep(logNewItem, emailDeleted, issueNumber as number, steps);
    };

    const validateLogIssueFieldForm = (form: LogIssueFieldForm): form is ValidLogIssueFieldForm => isLogIssueFormValid;

    const isLogIssueFormValid = useMemo((): boolean => !!logIssueForm.title.trim(), [logIssueForm]);

    const initialRequirementsMet = (selectedFiles: (Office.AttachmentDetails | FileWithId)[]): boolean => {
        if (!validateLogIssueFieldForm(logIssueForm) || !(props.selectedHub && props.selectedProject)) {
            return false;
        }

        if (logIssueForm.selectedAttachmentIds.length > maxAllowedFile) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.TOO_MANY_FILES") as string },
                MessageBarType.error
            );
            return false;
        }

        if (new Set(selectedFiles.map(({ name }) => name.toLowerCase())).size < selectedFiles.length) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.DUPLICATE_NAME") as string },
                MessageBarType.error
            );
            return false;
        }

        return true;
    };

    const handleAttachmentsForIssue = async (
        totalSteps: number,
        stepCounter: number,
        selectedFiles: (Office.AttachmentDetails | FileWithId)[],
        issueId: string
    ) => {
        const progressMessageTemplate = props.translate("SHARED.PROGRESS.FILE_UPLOADING", {
            totalSteps: totalSteps,
            step: stepCounter,
        }) as string;

        const progressMessage = pluralizeSentence(
            progressMessageTemplate,
            "ATTACHMENT",
            selectedFiles.length,
            props.translate
        );

        props.showProgress(progressMessage);

        const attachmentSessionIdList: string[] = attachmentSessionIds.length
            ? attachmentSessionIds
            : await props.issueApiService.createIssueAttachments(
                  props.selectedHub as IHub,
                  props.selectedProject as ITag,
                  issueId,
                  selectedFiles
              );

        setAttachmentSessionIds(attachmentSessionIdList);
    };

    const handleLogIssueError = (e: any) => {
        if (e.status === 403) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.NOT_AUTHORIZED") as string },
                MessageBarType.error
            );
        } else if (e.status === 400 && e.responseText.includes(Common.CommonErrorCode.INVALID_EMAIL)) {
            props.onShowToast(
                { message: props.translate("SHARED.ERRORS.INVALID_EMAIL") as string },
                MessageBarType.error
            );
        } else {
            setAttachmentSessionIds([]);
            props.onShowToast(
                { message: props.translate("ISSUE.ERRORS.LOG_ISSUE_FAILED_GENERIC") as string },
                MessageBarType.error
            );
        }
    };

    const fileEmailSucceeded = async (
        totalSteps: number,
        stepCounter: number,
        currentMessageId: string,
        logNewItem: boolean,
        steps: { isSuccess: boolean; message: string }[]
    ) => {
        props.showProgress(
            props.translate("SHARED.PROGRESS.FILING_EMAIL", {
                totalSteps: totalSteps,
                step: stepCounter,
            }) as string
        );

        try {
            await props.emailApiService.fileEmail(
                currentMessageId,
                props.selectedProject as ITag,
                props.selectedHub as IHub
            );
        } catch (e) {
            steps.push(
                { isSuccess: false, message: props.translate("SHARED.ERRORS.EMAIL_NOT_FILED") as string },
                {
                    isSuccess: false,
                    message: props.translate("ISSUE.ERRORS.ISSUE_LINKING_TO_EMAIL") as string,
                }
            );
            return false;
        }

        return true;
    };

    const linkIssueToEmailStep = async (
        steps: { isSuccess: boolean; message: string }[],
        totalSteps: number,
        stepCounter: number,
        issueId: string,
        internetMessageId: string
    ) => {
        steps.push({ isSuccess: true, message: props.translate("SHARED.SUCCESS.EMAIL_FILED") as string });

        try {
            props.showProgress(
                props.translate("ISSUE.PROGRESS.LINKING_ISSUE_TO_EMAIL", {
                    totalSteps: totalSteps,
                    step: stepCounter,
                }) as string
            );
            await props.relatedItemApiService.linkItems(
                props.selectedHub as IHub,
                props.selectedProject as ITag,
                {
                    id: issueId,
                    type: Common.ProjectItemType.Issue,
                },
                RelatedItemMapping.mapToRelatedItemRequest([
                    {
                        id: internetMessageId,
                        type: Common.ProjectItemType.Email,
                    },
                ])
            );
        } catch (e) {
            steps.push({
                isSuccess: false,
                message: props.translate("ISSUE.ERRORS.ISSUE_LINKING_TO_EMAIL") as string,
            });
        }
    };

    const deleteEmailAfterFilingStep = async (logNewItem: boolean, currentMessageId: string) => {
        try {
            if (
                !logNewItem &&
                props.supportedAddinItems?.canDeleteEmail &&
                props.officeRoamingSettings.getDeleteEmailAfterFiling()
            ) {
                await msGraphApiService.moveMessage(currentMessageId);
                setIsEmailFromDeletion(true);
                return true;
            }
        } catch (e) {
            console.error("Error while moving email to bin");
        }

        return false;
    };

    const displaySuccessStep = (
        logNewItem: boolean,
        emailDeleted: boolean,
        issueNumber: number,
        steps: { isSuccess: boolean; message: string }[]
    ) => {
        props.showProgress(null);

        if (logNewItem && steps.every(({ isSuccess }) => isSuccess)) {
            props.onShowToast(
                {
                    message: props.translate("ISSUE.SUCCESS.LOG_ISSUE") as string,
                    link: props.issueApiService.getIssueUrl(
                        issueNumber,
                        props.selectedHub as IHub,
                        props.selectedProject as ITag
                    ),
                    linkText: props.translate("SHARED.OPEN_IN_BIMTRACK") as string,
                },
                MessageBarType.success
            );
        } else {
            setSuccessInfo({
                message: steps.every(({ isSuccess }) => isSuccess)
                    ? (props.translate("ISSUE.SUCCESS.LOG_ISSUE") as string)
                    : (props.translate("ISSUE.ERRORS.LOG_ISSUE_PARTIAL_FAILED") as string),
                redirectUrl: props.issueApiService.getIssueUrl(
                    issueNumber,
                    props.selectedHub as IHub,
                    props.selectedProject as ITag
                ),
                projectItemFeature: ProjectItemFeature.Issues,
                steps,
                navigateBackToForm: props.navigateBackToForm,
                displayNewButton: !emailDeleted,
            });
        }
    };

    const updateField = (updatedField: Partial<LogIssueFieldForm>) => {
        setLogIssueForm((prevState) => ({ ...prevState, ...updatedField }));
    };

    const getIDropdownOptionsForCustomAttribute = (attribute: BimProjectCustomAttribute): IDropdownOption[] =>
        attribute.ProjectCustomAttributeValues
            ? attribute.ProjectCustomAttributeValues?.map(
                  BimProjectAttributesMapping.mapBimProjectAttributeWithColorToIDropdownOption
              )
            : [];

    const subMenuOptions = useMemo(
        (): IContextualMenuProps => ({
            items: [
                {
                    key: props.translate("SHARED.SUBMIT_BUTTON.LOG_AND_FILE_ANOTHER") as string,
                    text: props.translate("SHARED.SUBMIT_BUTTON.LOG_AND_FILE_ANOTHER") as string,
                    onClick: () => {
                        submitLogIssue({ logNewItem: true });
                    },
                },
            ],
            calloutProps: {
                className: "splitMenuPropStyle",
            },
        }),
        [props.translate, logIssueForm]
    );

    return {
        logIssueForm,
        updateField,
        priorityLabel,
        disciplineLabel,
        disciplinePlaceholderLabel,
        zoneLabel,
        phaseLabel,
        teamLabel,
        teamPlaceholderLabel,
        notifyLabel,
        notifyPlaceholderLabel,
        statusOptions,
        typeOptions,
        priorityOptions,
        disciplineOptions,
        zoneOptions,
        phaseOptions,
        userOptions,
        groupOptions,
        teamsInvolvedOptions,
        customAttributes,
        getIDropdownOptionsForCustomAttribute,
        modifyCustomAttribute,
        currentOptionCustomValues,
        isLogIssueFormValid,
        submitLogIssue,
        isFieldDisabled: isFiling || !isProjectSelected || isValueListLoading || isLoadingProjectFail,
        subMenuOptions,
    };
};

export default useLogIssue;
