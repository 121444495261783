import * as React from "react";
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import { ToastMessage } from "../../../models/ToastMessage";
import { MessageBarType } from "office-ui-fabric-react";
import { useAppState } from "../../../store/AppProvider";
import { useAppService } from "../../../services/AppServiceProvider";
import { INFO_TOAST_KEY } from "../../../utils/constants";

export interface ToastDataContextProps {
    toastMessage: ToastMessage | null;
    toastType: MessageBarType | null;
}

export interface ToastActionsContextProps {
    showToast: (message: ToastMessage | null, type: MessageBarType) => void;
    dismissToast: () => void;
}

export const ToastDataContext = createContext<ToastDataContextProps>({
    toastMessage: null,
    toastType: null,
});

export const ToastActionsContext = createContext<ToastActionsContextProps>({
    showToast: () => {},
    dismissToast: () => {},
});

export default function ToastProvider({ children }: PropsWithChildren<unknown>) {
    const [toastMessage, setToastMessage] = useState<ToastMessage | null>(null);
    const [toastType, setToastType] = useState<MessageBarType | null>(null);

    const {
        appState: { mailboxItem },
    } = useAppState();
    const {
        services: { officeNotificationService },
    } = useAppService();

    const dismissToast = useCallback((): void => {
        setToastMessage(null);
        setToastType(null);
        officeNotificationService.dismissInfoToast(mailboxItem, INFO_TOAST_KEY);
    }, [officeNotificationService, mailboxItem]);

    const showToast = useCallback(
        (message: ToastMessage | null, type: MessageBarType): void => {
            if (!message) {
                dismissToast();
                return;
            }

            const updatedMessage: ToastMessage = {
                message: officeNotificationService.truncateMessage(message.message),
                link: message.link,
                linkText: message.linkText,
            };

            setToastMessage(updatedMessage);
            setToastType(type);

            if (type === MessageBarType.success) {
                setTimeout(dismissToast, 5000);
            }
            officeNotificationService.showInfoToast(mailboxItem, INFO_TOAST_KEY, updatedMessage.message);
        },
        [officeNotificationService, mailboxItem, dismissToast]
    );

    const actions = useMemo<ToastActionsContextProps>(() => ({ showToast, dismissToast }), [showToast, dismissToast]);
    const data = useMemo<ToastDataContextProps>(() => ({ toastType, toastMessage }), [toastType, toastMessage]);

    return (
        <ToastDataContext.Provider value={data}>
            <ToastActionsContext.Provider value={actions}>{children}</ToastActionsContext.Provider>
        </ToastDataContext.Provider>
    );
}

export const useToast = () => useContext(ToastActionsContext);
export const useToastData = () => useContext(ToastDataContext);
