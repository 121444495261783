import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { CommandBarButton, IRenderFunction, OverflowSet, TooltipHost } from "office-ui-fabric-react";
import * as React from "react";
import { IOverflowSetItemProps } from "office-ui-fabric-react";
import "./ProjectEmailActions.less";
import useProjectEmailActions from "../../../hooks/useProjectEmailActions";
import { ProjectEmail } from "../ProjectEmailComponent";

export interface ProjectEmailActionsProps extends LocalizeContextProps {
    item: ProjectEmail;
}

function ProjectEmailActions(props: ProjectEmailActionsProps) {
    const { isActionButtonDisabled, emailActionReply, emailActionsMore } = useProjectEmailActions(props);

    const onRenderOverflowButton: IRenderFunction<any[]> = (overflowItems) => (
        <CommandBarButton
            data-testid="newforma-projectEmailsMoreButton"
            menuIconProps={{ iconName: "More" }}
            menuProps={{
                items: overflowItems as any,
                calloutProps: {
                    className: "newforma-projectEmailActionCallout",
                },
            }}
        />
    );

    const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => (
        <TooltipHost content={item.title}>
            <CommandBarButton
                disabled={isActionButtonDisabled}
                iconProps={{ iconName: item.icon }}
                onClick={item.onClick}
                className={item.className}
                data-testid={item.className}
            />
        </TooltipHost>
    );

    return (
        <div className="newforma-fileEmailActions">
            <OverflowSet
                items={emailActionReply}
                overflowItems={emailActionsMore}
                onRenderOverflowButton={onRenderOverflowButton}
                onRenderItem={onRenderItem}
            />
        </div>
    );
}

export default withLocalize(ProjectEmailActions);
