import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Label } from "office-ui-fabric-react";
import * as React from "react";
import "./NoResultsFoundComponent.less";

function NoResultsFoundComponent(props: LocalizeContextProps) {
    const defaultEmptyResultPath = "assets/no-filed-emails.svg";

    return (
        <div className="noResult-container" data-testid="noResult">
            <img src={defaultEmptyResultPath} alt="Newforma Konekt no emails" />
            <div className="noResult-content">
                <div>
                    <Label className="noResult-title">
                        {props.translate("SHARED.SEARCH_NO_RESULTS.HEADER")}
                        {}
                    </Label>
                </div>
                <div>
                    <Label className="noResult-message">
                        {props.translate("SHARED.SEARCH_NO_RESULTS.MESSAGE") as string}
                    </Label>
                </div>
            </div>
        </div>
    );
}
export default withLocalize(NoResultsFoundComponent);
