import * as React from "react";

function IssueErrorIcon() {
    return (
        <svg width="132" height="100" viewBox="0 0 132 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M66 96C102.451 96 132 80.7298 132 61.893C132 43.0562 102.451 27.786 66 27.786C29.5492 27.786 0 43.0562 0 61.893C0 80.7298 29.5492 96 66 96Z"
                fill="#F6F6F6"
            />
            <path
                d="M82.3419 37.719C82.3469 37.648 82.3469 37.578 82.3419 37.507C82.3469 37.454 82.3469 37.4 82.3419 37.347C82.3149 37.275 82.2839 37.205 82.2489 37.136L82.1639 37.009V36.95C82.0919 36.861 82.0149 36.777 81.9339 36.696C81.8679 36.636 81.7969 36.583 81.7209 36.536C81.6639 36.482 81.6009 36.433 81.5329 36.392L81.4059 36.316C81.1369 36.16 80.8519 36.035 80.5539 35.945C80.4869 35.923 80.4189 35.906 80.3499 35.894L80.1789 35.852L79.8899 35.784C79.7319 35.753 79.5729 35.73 79.4129 35.717H78.2379L77.8709 35.759H77.4879L77.0879 35.86L76.6369 36.004L76.4579 36.08L76.0829 36.283L65.8549 41.942C65.2429 42.218 64.8269 42.798 64.7649 43.462V51.149C64.8289 50.483 65.2479 49.902 65.8639 49.628L75.8869 43.851L76.2619 43.648C76.6439 43.489 77.0449 43.375 77.4539 43.31H77.4969C77.9209 43.235 78.3519 43.207 78.7829 43.226C79.1839 43.24 79.5839 43.294 79.9749 43.386H80.1449C80.5179 43.479 80.8759 43.621 81.2099 43.809C81.3219 43.875 81.4299 43.948 81.5329 44.028L81.7379 44.189C81.8509 44.286 81.9509 44.396 82.0359 44.518C82.0379 44.535 82.0379 44.552 82.0359 44.569C82.1169 44.68 82.1799 44.802 82.2229 44.932C82.2659 45.06 82.2889 45.194 82.2909 45.329V37.643L82.3419 37.719Z"
                fill="url(#paint0_linear_328_3853-767878)"
            />
            <path
                d="M63.241 55.879L63.292 55.955C63.396 56.106 63.479 56.271 63.539 56.445C63.592 56.62 63.621 56.802 63.624 56.985V48.927C63.612 48.852 63.592 48.778 63.564 48.708C63.529 48.609 63.486 48.513 63.437 48.42C63.4 48.362 63.36 48.306 63.317 48.251V48.175L63.275 48.116C63.183 48 63.083 47.89 62.977 47.787L62.917 47.736L62.636 47.525C62.555 47.456 62.469 47.391 62.381 47.331L62.168 47.153C61.836 46.974 61.488 46.824 61.129 46.706L61.001 46.655L60.737 46.587L60.286 46.478H60.09C59.941 46.446 59.79 46.424 59.638 46.41H58.37C58.205 46.402 58.04 46.402 57.876 46.41H57.382L56.888 46.495H56.564L56.351 46.545L55.815 46.723H55.755C55.488 46.825 55.229 46.946 54.98 47.086C54.148 47.463 53.583 48.252 53.498 49.155V56.926C53.585 56.021 54.154 55.231 54.989 54.857C55.242 54.718 55.504 54.594 55.772 54.485H55.832C56.07 54.401 56.326 54.325 56.59 54.257H56.743C57.007 54.198 57.279 54.147 57.595 54.113H57.714C57.996 54.079 58.281 54.062 58.565 54.063H58.693C58.983 54.063 59.272 54.063 59.545 54.113H59.673C59.894 54.113 60.107 54.181 60.32 54.223L60.771 54.333L61.163 54.451C61.524 54.571 61.874 54.721 62.21 54.899C62.358 54.991 62.501 55.093 62.636 55.203L62.917 55.414C63.039 55.559 63.147 55.715 63.241 55.879Z"
                fill="url(#paint1_linear_328_3853-146405)"
            />
            <path
                d="M100.167 31.367C100.167 31.62 99.579 31.721 99.519 31.975C99.45 32.239 99.35 32.494 99.221 32.735L81.261 67.459C80.722 68.403 79.925 69.176 78.962 69.689C78.499 69.949 78.018 70.175 77.522 70.365H77.412C76.961 70.53 76.5 70.669 76.032 70.778L75.759 70.846C75.27 70.956 74.776 71.041 74.278 71.099H74.048C73.537 71.15 73.017 71.184 72.498 71.184H72.268C71.74 71.184 71.212 71.184 70.684 71.091H70.462C70.054 71.04 69.662 70.964 69.27 70.888C68.878 70.812 68.717 70.762 68.419 70.686C68.121 70.61 67.797 70.517 67.499 70.407L67.107 70.263C66.659 70.091 66.223 69.888 65.804 69.655L23.147 45.582C22.985 45.489 22.849 45.388 22.695 45.287C22.358 45.071 22.042 44.825 21.75 44.552C21.566 44.371 21.392 44.179 21.231 43.977C21.086 43.79 20.956 43.592 20.839 43.386C20.724 43.166 20.635 42.934 20.575 42.694L20.524 42.533C20.473 42.297 20.302 42.271 20.302 42.035V49.172C20.302 49.348 20.32 49.523 20.354 49.696V49.822L20.405 49.991C20.405 50.059 20.405 50.135 20.464 50.211C20.518 50.372 20.586 50.527 20.669 50.676C20.78 50.873 20.905 51.062 21.043 51.242C21.2 51.444 21.374 51.633 21.563 51.807C21.854 52.081 22.171 52.327 22.508 52.542L22.627 52.635L22.994 52.922L65.915 77.418C66.337 77.653 66.775 77.859 67.226 78.034L67.61 78.178L68.044 78.338L68.538 78.456C68.81 78.524 69.083 78.6 69.389 78.651C69.509 78.651 69.611 78.718 69.73 78.735C70.011 78.786 70.292 78.82 70.582 78.853H70.897C71.229 78.853 71.57 78.904 71.91 78.921H74.661C74.967 78.882 75.274 78.831 75.581 78.769L75.879 78.701L76.151 78.634L76.551 78.541C76.884 78.448 77.207 78.338 77.522 78.22H77.642C78.131 77.99 78.601 77.722 79.047 77.418C80.012 76.908 80.812 76.138 81.355 75.196L99.758 39.932C100.011 39.464 100.145 38.942 100.15 38.411V31.324L100.167 31.367Z"
                fill="url(#paint2_radial_328_3853-768490)"
            />
            <path
                d="M88.176 26.231C89.957 25.923 91.778 25.923 93.558 26.231C94.916 26.456 96.225 26.913 97.425 27.583C99.775 28.934 100.788 30.961 99.783 32.904L81.372 68.126C80.833 69.07 80.036 69.843 79.072 70.356C74.947 72.438 70.066 72.438 65.94 70.356L22.994 45.793C22.021 45.282 21.21 44.513 20.652 43.572C19.153 40.751 21.912 37.786 26.817 36.916L88.176 26.231ZM80.639 39.56C82.002 38.774 81.993 37.507 80.639 36.722C79.211 36.012 77.543 35.95 76.066 36.553L75.717 36.73L66.426 42.06C65.08 42.837 65.089 44.104 66.426 44.881C67.844 45.599 69.509 45.667 70.982 45.067L71.348 44.89L80.588 39.56M55.295 51.326C57.362 52.364 59.803 52.364 61.87 51.326C63.675 50.287 63.658 48.598 61.87 47.55C59.813 46.511 57.378 46.511 55.321 47.55C53.515 48.598 53.507 50.287 55.321 51.326"
                fill="url(#paint3_radial_328_3853-672976)"
            />
            <path
                d="M81.4571 38.8C81.2471 39.124 80.9531 39.387 80.6051 39.56L71.3911 44.89L71.0331 45.059C69.5581 45.664 67.8891 45.596 66.4681 44.873C66.1201 44.698 65.8251 44.433 65.6171 44.104C65.8311 43.787 66.1241 43.531 66.4681 43.361L75.7001 38.031L76.0491 37.846C77.5321 37.249 79.2021 37.317 80.6311 38.031C80.9691 38.211 81.2541 38.476 81.4571 38.8ZM63.0711 50.11C62.8091 50.631 62.3891 51.056 61.8701 51.326C59.8001 52.365 57.3561 52.365 55.2871 51.326C54.7581 51.055 54.3311 50.623 54.0691 50.093C54.3301 49.565 54.7541 49.134 55.2781 48.86C57.3361 47.812 59.7781 47.812 61.8361 48.86C62.3741 49.132 62.8071 49.571 63.0711 50.11Z"
                fill="#4688ED"
            />
            <path
                d="M62.9451 19.35V22.772C62.9501 22.815 62.9501 22.858 62.9451 22.901C62.7801 23.701 62.2461 24.375 61.5061 24.715L52.5901 29.849C50.7551 30.88 48.5421 30.972 46.6281 30.097L46.2201 29.858C45.6661 29.601 45.2811 29.078 45.1981 28.472C45.1941 28.435 45.1941 28.397 45.1981 28.36V24.963C45.2401 25.615 45.6361 26.191 46.2281 26.461L46.4751 26.606L46.6371 26.7C47.0791 26.948 47.5601 27.116 48.0591 27.197C49.6041 27.455 51.1911 27.193 52.5731 26.452L59.0111 22.721L61.4981 21.284C61.5631 21.249 61.6261 21.209 61.6851 21.164C62.3851 20.814 62.8591 20.13 62.9451 19.35Z"
                fill="url(#paint4_linear_328_3853-166326)"
            />
            <path
                d="M46.143 29.37V32.836C46.147 32.876 46.147 32.916 46.143 32.955C46.015 33.709 45.524 34.35 44.832 34.667C42.831 35.694 40.462 35.694 38.462 34.667C37.77 34.35 37.279 33.709 37.15 32.955C37.146 32.916 37.146 32.876 37.15 32.836V29.37C37.217 30.098 37.647 30.742 38.291 31.081L38.47 31.193C39.167 31.572 39.93 31.811 40.718 31.894C41.34 31.971 41.97 31.971 42.592 31.894C43.38 31.811 44.144 31.572 44.84 31.193L45.019 31.081C45.657 30.738 46.08 30.094 46.143 29.37Z"
                fill="url(#paint5_linear_328_3853-826073)"
            />
            <path
                d="M62.9451 19.35C62.8591 20.13 62.3851 20.814 61.6851 21.164C61.6261 21.209 61.5631 21.249 61.4981 21.284L59.0191 22.721L52.5811 26.418C51.1991 27.159 49.6131 27.42 48.0681 27.162C47.5681 27.082 47.0871 26.914 46.6461 26.666L46.4841 26.572L46.2371 26.426C45.6551 26.162 45.2611 25.602 45.2061 24.963C45.3061 24.092 45.8611 23.342 46.6631 22.995L55.5791 17.861C57.4141 16.827 59.6291 16.738 61.5401 17.621L61.9491 17.852C62.5271 18.133 62.9081 18.706 62.9451 19.35Z"
                fill="url(#paint6_radial_328_3853-843343)"
            />
            <path
                d="M46.1431 29.37C46.0761 30.098 45.6461 30.742 45.0021 31.081L44.8231 31.193C44.1271 31.572 43.3631 31.811 42.5751 31.894C41.9531 31.971 41.3231 31.971 40.7011 31.894C39.9131 31.811 39.1501 31.572 38.4531 31.193L38.2741 31.081C37.6301 30.742 37.2001 30.098 37.1331 29.37C37.2061 28.559 37.7111 27.851 38.4531 27.522C40.4531 26.495 42.8231 26.495 44.8231 27.522C45.5651 27.851 46.0701 28.559 46.1431 29.37Z"
                fill="url(#paint7_radial_328_3853-979341)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_328_3853-767878"
                    x1="76.4492"
                    y1="49.28"
                    x2="66.3066"
                    y2="31.1947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#808080" />
                    <stop offset="0.18" stopColor="#848484" />
                    <stop offset="0.55" stopColor="#C2C2C2" />
                    <stop offset="0.81" stopColor="#E8E8E8" />
                    <stop offset="0.95" stopColor="#F7F7F7" />
                    <stop offset="1" stopColor="#F7F7F7" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_328_3853-146405"
                    x1="58.5569"
                    y1="53.7248"
                    x2="58.5569"
                    y2="38.9179"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#808080" />
                    <stop offset="0.6" stopColor="#D3D3D3" />
                    <stop offset="0.89" stopColor="#F7F7F7" />
                    <stop offset="1" stopColor="#F7F7F7" />
                </linearGradient>
                <radialGradient
                    id="paint2_radial_328_3853-768490"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(27.4615 43.8) scale(57.4017 96.3115)"
                >
                    <stop stopColor="#CAD8EC" />
                    <stop offset="0.6" stopColor="#9CBCEC" />
                    <stop offset="0.72" stopColor="#99BAEC" />
                    <stop offset="0.8" stopColor="#90B5EC" />
                    <stop offset="0.87" stopColor="#81ACEC" />
                    <stop offset="0.93" stopColor="#6C9FED" />
                    <stop offset="0.98" stopColor="#518EED" />
                    <stop offset="1" stopColor="#4688ED" />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_328_3853-672976"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(57.9071 20.0059) scale(132.272 230.238)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#72A3ED" />
                </radialGradient>
                <linearGradient
                    id="paint4_linear_328_3853-166326"
                    x1="45.4022"
                    y1="25.0658"
                    x2="50.3245"
                    y2="25.0658"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FD5058" />
                    <stop offset="1" stopColor="#FD3B45" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_328_3853-826073"
                    x1="37.4396"
                    y1="32.4164"
                    x2="40.1051"
                    y2="32.4164"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FD646C" />
                    <stop offset="1" stopColor="#FD3B45" />
                </linearGradient>
                <radialGradient
                    id="paint6_radial_328_3853-843343"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(39.9127 5.0249) scale(30.8215 53.3798)"
                >
                    <stop stopColor="#FEC5C8" />
                    <stop offset="1" stopColor="#FEB8BB" />
                </radialGradient>
                <radialGradient
                    id="paint7_radial_328_3853-979341"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(39.9068 5.02491) scale(30.8194 53.3947)"
                >
                    <stop stopColor="#FEB8BB" />
                    <stop offset="1" stopColor="#FEB5B9" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default IssueErrorIcon;
