import * as React from "react";
import { DefaultButton, Label, Spinner, SpinnerSize } from "office-ui-fabric-react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./HubSelectorDashboard.less";
import { ConfigurationService } from "../../services/ConfigurationService";
import { IHub } from "../../models/Hub";
import HubItemEach from "./HubItemEach";
import { AppPage } from "../../models/AppPage";
import { StorageWrapper } from "../../services/StorageWrapper";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { LocalStorageKeys } from "../../models/StorageKeys";
import { UserInfo } from "../../models/PimModels";
import { Logger } from "../../services/Logger";
import { useAppState } from "../../store/AppProvider";

export interface HubSelectorDashboardProps extends LocalizeContextProps {
    children: string | JSX.Element | JSX.Element[];
    configService: ConfigurationService;
    hubs?: IHub[];
    onLogOut: () => void;
    navigationPage: AppPage;
    storageWrapper: StorageWrapper;
    officeWrapper: OfficeWrapper;
    logger: Logger;
    userEmail?: string;
}

function HubSelectorDashboardComponent(props: HubSelectorDashboardProps) {
    const {
        appState: { selectedHub },
        actions: { setSelectedHub },
    } = useAppState();
    const isSameUser = (): boolean => {
        const outlookUserEmail = props.officeWrapper.userProfileEmailAddress;
        const addinUser = props.storageWrapper.loadLocalStorage(LocalStorageKeys.addInUser);

        if (addinUser) {
            const { email: addinUserEmail }: UserInfo = JSON.parse(addinUser);
            return addinUserEmail.toLocaleLowerCase() === outlookUserEmail.toLocaleLowerCase();
        }

        return false;
    };

    if (!props.hubs) {
        return (
            <div className="spinner-wrapper">
                <Spinner size={SpinnerSize.large} />
            </div>
        );
    }

    if (props.hubs && !isSameUser()) {
        props.logger.error("Not same user");
        props.onLogOut();
    }

    if (props.hubs.length === 0) {
        return (
            <div id="NoHubAvailable" className="bimtrack-EmptyHubsComponent">
                <img src="assets/bimtrack-empty-hub.svg" alt="Newforma Konekt empty hubs" />
                <div className="bimtrack-emptyHubsContent">
                    <div>
                        <Label className="bimtrack-emptyHubTitle">
                            {props.translate("APP.EMPTY_HUBS.TITLE") as string}
                        </Label>
                    </div>
                    <div>
                        <Label className="bimtrack-emptyHubMessage">
                            {props.translate("APP.EMPTY_HUBS.MESSAGE") as string}
                        </Label>
                    </div>
                    <div className="bimtrack-emptyHubLink">
                        <a href={props.configService.webAppURL} className="bimtrack-emptyHubLinkLabel" target="_blank">
                            {props.translate("APP.EMPTY_HUBS.OPEN_BIMTRACK") as string}
                            <span>&nbsp;</span>
                            <img src="assets/external-link.svg" alt="Newforma Konekt external link icon" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    if (!selectedHub) {
        return (
            <div className="hub-selector-dashboard-body">
                <div className="hub-selector-dashboard-header">Hubs</div>
                <div className="hub-selector-dashboard-sub-text">
                    {props.translate("APP.HUB_SELECTOR_SUB_HEADER") as string}
                </div>

                <div className="hub-selector-dashboard-contents">
                    <div className="hub-selector-dashboard-item-wrapper">
                        {props.hubs.map((hub) => (
                            <HubItemEach
                                key={hub.key}
                                hub={hub}
                                setSelectedHub={() => {
                                    setSelectedHub(hub);
                                }}
                            />
                        ))}
                    </div>
                </div>
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        className="hub-selector-dashboard-button-signout"
                        data-testid="newforma-footerButton"
                        onClick={props.onLogOut}
                        text={props.translate("APP.COMMANDBAR.SIGN_OUT") as string}
                    />
                </div>
            </div>
        );
    }

    return <React.Fragment>{props.children}</React.Fragment>;
}

export default withLocalize(HubSelectorDashboardComponent);
