import { useAppService } from "../services/AppServiceProvider";
import { useQueryClient } from "@tanstack/react-query";
import { useAppState } from "../store/AppProvider";
import { Email } from "@newforma/platform-client-api-sdk";

export interface UseProjectEmailAddressResult {
    actions: {
        fetchProjectEmailSettings: () => Promise<(Email.ProjectEmailSettings & { projectEmail: string | null }) | null>;
    };
}

const useProjectEmailAddress = (): UseProjectEmailAddressResult => {
    const {
        services: { bimProjectsApiService, configService },
    } = useAppService();
    const queryClient = useQueryClient();
    const {
        appState: { selectedHub, selectedProject },
    } = useAppState();

    const fetchProjectEmailSettings = async () => {
        if (!selectedProject || !selectedHub) {
            return null;
        }

        const projectEmailSettings = await queryClient.fetchQuery({
            queryKey: ["projectEmailSettings", selectedHub.key, selectedProject.key],
            queryFn: () => bimProjectsApiService.fetchProjectEmailSettings(selectedHub, selectedProject),
        });

        return {
            ...projectEmailSettings,
            projectEmail: projectEmailSettings.projectEmailAddressPrefix
                ? `${projectEmailSettings.projectEmailAddressPrefix}@${selectedHub.name}.${configService.email}`
                : null,
        };
    };

    return {
        actions: {
            fetchProjectEmailSettings,
        },
    };
};

export default useProjectEmailAddress;
