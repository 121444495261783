import { Email } from "@newforma/platform-client-api-sdk";
import { IPersonaProps } from "office-ui-fabric-react";
import { IFiltersState } from "../hooks/useProjectEmailSearch";

export class EmailFiltersMapping {
    static toRequest(filters: IFiltersState): Email.SearchRequestFilters {
        const getUserEmail = (user: IPersonaProps): string => user.secondaryText ?? "";

        return {
            hasAttachments: filters.hasAttachments,
            ...(filters.selectedFromUsers?.at(0) && {
                sender: getUserEmail(filters.selectedFromUsers?.at(0) as IPersonaProps),
            }),
            ...(filters.selectedToUsers?.length && {
                recipients: filters.selectedToUsers.map((user) => getUserEmail(user)),
            }),
            ...((filters.receivedDateFrom || filters.receivedDateTo) && {
                receivedDate: {
                    ...(filters.receivedDateFrom && {
                        start: filters.receivedDateFrom.toISOString(),
                    }),
                    ...(filters.receivedDateTo && {
                        end: filters.receivedDateTo.toISOString(),
                    }),
                },
            }),
        };
    }
}
