export class Logger {
    private logs: string[] = [];
    private readonly maximumLogLength = 1000;

    info(message: string): void {
        this.log("INFO", message);
    }

    warning(message: string): void {
        this.log("WARN", message);
    }

    error(message: string): void {
        this.log("ERROR", message);
    }

    getLogs(): string[] {
        return this.logs;
    }

    private getTimestamp(): string {
        return `[${new Date().toUTCString()}]`;
    }

    private log(prefix: string, message: string): void {
        const completeMessage = `${this.getTimestamp()} ${prefix}: ${message}`;

        if (this.logs.length >= this.maximumLogLength) {
            this.logs.shift();
        }
        this.logs.push(completeMessage);
    }
}
